import React, { useState, useEffect } from "react";
import { useSelector } from 'react-redux';
import VButton from '../Button/Button'
// 多语言
import { useTranslation } from 'react-i18next';
import "./DropDialog.css";
import {
  useFetchDrop,
} from '../../features/vault/redux/hooks';
import { useConnectWallet } from "features/home/redux/connectWallet.js";
import { _IS_ETH_, _IS_HECO_ } from '../../features/configure/chainsParamsConfig'
export default function DropDialog({ setDropFlag }) {
  const theme = useSelector(state => state.common.theme);
  // 地址
  const [shortAddress, setShortAddress] = useState('');
  const { address, connected } = useConnectWallet();
  // 领取空投弹框Flag
  const { t } = useTranslation();
  // 待领取 COW
  const { reward, dropPending, onDrop } = useFetchDrop();
  // 千分位格式化
  function formatThousands(num) {
    return num.toString().replace(/\d+/, function (n) { // 先提取整数部分
      return n.replace(/(\d)(?=(\d{3})+$)/g, function ($1) { // 对整数部分添加分隔符
        return $1 + ",";
      });
    });
  }

  // format 用户地址
  useEffect(() => {
    if (!connected) return;
    if (address.length < 11) {
      setShortAddress(address)
    } else {
      setShortAddress(`${address.slice(0, 6)}...${address.slice(-6)}`)
    }
  }, [address, connected])

  return (
    <>
      <div className='home__dialog'>
        <div className="home__dialog-inner home__dialog-deposite">
          <div className="home__dialog__header">
            {t("Btn-Drop")}
            <span className="home__dialog__header__close" onClick={() => setDropFlag(false)}></span>
          </div>
          <div className="home__dialog__fields">
            <div className="drop__title">
              <span>{t("Drop-Text1")}</span>
              <div style={{ display: "flex", alignItems: "center" }}>
                <span className={`drop__amount drop__amount-${theme}`}>{formatThousands(Number(reward).toFixed(4))}</span>
                <span style={{ marginLeft: "5px" }}>COW</span>
              </div>
            </div>
            <div className="drop__center">
              <div className="drop__wrap">
                <div className="center__left">
                  <p>{t("Drop-Text2")}</p>
                  <p className="left__amount">{shortAddress}</p>
                </div>
                <div className="center__right">
                  <VButton
                    className="home__dialog__option"
                    loading={dropPending}
                    disabled={!Number(reward)}
                    onClick={() => onDrop()}>
                    {dropPending ? "" : t("Btn-Drop")}
                  </VButton>
                </div>
              </div>
            </div>
            {
              _IS_HECO_ ?
                <>
                  <p className="drop__desc">{t("Drop-Time")}<span>{t("heco-airdrop-time-1")}</span></p>
                  <p className="drop__desc">{t("Drop-Rule")}<span>{t("heco-airdrop-time-2")}</span>
                  <br/><a href={'https://twitter.com/coinwind_com/status/1419948365693931520'} rel='noopener noreferrer' target="_blank">
                    https://twitter.com/coinwind_com/status/1419948365693931520
                  </a></p>
                </>
               :
                <>
                  <p className="drop__desc">{t("Drop-Time")}<span>{_IS_ETH_ ? t("Drop-Time1-eth") : t("Drop-Time1")}</span></p>
                  <p className="drop__desc">{t("Drop-Rule")}<span>{_IS_ETH_ ? <>{t("Drop-Rule1-eth")}<br/><a href={'https://twitter.com/coinwind_com/status/1423199163353763842'}  className="drop__desc" rel='noopener noreferrer' target="_blank">
                    https://twitter.com/coinwind_com/status/1423199163353763842
                  </a></>  : t("Drop-Rule1")}</span></p>
                </>
            }
          </div>
        </div>
      </div>
    </>
  );
}
