import { DelayPool } from "../configure";
import { enqueueSnackbar } from '../common/redux/actions';
import {gasMap,_CURRENT_NETWORK_ID_} from '../configure/chainsParamsConfig'
export const withdrawDui = async ({web3, address,isAll, amount, contractAddress, dispatch,contractToken,isWithdrawIncome}) => {
  // console.log(`=====================================withdraw begin=====================================`)
  const contract = new web3.eth.Contract(DelayPool, contractAddress);
  // contract.options.gas = 8600000;
  contract.options.gas = gasMap[_CURRENT_NETWORK_ID_] ? (_CURRENT_NETWORK_ID_ === 56 ? gasMap[_CURRENT_NETWORK_ID_].withdraw : gasMap[_CURRENT_NETWORK_ID_]) : 8600000;
  // console.log(`=====================================withdraw=====================================`)
  const data = await _withdraw({web3, contract, isAll, amount, address, dispatch,contractToken,isWithdrawIncome});
  // console.log(`=====================================withdraw success=====================================`)
  return data;
}

const _withdraw = ({web3, contract, address,isAll, amount, dispatch,contractToken,isWithdrawIncome}) => {
  // console.log(isAll&&!isWithdrawIncome)
  // console.log(contractToken)
  return new Promise((resolve, reject) => {
    if (isAll&&!isWithdrawIncome) {
      contract.methods.withdrawAll(contractToken).send({ from: address }).on('transactionHash', function(hash){
        dispatch(enqueueSnackbar({
          message: hash,
          options: {
            key: new Date().getTime() + Math.random(),
            variant: 'success'
          },
          hash
        }));
      })
      .on('receipt', function(receipt){
        console.log('withdrawAll',receipt);
        resolve(receipt)
      })
      .on('error', function(error) {
        console.log(error)
        reject(error)
      })
      .catch((error) => {
        console.log(error)
        reject(error)
      })
    } else {
      // 如果是提取收益isWithdrawIncome
      contract.methods.withdrawAll(contractToken).send({ from: address }).on('transactionHash', function(hash){
        dispatch(enqueueSnackbar({
          message: hash,
          options: {
            key: new Date().getTime() + Math.random(),
            variant: 'success'
          },
          hash
        }));
      })
      .on('receipt', function(receipt){
        console.log('withdraw',receipt);
        resolve(receipt)
      })
      .on('error', function(error) {
        console.log(error)
        reject(error)
      })
      .catch((error) => {
        console.log(error)
        reject(error)
      })
    }
    
  })
}