// import { useCallback } from 'react';
import { useDispatch,useSelector } from 'react-redux';
import { _IS_CURRENT_NETWORK_ID_SUPPORTED_,_IS_BSC_ } from '../../configure/chainsParamsConfig';
import { AggregateHubNew } from 'features/configure/abi';
import {
  earnContractAddress as EARN_CONTRACT_ADDRESS,
  aggContract as AGG_CONTRACT
} from "../../configure/pools_LP";
export function useFetchPoolsInfo() {
  // const pools = useSelector(state => state.lp.pools);
  const dispatch = useDispatch();
  async function fetchPoolsInfo({ address, web3,pools }) {
    if (!web3 || !_IS_CURRENT_NETWORK_ID_SUPPORTED_) {
      return;
    }
    // console.log(AggregateHubNew,AGG_CONTRACT)
    const contract = new web3.eth.Contract(AggregateHubNew, AGG_CONTRACT);
    // const newPools = [];
    for (let pool of pools) {
      const poolInfo = await contract.methods.getPoolInfo(EARN_CONTRACT_ADDRESS, pool.tokenAddress, address, false).call().catch(e => {
        console.log(e);
      });
      // console.log(poolInfo,AGG_CONTRACT,EARN_CONTRACT_ADDRESS, pool.tokenAddress, address,'22222222')
      // console.log(poolInfo)
      // console.log(poolInfo['allowance'])
      // poolInfo['allowance'] = Number(poolInfo['allowance'])
      // poolInfo[allowance]=
      // console.log(poolInfo['allowance'])
      Object.assign(pool,{...poolInfo,allowance:poolInfo['allowance']})
      // const returnLpTokenInfo = await contract.methods.getLpTokenInfo(pool.tokenAddress, 0, _IS_BSC_ ? 1 : 0).call().catch(0);
      // newPools.push({ ...pool, ...poolInfo });
    }
    
    dispatch({
      type:'UPDATE_POOLS',
      data:[...pools]
    })
  }
  return {
    fetchPoolsInfo
  }
}
