import React,{useState} from 'react';
import './Questionss.css'
export default props => {
    const {value,label,type=0} = props;
    const [show,setShow] = useState(false)
    function toggleShow(e){
        e.stopPropagation()
        setShow(!show)
    }
    return <div className="g-pop-tips"  label={label} onClick={e=>e.stopPropagation()} onMouseOver={toggleShow} onMouseOut={toggleShow}>
        {show?<div className="g-pop-tips__inner_two-xuss">{value}</div>:null}  
    </div>
}