/* eslint-disable */
import React, { useState, useEffect, useCallback, memo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import BigNumber from "bignumber.js";
import { byDecimals, isNumber } from 'features/helpers/bignumber';
// import { isMobile } from 'features/helpers/utils';
import Web3Modal from "web3modal";
import WalletConnectProvider from "@walletconnect/web3-provider";
import Button from '../../../components/Button/Button'
import Dialog from '../../../components/Dialog/Dialog'
import SectionQuestion from "../../../components/Question/Question";
//  hooks
import { useConnectWallet } from '../../home/redux/hooks';
import '../jss/sections/SectionPools.css';
import '../jss/sections/SectionFilter.css'
import {
  usePoolList,
  useFetchApproval,
  useFetchDeposit,
  useFetchWithdraw,
  usePendingNum,
  useAllRate,
  useCoinRate,
  useNeedMigrator,
  usePoolListNew,
  useMigratorV2Pool,
  useWithdraw,
  useDeposit,
  useWithdrawAll,
  useDepositAll
} from '../redux/hooks';
import { useSnackbar } from 'notistack';
// import SectionTitle from "./SectionTitle";
import SectionTotal from "./SectionTotal";
import SectionTitle from "./SectionTitle";
import SectionFilter from "./SectionFilter";
import SectionFilters from "./SectionFilters";
// import SectionCountDown from "./SectionCountDown";
import { inputLimitPass, inputFinalVal, isEmpty } from 'features/helpers/utils';
import { useBasicInfo } from 'features/configure/pools';
import { txUrlMap, _NETWORK_ID_MAP_NAME_, cowAddressMap, _IS_ETH_,_IS_HECO_,_IS_BSC_ } from '../../configure/chainsParamsConfig';
import Switch from '../../../components/Switch/Switch'
import { isTSMappedType } from '@babel/types';
import { Popover } from '@material-ui/core';
export default memo(function SectionPools() {
  const { t, i18n } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { web3, address, networkId, connected, connectWallet, connectWalletPending } = useConnectWallet();
  const theme = useSelector(state => state.common.theme);
  const [price, setPrice] = useState();
  // // Tab 切换
  const [tabActive, setTabActive] = useState(1);
  const [listData, setListData] = useState([]);
  // cow复利 取 cow策略下 cow池子cow单利字段
  const [cowCompoundApy, setCowCompoundApy] = useState("");
  // 赋值默认值
  const { pools,delayAddress } = useBasicInfo();
  const _CURRENT_NETWORK_ID_ = Number(window.localStorage.getItem("NETWORK_ID_USE"));
  const {withdraw} = useWithdraw()
  const {deposit} = useDeposit()
  const {withdrawAll} = useWithdrawAll()
  const {depositAll} = useDepositAll()

  const [isCun, setIsCun] = useState(false);

  // 连接钱包弹框
  function connectHandler() {
    const newModal = new Web3Modal({
      network: process.env.NETWORK ? process.env.NETWORK : "mainet",
      cacheProvider: true,
      providerOptions: {
        injected: {
          display: {
            name: "MetaMask",
            description: t('Home-BrowserWallet')
          },
        }
        ,
        walletconnect: {
          package: WalletConnectProvider,
          options: {
            infuraId: process.env.INFURA_ID
          }
        }
      }
    })
    connectWallet(newModal);
  }
  useEffect(() => {
    const temp = [];
    for (let pool of pools) {
      const poolInfo = {
        token: pool.tokenAddress,
        amount: 0,
        balance: 0,
        allowance: 0,
        mdxApy: 0,
        cowApy: 0,
        mdxCompoundApy: 0,
        mdxReward: 0,
        cowReward: 0,
        totalAmount: 0,
        totalAmountLimit: 0,
      }
      temp.push({ ...pool, ...poolInfo });
    }
    setListData(temp);
    // if(_CURRENT_NETWORK_ID_===128){
    //   setCowCompoundApy(temp[3].cowApy);
    // }else  if(_CURRENT_NETWORK_ID_===56){
    //   setCowCompoundApy(temp[3].cowApy);
    // }else  if(_CURRENT_NETWORK_ID_===1){
    //   setCowCompoundApy(temp[0].cowApy);
    // }
    
  }, [tabActive])
  usePendingNum((pools)=>{
    // console.log(pools)
    setIsCun(true)
    setListData([...pools]);
  })
  // 授权 存入 提取
  const { fetchApproval, fetchApprovalPending } = useFetchApproval();
  const { fetchDeposit, fetchDepositPending } = useFetchDeposit();
  const { fetchWithdraw, fetchWithdrawPending, fetchWithdrawIncomePending } = useFetchWithdraw();
  const [depositedBalance, setDepositedBalance] = useState({});
  const [withdrawAmount, setWithdrawAmount] = useState({});
  // 打开对话框
  const openDialogHandler = (type, index) => {
    listData[index].dialog.opened = true;
    if (type === 'widthdraw') {
      listData[index].dialog.widthdrawOpened = true
    }
    if (type === 'deposit') {
      listData[index].dialog.depositOpened = true
    }
    // 深拷贝 更新视图
    setListData([...listData]);
  }

  // 关闭对话框
  const closeDialogHandler = (type, index) => {
    listData[index].dialog.opened = false;
    if (type === 'widthdraw') {
      listData[index].dialog.widthdrawOpened = false
    }
    if (type === 'deposit') {
      listData[index].dialog.depositOpened = false
    }
    // 深拷贝 更新视图
    setListData([...listData]);
  }
  // 所有状态的对话操作
  const [dialogCtrl, setDialogCtrl] = useState({
    opened: false,
    type: 'success',
    // "我知道了"
    buttonText: t('v2-ui-1'),
    // 授权成功
    contentText: t('v2-ui-2'),
    buttonClickCallback: () => {
      setDialogCtrl({ ...dialogCtrl, opened: false })
    }
  })
  // 输入框change
  const changeDetailInputValue = (type, index, total, tokenDecimals, event) => {
      total = Number(total)
      let value = event.target.value;
      if (!inputLimitPass(value, tokenDecimals)) {
        return;
      }
      if (isNaN(Number(value))) {
        value = ''
      }
      switch (type) {
        case 'depositedBalance':
          setDepositedBalance({
            ...depositedBalance,
            [index]: inputFinalVal(value, total, tokenDecimals),
            // [`slider-${index}`]: sliderNum,
          });
          break;
        case 'withdrawAmount':
          setWithdrawAmount({
            ...withdrawAmount,
            [index]: inputFinalVal(value, total, tokenDecimals),
            // [`slider-${index}`]: sliderNum,
          });
          break;
        default:
          break;
      }
  }
  // 授权
  const onApproval = (pool, index, event) => {
      event.stopPropagation();
      fetchApproval({
        address,
        web3,
        tokenAddress: pool.dtoken,
        contractAddress: pool.delayAddress,
        index
      }).then(
        () => {
          setIsDia(false)
          setIsDiaS(false)
          closeDialogHandler('deposit', index);
          setDialogCtrl({
            ...dialogCtrl,
            opened: true,
            type: 'success',
            contentText: t("Approval-success"),
            // 确认、存入
            cancelButtonText: t('Farm-Dialog-Confirm'),
            buttonText: t('Vault-DepositButton'),
            buttonClickCallback: () => {
              setDialogCtrl({ ...dialogCtrl, opened: false });
              openDialogHandler('deposit', index);
            },
            cancelButtonCallback: () => {
              setDialogCtrl({ ...dialogCtrl, opened: false })
            },
          })
        }
      ).catch(
        error => {
          const errorMessage = JSON.stringify(error);
          const isEthError = _IS_ETH_ && errorMessage.includes('Failed to check for transaction receipt')
          setDialogCtrl({
            ...dialogCtrl,
            opened: true,
            type: isEthError ? 'success' : 'warning',
            contentText: isEthError ? t("error-message") : t("Approval-error"),
            // 重试
            buttonText: isEthError ? t('v2-ui-1') : t('v2-ui-3')
          })
        }
      )
  }
  const [isDia,setIsDia] = useState(false)
  const [isDiaS,setIsDiaS] = useState(false)
  const onDeposits = async(pool, index,balanceSingle,isAll,event)=>{
    // let aa = byDecimals(balanceSingle, 0).toFormat(pool.tokenDecimals)
    // console.log(isNaN(balanceSingle))
    // console.log(balanceSingle,balanceSingle*1e18,aa)
    let amountValue;
    if (isAll) {
      setDepositedBalance({
        ...depositedBalance,
        [index]: byDecimals(balanceSingle, 0).toFormat(pool.tokenDecimals),
        // [`slider-${index}`]: 100,
      })
      amountValue=balanceSingle.toString(10)
      // amountValue=byDecimals(balanceSingle, 0).toFormat(pool.tokenDecimals)
    }else{
      amountValue = depositedBalance[index] ? depositedBalance[index].replace(',', '') : depositedBalance[index];
    }
    // let amountValue = depositedBalance[index] ? depositedBalance[index].replace(',', '') : depositedBalance[index];
    if (amountValue == undefined) {
      amountValue = '0';
    }
    let sheng = balanceSingle-amountValue
    // console.log(amountValue,balanceSingle,sheng)
    
    if(sheng>=0.001){
      let amount=new BigNumber(amountValue).multipliedBy(new BigNumber(10).exponentiatedBy(pool.tokenDecimals)).toString(10)
      // console.log(amount)
      // console.log(pool, index,event,address)
      // console.log(amount)
      await deposit(pool.tokenAddress,amount).then(
        (transactionHash) => {
          // console.log(networkId,transactionHash)
          setDepositedBalance({ ...depositedBalance, [index]: '' })
          setDialogCtrl({
            ...dialogCtrl,
            opened: true,
            type: 'success',
            contentText: t("Deposit-success"),
            // 查看我的交易
            buttonText: <a href={txUrlMap[networkId] + transactionHash} target="_blank">{t("v2-ui-4")}</a>,
            cancelButtonText: t("v2-ui-1"),
            cancelButtonCallback: () => {
              setDialogCtrl({ ...dialogCtrl, opened: false })
            },
            buttonClickCallback: () => {
              setDialogCtrl({ ...dialogCtrl, opened: false });
            }
          })
        }
      ).catch(
        error => {
          console.log(error)
          {
            const errorMessage = JSON.stringify(error);
            const isEthError = _IS_ETH_ && errorMessage.includes('Failed to check for transaction receipt');
            setDepositedBalance({ ...depositedBalance, [index]: '' })
            setDialogCtrl({
              ...dialogCtrl,
              opened: true,
              type: isEthError ? 'success' : 'warning',
              contentText: isEthError ? t("error-message") : t("Deposit-error"),
              buttonText: isEthError ? t('v2-ui-1') : t("v2-ui-3")
            })
          }
        }
      )
    }else{
      await depositAll(pool.tokenAddress).then(
        (transactionHash) => {
          // console.log(networkId,transactionHash)
          setDepositedBalance({ ...depositedBalance, [index]: '' })
          setDialogCtrl({
            ...dialogCtrl,
            opened: true,
            type: 'success',
            contentText: t("Deposit-success"),
            // 查看我的交易
            buttonText: <a href={txUrlMap[networkId] + transactionHash} target="_blank">{t("v2-ui-4")}</a>,
            cancelButtonText: t("v2-ui-1"),
            cancelButtonCallback: () => {
              setDialogCtrl({ ...dialogCtrl, opened: false })
            },
            buttonClickCallback: () => {
              setDialogCtrl({ ...dialogCtrl, opened: false });
            }
          })
        }
      ).catch(
        error => {
          console.log(error)
          {
            const errorMessage = JSON.stringify(error);
            const isEthError = _IS_ETH_ && errorMessage.includes('Failed to check for transaction receipt');
            setDepositedBalance({ ...depositedBalance, [index]: '' })
            setDialogCtrl({
              ...dialogCtrl,
              opened: true,
              type: isEthError ? 'success' : 'warning',
              contentText: isEthError ? t("error-message") : t("Deposit-error"),
              buttonText: isEthError ? t('v2-ui-1') : t("v2-ui-3")
            })
          }
        }
      )
    }
    
  }
  const onWithdraws = (pool, index,amount,event)=>{
    // console.log(pool, index,event,address)
    // console.log(amount)
    let amounts=new BigNumber(formatThousands(amount.toFixed(10))).multipliedBy(new BigNumber(10).exponentiatedBy(pool.tokenDecimals)).toString(10)  
    // console.log(amounts,formatThousands(amount.toFixed(20)))//666700000000
    // console.log(amounts)
    // withdrawAll(pool.tokenAddress).then(
    //   (transactionHash) => {
    //     setWithdrawAmount({ ...withdrawAmount, [index]: '' })
    //     setDialogCtrl({
    //       ...dialogCtrl,
    //       opened: true,
    //       type: 'success',
    //       contentText:t("v2-ui-5"),
    //       buttonText: <a href={txUrlMap[networkId] + transactionHash} target="_blank">{t("v2-ui-4")}</a>,
    //       cancelButtonText: t("v2-ui-1"),
    //       cancelButtonCallback: () => {
    //         setDialogCtrl({ ...dialogCtrl, opened: false })
    //       },
    //       buttonClickCallback: () => {
    //         setDialogCtrl({ ...dialogCtrl, opened: false });
    //       }
    //     })
    //   }
    // ).catch(
    //   error => {
    //     const errorMessage = JSON.stringify(error);
    //     const isEthError = _IS_ETH_ && errorMessage.includes('Failed to check for transaction receipt');
    //     setWithdrawAmount({ ...withdrawAmount, [index]: '' })
    //     setDialogCtrl({
    //       ...dialogCtrl,
    //       opened: true,
    //       type: isEthError ? 'success' : 'warning',
    //       contentText: isEthError ? t("error-message") : t("v2-ui-6"),
    //       buttonText: isEthError ? t('v2-ui-1') : t("v2-ui-3")
    //     })
    //   }
    // )
    fetchWithdraw({
      address,
      web3,
      isAll:false,
      // amount: new BigNumber(amountValue).multipliedBy(new BigNumber(10).exponentiatedBy(pool.tokenDecimals)).toString(10),
      contractAddress: delayAddress,
      contractToken: pool.tokenAddress,
      index,
      isWithdrawIncome:true
    }).then(
      ({ transactionHash }) => {
        setWithdrawAmount({ ...withdrawAmount, [index]: '' })
        setDialogCtrl({
          ...dialogCtrl,
          opened: true,
          type: 'success',
          contentText: t("v2-ui-5"),
          buttonText: <a href={txUrlMap[networkId] + transactionHash} target="_blank">{t("v2-ui-4")}</a>,
          cancelButtonText: t("v2-ui-1"),
          cancelButtonCallback: () => {
            setDialogCtrl({ ...dialogCtrl, opened: false })
          },
          buttonClickCallback: () => {
            setDialogCtrl({ ...dialogCtrl, opened: false });
          }
        })
      }
    ).catch(
      error => {
        const errorMessage = JSON.stringify(error);
        const isEthError = _IS_ETH_ && errorMessage.includes('Failed to check for transaction receipt');
        setWithdrawAmount({ ...withdrawAmount, [index]: '' })
        setDialogCtrl({
          ...dialogCtrl,
          opened: true,
          type: isEthError ? 'success' : 'warning',
          contentText: isEthError ? t("error-message") : t("v2-ui-6"),
          buttonText: isEthError ? t('v2-ui-1') : t("v2-ui-3")
        })
      }
    )
  }
  // 是否最大
  const isMoreDepostLimit = (value, depostLimit) => {
      if (isEmpty(value) || depostLimit == 0 || value < depostLimit) {
        return false
      }
      return true;
  }
  // 格式化数据
  const forMat = (number, precision = 2) => {
    return new BigNumber(
      number
    ).multipliedBy(
      new BigNumber(10000)
    ).dividedToIntegerBy(
      new BigNumber(1)
    ).dividedBy(
      new BigNumber(10000)
    ).toNumber().toFixed(precision)
  }
  // 千分位格式化
  function formatThousands(num) {
    return num.toString().replace(/\d+/, function (n) { // 先提取整数部分
      return n.replace(/(\d)(?=(\d{3})+$)/g, function ($1) { // 对整数部分添加分隔符
        return $1 + ",";
      });
    });
  }
  // 过滤参与打项目
  const [isPartake, setIsPartake] = useState(false);
  const poolsMap = listData.map((pool, index) => {
    const totalAmount = formatThousands(byDecimals(pool.totalAmount,pool.tokenDecimals).toNumber().toFixed(2));//总发行量
    const depositAmount = formatThousands(byDecimals(pool.depositAmount,pool.tokenDecimals).toNumber().toFixed(2));//已开放兑换数量
    const lockDtokenAmount = byDecimals(pool.lockDtokenAmount,pool.tokenDecimals).toNumber();//已存入数量
    const withdrawAmounts = byDecimals(pool.withdrawAmount,pool.tokenDecimals).toNumber();//已兑换数量
    const availableAmount = byDecimals(pool.availableAmount,pool.tokenDecimals).toNumber();//可兑换数量
    const dtokenAmount =byDecimals(pool.dtokenAmount,pool.tokenDecimals).toNumber();//余额
    // console.log(lockDtokenAmount,withdrawAmounts,formatThousands(lockDtokenAmount.toFixed(10)),formatThousands(withdrawAmounts.toFixed(10)))
    // const daiAmount= formatThousands(lockDtokenAmount.toFixed(10))-formatThousands(withdrawAmounts.toFixed(10))//待兑换数量
    const daiAmount= lockDtokenAmount-withdrawAmounts//待兑换数量
    // console.log(depositAmount,lockDtokenAmount,withdrawAmounts,availableAmount,dtokenAmount)
    // console.log(lockDtokenAmount,withdrawAmounts)
    // console.log(new BigNumber(formatThousands(withdrawAmounts.toFixed(20))).multipliedBy(new BigNumber(10).exponentiatedBy(pool.tokenDecimals)).toString(10))
    // console.log(formatThousands(lockDtokenAmount.toFixed(10)))
    // console.log(formatThousands(withdrawAmounts.toFixed(10)))
    // console.log(daiAmount,formatThousands(daiAmount.toFixed(4)))
    // console.log(dtokenAmount)
    // console.log(typeof(formatThousands(availableAmount.toFixed(4))))
    // if(pool.name==='DUSDC'){
    //   console.log(typeof(availableAmount),availableAmount,typeof(formatThousands(availableAmount.toFixed(10))),formatThousands(availableAmount.toFixed(10)),Number(formatThousands(availableAmount.toFixed(10)))>0)
    // }
      return (
        <div className="pools__item" key={index}>
          {/* <div className={`pools__item${isPartake&&!Number(finaCompoundEarned)?' is-hide':''}`} key={index}> */}
          <div className="pools__box">
            <ul className="pools__rows">
              {/* 币种名称+ICON */}
              <li className="pools__row-1">
                <div className="pools__logo-name">
                  <img
                    className="pools__coin-logo"
                    alt={pool.name}
                    src={require(`../../../images/icon2/${pool.name}.svg`)}
                  />
                  <div className="pools__coin-name">
                    {pool.name}
                  </div>
                </div>
              </li>
              {/* 总发行数量 */}
              <li className="pools__row pools__apy">
                <div className="pools__labe-field">
                  {t('Dui_2')}({pool.name})
                  <SectionQuestion value={t('Dui_11')}/>
                </div>
                {/* <div className="pools__apy-values">
                  {totalAmount}
                </div> */}
                <div className="pools__label-value">
                  {totalAmount}
                </div>
              </li>
              {/* 已开放兑换数量 */}
              <li className="pools__row">
                <div className="pools__labe-field">
                  {t('Dui_3')}({pool.names})
                  <SectionQuestion value={t('Dui_12')}/>
                </div>
                <div className="pools__label-value">
                  {depositAmount}
                </div>
              </li>
              {/* 待兑换数量(DBTC) */}
              <li className="pools__row">
                <div className="pools__labe-field">{t('Dui_4')}({pool.name})<SectionQuestion value={t('Dui_13')}/></div>
                <div className="pools__label-value">{formatThousands(daiAmount.toFixed(2))}</div>
              </li>
              {/* 可兑换数量 */}
              <li className="pools__row">
                <div className="pools__labe-field">{t('Dui_5')} ({pool.names})</div>
                <div className="pools__label-value">
                  {formatThousands(availableAmount.toFixed(2))}
                </div>
              </li>
              {/* 已兑换数量 */}
              <li className="pools__row">
                <div className="pools__labe-field">{t('Dui_6')} ({pool.names})</div>
                <div className="pools__label-value">
                  {formatThousands(withdrawAmounts.toFixed(2))}
                </div>
              </li>
              {/* 授权 提取 存入按钮 */}
              {
                isCun?<li className="pools__group-buttons">
                {connected ? (pool.dtokenAllowance == 0 ?
                  // 未授权 展示 提取 和 授权
                  <div className="pools__button-group">
                    <Button
                      className="pools__dialog__option"
                      type={2}
                      loading={fetchWithdrawIncomePending[index]}
                      disabled={Number(formatThousands(availableAmount.toFixed(10)))<=0}
                      
                      // onClick={() => openDialogHandler('widthdraw', index)}
                      onClick={onWithdraws.bind(this, pool, index,availableAmount)}
                    >
                      {fetchWithdrawIncomePending[index] ? '' : t('Dui_7')}
                    </Button>
                    <Button
                      className="pools__dialog__option"
                      loading={fetchApprovalPending[index]}
                      disabled={fetchApprovalPending[index]}
                      onClick={onApproval.bind(this, pool, index)}
                    >
                      {fetchApprovalPending[index] ? '' : t('Vault-ApproveButton')}
                    </Button>
                  </div> :
                  // 已授权 展示 提取 和 存入
                  <div className="pools__button-group">
                    <Button
                      className="pools__dialog__option"
                      type={2}
                      loading={fetchWithdrawIncomePending[index]}
                      disabled={Number(formatThousands(availableAmount.toFixed(10)))<=0}
                      // onClick={() => openDialogHandler('widthdraw', index)}
                      onClick={onWithdraws.bind(this, pool, index,availableAmount)}
                    >
                      {fetchWithdrawIncomePending[index] ? '' : t('Dui_7')}
                    </Button>
                    <Button
                      className="pools__dialog__option"
                      loading={pool.dialog.depositOpened}
                      onClick={() => openDialogHandler('deposit', index)}
                    >
                      {pool.dialog.depositOpened ? '' : t("Vault-DepositButton")}
                    </Button>
                  </div>) :
                  <Button className="pools__dialog__option" loading={connectWalletPending} onClick={connectHandler} disabled={connectWalletPending}>
                    {connectWalletPending ? '' : t("v2-ui-17")}
                  </Button>
                }
              </li>:<li className="pools__group-buttons">
                {connected ? <div className="pools__button-group">
                    <Button
                      className="pools__dialog__option"
                      type={2}
                      loading={fetchWithdrawIncomePending[index]}
                      disabled={true}
                      // onClick={() => openDialogHandler('widthdraw', index)}
                      onClick={onWithdraws.bind(this, pool, index,availableAmount)}
                    >
                      {fetchWithdrawIncomePending[index] ? '' : t('Dui_7')}
                    </Button>
                    <Button
                      className="pools__dialog__option"
                      loading={fetchApprovalPending[index]}
                      disabled={fetchApprovalPending[index]}
                      onClick={onApproval.bind(this, pool, index)}
                    >
                      {fetchApprovalPending[index] ? '' : t('Vault-ApproveButton')}
                    </Button>
                  </div> :
                  <Button className="pools__dialog__option" loading={connectWalletPending} onClick={connectHandler} disabled={connectWalletPending}>
                    {connectWalletPending ? '' : t("v2-ui-17")}
                  </Button>
                }
              </li>
              }
              
              <li className='li_title'>{t('Dui_8')}</li>
            </ul>
            {/* 毛玻璃icon */}
            <div className="pools__mao-logo__wrap">
              <img
                className="pools__mao-logo"
                alt={pool.name}
                src={require(`../../../images/icon2/${pool.name}.svg`)}
              />
            </div>
            {/* 角标 */}
            {
              pool.type === "NEW" ? <img src={require(`../../../images/jiaobiao-${theme}.svg`)} style={{
                position: "absolute",
                right: "-1px",
                top: "-1px",
              }} /> : ""
            }
          </div>
  
          {/* 弹出框内容 */}
          {pool.dialog.opened ? <div className='pools__dialog'>
            {/* 存入相关 */}
            {
              pool.dialog.depositOpened ? <div className="pools__dialog-inner pools__dialog-deposite">
                <div className="pools__dialog__header">
                  {t("Vault-DepositButton")}
                  <span className="pools__dialog__header__close" onClick={() => closeDialogHandler('deposit', index)}></span>
                </div>
                <ul className="pools__dialog__fields">
                  {/* logo coinname info */}
                  <li className="pools__row-1">
                    <div className="pools__logo-name">
                      <img
                        className="pools__coin-logo"
                        alt={pool.name}
                        src={require(`../../../images/icon2/${pool.name}.svg`)}
                      />
                      <div className="pools__coin-name">
                        {pool.name}
                        {/* 存在标签时展示问号悬浮 否则 不展示 */}
                        {/* {pool.type ? <SectionQuestion value={<span className="SectionQuestion-custom-tips">
                      <time>{pool.openDate}</time>
                      <span>{t('Vault-View-9')}<strong>{pool.openAmount}</strong>{t('Vault-View-10')}</span>
                    </span>} label={pool.type == 'NEW' ? "NEW" : t('Vault-View-11')} type={pool.type ? 1 : 0} /> : ''} */}
                      </div>
                    </div>
                    {/* <div className="pools__info">
                      {
                        _IS_ETH_ ? `${t("Vault-HarvestButton")}COW` : (tabActive === 1 ? `${t("Vault-HarvestButton")}MDX、COW` : `${t('Vault-Tips-3')}${i18n.language === 'zh' || i18n.language === 'zh-CN' ? pool.name : ''}${t('Vault-Tips-4')}`)
                      }
                    </div> */}
                  </li>
                  {/* 已存入 */}
                  <li className="pools__row">
                    <div className="pools__labe-field">
                      {t('Dui_4')}({pool.name})
                    </div>
                    <div className="pools__label-value pools__label-value--black">
                      {/* {formatThousands(lockDtokenAmount.toFixed(4))} */}
                      {formatThousands(daiAmount.toFixed(4))}
                    </div>
                  </li>
                   {/* 余额 */}
                   <li className="pools__row">
                    <div className="pools__labe-field">
                      {t('Dui_10')}({pool.name})
                    </div>
                    <div className="pools__label-value pools__label-value--black">
                      {formatThousands(dtokenAmount.toFixed(4))}
                    </div>
                  </li>
                  {/* 输入框 */}
                  <li className="pools__dialog__input">
                    <input
                      placeholder={t('v2-ui-11')}
                      value={depositedBalance[index] != undefined ? depositedBalance[index] : ''}
                      onChange={changeDetailInputValue.bind(this, 'depositedBalance', index, dtokenAmount, pool.tokenDecimals)}
                    />
                    {/* 最大 全部存入按钮 */}
                    <Button
                      className="pools__dialog__deposit-all"
                      // onClick={onDeposit.bind(this, pool, index, true, lockDtokenAmount)}
                      onClick={onDeposits.bind(this, pool, index,dtokenAmount,true)}
                      loading={fetchDepositPending[index]}
                      type={2}
                      disabled={
                        !dtokenAmount || fetchDepositPending[index] || (isMoreDepostLimit(dtokenAmount, pool.depostLimit))
                      }>
                      {fetchDepositPending[index] ? '' : t('v2-ui-12')}
                    </Button>
                  </li>
                  {/* 存入说明 */}
                  {/* {theme === "bsc" ? <li className={`gas-line gas-${theme}`}>
                    {t("gasTip1")}<span>{((3000000 * price) / 1e18).toFixed(4).slice(0, -1)}BNB(≈${(((3000000 * price) / 1e18) * bnbRate).toFixed(3).slice(0, -1)})</span>{t("gasTip2")}<span>
                      {(((3000000 * price) / 1e18) * 0.3).toFixed(4).slice(0, -1)}BNB(≈${(((3000000 * price) / 1e18) * bnbRate * 0.3).toFixed(3).slice(0, -1)})
                      - {(((3000000 * price) / 1e18) * 0.65).toFixed(4).slice(0, -1)}BNB(≈${(((3000000 * price) / 1e18) * bnbRate * 0.65).toFixed(3).slice(0, -1)})</span>
                    {t("gasTip3")}
                  </li> : ''} */}
  
                  {/* 存入按钮 */}
                  <li>
                    <Button
                      className="pools__dialog__option"
                      // onClick={onDeposit.bind(this, pool, index, false, balanceSingle)}
                      onClick={onDeposits.bind(this, pool, index,dtokenAmount,false)}
                      loading={fetchDepositPending[index]}
                      disabled={
                        !Number(String((depositedBalance[index])).replace(',', '')) || fetchDepositPending[index] || isMoreDepostLimit(new BigNumber(depositedBalance[index]).toNumber(), pool.depostLimit)
                      }
                      >{fetchDepositPending[index] ? '' : t("Vault-DepositButton")}</Button>
                  </li>
                </ul>
              </div> : null
            }
            {/* 提取相关 */}
            {
              pool.dialog.widthdrawOpened ? <div className="pools__dialog-inner pools__dialog-withdraw">
                <div className="pools__dialog__header">
                  {t('v2-ui-10')}
                  <span className="pools__dialog__header__close" onClick={() => closeDialogHandler('widthdraw', index)}></span>
                </div>
                <ul className="pools__rows">
                  {/* 币种名称+ICON */}
                  <li className="pools__row-1">
                    <div className="pools__logo-name">
                      <img
                        className="pools__coin-logo"
                        alt={pool.name}
                        src={require(`../../../images/icon2/${pool.name}.svg`)}
                      />
                      <div className="pools__coin-name">
                        {pool.name}
                        {/* 存在标签时展示问号悬浮 否则 不展示 */}
                        {/* {pool.type ? <SectionQuestion value={<span className="SectionQuestion-custom-tips">
                      <time>{pool.openDate}</time>
                      <span>{t('Vault-View-9')}<strong>{pool.openAmount}</strong>{t('Vault-View-10')}</span>
                    </span>} label={pool.type == 'NEW' ? "NEW" : t('Vault-View-11')} type={pool.type ? 1 : 0} /> : ''} */}
                      </div>
                    </div>
                    <div className="pools__info">
                      {
                        _IS_ETH_ ? `${t("Vault-HarvestButton")}COW` : (tabActive === 1 ? `${t("Vault-HarvestButton")}MDX、COW` : `${t('Vault-Tips-3')}${i18n.language === 'zh' || i18n.language === 'zh-CN' ? pool.name : ''}${t('Vault-Tips-4')}`)
                      }
                    </div>
                  </li>
                  {/* 提取收益*/}
                  <li className="pools__income-field">
                    <div className="pools__income-label">
                      <p className="pools__income-title">{t('Vault-WithdrawIncome1')}</p>
                      {
                        _IS_ETH_ ? null : <p className="pools__income-text">{String((finaCompoundEarned / 1e18).toFixed(5)).slice(0, -1)} {pool.earnedToken}</p>
                      }
                      {
                        tabActive == 1 ? <p className="pools__income-text">{cowRewards} COW</p> : null
                      }
                    </div>
                    <div className="pools__income-button">
                      <Button
                        loading={fetchWithdrawIncomePending[index]}
                        // disabled={true}
                        disabled={fetchWithdrawIncomePending[index] || (!Number(forMat(finaCompoundEarned / 1e18, 8)) && !Number(pool.cowReward / 1e18))}
                        onClick={onWithdraw.bind(this, pool, index, false, deposite, true)}
                      >{fetchWithdrawIncomePending[index] ? '' : t("Vault-WithdrawIncome")}</Button>
                    </div>
                  </li>
                  {/* 可提取本金 */}
                  <li className="pools__dialog__withdraw-field">
                    <span>{t("v2-ui-14")}({pool.name})</span>
                    <span>{String(deposite.toFixed(5)).slice(0, -1)}</span>
                  </li>
                  {/* 输入框 */}
                  <li className="pools__dialog__input">
                    <input
                      placeholder={t('v2-ui-11')}
                      value={withdrawAmount[index] != undefined ? withdrawAmount[index] : ''}
                      onChange={changeDetailInputValue.bind(this, 'withdrawAmount', index, deposite, pool.itokenDecimals)}
                    />
                    {/* 最大按钮 */}
                    <Button
                      className="pools__dialog__deposit-all"
                      type={2}
                      onClick={onWithdraw.bind(this, pool, index, true, deposite, false)}
                      loading={fetchWithdrawPending[index]}
                      disabled={
                        
                        !deposite || fetchWithdrawPending[index] || (isMoreDepostLimit(deposite, pool.depostLimit))
                        
                      }>
                      {fetchWithdrawPending[index] ? '' : t('v2-ui-13')}
                    </Button>
                  </li>
                  {/* 提取说明 */}
                  {theme === "bsc" ? <li className={`gas-line gas-${theme}`}>
                    {t("gasTip1")}<span>{((8600000 * price) / 1e18).toFixed(4).slice(0, -1)}BNB(≈${(((8600000 * price) / 1e18) * bnbRate).toFixed(3).slice(0, -1)})</span>{t("gasTip2")}<span>
                      {(((6000000 * price) / 1e18) * 0.3).toFixed(4).slice(0, -1)}BNB(≈${(((6000000 * price) / 1e18) * bnbRate * 0.3).toFixed(3).slice(0, -1)})
                      - {(((6000000 * price) / 1e18) * 0.65).toFixed(4).slice(0, -1)}BNB(≈${(((6000000 * price) / 1e18) * bnbRate * 0.65).toFixed(3).slice(0, -1)})</span>
                    {t("gasTip3")}
                  </li> : ''}
                  {theme === "eth" ? <li className={`gas-line gas-${theme}`}>
                    {t("gasTip1")}<span>{((1000000 * price) / 1e18).toFixed(4).slice(0, -1)}ETH(≈${(((1000000 * price) / 1e18) * ethRate).toFixed(3).slice(0, -1)})</span>{t("gasTip2")}<span>
                      {(((2000000 * price) / 1e18) * 0.1).toFixed(4).slice(0, -1)}ETH(≈${(((2000000 * price) / 1e18) * ethRate * 0.1).toFixed(3).slice(0, -1)})
                      - {(((2000000 * price) / 1e18) * 0.5).toFixed(4).slice(0, -1)}ETH(≈${(((2000000 * price) / 1e18) * ethRate * 0.5).toFixed(3).slice(0, -1)})</span>
                    {t("gasTip3")}
                  </li> : ''}
                  {/* 提取本金 */}
                  <li>
                    <Button
                      className="pools__dialog__option"
                      loading={fetchWithdrawPending[index]}
                      disabled={!Number(String(withdrawAmount[index]).replace(',', '')) || fetchWithdrawPending[index]}
                      // disabled={true}
                      onClick={onWithdraw.bind(this, pool, index, false, deposite, false)}>{fetchWithdrawPending[index] ? '' : t("v2-ui-10")}</Button>
                  </li>
                </ul>
              </div> : null
            }
            {
              isDia&& <div className='pools__dialog' style={{zIndex:'3000'}}>
                <div className='pools__dialog-inner pools__dialog-deposite'>
                  <div className='pools__dialog__header'>{t('Tips')}<span className='pools__dialog__header__close' onClick={()=>setIsDia(false)}></span></div>
                  <div className='pools__dialog__fields'>
                    <div style={{textAlign:'center'}}>{t('Tips-content')}</div>
                    <div style={{marginTop:'30px'}} className="pools__button-group">
                        <Button
                          className="pools__dialog__option"
                          type={2}
                          onClick={()=>setIsDia(false)}
                        >
                          {t('Tips-button')}
                        </Button>
                        <Button
                          className="pools__dialog__option"
                              loading={fetchApprovalPending[index]}
                              disabled={fetchApprovalPending[index]}
                              onClick={onApproval.bind(this, pool, index)}
                        >
                              {fetchApprovalPending[index] ? '' : t('Vault-ApproveButton')}
                        </Button>
                    </div>
                  </div>
                </div>
              </div>
            }
            {
              isDiaS&& <div className='pools__dialog' style={{zIndex:'3000'}}>
                <div className='pools__dialog-inner pools__dialog-deposite'>
                  <div className='pools__dialog__header'>{t('Tips')}<span className='pools__dialog__header__close' onClick={()=>setIsDiaS(false)}></span></div>
                  <div className='pools__dialog__fields'>
                    <div className='money_content'>
                      <div>{t('Dis-1')} {pool.allowance/1e18}，{t('Dis-2')}</div>
                      <div>
                        <p>1、{t('Dis-3')}</p>
                        <p>2、{t('Dis-4')}</p>
                      </div>
                    </div>
                    <div style={{marginTop:'30px'}} className="pools__button-group">
                        <Button
                          className="pools__dialog__option"
                          type={2}
                          onClick={()=>setIsDiaS(false)}
                        >
                          {t('Tips-button')}
                        </Button>
                        <Button
                          className="pools__dialog__option"
                              loading={fetchApprovalPending[index]}
                              disabled={fetchApprovalPending[index]}
                              onClick={onApproval.bind(this, pool, index)}
                        >
                              {fetchApprovalPending[index] ? '' : t('Vault-ApproveButton')}
                        </Button>
                    </div>
                  </div>
                </div>
              </div>
            }
          </div> : null
          }
        </div>
      )
  })
  return (
    <>
      <Dialog
        type={dialogCtrl.type}
        opened={dialogCtrl.opened}
        buttonText={dialogCtrl.buttonText}
        cancelButtonText={dialogCtrl.cancelButtonText}
        cancelButtonCallback={dialogCtrl.cancelButtonCallback}
        buttonClickCallback={dialogCtrl.buttonClickCallback}
      >{dialogCtrl.contentText}</Dialog>
      {/* 说明文字 */}
      <SectionTitle />
      {/* 倒计时 */}
      {/* <SectionCountDown endDate="2021-03-19 14:00:00" date={{ year: 2021, month: 3, day: 19, h: 14, m: 0, s: 0 }} /> */}
      {/* 总锁仓量总收益 */}
      {/* <SectionTotal /> */}
      <div className="pools-main">
        {networkId in _NETWORK_ID_MAP_NAME_ && poolsMap}
        {!poolsMap.filter(pool => !!pool).length ? <div className="pools__empty">
          <img src={require("../../../assets/img/empty.svg")} />
          <p>{t("v2-ui-15")}</p>
          {/* <Button type={2} onClick={() => {setIsPartake(false),setProjectStatus(true)}}>{t("v2-ui-16")}</Button> */}
        </div> : null}
      </div>
      
    </>
  )
})
