
import { useSelector } from 'react-redux';
import { _IS_HECO_, _IS_BSC_, _IS_ETH_ } from './chainsParamsConfig'
import { bsc_info } from "./pools_bsc";
import { heco_info } from "./pool_heco";
import { cow_bsc_info } from "./pools_cow_bsc";
import { cow_bsc_info_v2 } from "./pools_cow_bsc-v2";
import { cow_bsc_info_ctoken } from "./pools_cow_bsc-ctoken";
import { cow_heco_info } from "./pools_cow_heco";
import { cow_heco_info_v2 } from "./pools_cow_heco-v2";
import { cow_heco_info_ctoken } from "./pools_cow_heco-ctoken";
import { cow_eth_info } from "./pools_cow_eth";
import { cow_eth_info_v2 } from "./pools_cow_eth-v2";
import {cow_dao_info} from './pools_cow_dao';
import {cow_dao_bsc_info} from './pools_cow_dao_bsc'
import {cow_dao_eth_info} from './pools_cow_dao_eth'
import { cow_heco_info_exchange } from "./pools_cow_heco_exchange";
import { cow_bsc_info_exchange } from "./pools_cow_bsc_exchange";
import { cow_eth_info_exchange } from "./pools_cow_eth_exchange";
import {staking_info} from './pools_cow_staking'
import {staking_bsc_info} from './pools_bsc_staking'

export function useBasicInfo() {
  const tabActive = useSelector(state => state.vault.tabActive);
  const currentPathName = window.location.pathname;
  if(currentPathName==='/staking' && _IS_HECO_){
    const { earnContractAddress, USDTAddress, pools, aggAddress, MdxAddress,cowAddress,compoundAddress } = staking_info();
    return {
      earnContractAddress,
      USDTAddress,
      aggAddress,
      MdxAddress,
      cowAddress,
      compoundAddress,
      pools: pools.map(pool => {
        return {
          ...pool,
          // 对每一个pool增加弹出框状态控制
          dialog: {
            //对话框弹出
            opened: false,
            // 存入是否打开
            widthdrawOpened: false,
            // 策略
            depositOpened: false,
            //提取本金
            principalOpened:false,
            //提取收益
            profitOpened:false,
            //提取收益按钮
            tiQu:false
          }
        }
      }),
    };
  }
  if(currentPathName==='/staking' && _IS_BSC_){
    const { earnContractAddress, USDTAddress, pools, aggAddress, MdxAddress,cowAddress,compoundAddress } = staking_bsc_info();
    return {
      earnContractAddress,
      USDTAddress,
      aggAddress,
      MdxAddress,
      cowAddress,
      compoundAddress,
      pools: pools.map(pool => {
        return {
          ...pool,
          // 对每一个pool增加弹出框状态控制
          dialog: {
            //对话框弹出
            opened: false,
            // 存入是否打开
            widthdrawOpened: false,
            // 策略
            depositOpened: false,
            //提取本金
            principalOpened:false,
            //提取收益
            profitOpened:false,
            //提取收益按钮
            tiQu:false
          }
        }
      }),
    };
  }
  if((currentPathName==='/bonus'||currentPathName==='/destruction') && _IS_HECO_){
    const { earnContractAddress, USDTAddress, pools, aggAddress, MdxAddress,cowAddress,daoConfig } = cow_dao_info();
    return {
      earnContractAddress,
      USDTAddress,
      aggAddress,
      MdxAddress,
      cowAddress,
      daoConfig,
      pools: pools.map(pool => {
        return {
          ...pool,
          // 对每一个pool增加弹出框状态控制
          dialog: {
            //对话框弹出
            opened: false,
            // 提现是否打开
            widthdrawOpened: false,
            // 存入是否打开
            depositOpened: false
          }
        }
      }),
    };
  }
  if((currentPathName==='/bonus'||currentPathName==='/destruction') && _IS_BSC_){
    const { earnContractAddress, USDTAddress, pools, aggAddress, MdxAddress,cowAddress,daoConfig } = cow_dao_bsc_info();
    return {
      earnContractAddress,
      USDTAddress,
      aggAddress,
      MdxAddress,
      cowAddress,
      daoConfig,
      pools: pools.map(pool => {
        return {
          ...pool,
          // 对每一个pool增加弹出框状态控制
          dialog: {
            //对话框弹出
            opened: false,
            // 提现是否打开
            widthdrawOpened: false,
            // 存入是否打开
            depositOpened: false
          }
        }
      }),
    };
  }
  if((currentPathName==='/bonus'||currentPathName==='/destruction') && _IS_ETH_){
    const { earnContractAddress, USDTAddress, pools, aggAddress, MdxAddress,cowAddress,daoConfig } = cow_dao_eth_info();
    return {
      earnContractAddress,
      USDTAddress,
      aggAddress,
      MdxAddress,
      cowAddress,
      daoConfig,
      pools: pools.map(pool => {
        return {
          ...pool,
          // 对每一个pool增加弹出框状态控制
          dialog: {
            //对话框弹出
            opened: false,
            // 提现是否打开
            widthdrawOpened: false,
            // 存入是否打开
            depositOpened: false
          }
        }
      }),
    };
  }
  // if((currentPathName==='/vault'||currentPathName==='/')&&_IS_ETH_){
  //   const { earnContractAddress, USDTAddress, pools, aggAddress, MdxAddress,cowAddress,migrator } = cow_eth_info();
  //   return {
  //     earnContractAddress,
  //     USDTAddress,
  //     aggAddress,
  //     MdxAddress,
  //     cowAddress,
  //     migrator,
  //     pools: pools.map(pool => {
  //       return {
  //         ...pool,
  //         // 对每一个pool增加弹出框状态控制
  //         dialog: {
  //           //对话框弹出
  //           opened: false,
  //           // 提现是否打开
  //           widthdrawOpened: false,
  //           // 存入是否打开
  //           depositOpened: false
  //         }
  //       }
  //     }),
  //   };
  // }
  if ((currentPathName==='/assetExchange') && _IS_HECO_) {
    const { earnContractAddress, USDTAddress, pools, aggAddress, MdxAddress, cowAddress,delayAddress } = cow_heco_info_exchange();
    return {
      earnContractAddress,
      USDTAddress,
      aggAddress,
      MdxAddress,
      cowAddress,
      delayAddress,
      pools: pools.map(pool => {
        return {
          ...pool,
          // 对每一个pool增加弹出框状态控制
          dialog: {
            //对话框弹出
            opened: false,
            // 提现是否打开
            widthdrawOpened: false,
            // 存入是否打开
            depositOpened: false
          }
        }
      }),
    };
  }
  if ((currentPathName==='/assetExchange') && _IS_BSC_) {
    const { earnContractAddress, USDTAddress, pools, aggAddress, MdxAddress, cowAddress,delayAddress } = cow_bsc_info_exchange();
    return {
      earnContractAddress,
      USDTAddress,
      aggAddress,
      MdxAddress,
      cowAddress,
      delayAddress,
      pools: pools.map(pool => {
        return {
          ...pool,
          // 对每一个pool增加弹出框状态控制
          dialog: {
            //对话框弹出
            opened: false,
            // 提现是否打开
            widthdrawOpened: false,
            // 存入是否打开
            depositOpened: false
          }
        }
      }),
    };
  }
  if ((currentPathName==='/assetExchange') && _IS_ETH_) {
    const { earnContractAddress, USDTAddress, pools, aggAddress, MdxAddress, cowAddress,delayAddress } = cow_eth_info_exchange();
    return {
      earnContractAddress,
      USDTAddress,
      aggAddress,
      MdxAddress,
      cowAddress,
      delayAddress,
      pools: pools.map(pool => {
        return {
          ...pool,
          // 对每一个pool增加弹出框状态控制
          dialog: {
            //对话框弹出
            opened: false,
            // 提现是否打开
            widthdrawOpened: false,
            // 存入是否打开
            depositOpened: false
          }
        }
      }),
    };
  }
  if (tabActive === 0 && _IS_BSC_) {
    const { earnContractAddress, USDTAddress, pools, aggAddress, MdxAddress } = bsc_info();
    return {
      earnContractAddress,
      USDTAddress,
      aggAddress,
      MdxAddress,
      pools: pools.map(pool => {
        return {
          ...pool,
          // 对每一个pool增加弹出框状态控制
          dialog: {
            //对话框弹出
            opened: false,
            // 提现是否打开
            widthdrawOpened: false,
            // 存入是否打开
            depositOpened: false
          }
        }
      }),
    };
  }
  if ((tabActive === 1)  && _IS_BSC_) {
    const { earnContractAddress, USDTAddress, pools, aggAddress, MdxAddress, cowAddress,migrator } = cow_bsc_info();
    return {
      earnContractAddress,
      USDTAddress,
      aggAddress,
      MdxAddress,
      cowAddress,
      migrator,
      pools: pools.map(pool => {
        return {
          ...pool,
          // 对每一个pool增加弹出框状态控制
          dialog: {
            //对话框弹出
            opened: false,
            // 提现是否打开
            widthdrawOpened: false,
            // 存入是否打开
            depositOpened: false
          }
        }
      }),
    };
  }
  if ((tabActive === 2)  && _IS_BSC_) {
    const { earnContractAddress, USDTAddress, pools, aggAddress, MdxAddress, cowAddress,migrator } = cow_bsc_info_v2();
    return {
      earnContractAddress,
      USDTAddress,
      aggAddress,
      MdxAddress,
      cowAddress,
      migrator,
      pools: pools.map(pool => {
        return {
          ...pool,
          // 对每一个pool增加弹出框状态控制
          dialog: {
            //对话框弹出
            opened: false,
            // 提现是否打开
            widthdrawOpened: false,
            // 存入是否打开
            depositOpened: false
          }
        }
      }),
    };
  }
  if ((tabActive === 3)  && _IS_BSC_) {
    const { earnContractAddress, USDTAddress, pools, aggAddress, MdxAddress, cowAddress,migrator } = cow_bsc_info_ctoken();
    return {
      earnContractAddress,
      USDTAddress,
      aggAddress,
      MdxAddress,
      cowAddress,
      migrator,
      pools: pools.map(pool => {
        return {
          ...pool,
          // 对每一个pool增加弹出框状态控制
          dialog: {
            //对话框弹出
            opened: false,
            // 提现是否打开
            widthdrawOpened: false,
            // 存入是否打开
            depositOpened: false
          }
        }
      }),
    };
  }
  if (tabActive === 0 && _IS_HECO_) {
    const { earnContractAddress, USDTAddress, pools, aggAddress, MdxAddress } = heco_info();
    return {
      earnContractAddress,
      USDTAddress,
      aggAddress,
      MdxAddress,
      pools: pools.map(pool => {
        return {
          ...pool,
          // 对每一个pool增加弹出框状态控制
          dialog: {
            //对话框弹出
            opened: false,
            // 提现是否打开
            widthdrawOpened: false,
            // 存入是否打开
            depositOpened: false,
          }
        }
      }),
    };
  }
  if ((tabActive === 1) && _IS_HECO_) {
    const { earnContractAddress, USDTAddress, pools, aggAddress, MdxAddress, cowAddress,migrator,cTokenMigrator } = cow_heco_info();
    return {
      earnContractAddress,
      USDTAddress,
      aggAddress,
      MdxAddress,
      cowAddress,
      migrator,
      cTokenMigrator,
      pools: pools.map(pool => {
        return {
          ...pool,
          // 对每一个pool增加弹出框状态控制
          dialog: {
            //对话框弹出
            opened: false,
            // 提现是否打开
            widthdrawOpened: false,
            // 存入是否打开
            depositOpened: false
          }
        }
      }),
    };
  }
  if ((tabActive === 2) && _IS_HECO_) {
    const { earnContractAddress, USDTAddress, pools, aggAddress, MdxAddress, cowAddress,migrator,cTokenMigrator } = cow_heco_info_v2();
    return {
      earnContractAddress,
      USDTAddress,
      aggAddress,
      MdxAddress,
      cowAddress,
      migrator,
      cTokenMigrator,
      pools: pools.map(pool => {
        return {
          ...pool,
          // 对每一个pool增加弹出框状态控制
          dialog: {
            //对话框弹出
            opened: false,
            // 提现是否打开
            widthdrawOpened: false,
            // 存入是否打开
            depositOpened: false
          }
        }
      }),
    };
  }
  if ((tabActive === 3) && _IS_HECO_) {
    const { earnContractAddress, USDTAddress, pools, aggAddress, MdxAddress, cowAddress,migrator,cTokenMigrator } = cow_heco_info_ctoken();
    return {
      earnContractAddress,
      USDTAddress,
      aggAddress,
      MdxAddress,
      cowAddress,
      migrator,
      cTokenMigrator,
      pools: pools.map(pool => {
        return {
          ...pool,
          // 对每一个pool增加弹出框状态控制
          dialog: {
            //对话框弹出
            opened: false,
            // 提现是否打开
            widthdrawOpened: false,
            // 存入是否打开
            depositOpened: false
          }
        }
      }),
    };
  }
  if ((tabActive === 1) && _IS_ETH_) {
    const { earnContractAddress, USDTAddress, pools, aggAddress, MdxAddress, cowAddress,migrator } = cow_eth_info();
    return {
      earnContractAddress,
      USDTAddress,
      aggAddress,
      MdxAddress,
      cowAddress,
      migrator,
      pools: pools.map(pool => {
        return {
          ...pool,
          // 对每一个pool增加弹出框状态控制
          dialog: {
            //对话框弹出
            opened: false,
            // 提现是否打开
            widthdrawOpened: false,
            // 存入是否打开
            depositOpened: false
          }
        }
      }),
    };
  }
  if ((tabActive === 2) && _IS_ETH_) {
    const { earnContractAddress, USDTAddress, pools, aggAddress, MdxAddress, cowAddress,migrator } = cow_eth_info_v2();
    return {
      earnContractAddress,
      USDTAddress,
      aggAddress,
      MdxAddress,
      cowAddress,
      migrator,
      pools: pools.map(pool => {
        return {
          ...pool,
          // 对每一个pool增加弹出框状态控制
          dialog: {
            //对话框弹出
            opened: false,
            // 提现是否打开
            widthdrawOpened: false,
            // 存入是否打开
            depositOpened: false
          }
        }
      }),
    };
  }
  const { earnContractAddress, USDTAddress, pools, aggAddress, MdxAddress } = heco_info();
  return {
    earnContractAddress,
    USDTAddress,
    aggAddress,
    MdxAddress,
    pools: pools.map(pool => {
      return {
        ...pool,
        // 对每一个pool增加弹出框状态控制
        dialog: {
          //对话框弹出
          opened: false,
          // 提现是否打开
          widthdrawOpened: false,
          // 存入是否打开
          depositOpened: false
        }
      }
    }),
  };
}
