/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useCallback ,useRef} from "react";
import { useSelector } from 'react-redux';
import {
    withRouter
} from "react-router-dom";
import Web3Modal from "web3modal";
import WalletConnectProvider from "@walletconnect/web3-provider";
import DropDown from "../DropDown/DropDown.js"
import DropDialog from '../DropDialog/DropDialog'
import { makeStyles } from "@material-ui/core/styles";
// 多语言
import { useTranslation } from 'react-i18next';
// core components
import styles from "assets/jss/components/navListStyle.js";
import "./NavList.css";
import { primaryColor } from "assets/jss/material-kit-pro-react";
import { useConnectWallet } from "features/home/redux/connectWallet.js";
import { headerAndAsideLinks, _IS_ETH_ } from '../../features/configure/chainsParamsConfig'
const useStyles = makeStyles(styles);
export default withRouter(function NavList({ history }) {
    const theme = useSelector(state => state.common.theme);
    const currentPathName = history.location.pathname;
    const { connected, connectWallet } = useConnectWallet();
    const classes = useStyles();
    const [danbiActive, setDanbiActive] = useState(true);
    const [danDao, setDanDao] = useState(true);
    const { t, i18n } = useTranslation();
    const [lang, setLanguage] = useState('zh');
    const langID = (i18n.language === 'zh' || i18n.language === 'zh-CN') ? 'zh' : 'en';
    const [opened, setOpened] = useState(false);
    // 领取空投弹框Flag
    const [dropFlag, setDropFlag] = useState(false);

    // const currentPathName = window.location.pathname;
    const isFirst = useRef(true)
    useEffect(()=>{
        if(isFirst.current){
            if(window.location.pathname==='/bonus'||window.location.pathname==='/destruction'){
                setDanbiActive(true)
                setDanDao(false)
            }if(window.location.pathname==='/vault'){
                setDanDao(true)
                setDanbiActive(false)
            }
            isFirst.current = false
        }
      },[window.location.pathname])
    



    const onDropFun = useCallback(() => {
        if (!connected) {
            connectHandler();
        } else {
            setDropFlag(true);
        }
    }, [connected])

    // 语言列表
    const dropDownList = [
        {
            text: "中文繁體",
            id: "1",
        },
        {
            text: "English",
            id: "2",
        },
        // {
        //     text: "Japan",
        //     id: "3",
        // },
        // {
        //     text: "KR",
        //     id: "4",
        // },
        // {
        //     text: "RU",
        //     id: "5",
        // },
        // {
        //     text: "TU",
        //     id: "6",
        // },
    ]
    // 切换语言回调
    function getLanguage(props) {
        switch (props) {
            case 'English':
                return i18n.changeLanguage('en').then(() => {
                    setLanguage(props)
                })
            case '中文繁體':
                return i18n.changeLanguage('zh').then(() => {
                    setLanguage(props)
                })
            case 'Japan':
                return i18n.changeLanguage('jp').then(() => {
                    setLanguage(props)
                })
            case 'KR':
                return i18n.changeLanguage('kr').then(() => {
                    setLanguage(props)
                })
            case 'RU':
                return i18n.changeLanguage('ru').then(() => {
                    setLanguage(props)
                })
            case 'TU':
                return i18n.changeLanguage('tu').then(() => {
                    setLanguage(props)
                })
            default:
                return
        }
    }
    // 切换语言
    const switchLanguage = useCallback(() => {
        switch (i18n.language) {
            case 'zh':
            case 'zh-CN':
                return '中文繁體'
            case 'en':
            case 'en-US':
                return 'English';
            case 'jp':
            case 'jp-JP':
                return 'Japan';
            case 'kr':
            case 'kr-KR':
                return 'KR';
            case 'ru':
            case 'ru-RU':
                return 'RU';
            case 'tu':
            case 'tu-TU':
                return 'TU';
            default:
                return '中文繁體'
        }
    }, [i18n.language])
    // 连接钱包
    function connectHandler() {
        const newModal = new Web3Modal({
            network: process.env.NETWORK ? process.env.NETWORK : "mainet",
            cacheProvider: true,
            providerOptions: {
                injected: {
                    display: {
                        name: "MetaMask",
                        description: t('Home-BrowserWallet')
                    },
                }
                ,
                walletconnect: {
                    package: WalletConnectProvider,
                    options: {
                        infuraId: process.env.INFURA_ID
                    }
                }
            }
        })
        connectWallet(newModal);
    }
    useEffect(() => {
        setLanguage(switchLanguage());
    }, [switchLanguage]);

    const listHeight=useRef()
    const listHeights=useRef()
    const listHeightAll=useRef()
    const [isScroll, setIsScroll] = useState(false);
    const [sheng, setSheng] = useState();
    let alls= document.body.scrollHeight
    // console.log(alls)
    useEffect(() => {
        let allHeight= listHeightAll.current.clientHeight
        // console.log(allHeight)
        let navHeight = listHeight.current.clientHeight
        let btnHeight = listHeights.current.clientHeight
        let sheng = allHeight-btnHeight-50
        // console.log(navHeight)
        // console.log(sheng<navHeight)
        if(sheng<navHeight){
            setSheng(sheng)
            setIsScroll(true)
        }else{
            setSheng(sheng)
            setIsScroll(false)
        }
    }, [alls]);
    // console.log(isScroll)
    // console.log(sheng)



    function ChangeSize(){
        const [size,setSize] = useState({
                width:document.documentElement.clientWidth,
                hieght:document.documentElement.clientHeight
        })
    
        const onResize = useCallback(()=>{
            setSize({
                width:document.documentElement.clientWidth,
                height:document.documentElement.clientHeight,
            })
        },[])
    
        useEffect(()=>{
            window.addEventListener('resize',onResize);
            return (()=>{
                window.removeEventListener('resize',onResize)
            })
        },[])
    
        return size;
    }
    let size  = ChangeSize();
    return (
        <div className={classes.navContainer} ref={listHeightAll}>
            {/* 空投弹框 */}
            {dropFlag ? <DropDialog setDropFlag={setDropFlag}></DropDialog> : null}
            <div className={classes.navWapper}>
                <ul 
                    ref={listHeight}
                    style={{
                        overflowY:'scroll',
                        height:`${sheng}px`
                    }}
                >
                    <li className={classes.navListItem}>
                        <div className={`${classes.navListItemContainer} navlist__item-container ${currentPathName === '/' ? classes.navListItemActived : ''} ${currentPathName === '/' ? 'navlist__item-actived' : ''}`}
                            onClick={() => {
                                history.push('/');
                                window.location.reload();
                            }}>
                            <i className={`${classes.navIcon} nav__index nav__index-${theme}`}></i>
                            {t('Nav-Home')}
                            {/* 竖线 */}
                            <span></span>
                        </div>
                    </li>
                    <li className={classes.navListItem} >
                        <div
                            className={`${classes.navListItemContainer} navlist__item-container`}
                            onClick={() => {
                                if(danbiActive==true&&danDao==true){
                                    setDanbiActive(false)
                                    setDanDao(true)
                                }else if(danbiActive==false&&danDao==true){
                                    setDanbiActive(true)
                                    setDanDao(true)
                                }else if(danbiActive==true&&danDao==false){
                                    setDanbiActive(false)
                                    setDanDao(true)
                                }else if(danbiActive==false&&danDao==false){
                                    setDanbiActive(true)
                                    setDanDao(false)
                                }
                                // setDanbiActive(!danbiActive)
                                
                            }}>
                            <i className={`${classes.navIcon} nav__danbi nav__danbi-${theme}`}></i>
                            <span>{t('Nav-defi')}</span>
                            <strong style={{
                                color: primaryColor[2],
                                width: "0",
                                height: "0",
                                display: "inline-block",
                                borderBottom: "4px solid",
                                transition: "all 150ms ease-in",
                                borderLeft: "4px solid transparent",
                                marginLeft: "4px",
                                borderRight: "4px solid transparent",
                                verticalAlign: "middle",
                                position: "absolute",
                                right: "32px",
                                top: "30px",
                                transform: danbiActive ? "rotate(180deg)" : ''
                            }}></strong>
                        </div>
                        <ul className={classes.navListItemOptionContainer} style={{
                            maxHeight: danbiActive ? "0px" : "200px"
                        }}>
                            <li className={`${classes.navListItemOptionItem} ${currentPathName === '/vault' ? classes.navListItemActived : ''}`} onClick={() => {
                                history.push('/vault')
                                window.location.reload();
                            }}>
                                {t('v2-ui-22')}
                                {/* 竖线 */}
                                <span></span>
                            </li>
                            {
                                theme === 'heco' ? <li className={`${classes.navListItemOptionItem}`} onClick={() => window.open('https://v1.coinwind.com/')}>
                                    {t('v2-ui-v1')}
                                </li> : null
                            }
                            {
                                _IS_ETH_ ? null : <li className={`${classes.navListItemOptionItem} ${currentPathName === '/lp' ? classes.navListItemActived : ''}`} onClick={() => {
                                    history.push('/lp')
                                    window.location.reload();
                                }}>
                                    {t('Nav-Lp')}
                                    {/* 竖线 */}
                                    <span></span>
                                </li>
                            }
                            
                        </ul>
                    </li>
                    {/* {
                        _IS_ETH_ ? null :
                            <>
                                <li className={classes.navListItem}>
                                    <div className={`${classes.navListItemContainer} ${classes.lpIcon} navlist__item-container ${currentPathName === '/lp' ? classes.navListItemActived : ''} ${currentPathName === '/lp' ? 'navlist__item-actived' : ''}`}
                                        onClick={() => {
                                            // setLpActive(!lpActive)
                                            history.push('/lp')
                                            window.location.reload();
                                        }}>
                                        <i className={`${classes.navIcon} nav__lp nav__lp-${theme}`}></i>
                                        {t('Nav-Lp')}
                                        <span></span>
                                    </div>
                                </li>
                            </>
                    } */}
                    {
                        _IS_ETH_?null:<li className={classes.navListItem}>
                        <div className={`${classes.navListItemContainer} ${classes.lpIcon} navlist__item-container ${currentPathName === '/staking' ? classes.navListItemActived : ''} ${currentPathName === '/staking' ? 'navlist__item-actived' : ''}`}
                            onClick={() => {
                                // setLpActive(!lpActive)
                                history.push('/staking')
                                window.location.reload();
                            }}>
                            <i className={`${classes.navIcon} nav__lp nav__lp-${theme}`}></i>
                            Staking
                            {/* 竖线 */}
                            <span></span>
                        </div>
                    </li>
                    }
                    
                    {
                        _IS_ETH_?null:<li className={classes.navListItem}>
                        <div
                            className={`${classes.navListItemContainer} navlist__item-container`}
                            onClick={() => {
                                // setDanDao(!danDao)
                                if(danbiActive==true&&danDao==true){
                                    setDanbiActive(true)
                                    setDanDao(false)
                                }else if(danbiActive==true&&danDao==false){
                                    setDanbiActive(true)
                                    setDanDao(true)
                                }else if(danbiActive==false&&danDao==true){
                                    setDanbiActive(true)
                                    setDanDao(false)
                                }else if(danbiActive==false&&danDao==false){
                                    setDanbiActive(false)
                                    setDanDao(true)
                                }
                            }}>
                            <i className={`${classes.navIcon} nav__dao nav__dao-${theme}`}></i>
                            {/* <span>{t('Nav-Danbi')}</span> */}
                            <span>DAO</span>
                            <strong style={{
                                color: primaryColor[2],
                                width: "0",
                                height: "0",
                                display: "inline-block",
                                borderBottom: "4px solid",
                                transition: "all 150ms ease-in",
                                borderLeft: "4px solid transparent",
                                marginLeft: "4px",
                                borderRight: "4px solid transparent",
                                verticalAlign: "middle",
                                position: "absolute",
                                right: "32px",
                                top: "30px",
                                transform: danDao ? "rotate(180deg)" : ''
                            }}></strong>
                        </div>
                        <ul className={classes.navListItemOptionContainer} style={{
                            maxHeight: danDao ? "0px" : "100px"
                        }}>
                            <li className={`${classes.navListItemOptionItem} ${currentPathName === '/bonus' ? classes.navListItemActived : ''}`} onClick={() => {
                                history.push('/bonus')
                                window.location.reload();
                            }}>
                                {t('Title-Bonus')}
                                {/* 竖线 */}
                                <span></span>
                            </li>
                            <li className={`${classes.navListItemOptionItem} ${currentPathName === '/destruction' ? classes.navListItemActived : ''}`} onClick={() => {
                                history.push('/destruction')
                                window.location.reload();
                            }}>
                                {t('Title-destruction')}
                                {/* 竖线 */}
                                <span></span>
                            </li>
                        </ul>
                    </li>
                    }
                    {/* <li>
                        <a className={classes.navListItemContainer} href={ headerAndAsideLinks[langID][0].href} target="_blank">
                            <img src={require("../../images/liwu.svg")} alt="" />
                            <span style={{ marginLeft: "10px" }}>{t("Tutorial-7")}</span>
                        </a>
                    </li> */}
                    <li onClick={() => {
                        onDropFun();
                    }}>
                        <div className={classes.navListItemContainer}>
                            <img src={require("../../images/liwu.svg")} alt="" />
                            <span style={{ marginLeft: "10px" }}>{t("Btn-Drop")}</span>
                        </div>
                    </li>
                </ul>
                <div className="nav__bottom"  ref={listHeights}>
                    <div className="nav__tutoril">
                        {
                            headerAndAsideLinks[langID].map((item, ind) => {
                                return (
                                    <a className="tutoril__item" href={item.href} key={ind} target="_blank">
                                        <span>{t(item.content)}</span>
                                        <img src={require('../../images/icon-more.svg')} alt="" />
                                    </a>
                                )
                            })
                        }
                    </div>
                    {/* <div className='wen_ti'>
                        <div>
                            <a href={headerAndAsideLinks[langID][1].href} target="_blank">
                                <span>{t(headerAndAsideLinks[langID][1].content)}</span>
                            </a>
                        </div>
                        <div>
                            <a href={headerAndAsideLinks[langID][2].href} target="_blank">
                                <span>{t(headerAndAsideLinks[langID][2].content)}</span>
                            </a>
                        </div>
                    </div> */}
                    <div className='icon_all'>
                        <div className="footer__right-img-box" style={{marginRight: "56px"}} onClick={() => {
                            const larkUrl = i18n.language === 'zh' || i18n.language === 'zh-CN' ? "https://t.me/coinwind_cn" : "https://t.me/CoinWind"
                            window.open(larkUrl)
                            }}>
                            {/* <i className="footer__right-img-wechat"></i> */}
                            <img className="footer__right-img-wechat" src={require('../../images/telegram.svg')} alt=""/>
                        </div>
                         <div className="footer__right-img-box" style={{marginRight: "56px"}}>
                            {/* <i className="footer__right-img-wechat"></i> */}
                            <a href="https://twitter.com/coinwind_com" target="_blank">
                                <img className="footer__right-img-wechat" src={require('../../images/Twitter.svg')} alt=""/>
                            </a>
                        </div>
                        <div className="footer__right-img-box" style={{marginBottom:'20px'}}>
                            <a href="https://medium.com/coinwind" target="_blank">
                                {/* <i className="footer__right-img-tw"></i> */}
                                <img className="footer__right-img-wechat" src={require('../../images/M.svg')} alt=""/>
                            </a>
                        </div>
                    </div>
                    {/* <div className="nav__language-container" onClick={() => {
                        setOpened(!opened);
                    }}>
                        <DropDown
                            className={`nav__language nav__language-${theme}`}
                            buttonText={lang}
                            opened={opened}
                            dropDownList={dropDownList}
                            position="top"
                            buttonClickCallback={(props) => {
                                getLanguage(props);
                                setOpened(!opened);
                            }}
                        >
                        </DropDown>
                        <strong style={{
                            color: primaryColor[2],
                            width: "0",
                            height: "0",
                            display: "inline-block",
                            borderTop: "4px solid",
                            transition: "all 150ms ease-in",
                            borderLeft: "4px solid transparent",
                            marginLeft: "4px",
                            borderRight: "4px solid transparent",
                            verticalAlign: "middle",
                            position: "absolute",
                            right: "32px",
                            top: "25px",
                            transform: opened ? "rotate(180deg)" : ''
                        }}></strong>
                    </div> */}
                </div>
            </div>
        </div>
    );
})
