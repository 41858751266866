/*eslint-disable*/
import React, { useEffect } from "react";
export default function PageNotFound() {
  useEffect(() => {
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
  });
  return (
    <div className="common-page-not-found">
     404
    </div>
  );
}
