/* eslint-disable */
import React, { useState, useEffect, useCallback, memo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import BigNumber from "bignumber.js";
import { byDecimals } from 'features/helpers/bignumber';
// import { isMobile } from 'features/helpers/utils';
import Web3Modal from "web3modal";
import WalletConnectProvider from "@walletconnect/web3-provider";
import Button from '../../../components/Button/Button'
import Dialog from '../../../components/Dialog/Dialog'
import SectionQuestion from "../../../components/Question/Question";
import H5Tab from '../../../components/H5Tab/H5Tab'
//  hooks
import { useConnectWallet } from '../../home/redux/hooks';
import '../jss/sections/SectionPools.css';
import '../jss/sections/SectionFilter.css'
import {
  usePoolList,
  useFetchApproval,
  useFetchDeposit,
  useFetchWithdraw,
  useAllRate,
  useCoinRate,
} from '../redux/hooks';

// import SectionTitle from "./SectionTitle";
import SectionTotal from "./SectionTotal";
import SectionTitle from "./SectionTitle";
import SectionFilter from "./SectionFilter";
// import SectionCountDown from "./SectionCountDown";
import { isMobile } from 'features/helpers/utils';
import LpContent from '../../daoLp/sections/SectionPools'
import CowDao from '../../daoVault/sections/SectionPools'
import { inputLimitPass, inputFinalVal, isEmpty } from 'features/helpers/utils';
import { useBasicInfo } from 'features/configure/pools';
import { txUrlMap, _NETWORK_ID_MAP_NAME_, cowAddressMap, _IS_ETH_ } from '../../configure/chainsParamsConfig';
export default memo(function SectionPools() {
  const { t, i18n } = useTranslation();
  const { web3, address, networkId, connected, connectWallet, connectWalletPending } = useConnectWallet();
  const isH5 = isMobile() ? 'h5' : 'pc';
  return (
    <>
      <H5Tab></H5Tab>
      {/* 说明文字 */}
      <SectionTitle />
      {/* 倒计时 */}
      {/* <SectionCountDown endDate="2021-03-19 14:00:00" date={{ year: 2021, month: 3, day: 19, h: 14, m: 0, s: 0 }} /> */}
      {/* 总锁仓量总收益 */}
      {/* <SectionTotal /> */}
      {
        !_IS_ETH_&&<>{
          address ? <SectionTotal
            address={address}
          /> : null
        }</>
      }
      
      {/* <BonusContent/> */}
      {
          !_IS_ETH_&& <div className={`cash-dividends`} >
            {
              isH5==='pc'?<>
                <CowDao/>
                <LpContent/>
              </>:<>
                <LpContent/>
                <CowDao/>
              </>
            }
          
        </div>
      }
     
    </>
  )
})
