/* eslint-disable */
import React, { useState, useEffect, useCallback, memo,useRef} from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import BigNumber from "bignumber.js";
import { byDecimals } from 'features/helpers/bignumber';
// import { pool } from '../../configure/pools';
// import { isMobile } from 'features/helpers/utils';
import Web3Modal from "web3modal";
import WalletConnectProvider from "@walletconnect/web3-provider";
import Button from '../../../components/Button/Button'
import Dialog from '../../../components/Dialog/Dialog'
import SectionQuestion from "../../../components/Question/Question";
//  hooks
import { useConnectWallet } from '../../home/redux/hooks';
import '../jss/sections/SectionPools.css';
import {
  useSetAndGetTotals,
  useFetchBalances,
  useFetchPoolBalances,
  useFetchApproval,
  useFetchDeposit,
  useFetchWithdraw,
  useEarned,
  useApy,
  useCompound,
  useDeposited,
  useRateAndTvl,
  useAllRate,
  useConvertU,
  usePoolList,
  useFetchPoolsInfo,
} from '../redux/hooks';
// import  initialState from '../redux//initialState'
import SectionTotal from "./SectionTotal";
import SectionTitle from "./SectionTitle";
import SectionFilter from "./SectionFilter";
import { inputLimitPass, inputFinalVal, isEmpty } from 'features/helpers/utils';
import {txUrlMap,_IS_ETH_} from '../../configure/chainsParamsConfig';
import H5TabDefi from '../../../components/H5TabDefi/H5Tab'
import i18next from 'i18next';
export default memo(function SectionPools() {
  const { t, i18n } = useTranslation();
  const { web3, address, networkId, connected, connectWallet, connectWalletPending } = useConnectWallet();
  const theme = useSelector(state => state.common.theme);
  const pools = useSelector(state => state.lp.pools);
  const {fetchPoolsInfo} = useFetchPoolsInfo();
  const [price, setPrice] = useState();
  const [web3Modal, setModal] = useState(null)
  // cow复利 取 cow策略下 cow池子cow单利字段
  const [cowCompoundApy, setCowCompoundApy] = useState("");
  // 获取 gasPrice
  const getPrice = useCallback(async () => {
    if (!web3) { return }
    const price = await new web3.eth.getGasPrice()
    setPrice(price);
  }, [web3])
  useEffect(() => {
    getPrice();
  }, [web3, getPrice])
  useEffect(() => {
    const newModal = new Web3Modal({
      network: process.env.NETWORK ? process.env.NETWORK : "mainet",
      cacheProvider: true,
      // disableInjectedProvider: true,
      
      providerOptions: {
        injected: {
          display: {
            name: "MetaMask",
            description: i18next.t('Home-BrowserWallet')
          },
        },
        walletconnect: {
          package: WalletConnectProvider,
          options: {
            infuraId: process.env.INFURA_ID
          }
        }
      }
    })
    setModal(newModal)
  }, [setModal])
  useEffect(() => {
    if (web3Modal && (web3Modal.cachedProvider || window.ethereum)) {
    // if (web3Modal && (web3Modal.cachedProvider || window.ethereum || window.BinanceChain)) {
      connectWallet(web3Modal);
    }
  }, [web3Modal, connectWallet])
  //连接钱包弹框
  function connectHandler() {
    const newModal = new Web3Modal({
      network: process.env.NETWORK ? process.env.NETWORK : "mainet",
      cacheProvider: true,
      providerOptions: {
        injected: {
          display: {
            name: "MetaMask",
            description: t('Home-BrowserWallet')
          },
        }
        ,
        walletconnect: {
          package: WalletConnectProvider,
          options: {
            infuraId: process.env.INFURA_ID
          }
        }
      }
    })
    connectWallet(newModal);
  }

  
  let {  fetchPoolBalances } = useFetchPoolBalances();
  // 设置池子的总额度
  const { getPoolTotal } = useSetAndGetTotals();
  const { tokens, fetchBalances } = useFetchBalances();
  // const { compounds, compoundEarneds } = useFetchCompoundAndcompoundEarned();
  const { fetchApproval, fetchApprovalPending } = useFetchApproval();
  const { fetchDeposit, fetchDepositEth, fetchDepositPending } = useFetchDeposit();
  const { fetchWithdraw, fetchWithdrawEth, fetchWithdrawPending, fetchWithdrawIncomePending } = useFetchWithdraw();
  const rateAndTvl = useRateAndTvl();
  // 新增 LP 各币种兑MDX汇率
  const { poolRate, coinRate, bnbRate } = useAllRate(true);
  const [depositedBalance, setDepositedBalance] = useState({});
  const [withdrawAmount, setWithdrawAmount] = useState({});

  const dispatch = useDispatch();
  // 打开对话框
  const openDialogHandler = (type, index) => {
    pools[index].dialog.opened = true;
    if (type === 'widthdraw') {
      pools[index].dialog.widthdrawOpened = true
    }
    if (type === 'deposit') {
      pools[index].dialog.depositOpened = true
    }
    dispatch({ type: 'OPEN_POOL_DIALOG', data: pools });
  }

  // 关闭对话框
  const closeDialogHandler = (type, index) => {
    pools[index].dialog.opened = false;
    if (type === 'widthdraw') {
      pools[index].dialog.widthdrawOpened = false
    }
    if (type === 'deposit') {
      pools[index].dialog.depositOpened = false
    }
    dispatch({ type: 'OPEN_POOL_DIALOG', data: pools });
  }
  // 所有状态的对话操作
  const [dialogCtrl, setDialogCtrl] = useState({
    opened: false,
    type: 'success',
    // "我知道了"
    buttonText: t('v2-ui-1'),
    // 授权成功
    contentText: t('v2-ui-2'),
    buttonClickCallback: () => {
      setDialogCtrl({ ...dialogCtrl, opened: false })
    }
  })
  const changeDetailInputValue = (type, index, total, tokenDecimals, event) => {
    total = Number(total)
    let value = event.target.value;
    if (!inputLimitPass(value, tokenDecimals)) {
      return;
    }
    if (isNaN(Number(value))) {
      value = ''
    }
    // let sliderNum = 0;
    let inputVal = Number(value.replace(',', ''));
    // if (value) {
    //   sliderNum = byDecimals(inputVal / total, 0).toFormat(2) * 100;
    // }
    switch (type) {
      case 'depositedBalance':
        setDepositedBalance({
          ...depositedBalance,
          [index]: inputFinalVal(value, total, tokenDecimals),
          // [`slider-${index}`]: sliderNum,
        });
        break;
      case 'withdrawAmount':
        setWithdrawAmount({
          ...withdrawAmount,
          [index]: inputFinalVal(value, total, tokenDecimals),
          // [`slider-${index}`]: sliderNum,
        });
        break;
      default:
        break;
    }
  }
  const onApproval = (pool, index, event) => {
    event.stopPropagation();
    fetchApproval({
      address,
      web3,
      tokenAddress: pool.tokenAddress,
      contractAddress: pool.earnContractAddress,
      index
    }).then(
      () => {
        setIsDia(false)
        closeDialogHandler('deposit', index);
        setDialogCtrl({
          ...dialogCtrl,
          opened: true,
          type: 'success',
          contentText: t("Approval-success"),
          // 确认、存入
          cancelButtonText: t('Farm-Dialog-Confirm'),
          buttonText: t('Vault-DepositButton'),
          buttonClickCallback: () => {
            setDialogCtrl({ ...dialogCtrl, opened: false });
            openDialogHandler('deposit', index);
          },
          cancelButtonCallback: () => {
            setDialogCtrl({ ...dialogCtrl, opened: false })
          },
        })
      }
    ).catch(
      error => {
        setDialogCtrl({
          ...dialogCtrl,
          opened: true,
          type: 'warning',
          contentText: t("Approval-error"),
          // 重试
          buttonText: t('v2-ui-3')
        })
      }
    )
  }
  const [isDia,setIsDia] = useState(false)
  const onDeposit = (pool, index, isAll, balanceSingle, event) => {
    // console.log(pool)
    event.stopPropagation();
    let amountValue;
    if (isAll) {
      setDepositedBalance({
        ...depositedBalance,
        [index]: balanceSingle.toString(10),
        // [`slider-${index}`]: 100,
      })
      amountValue=balanceSingle.toString(10)
    }else{
      amountValue = depositedBalance[index] ? depositedBalance[index].replace(',', '') : depositedBalance[index];
    }
    // let amountValue = depositedBalance[index] ? depositedBalance[index].replace(',', '') : depositedBalance[index];
    if (amountValue == undefined) {
      amountValue = '0';
    }

    // if (depositedBalance[`slider-${index}`] === 100) {
    //   isAll = true;
    // }
    // console.log(new BigNumber(amountValue).multipliedBy(new BigNumber(10).exponentiatedBy(pool.tokenDecimals)).toString(10))
    // console.log((pool.allowance))
    if(Number(new BigNumber(amountValue).multipliedBy(new BigNumber(10).exponentiatedBy(pool.tokenDecimals)).toString(10))>Number((pool.allowance))){
      // console.log('比你大')
      setIsDia(true)
    }else{

      fetchDeposit({
        address,
        web3,
        isAll,
        amount: new BigNumber(amountValue).multipliedBy(new BigNumber(10).exponentiatedBy(pool.tokenDecimals)).toString(10),
        contractAddress: pool.earnContractAddress,
        contractToken: pool.tokenAddress,
        index
      }).then(
        ({ transactionHash }) => {
          // setDepositedBalance({ ...depositedBalance, [index]: '', [`slider-${index}`]: 0 })
          setDepositedBalance({ ...depositedBalance, [index]: '' })
          setDialogCtrl({
            ...dialogCtrl,
            opened: true,
            type: 'success',
            contentText: t("Deposit-success"),
            // 查看我的交易
            buttonText: <a href={txUrlMap[networkId] + transactionHash} target="_blank">{t("v2-ui-4")}</a>,
            cancelButtonText: t("v2-ui-1"),
            cancelButtonCallback: () => {
              setDialogCtrl({ ...dialogCtrl, opened: false })
            },
            buttonClickCallback: () => {
              setDialogCtrl({ ...dialogCtrl, opened: false });
            }
          })
        }
      ).catch(
        error => {
          {
            // setDepositedBalance({ ...depositedBalance, [index]: '', [`slider-${index}`]: 0 })
            setDepositedBalance({ ...depositedBalance, [index]: '' })
            setDialogCtrl({
              ...dialogCtrl,
              opened: true,
              type: 'warning',
              contentText: t("Deposit-error"),
              buttonText: t("v2-ui-3")
            })
          }
        }
      )
    }
  }

  const onWithdraw = (pool, index, isAll, singleDepositedBalance, isWithdrawIncome, event) => {

    event.stopPropagation();
    if (isAll) {
      setWithdrawAmount({
        ...withdrawAmount,
        // [index]: String(forMat(singleDepositedBalance, 8)),
        [index]: String(singleDepositedBalance.toFixed(8)),
        [`slider-${index}`]: 100,
      })
      // console.log(singleDepositedBalance, forMat(singleDepositedBalance, 8), '========');
    }
    let amountValue = withdrawAmount[index] ? withdrawAmount[index].replace(',', '') : withdrawAmount[index];
    if (amountValue == undefined) {
      amountValue = '0';
    }
    if (withdrawAmount[`slider-${index}`] === 100) {
      isAll = true;
    }


    fetchWithdraw({
      address,
      web3,
      isAll,
      amount: new BigNumber(amountValue).multipliedBy(new BigNumber(10).exponentiatedBy(pool.tokenDecimals)).toString(10),
      contractAddress: pool.earnContractAddress,
      contractToken: pool.tokenAddress,
      index,
      isWithdrawIncome
    }).then(
      ({ transactionHash }) => {
        setWithdrawAmount({ ...withdrawAmount, [index]: '', [`slider-${index}`]: 0 })
        setDialogCtrl({
          ...dialogCtrl,
          opened: true,
          type: 'success',
          contentText: isWithdrawIncome ? t("v2-ui-7") : t("v2-ui-5"),
          buttonText: <a href={txUrlMap[networkId]+transactionHash} target="_blank">{t("v2-ui-4")}</a>,
          cancelButtonText: t("v2-ui-1"),
          cancelButtonCallback: () => {
            setDialogCtrl({ ...dialogCtrl, opened: false })
          },
          buttonClickCallback: () => {
            setDialogCtrl({ ...dialogCtrl, opened: false });
          }
        })
      }
    ).catch(
      error => {
        setWithdrawAmount({ ...withdrawAmount, [index]: '', [`slider-${index}`]: 0 })
        setDialogCtrl({
          ...dialogCtrl,
          opened: true,
          type: 'warning',
          contentText: isWithdrawIncome ? t("v2-ui-8") : t("v2-ui-6"),
          buttonText: t("v2-ui-3")
        })
      }
    )


  }

  useEffect(() => {
    if (address && web3) {
      fetchBalances({ address, web3, tokens });
      fetchPoolBalances({ address, web3, pools });
      fetchPoolsInfo({ address, web3,pools })
      // 获取所有池子的项目总额并设置
      getPoolTotal(pools)
      const id = setInterval(() => {
        fetchBalances({ address, web3, tokens });
        fetchPoolBalances({ address, web3, pools });
        fetchPoolsInfo({ address, web3,pools })
        // 获取所有池子的项目总额并设置
        getPoolTotal(pools)
      }, 10000);
      return () => clearInterval(id);
    }
  }, [address, web3, fetchBalances, fetchPoolBalances]);

  const isMoreDepostLimit = (value, depostLimit) => {
    if (isEmpty(value) || depostLimit == 0 || value < depostLimit) {
      return false
    }
    return true;
  }
  const matchTvl = (address) => {
    const matched = rateAndTvl.find(tvl => tvl[0].toLowerCase() === address.toLowerCase());
    if (!matched) {
      return (0)
    }
    return (matched[2] / Number(`1e${matched[3]}`))
  }
  // const matchRote = (address) => {
  //   const matched = rateAndTvl.find(tvl => tvl[0].toLowerCase() === address.toLowerCase()) || [];
  //   return matched[1]
  // }
  const forMat = (number, precision = 2) => {
    return new BigNumber(
      number
    ).multipliedBy(
      new BigNumber(10000)
    ).dividedToIntegerBy(
      new BigNumber(1)
    ).dividedBy(
      new BigNumber(10000)
    ).toNumber().toFixed(precision)
  }
  // 千分位格式化
  function formatThousands(num) {
    return num.toString().replace(/\d+/, function (n) { // 先提取整数部分
      return n.replace(/(\d)(?=(\d{3})+$)/g, function ($1) { // 对整数部分添加分隔符
        return $1 + ",";
      });
    });
  }
  // 过滤参与打项目
  const [isPartake, setIsPartake] = useState(false);
  //过滤进行的项目
  const [isProjectStatus, setProjectStatus] = useState(true);
  // 拿到WBNB兑U汇率
  const wbnbRate = bnbRate / 1e18;
  // 所有的项目
  const poolsMap = pools.map((pool, index) => {
    // console.log(pool.compound,pool.mdxCompoundApy)
    // DOGE、HUSD 精度展示6位
    const isSix = pool.tokenOne === 'HUSD' || pool.tokenTwo === 'HUSD' || pool.tokenOne === 'DOGE' || pool.tokenTwo === 'DOGE';
    // 余额
    // console.log(tokens[pool.name])
    const balanceSingle = byDecimals(tokens[pool.name].tokenBalance, pool.tokenDecimals);
    // 已存入
    let { deposited: deposite } = useDeposited(pool.earnContractAddress, pool.tokenAddress, pool.tokenDecimals);
    // // 已存入 折合 U
    const { amountA, amountB, amountUsd, tokenA } = useConvertU(pool.tokenAddress, pool.amount);
    const amountOne = tokenA && pool.tokenOneAddress.toLowerCase() === tokenA.toLowerCase() ? amountA : amountB;
    const amountTwo = tokenA && pool.tokenTwoAddress.toLowerCase() === tokenA.toLowerCase() ? amountA : amountB;

    const url = theme === "bsc" ? "https://bsc.mdex.me/#/add/" : "https://ht.mdex.me/#/add/";

    deposite = byDecimals(deposite, pool.tokenDecimals).toNumber();
    // 已获益
    const earned = useEarned(pool.earnContractAddress, pool.tokenAddress);
    // 回报率
    // const rateMdx = matchRote(pool.tokenAddress) / MDXROTE || pool.rateMdx;
    // 复利 R1/R2*((1+R2/m)^m-1)
    // let { compound, compoundEarned } = useCompound(pool.tokenAddress)
    let finaCompound;
    let finaCompoundTotal;
    let finaCompoundTotalView;
    // let apy;
    const rateMdx = poolRate[pool.tokenAddress];
    const mdxApy = (pool.mdxApy / (100 * rateMdx)) / (10 ** (18 - pool.tokenDecimals));
    let depositedApy = forMat(mdxApy)
    // 单利计算 取接口返回单利 / 币种对mdx汇率 / 币种进度差（拿到币种对mdx汇率再进行计算 否则 展示为0）
    // console.log(useApy(pool.earnContractAddress, pool.tokenAddress, pool, rateMdx))
    // apy = (useApy(pool.earnContractAddress, pool.tokenAddress, pool, rateMdx) / (100 * rateMdx)) / (10 ** (18 - pool.tokenDecimals));
    // apy = (pool.mdxApy / (100 * rateMdx)) / (10 ** (18 - pool.tokenDecimals))
    // depositedApy = forMat(mdxApy);
    // 复利存在 取复利 复利不存在 取单利
    // 复利新增 =  复利 - 单利
    if (pool.mdxCompoundApy > 0 && rateMdx) {
      const depositedCompound = pool.mdxCompoundApy / 100;
      const xCompound = Math.pow((1 + (depositedCompound / 100) / 105120), 105120);
      finaCompound = forMat(((((mdxApy / 100) / (depositedCompound / 100)) * (xCompound - 1)) * 100) - mdxApy);
      finaCompoundTotal = forMat((((mdxApy / 100) / (depositedCompound / 100)) * (xCompound - 1)) * 100);
      finaCompoundTotalView = forMat((((mdxApy / 100) / (depositedCompound / 100)) * (xCompound - 1)) * 100);
    } else {
      finaCompound = undefined;
      finaCompoundTotalView = undefined;
      finaCompoundTotal = depositedApy;
    }
    // console.log(finaCompoundTotalView)
    // 复利未提收益  汇总
    const finaCompoundEarned = forMat(earned * 1 + pool.compoundEarned * 1);
    // console.log(finaCompoundEarned,pool.mdxReward,pool.allowance,pool.amount)
    // 过滤已参与
    // console.log(pool.mdxReward)
    // if(isProjectStatus===pool.conduct){
    //   if (isPartake && !Number(pool.amount)) {
    //     return null;
    //   }
    // }else{
    //   return null
    // }
    if (isPartake && !Number(pool.amount)) {
      return null;
    }
   
    return (
      <div className="pools__item" key={index}>
        {/* <div className={`pools__item${isPartake&&!Number(finaCompoundEarned)?' is-hide':''}`} key={index}> */}
        <div className="pools__box">
          <ul className="pools__rows">
            {/* 币种名称+ICON */}
            <li className="pools__row-1">
              <div className="pools__logo-name">
                <div className="pool__logo-container">
                  <img
                    className="pools__coin-logo"
                    alt={pool.name}
                    src={require(`../../../images/icon2/${pool.tokenOne}.svg`)}
                  />
                  <img
                    className="pools__coin-logo pools__coin-logo--overlap"
                    alt={pool.name}
                    src={require(`../../../images/icon2/${pool.tokenTwo}.svg`)}
                  />
                </div>
                <div className="pools__coin-name-multiply">
                  {pool.name}
                  <div className="pools__info">
                    {/* {t('Vault-Tips-3')}LP{t('Vault-Tips-4')} */}
                    {t('Vault-Tips-3')}{i18n.language === 'zh' || i18n.language === 'zh-CN' ? 'LP' : ''}{t('Vault-Tips-4')}
                    {/* <SectionQuestion value={t('Vault-Tips1')} /> */}
                  </div>
                </div>
              </div>
              {/* <div className="pools__info">
                {t('Vault-Tips-3')}LP{t('Vault-Tips-4')}
                <SectionQuestion value={t('Vault-Tips1')} />
              </div> */}
            </li>
            {/* 年化收益率 */}
            <li className="pools__row pools__apy">
              <div className="pools__labe-field">
                {t('Vault-ListAPY')}<span className={`color-blue ${theme}-color-blue`}>{t('Vault-ListAPY1')}</span>
                <SectionQuestion value={
                  <div>
                    <div className="question__title">
                      {t("Total-Yield")}
                      <strong style={{ color: "rgb(255, 175, 103)" }}>
                        {
                          pool.modify===true?'0.00':isNaN(finaCompoundTotal) ? '0.00' : finaCompoundTotal
                        }
                        %
                      </strong>
                    </div>
                    <div className="question__item">
                      <div className="ques__labe-field">
                        <span className="ques__labe-line ques__labe-line--first"></span>
                        <span className="ques__labe-child">MDX{t("Nav-Farm")}({t('APR')})</span>
                      </div>
                      <strong style={{ color: "rgb(255, 175, 103)" }}>
                        {
                          pool.modify===true?'0.00':isNaN(depositedApy) ? '0.00' : depositedApy
                        }
                        %
                      </strong>
                    </div>
                    <div className="question__item">
                      <div className="ques__labe-field">
                        <span className="ques__labe-line"></span>
                        <span className="ques__labe-child">MDX{t("Nav-Farm")}({t('APY')})</span>
                      </div>
                      <strong style={{ color: "rgb(255, 175, 103)" }}>
                        {
                          pool.modify===true?'0.00':isNaN(finaCompound) ? '0.00' : finaCompound
                        }
                        %
                      </strong>
                    </div>
                    <div className="question__item-text" style={{ display: 'block', marginTop: '10px', color: "#131D32" }}>
                      {t('Apr-Tips1')}
                    </div>
                    <div className="question__item-text" style={{ color: "#131D32" }}>
                      {t('Apr-Tips2')}
                    </div>
                  </div>
                } />
              </div>
              <div className="pools__apy-value">
                {
                  pool.modify===true?'0.00':isNaN(finaCompoundTotalView) ? '0.00' : finaCompoundTotalView
                }
                %
              </div>
            </li>
            {/* 未提收益 */}
            <li className="pools__row">
              <div className="pools__labe-field">{t('v2-ui-9')}(MDX)<SectionQuestion value={t('Vault-TipsEarned1')} /></div>
              {/* <div className="pools__label-value pools__label-value--black">{String((finaCompoundEarned / 1e18).toFixed(5)).slice(0, -1)}</div> */}
              <div className="pools__label-value pools__label-value--black">{String((pool.mdxReward / 1e18).toFixed(5)).slice(0, -1)}</div>
            </li>
            {/* 已存入 */}
            <li className="pools__row">
              <div className="pools__labe-field">{t('Vault-ListDeposited')} ($)</div>
              <div className="pools__label-value">{amountUsd ? String((amountUsd / 1e18).toFixed(3)).slice(0, -1) : 0}</div>
              {/* <div className="pools__label-value">{String((pool.amount / 1e18).toFixed(3)).slice(0, -1)}</div> */}
            </li>
            {/* 锁仓量 */}
            <li className="pools__row">
              <div className="pools__labe-field">{t('Lock-volume')} ($)</div>
              <div className="pools__label-value">{formatThousands((matchTvl(pool.tokenAddress) * (coinRate[pool.tokenAddress] ? coinRate[pool.tokenAddress] / 1e18 : 1)).toFixed(2))}</div>
            </li>
            {/* 剩余可投 */}
            {/* <li className="pools__row">
              <div className="pools__labe-field">{t('Remaining-investment')} (LP)<SectionQuestion value={t('Vault-Tips2')} /></div>
              <div className="pools__label-value">{(pool.total - matchTvl(pool.tokenAddress)).toFixed(2) <= 0 ? (0).toFixed(2) : (pool.total - matchTvl(pool.tokenAddress)).toFixed(2)}</div>
            </li> */}
            {/* 百分比 */}
            {/* <li className="pools__row-rate">
              <div className="pools__rate">
                <span className={`${theme}-pools_rate`} style={{ width: `${(matchTvl(pool.tokenAddress) / pool.total).toFixed(2) * 100}%` }}></span>
              </div>
              <div className="pools__rate-value">
                {(matchTvl(pool.tokenAddress) / pool.total).toFixed(2) * 100 > 100 ? 100 : (pool.total == 0 ? 0 : String((matchTvl(pool.tokenAddress) / pool.total * 100)).split('.')[0])}%
              </div>
            </li> */}
            {/* 授权 提取 存入按钮 */}
            <li className="pools__group-buttons">
              {connected ? (pool.allowance == 0 ?
                // 未授权 展示 提取 和 授权
                <div className="pools__button-group">
                  <Button
                    className="pools__dialog__option"
                    type={2}
                    loading={pool.dialog.widthdrawOpened}
                    onClick={() => openDialogHandler('widthdraw', index)}
                  >
                    {pool.dialog.widthdrawOpened ? '' : t('v2-ui-10')}
                  </Button>
                  <Button
                    className="pools__dialog__option"
                    loading={fetchApprovalPending[index]}
                    disabled={fetchApprovalPending[index]}
                    onClick={onApproval.bind(this, pool, index)}
                  >
                    {fetchApprovalPending[index] ? '' : t('Vault-ApproveButton')}
                  </Button>
                </div> :
                // <Button loading={fetchApprovalPending[index]} onClick={onApproval.bind(this, pool, index)} disabled={fetchApprovalPending[index]}>
                //   {fetchApprovalPending[index] ? '' : t('Vault-ApproveButton')}
                // </Button> :
                // 已授权 展示 提取 和 存入
                <div className="pools__button-group">
                  <Button
                    className="pools__dialog__option"
                    type={2}
                    loading={pool.dialog.widthdrawOpened}
                    onClick={() => openDialogHandler('widthdraw', index)}
                  >
                    {pool.dialog.widthdrawOpened ? '' : t('v2-ui-10')}
                  </Button>
                  <Button
                    className="pools__dialog__option"
                    loading={pool.dialog.depositOpened}
                    onClick={() => openDialogHandler('deposit', index)}
                  >
                    {pool.dialog.depositOpened ? '' : t("Vault-DepositButton")}
                  </Button>
                </div>) :
                <Button className="pools__dialog__option" loading={connectWalletPending} onClick={connectHandler} disabled={connectWalletPending}>
                  {connectWalletPending ? '' : t("v2-ui-17")}
                </Button>
              }
            </li>
            <li className={`pools__row-jump pools__row-${theme}`}>
              <a href={`${url}${pool.tokenOneAddress}/${pool.tokenTwoAddress}`} target="_blank">
                {t('GoLiquied')}
              </a></li>
          </ul>
          {/* 毛玻璃icon */}
          <div className="pools__mao-logo__wrap">
            <img
              className="pools__mao-logo"
              alt={pool.token}
              src={require(`../../../images/icon2/${pool.tokenOne}.svg`)}
            />
          </div>
          {/* 角标 */}
          {
            pool.type === "New" ? <img src={require(`../../../images/jiaobiao-${theme}.svg`)} style={{
              position: "absolute",
              right: "-1px",
              top: "-1px",
            }} /> : ""
          }
        </div>

        {/* 弹出框内容 */}
        {pool.dialog.opened ? <div className='pools__dialog'>
          {/* 存入相关 */}
          {
            pool.dialog.depositOpened ? <div className="pools__dialog-inner pools__dialog-deposite">
              <div className="pools__dialog__header">
                {t("Vault-DepositButton")}
                <span className="pools__dialog__header__close" onClick={() => closeDialogHandler('deposit', index)}></span>
              </div>
              <ul className="pools__dialog__fields">
                {/* logo coinname info */}
                <li className="pools__row-1">
                  <div className="pools__logo-name">
                    <div className="pool__logo-container">
                      <img
                        className="pools__coin-logo"
                        alt={pool.token}
                        src={require(`../../../images/icon2/${pool.tokenOne}.svg`)}
                      />
                      <img
                        className="pools__coin-logo pools__coin-logo--overlap"
                        alt={pool.token}
                        src={require(`../../../images/icon2/${pool.tokenTwo}.svg`)}
                      />
                    </div>
                    <div className="pools__coin-name-multiply">
                      {pool.name}
                      {/* 存在标签时展示问号悬浮 否则 不展示 */}
                      {/* {pool.type ? <SectionQuestion value={<span className="SectionQuestion-custom-tips">
                    <time>{pool.openDate}</time>
                    <span>{t('Vault-View-9')}<strong>{pool.openAmount}</strong>{t('Vault-View-10')}</span>
                  </span>} label={pool.type == 'NEW' ? "NEW" : t('Vault-View-11')} type={pool.type ? 1 : 0} /> : ''} */}
                    </div>
                  </div>
                  <div className="pools__info">
                    {/* {t('Vault-Tips-3')}LP{t('Vault-Tips-4')} */}
                    {t('Vault-Tips-3')}{i18n.language === 'zh' || i18n.language === 'zh-CN' ? 'LP' : ''}{t('Vault-Tips-4')}
                    {/* <SectionQuestion value={t('Vault-Tips1')} /> */}
                  </div>
                </li>
                {/* 已存入 */}
                <li className="pools__row">
                  <div className="pools__labe-field">
                    {t('Vault-ListDeposited')}(LP)
                    <SectionQuestion value={
                      <span>
                        <span style={{ display: 'block', fontSize: "12px" }}>{t('Lp-Tips1')}</span>
                        <span style={{ display: 'block', fontSize: "12px" }}>{t('Lp-Tips2')}</span>
                      </span>
                    } />
                  </div>
                  <div className="pools__label-value pools__label-value--black">
                    <span className="pools__label-value-text">≈$ {amountUsd ? String((amountUsd / 1e18).toFixed(5)).slice(0, -1) : 0}</span>
                    {/* <span className="pools__label-value-text">≈$ {pool.amount ? String((pool.amount / 1e18).toFixed(5)).slice(0, -1) : 0}</span> */}
                    {(isSix) ? String(deposite.toFixed(7)).slice(0, -1) : String(deposite.toFixed(5)).slice(0, -1)}
                  </div>
                </li>
                {/* 折合 币种1 */}
                <li className="pools__row pools_row-child">
                  <div className="pools__labe-field">
                    <span className="pools__labe-line pools__labe-line--first"></span>
                    <span className="pools__labe-child">{t('Covert')} {pool.tokenOne}</span>
                  </div>
                  <div className="pools__label-value pools__label-value--black">
                    {/* <span className="pools__label-value-text">≈$ {String((amountUsd/1e18/2).toFixed(4)).slice(0, -1)}</span> */}
                    {isNaN(amountOne) ? 0 : String((amountOne / 1e18).toFixed(5)).slice(0, -1)}
                  </div>
                </li>
                {/* 折合 币种2 */}
                <li className="pools__row pools_row-child">
                  <div className="pools__labe-field">
                    <span className="pools__labe-line"></span>
                    <span className="pools__labe-child">{t('Covert')} {pool.tokenTwo}</span>
                  </div>
                  <div className="pools__label-value pools__label-value--black">
                    {/* <span className="pools__label-value-text">≈$ {String((amountUsd/1e18/2).toFixed(4)).slice(0, -1)}</span> */}
                    {isNaN(amountTwo) ? 0 : String((amountTwo / 1e18).toFixed(5)).slice(0, -1)}
                  </div>
                </li>
                {/* 剩余可存 */}
                {/* <li className="pools__row">
                  <div className="pools__labe-field">
                    {t('Vault-SYDeposited')}(LP)
                  </div>
                  <div className="pools__label-value pools__label-value--black">
                    {(pool.total - matchTvl(pool.tokenAddress)).toFixed(2) <= 0 ? (0).toFixed(2) : (pool.total - matchTvl(pool.tokenAddress)).toFixed(2)}
                  </div>
                </li> */}
                {/* 余额 */}
                <li className="pools__row">
                  <div className="pools__labe-field">
                    {t('Vault-Balance')}(LP)
                  </div>
                  <div className="pools__label-value pools__label-value--black">
                    <span className="pools__label-value-text">≈$ {(balanceSingle * (coinRate[pool.tokenAddress] ? coinRate[pool.tokenAddress] / 1e18 : 1)).toFixed(5).slice(0, -1)}</span>
                    {(isSix) ? String(balanceSingle.toFormat(7)).slice(0, -1) : String(balanceSingle.toFormat(5)).slice(0, -1)}
                  </div>
                </li>
                {/* 输入框 */}
                <li className="pools__dialog__input">
                  <input
                    placeholder={t('v2-ui-11')}
                    value={depositedBalance[index] != undefined ? depositedBalance[index] : ''}
                    onChange={changeDetailInputValue.bind(this, 'depositedBalance', index, balanceSingle.toNumber(), pool.tokenDecimals)}
                  />
                  {/* 最大 全部存入按钮 */}
                  <Button
                    className="pools__dialog__deposit-all"
                    onClick={onDeposit.bind(this, pool, index, true, balanceSingle)}
                    loading={fetchDepositPending[index]}
                    type={2}
                    disabled={
                      !balanceSingle.toNumber() || fetchDepositPending[index] || (isMoreDepostLimit(balanceSingle.toNumber(), pool.depostLimit))
                    }
                    >
                    {fetchDepositPending[index] ? '' : t('v2-ui-12')}
                  </Button>
                </li>
                {/* 存入说明 */}
                {theme === "bsc" ? <li className={`gas-line gas-${theme}`}>
                  {t("gasTip1")}<span>{((3000000 * price) / 1e18).toFixed(4).slice(0, -1)}BNB(≈${(((3000000 * price) / 1e18) * wbnbRate).toFixed(3).slice(0, -1)})</span>{t("gasTip2")}<span>
                    {(((3000000 * price) / 1e18) * 0.3).toFixed(4).slice(0, -1)}BNB(≈${(((3000000 * price) / 1e18) * wbnbRate * 0.3).toFixed(3).slice(0, -1)})
                    - {(((3000000 * price) / 1e18) * 0.65).toFixed(4).slice(0, -1)}BNB(≈${(((3000000 * price) / 1e18) * wbnbRate * 0.65).toFixed(3).slice(0, -1)})</span>
                  {t("gasTip3")}
                </li> : ''}
                {/* 存入按钮 */}
                <li>
                  <Button
                    className="pools__dialog__option"
                    onClick={onDeposit.bind(this, pool, index, false, balanceSingle)}
                    loading={fetchDepositPending[index]}
                    disabled={
                      !Number(String((depositedBalance[index])).replace(',', '')) || fetchDepositPending[index] || isMoreDepostLimit(new BigNumber(depositedBalance[index]).toNumber(), pool.depostLimit)
                    }>{fetchDepositPending[index] ? '' : t("Vault-DepositButton")}</Button>
                </li>
              </ul>
            </div> : null
          }
          {/* 提取相关 */}
          {
            pool.dialog.widthdrawOpened ? <div className="pools__dialog-inner pools__dialog-withdraw">
              <div className="pools__dialog__header">
                {t('v2-ui-10')}
                <span className="pools__dialog__header__close" onClick={() => closeDialogHandler('widthdraw', index)}></span>
              </div>
              <ul className="pools__rows">
                {/* 币种名称+ICON */}
                <li className="pools__row-1">
                  <div className="pools__logo-name">
                    <div className="pool__logo-container">
                      <img
                        className="pools__coin-logo"
                        alt={pool.token}
                        src={require(`../../../images/icon2/${pool.tokenOne}.svg`)}
                      />
                      <img
                        className="pools__coin-logo pools__coin-logo--overlap"
                        alt={pool.token}
                        src={require(`../../../images/icon2/${pool.tokenTwo}.svg`)}
                      />
                    </div>
                    <div className="pools__coin-name-multiply-multiply">
                      {pool.name}
                      {/* 存在标签时展示问号悬浮 否则 不展示 */}
                      {/* {pool.type ? <SectionQuestion value={<span className="SectionQuestion-custom-tips">
                    <time>{pool.openDate}</time>
                    <span>{t('Vault-View-9')}<strong>{pool.openAmount}</strong>{t('Vault-View-10')}</span>
                  </span>} label={pool.type == 'NEW' ? "NEW" : t('Vault-View-11')} type={pool.type ? 1 : 0} /> : ''} */}
                    </div>
                  </div>
                  <div className="pools__info">
                    {/* {t('Vault-Tips-3')}LP{t('Vault-Tips-4')} */}
                    {t('Vault-Tips-3')}{i18n.language === 'zh' || i18n.language === 'zh-CN' ? 'LP' : ''}{t('Vault-Tips-4')}
                    {/* <SectionQuestion value={t('Vault-Tips1')} /> */}
                  </div>
                </li>
                {/* 提取收益 */}
                <li className="pools__income-field">
                  <div className="pools__income-label">
                    <p>{t('Vault-WithdrawCan')}({pool.earnedToken})</p>
                    {/* <p>{String((finaCompoundEarned / 1e18).toFixed(5)).slice(0, -1)}</p> */}
                    <p>{String((pool.mdxReward / 1e18).toFixed(5)).slice(0, -1)}</p>
                  </div>
                  <div className="pools__income-button">
                    <Button
                      loading={fetchWithdrawIncomePending[index]}
                      // disabled={fetchWithdrawIncomePending[index] || !Number(forMat(finaCompoundEarned / 1e18, 8))}
                      disabled={fetchWithdrawIncomePending[index] || !Number(forMat(pool.mdxReward / 1e18, 8))}
                      onClick={onWithdraw.bind(this, pool, index, false, deposite, true)}
                    >{fetchWithdrawIncomePending[index] ? '' : t("Vault-WithdrawIncome")}</Button>
                  </div>
                </li>
                {/* 可提取本金 */}
                {/* <li className="pools__dialog__withdraw-field">
                  <span>{t("v2-ui-14")}({pool.token})</span>
                  <span>{String(deposite.toFixed(5)).slice(0, -1)}</span>
                </li> */}
                <li className="pools__row">
                  <div className="pools__labe-field">
                    {t('Vault-WithdrawButtonAll')}(LP)
                    <SectionQuestion value={
                      <span>
                        <span style={{ display: 'block', fontSize: "12px" }}>{t('Lp-Tips1')}</span>
                        <span style={{ display: 'block', fontSize: "12px" }}>{t('Lp-Tips2')}</span>
                      </span>
                    } />
                  </div>
                  <div className="pools__label-value pools__label-value--black">
                    <span className="pools__label-value-text">≈$ {amountUsd ? String((amountUsd / 1e18).toFixed(5)).slice(0, -1) : 0}</span>
                    {/* <span className="pools__label-value-text">≈$ {pool.amount ? String((pool.amount / 1e18).toFixed(5)).slice(0, -1) : 0}</span> */}
                    {(isSix) ? String(deposite.toFixed(7)).slice(0, -1) : String(deposite.toFixed(5)).slice(0, -1)}
                  </div>
                </li>
                {/* 折合 币种1 */}
                <li className="pools__row pools_row-child">
                  <div className="pools__labe-field">
                    <span className="pools__labe-line pools__labe-line--first"></span>
                    <span className="pools__labe-child">{t('Covert')} {pool.tokenOne}</span>
                  </div>
                  <div className="pools__label-value pools__label-value--black">
                    {/* <span className="pools__label-value-text">≈$ {String((amountUsd/1e18/2).toFixed(4)).slice(0, -1)}</span> */}
                    {isNaN(amountOne) ? 0 : String((amountOne / 1e18).toFixed(5)).slice(0, -1)}
                  </div>
                </li>
                {/* 折合 币种2 */}
                <li className="pools__row pools_row-child">
                  <div className="pools__labe-field">
                    <span className="pools__labe-line"></span>
                    <span className="pools__labe-child">{t('Covert')} {pool.tokenTwo}</span>
                  </div>
                  <div className="pools__label-value pools__label-value--black">
                    {/* <span className="pools__label-value-text">≈$ {String((amountUsd/1e18/2).toFixed(4)).slice(0, -1)}</span> */}
                    {isNaN(amountTwo) ? 0 : String((amountTwo / 1e18).toFixed(5)).slice(0, -1)}
                  </div>
                </li>
                {/* 输入框 */}
                <li className="pools__dialog__input">
                  <input
                    placeholder={t('v2-ui-11')}
                    value={withdrawAmount[index] != undefined ? withdrawAmount[index] : ''}
                    onChange={changeDetailInputValue.bind(this, 'withdrawAmount', index, deposite, pool.itokenDecimals)}
                  />
                  {/* 最大按钮 */}
                  <Button
                    className="pools__dialog__deposit-all"
                    type={2}
                    onClick={onWithdraw.bind(this, pool, index, true, deposite, false)}
                    loading={fetchWithdrawPending[index]}
                    disabled={
                      !deposite || fetchWithdrawPending[index] || (isMoreDepostLimit(deposite, pool.depostLimit))
                    }>
                    {fetchWithdrawPending[index] ? '' : t('v2-ui-13')}
                  </Button>
                </li>
                {/* 提取说明 */}
                {theme === "bsc" ? <li className={`gas-line gas-${theme}`}>
                  {t("gasTip1")}<span>{((6000000 * price) / 1e18).toFixed(4).slice(0, -1)}BNB(≈${(((6000000 * price) / 1e18) * wbnbRate).toFixed(3).slice(0, -1)})</span>{t("gasTip2")}<span>
                    {(((6000000 * price) / 1e18) * 0.3).toFixed(4).slice(0, -1)}BNB(≈${(((6000000 * price) / 1e18) * wbnbRate * 0.3).toFixed(3).slice(0, -1)})
                    - {(((6000000 * price) / 1e18) * 0.65).toFixed(4).slice(0, -1)}BNB(≈${(((6000000 * price) / 1e18) * wbnbRate * 0.65).toFixed(3).slice(0, -1)})</span>
                  {t("gasTip3")}
                </li> : ''}
                {/* 提取本金 */}
                <li>
                  <Button
                    className="pools__dialog__option"
                    loading={fetchWithdrawPending[index]}
                    disabled={!Number(String(withdrawAmount[index]).replace(',', '')) || fetchWithdrawPending[index]}
                    onClick={onWithdraw.bind(this, pool, index, false, deposite, false)}>{fetchWithdrawPending[index] ? '' : t("v2-ui-10")}</Button>
                </li>
              </ul>
            </div> : null
          }
          {
            isDia&& <div className='pools__dialog' style={{zIndex:'3000'}}>
              <div className='pools__dialog-inners pools__dialog-deposite'>
                <div className='pools__dialog__header'>{t('Tips')}<span className='pools__dialog__header__close' onClick={()=>setIsDia(false)}></span></div>
                <div className='pools__dialog__fields'>
                  <div style={{textAlign:'center'}}>{t('Tips-content')}</div>
                  <div style={{display:"flex",justifyContent:"space-between",marginTop:'30px'}}>
                    <div style={{width:'40%'}}>
                      <Button
                        className="pools__dialog__option"
                        onClick={()=>setIsDia(false)}
                      >
                      {t('Tips-button')}
                      </Button>
                    </div>
                    <div style={{width:'40%'}}>
                      <Button
                        className="pools__dialog__option"
                            loading={fetchApprovalPending[index]}
                            disabled={fetchApprovalPending[index]}
                            onClick={onApproval.bind(this, pool, index)}
                      >
                            {fetchApprovalPending[index] ? '' : t('Vault-ApproveButton')}
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          }
        </div> : null
        }
      </div>
    )
  })
 
  return (
    <>
    {
      !_IS_ETH_&&<H5TabDefi></H5TabDefi>
    }
      <Dialog
        type={dialogCtrl.type}
        opened={dialogCtrl.opened}
        buttonText={dialogCtrl.buttonText}
        cancelButtonText={dialogCtrl.cancelButtonText}
        cancelButtonCallback={dialogCtrl.cancelButtonCallback}
        buttonClickCallback={dialogCtrl.buttonClickCallback}
      >{dialogCtrl.contentText}</Dialog>
      {/* 说明文字 */}
      <SectionTitle />
      {
        address ? <SectionTotal
          address={address}
        /> : null
      }
      {/* 少选过滤 */}
      <SectionFilter isPartake={isPartake} filterPartakeHandler={() => setIsPartake(!isPartake)} isProjectStatus={isProjectStatus} filterPartakeHandlers={() => setProjectStatus(!isProjectStatus)} />
      <div className="pools-main">
        {/* (Boolean(networkId === Number(process.env.NETWORK_ID)) */}
        {(Boolean(networkId === Number(process.env.NETWORK_ID)) || Boolean(networkId === Number(process.env.NETWORK_ID_II))) && poolsMap}
        {!poolsMap.filter(pool => !!pool).length ? <div className="pools__empty">
          <img src={require("../../../assets/img/empty.svg")} />
          <p>{t("v2-ui-15")}</p>
          {/* {
            (isPartake&&isProjectStatus)?<p>{t("v2-ui-15-2")}</p> :isPartake? <p>{t("v2-ui-15")}</p>:<p>{t("v2-ui-15-1")}</p>
          } */}
          <Button type={2} onClick={() => setIsPartake(false)}>{t("v2-ui-16")}</Button>
        </div> : null}
      </div>
    </>
  )
})
