export const _CURRENT_NETWORK_ID_ = Number(window.localStorage.getItem("NETWORK_ID_USE"));
const _ADD_NETWORK_ID_ = 1;
const _ADD_NETWORK_NAME_ = 'ETH';

export const _NETWORK_ID_MAP_NAME_ = {
    128: 'HECO',
    56: 'BSC',
    'BSC': 56,
    'HECO': 128,
    [_ADD_NETWORK_ID_]: _ADD_NETWORK_NAME_,
    [_ADD_NETWORK_NAME_]: [_ADD_NETWORK_ID_],
}

export const _IS_CURRENT_NETWORK_ID_SUPPORTED_ = !!_NETWORK_ID_MAP_NAME_[_CURRENT_NETWORK_ID_]
export const _IS_HECO_ = _NETWORK_ID_MAP_NAME_[_CURRENT_NETWORK_ID_] === 'HECO';
export const _IS_BSC_ = _NETWORK_ID_MAP_NAME_[_CURRENT_NETWORK_ID_] === 'BSC';
export const _IS_ETH_ = _NETWORK_ID_MAP_NAME_[_CURRENT_NETWORK_ID_] === _ADD_NETWORK_NAME_;

export const gasMap = {
    "128": 8600000,
    "56": {
        deposit:3000000,
        withdraw:8600000
    },
    [_ADD_NETWORK_ID_]: 1000000
}
export const ctrAddressMap = {
    "128": "0x05778C2877E54fc74a042240dA4263e9E7Ce76A1",
    "56": "0x739C0764Ba2Fb57629a6d186b5c262F998070DfB",
    [_ADD_NETWORK_ID_]: "0x67bc0466eec51f69c835b7351b3fc7ab2050601f"
}

export const txUrlMap = {
    "128": "https://hecoinfo.com/tx/",
    "56": "https://bscscan.com/tx/",
    [_ADD_NETWORK_ID_]: "https://cn.etherscan.com/tx/"
}

export const aggContractAddressMap = {
    "128": "0xF17456385c33c2eD84940a99C6a2C397B8A2e5Ce",
    "56": "0x390B8F965b8EBf4C33Ae88AAAc8b59052E77Eb96",
    [_ADD_NETWORK_ID_]: "0x9C96400C88bCCbD5D5e71f14E358e56d95A07ae4"
}

export const cowAddressMap = {
    "128": "0x80861A817106665bcA173DB6AC2ab628a738c737",
    "56": "0x422E3aF98bC1dE5a1838BE31A56f75DB4Ad43730",
     // [_ADD_NETWORK_ID_]: "0x34965f73Cfa05Bf8d8aF37cB4af64Fa950605Ea8"
     '1':"0x34965f73Cfa05Bf8d8aF37cB4af64Fa950605Ea8",
     '5':'0x8e9192D6f9d903b1BEb3836F52a9f71E05846e42'
}

export const mdxAddressMap = {
    "128": "0x25D2e80cB6B86881Fd7e07dd263Fb79f4AbE033c",
    "56": "0x9C65AB58d8d978DB963e63f2bfB7121627e3a739",
    // [_ADD_NETWORK_ID_]: "0x34965f73Cfa05Bf8d8aF37cB4af64Fa950605Ea8"
    '1':"0x34965f73Cfa05Bf8d8aF37cB4af64Fa950605Ea8",
    '5':'0x8e9192D6f9d903b1BEb3836F52a9f71E05846e42'
}

export const compoundHubAdressMap = {
    "128": "0x660C9CB8f940Df6625B850813f87C018b000bE5E",
    "56": "0x6BA90A574C7270150B7b3d88E88dF4d4Ab7b89bA",
    [_ADD_NETWORK_ID_]: "eth todo compoundHubAdressMap"
}

// 获取空投跳转地址
export const airdropAddressMap = {
    bsc: 'https://bsc.mdex.me/#/swap?inputCurrency=0x55d398326f99059ff775485246999027b3197955&outputCurrency=0x422E3aF98bC1dE5a1838BE31A56f75DB4Ad43730',
    heco: 'https://ht.mdex.me/#/swap?inputCurrency=0xa71edc38d189767582c38a3145b5873052c3e47a&outputCurrency=0x80861A817106665bcA173DB6AC2ab628a738c737',
    [_ADD_NETWORK_NAME_.toLowerCase()]: 'https://app.uniswap.org/#/swap?inputCurrency=0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48&outputCurrency=0x34965f73cfa05bf8d8af37cb4af64fa950605ea8'
}

export const netUrlAddressMap = {
    "128": "https://hecoinfo.com/address/",
    "56": "https://bscscan.com/address/",
    [_ADD_NETWORK_ID_]: "https://cn.etherscan.com/address/"
}

export const scanTxMaps = {
    "128": "https://bscscan.com/tx/",
    "56": "https://hecoinfo.com/tx/",
    [_ADD_NETWORK_ID_]: "https://cn.etherscan.com/tx/"
}

export const switchChainRequestParams = {
    BSC: {
        method: "wallet_addEthereumChain",
        params: [{
            chainId: "0x38",
            chainName: "BSC Mainnet",
            nativeCurrency: {
                name: "BSC",
                symbol: "BNB",
                decimals: 18
            },
            rpcUrls: ["https://rpc.ankr.com/bsc"],
            blockExplorerUrls: ["https://bscscan.com/"]
        }]
    },
    HECO: {
        method: "wallet_addEthereumChain",
        params: [{
            chainId: "0x80",
            chainName: "Heco Mainnet",
            nativeCurrency: {
                name: "Heco",
                symbol: "HT",
                decimals: 18
            },
            rpcUrls: ["https://http-mainnet-node.defibox.com"],
            blockExplorerUrls: ["https://hecoinfo.com"]
        }]
    },
    [_ADD_NETWORK_NAME_]: {
        method: 'wallet_switchEthereumChain',
        params: [{ chainId: '0x1' }],
    }
}

export const carouselBanners = {
    heco: {
        pc: {
            zh: [
                {
                    bannerSrc: require("../../assets/img/web_CH.png"),
                },

                // {
                //     bannerSrc: require("../../assets/img/ethbanner1/pc/联合空投¥30000-中文@2x-min.png"),
                //     href: "https://docs.coinwind.com/v/chinese/announcement/20210719-1"
                // },
                // {
                //     bannerSrc: require("../../assets/img/ethbanner1/pc/部署至ETH链-中文@2x-min.png"),
                //     href: "https://docs.coinwind.com/v/chinese/announcement/20210719"
                // },
                require("../../assets/img/web/home/banner-home-heco-zhong.png"),
                {
                    bannerSrc: require("../../assets/img/banner4.png"),
                    href: "https://docs.coinwind.pro/v/chinese/announcement/20210623"
                },
            ],
            en: [
                {
                    bannerSrc: require("../../assets/img/web_En.png"),
                },

                // {
                //     bannerSrc: require("../../assets/img/ethbanner1/pc/联合空投¥30000-英文@2x-min.png"),
                //     href: "https://docs.coinwind.com/announcement/20210719-1"
                // },
                // {
                //     bannerSrc: require("../../assets/img/ethbanner1/pc/部署至ETH链-英文@2x-min.png"),
                //     href: "https://docs.coinwind.com/announcement/20210719"
                // },
                require("../../assets/img/web/home/banner-home-heco-english.png"),
                {

                    bannerSrc: require("../../assets/img/banner4-english.png"),
                    href: "https://docs.coinwind.pro/announcement/20210623"
                },

            ]
        },
        h5: {
            zh: [

                {
                    bannerSrc: require("../../assets/img/h5_ch.png"),
                },

                // {
                //     bannerSrc: require("../../assets/img/ethbanner1/h5/手机端-联合空投¥30000-中文@2x-min.png"),
                //     href: "https://docs.coinwind.com/v/chinese/announcement/20210719-1"
                // },
                // {
                //     bannerSrc: require("../../assets/img/ethbanner1/h5/手机端-部署至ETH链-中文@2x-min.png"),
                //     href: "https://docs.coinwind.com/v/chinese/announcement/20210719"
                // },
                require("../../assets/img/app/home/banner-home-heco-zhong.png"),
                {
                    bannerSrc: require("../../assets/img/banner4-h5-heco.png"),
                    href: "https://docs.coinwind.pro/v/chinese/announcement/20210623"
                },

            ],
            en: [

                {
                    bannerSrc: require("../../assets/img/h5_EN.png"),
                },

                // {
                //     bannerSrc: require("../../assets/img/ethbanner1/h5/手机端-联合空投¥30000-英文@2x-min.png"),
                //     href: "https://docs.coinwind.com/announcement/20210719-1"
                // },
                // {
                //     bannerSrc: require("../../assets/img/ethbanner1/h5/手机端-部署至ETH链-英文@2x-min.png"),
                //     href: "https://docs.coinwind.com/announcement/20210719"
                // },
                require("../../assets/img/app/home/banner-home-heco-english.png"),
                {
                    bannerSrc: require("../../assets/img/banner4-h5-english-heco.png"),
                    href: "https://docs.coinwind.pro/announcement/20210623"
                },


            ]
        }
    },
    bsc: {
        pc: {
            zh: [
                {
                    bannerSrc: require("../../assets/img/web_CH.png"),
                },

                // {
                //     bannerSrc: require("../../assets/img/ethbanner1/pc/联合空投¥30000-中文@2x-min.png"),
                //     href: "https://docs.coinwind.com/v/chinese/announcement/20210719-1"
                // },
                // {
                //     bannerSrc: require("../../assets/img/ethbanner1/pc/部署至ETH链-中文@2x-min.png"),
                //     href: "https://docs.coinwind.com/v/chinese/announcement/20210719"
                // },
                require("../../assets/img/web/home/banner-home-bsc-zhong.png"),
                {
                    bannerSrc: require("../../assets/img/banner4.png"),
                    href: "https://docs.coinwind.pro/v/chinese/announcement/20210623"
                },

            ],
            en: [
                {
                    bannerSrc: require("../../assets/img/web_En.png"),
                },

                // {
                //     bannerSrc: require("../../assets/img/ethbanner1/pc/联合空投¥30000-英文@2x-min.png"),
                //     href: "https://docs.coinwind.com/announcement/20210719-1"
                // },
                // {
                //     bannerSrc: require("../../assets/img/ethbanner1/pc/部署至ETH链-英文@2x-min.png"),
                //     href: "https://docs.coinwind.com/announcement/20210719"
                // },
                require("../../assets/img/web/home/banner-home-bsc-english.png"),
                {
                    bannerSrc: require("../../assets/img/banner4-english.png"),
                    href: "https://docs.coinwind.pro/announcement/20210623"
                },

            ]
        },
        h5: {
            zh: [
                {
                    bannerSrc: require("../../assets/img/h5_ch.png"),
                },

                // {
                //     bannerSrc: require("../../assets/img/ethbanner1/h5/手机端-联合空投¥30000-中文@2x-min.png"),
                //     href: "https://docs.coinwind.com/v/chinese/announcement/20210719-1"
                // },
                // {
                //     bannerSrc: require("../../assets/img/ethbanner1/h5/手机端-部署至ETH链-中文@2x-min.png"),
                //     href: "https://docs.coinwind.com/v/chinese/announcement/20210719"
                // },
                // {
                //     bannerSrc: require("../../assets/img/ktbanner/banner-h5-heco.png"),
                //     href: "https://docs.coinwind.com/announcement/20210630"
                // },
                require("../../assets/img/app/home/banner-home-bsc-zhong.png"),
                {
                    bannerSrc: require("../../assets/img/banner4-h5-heco.png"),
                    href: "https://docs.coinwind.pro/v/chinese/announcement/20210623"
                },

            ],
            en: [
                {
                    bannerSrc: require("../../assets/img/h5_EN.png"),
                },

                // {
                //     bannerSrc: require("../../assets/img/ethbanner1/h5/手机端-联合空投¥30000-英文@2x-min.png"),
                //     href: "https://docs.coinwind.com/announcement/20210719-1"
                // },
                // {
                //     bannerSrc: require("../../assets/img/ethbanner1/h5/手机端-部署至ETH链-英文@2x-min.png"),
                //     href: "https://docs.coinwind.com/announcement/20210719"
                // },
                // {
                //     bannerSrc: require("../../assets/img/ktbanner/banner-h5-english-heco.png"),
                //     href: "https://docs.coinwind.com/v/english/announcement/20210630"
                // },
                require("../../assets/img/app/home/banner-home-bsc-english.png"),
                {
                    bannerSrc: require("../../assets/img/banner4-h5-english-heco.png"),
                    href: "https://docs.coinwind.pro/announcement/20210623"
                },

            ]
        }
    },
    eth: {
        pc: {
            zh: [
                {
                    bannerSrc: require("../../assets/img/web_CH.png"),
                },
                // {
                //     bannerSrc: require("../../assets/img/web_new_ch.png"),
                // },
                // {
                //     bannerSrc: require("../../assets/img/ethbanner1/pc/联合空投¥30000-中文@2x-min.png"),
                //     href: "https://docs.coinwind.com/v/chinese/announcement/20210719-1"
                // },
                // {
                //     bannerSrc: require("../../assets/img/ethbanner1/pc/部署至ETH链-中文@2x-min.png"),
                //     href: "https://docs.coinwind.com/v/chinese/announcement/20210719"
                // },
                require("../../assets/img/web/home/banner-home-eth-zhong.png"),
                require("../../assets/img/web/home/banner-home-addEth-zhong.png"),
                {
                    bannerSrc: require("../../assets/img/banner4.png"),
                    href: "https://docs.coinwind.pro/v/chinese/announcement/20210623"
                },

            ],
            en: [
                {
                    bannerSrc: require("../../assets/img/web_En.png"),
                },
                // {
                //     bannerSrc: require("../../assets/img/web_new_En.png"),
                // },
                // {
                //     bannerSrc: require("../../assets/img/ethbanner1/pc/联合空投¥30000-英文@2x-min.png"),
                //     href: "https://docs.coinwind.com/announcement/20210719-1"
                // },
                // {
                //     bannerSrc: require("../../assets/img/ethbanner1/pc/部署至ETH链-英文@2x-min.png"),
                //     href: "https://docs.coinwind.com/announcement/20210719"
                // },
                require("../../assets/img/web/home/banner-home-eth-english.png"),
                require("../../assets/img/web/home/banner-home-addEth-english.png"),
                {

                    bannerSrc: require("../../assets/img/banner4-english.png"),
                    href: "https://docs.coinwind.pro/announcement/20210623"
                },

            ]
        },
        h5: {
            zh: [
                {
                    bannerSrc: require("../../assets/img/h5_ch.png"),
                },
                // {
                //     bannerSrc: require("../../assets/img/h5_new_ch.png"),
                // },
                // {
                //     bannerSrc: require("../../assets/img/ethbanner1/h5/手机端-联合空投¥30000-中文@2x-min.png"),
                //     href: "https://docs.coinwind.com/v/chinese/announcement/20210719-1"
                // },
                // {
                //     bannerSrc: require("../../assets/img/ethbanner1/h5/手机端-部署至ETH链-中文@2x-min.png"),
                //     href: "https://docs.coinwind.com/v/chinese/announcement/20210719"
                // },
                require("../../assets/img/app/home/banner-home-eth-zhong.png"),
                require("../../assets/img/web/home/banner-home-addEth-zhong.png"),
                {
                    bannerSrc: require("../../assets/img/banner4-h5-heco.png"),
                    href: "https://docs.coinwind.pro/v/chinese/announcement/20210623"
                },

            ],
            en: [
                {
                    bannerSrc: require("../../assets/img/h5_EN.png"),
                },
                // {
                //     bannerSrc: require("../../assets/img/h5_new_en.png"),
                // },
                // {
                //     bannerSrc: require("../../assets/img/ethbanner1/h5/手机端-联合空投¥30000-英文@2x-min.png"),
                //     href: "https://docs.coinwind.com/announcement/20210719-1"
                // },
                // {
                //     bannerSrc: require("../../assets/img/ethbanner1/h5/手机端-部署至ETH链-英文@2x-min.png"),
                //     href: "https://docs.coinwind.com/announcement/20210719"
                // },
                require("../../assets/img/app/home/banner-home-eth-english.png"),
                require("../../assets/img/web/home/banner-home-addEth-english.png"),
                {
                    bannerSrc: require("../../assets/img/banner4-h5-english-heco.png"),
                    href: "https://docs.coinwind.pro/announcement/20210623"
                },


            ]
        }
    },
}

export const headerAndAsideLinks = {
    zh: [
        { content: 'Tutorial-7', href: 'https://docs.coinwind.pro/v/chinese/' },
        { content: 'Tutorial-5', href: 'https://docs.coinwind.pro/v/chinese/guide/faq' },
        { content: 'Tutorial-6', href: 'https://docs.coinwind.pro/v/chinese/guide/wallet&asset' },
        // { content: 'Tutorial-4', href: 'https://docs.coinwind.com/contactus' },
    ],
    en: [
        { content: 'Tutorial-7', href: 'https://docs.coinwind.pro/' },
        { content: 'Tutorial-5', href: 'https://docs.coinwind.pro/guide/faq' },
        { content: 'Tutorial-6', href: 'https://docs.coinwind.pro/guide/wallet&asset' },
        // { content: 'Tutorial-4', href: 'https://docs.coinwind.com/v/english/contactus' },
    ]
}

export const footerLinksReports = {
    "128": {
        zh: [
            { content: 'Audit-Report1', href: 'https://www.coinwind.com/BEOSIN_zh.pdf' },
            { content: 'Audit-Report2', href: 'https://www.coinwind.com/flyhub_zh.pdf' },
        ],
        en: [
            { content: 'Audit-Report1', href: 'https://www.coinwind.com/BEOSIN_en.pdf' },
            { content: 'Audit-Report2', href: 'https://www.coinwind.com/flyhub_en.pdf' },
        ]
    },
    "56": {
        zh: [
            { content: 'Audit-Report1', href: 'https://www.coinwind.com/BEOSIN_zh.pdf' },
            { content: 'Audit-Report2', href: 'https://www.coinwind.com/flyhub_zh.pdf' },
        ],
        en: [
            { content: 'Audit-Report1', href: 'https://www.coinwind.com/BEOSIN_en.pdf' },
            { content: 'Audit-Report2', href: 'https://www.coinwind.com/flyhub_en.pdf' },
        ]
    },
    [_ADD_NETWORK_ID_]: {
        zh: [
            { content: 'Audit-Report2', href: 'https://www.fairyproof.com/doc/CoinwindV2-Audit-Report-071821.pdf' },
        ],
        en: [
            { content: 'Audit-Report2', href: 'https://www.fairyproof.com/doc/CoinwindV2-Audit-Report-071821.pdf' },
        ]
    }
}
