import React, { memo,useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
// import { useConnectWallet } from '../../home/redux/hooks';
import {useAggregate} from '../redux/hooks';
import { useRepurchaseRecord } from '../redux/hooks';
import '../jss/sections/repurchaseRecord.css'
import BigNumber from "bignumber.js";
import {_CURRENT_NETWORK_ID_,txUrlMap} from '../../configure/chainsParamsConfig'
// 千分位格式化
function formatThousands(num) {
    return num.toString().replace(/\d+/, function (n) { // 先提取整数部分
        return n.replace(/(\d)(?=(\d{3})+$)/g, function ($1) { // 对整数部分添加分隔符
            return $1 + ",";
        });
    });
}
const forMat = (number, precision = 2) => {
    return new BigNumber(
      number
    ).multipliedBy(
      new BigNumber(10000)
    ).dividedToIntegerBy(
      new BigNumber(1)
    ).dividedBy(
      new BigNumber(10000)
    ).toNumber().toFixed(precision)
  }
function onMore(){
  if(_CURRENT_NETWORK_ID_===128){
    window.open('https://hecoinfo.com/address/0x214BB67652ebde3d5AC2f8153295fdbA31CEe0B9')
  }else if(_CURRENT_NETWORK_ID_===56){
    window.open('https://bscscan.com/address/0xDe6Adc57d48d386800eD445bb31e2cD75F9970FC ')
  }else if(_CURRENT_NETWORK_ID_===1){
    window.open('https://cn.etherscan.com/address/0xdc63c6b5d12de0fff54473f02385e2c70d0b7a12')
  }
}
function onDetails(pool){
  if(_CURRENT_NETWORK_ID_===128){
    window.open(`https://hecoinfo.com/tx/${pool.txid}`)
  }else if(_CURRENT_NETWORK_ID_===56){
    window.open(`https://bscscan.com/tx/${pool.txid}`)
  }else if(_CURRENT_NETWORK_ID_===1){
    window.open(`https://cn.etherscan.com/tx/${pool.txid}`)
  }
}  
export default memo(({address}) => {
    const { t } = useTranslation();
    const theme = useSelector(state => state.common.theme);
    const {dataList} = useRepurchaseRecord()
    const aggregateData = useAggregate(theme==='bsc');
    const rateAndTvl = aggregateData ? aggregateData[1]/1e18 : 0;
    const rateAndReward = aggregateData ? aggregateData[0]/1e18 : 0;
    const depositedAll = aggregateData ? aggregateData[3]/1e18 : 0;
    const allEarned = aggregateData ? aggregateData[2]/1e18 : 0;
    const [withdrawAmount, setWithdrawAmount] = useState([1,2,3]);
    return <div className={`repurchase-record`}>
      <div className={`repurchase-record-title ${theme}-back`}>
          <div className={`repurchase-record-title-start`}>{t('Buy-Back-1')}</div>
          <div className={`repurchase-record-title-end`} onClick={()=>onMore()}>{t('More')}>></div>
      </div>
      <div className={`repurchase-record-content`}>
          {
            dataList.length==0? <div className="pools__emptys">
            <img src={require("../../../assets/img/empty.svg")} />
            <p>{t("v2-ui-15-1")}</p>
          </div> :<div className={`repurchase-record-content-inner`}>
            <div className={`repurchase-record-content-txid`}>
              <span className='list-title'>TXID</span>
              {
                dataList.map((pool,index)=>{
                  return <div className={`single-data`} onClick={()=>onDetails(pool)}>{pool.txid}</div>
                })
              }
            </div>
            <div className={`repurchase-record-content-height`}>
              <span className='list-title'>{t('Buy-Back-2')}</span>
              {
                dataList.map((pool,index)=>{
                  return  <div className={`single-data-2`}>{pool.blcokNum}</div>
                })
              }
            </div>
            <div className={`repurchase-record-content-price`}>
              <span className='list-title'>{t('Buy-Back-3')}</span>
              {
                dataList.map((pool,index)=>{
                  return  <div className={`single-data-2`}>{formatThousands(forMat(pool.cow2Usdt,2))}</div>
                })
              }
            </div>
            <div className={`repurchase-record-content-num`}>
              <span className='list-title'>{t('Buy-Back-4')}</span>
              {
                dataList.map((pool,index)=>{
                  return  <div className={`single-data-2`}>{formatThousands(forMat(pool.burnCow ,2))}</div>
                })
              }
            </div>
            <div className={`repurchase-record-content-value`}>
              <span className='list-title'>{t('Buy-Back-5')}</span>
              {
                dataList.map((pool,index)=>{
                  return  <div className={`single-data-2`}> {formatThousands(forMat(( pool.cow2Usdt*pool.burnCow),2)) }</div>
                })
              }
            </div>
            </div>
          }
      </div>
    </div>
})
