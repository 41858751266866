/* eslint-disable */
import React, { useState, useEffect, useCallback, memo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import BigNumber from "bignumber.js";
import { byDecimals } from 'features/helpers/bignumber';
// import { isMobile } from 'features/helpers/utils';
import Web3Modal from "web3modal";
import WalletConnectProvider from "@walletconnect/web3-provider";
import Button from '../../../components/Button/Button'
import Dialog from '../../../components/Dialog/Dialog'
import SectionQuestion from "../../../components/Question/Question";
import H5Tab from '../../../components/H5Tab/H5Tab'
//  hooks
import { useConnectWallet } from '../../home/redux/hooks';
import '../jss/sections/SectionPools.css';
import '../jss/sections/SectionFilter.css'
import {
  usePoolList,
  useFetchApproval,
  useFetchDeposit,
  useFetchWithdraw,
  useAllRate,
  useCoinRate,
} from '../redux/hooks';

// import SectionTitle from "./SectionTitle";
import SectionTotal from "./SectionTotal";
import SectionTitle from "./SectionTitle";
import SectionFilter from "./SectionFilter";
// import SectionCountDown from "./SectionCountDown";
import RepurchaseRecord from './repurchaseRecord'
import { inputLimitPass, inputFinalVal, isEmpty } from 'features/helpers/utils';
import { useBasicInfo } from 'features/configure/pools';
import { txUrlMap, _NETWORK_ID_MAP_NAME_, cowAddressMap, _IS_ETH_ } from '../../configure/chainsParamsConfig';
export default memo(function SectionPools() {
  const { t, i18n } = useTranslation();
  const { web3, address, networkId, connected, connectWallet, connectWalletPending } = useConnectWallet();
  const theme = useSelector(state => state.common.theme);
  const [price, setPrice] = useState();
  // // Tab 切换
  const [tabActive, setTabActive] = useState(1);
  const [listData, setListData] = useState([]);
  // cow复利 取 cow策略下 cow池子cow单利字段
  const [cowCompoundApy, setCowCompoundApy] = useState("");
  // 赋值默认值
  const { pools } = useBasicInfo();
  useEffect(() => {
    const temp = [];
    for (let pool of pools) {
      const poolInfo = {
        token: pool.tokenAddress,
        amount: 0,
        balance: 0,
        allowance: 0,
        mdxApy: 0,
        cowApy: 0,
        mdxCompoundApy: 0,
        mdxReward: 0,
        cowReward: 0,
        totalAmount: 0,
        totalAmountLimit: 0,
      }
      temp.push({ ...pool, ...poolInfo });
    }
    setListData(temp);
    setCowCompoundApy(temp[0].cowApy);
  }, [tabActive])

  const tabList = [
    {
      id: 1,
      name: t("Tab-COW"),
    },
    {
      id: 0,
      name: t("Tab-MDX"),
    },
  ]
  // 获取 gasPrice
  const getPrice = useCallback(async () => {
    if (!web3) { return }
    const price = await new web3.eth.getGasPrice()
    setPrice(price);
  }, [web3])
  useEffect(() => {
    getPrice();
  }, [web3, getPrice])

  // 连接钱包弹框
  function connectHandler() {
    const newModal = new Web3Modal({
      network: process.env.NETWORK ? process.env.NETWORK : "mainet",
      cacheProvider: true,
      providerOptions: {
        injected: {
          display: {
            name: "MetaMask",
            description: t('Home-BrowserWallet')
          },
        }
        ,
        walletconnect: {
          package: WalletConnectProvider,
          options: {
            infuraId: process.env.INFURA_ID
          }
        }
      }
    })
    connectWallet(newModal);
  }

  // 更新取列表数据
  usePoolList(pools => {
    setListData([...pools]);
    setCowCompoundApy(pools[0].cowApy);
  });

  // 授权 存入 提取
  const { fetchApproval, fetchApprovalPending } = useFetchApproval();
  const { fetchDeposit, fetchDepositPending } = useFetchDeposit();
  const { fetchWithdraw, fetchWithdrawPending, fetchWithdrawIncomePending } = useFetchWithdraw();
  const [depositedBalance, setDepositedBalance] = useState({});
  const [withdrawAmount, setWithdrawAmount] = useState({});

  // 新增 各币种兑MDX汇率
  const { poolRate, coinRate } = useAllRate(false);
  const { tokenRate: cow2Usdt } = useCoinRate(cowAddressMap[networkId] ? cowAddressMap[networkId] : cowAddressMap['128']);
  const dispatch = useDispatch();
  // 打开对话框
  const openDialogHandler = (type, index) => {
    listData[index].dialog.opened = true;
    if (type === 'widthdraw') {
      listData[index].dialog.widthdrawOpened = true
    }
    if (type === 'deposit') {
      listData[index].dialog.depositOpened = true
    }
    // 深拷贝 更新视图
    setListData([...listData]);
  }

  // 关闭对话框
  const closeDialogHandler = (type, index) => {
    listData[index].dialog.opened = false;
    if (type === 'widthdraw') {
      listData[index].dialog.widthdrawOpened = false
    }
    if (type === 'deposit') {
      listData[index].dialog.depositOpened = false
    }
    // 深拷贝 更新视图
    setListData([...listData]);
  }
  // 所有状态的对话操作
  const [dialogCtrl, setDialogCtrl] = useState({
    opened: false,
    type: 'success',
    // "我知道了"
    buttonText: t('v2-ui-1'),
    // 授权成功
    contentText: t('v2-ui-2'),
    buttonClickCallback: () => {
      setDialogCtrl({ ...dialogCtrl, opened: false })
    }
  })

  // 输入框change
  const changeDetailInputValue = (type, index, total, tokenDecimals, event) => {
    total = Number(total)
    let value = event.target.value;
    if (!inputLimitPass(value, tokenDecimals)) {
      return;
    }
    if (isNaN(Number(value))) {
      value = ''
    }
    switch (type) {
      case 'depositedBalance':
        setDepositedBalance({
          ...depositedBalance,
          [index]: inputFinalVal(value, total, tokenDecimals),
          // [`slider-${index}`]: sliderNum,
        });
        break;
      case 'withdrawAmount':
        setWithdrawAmount({
          ...withdrawAmount,
          [index]: inputFinalVal(value, total, tokenDecimals),
          // [`slider-${index}`]: sliderNum,
        });
        break;
      default:
        break;
    }
  }
  // 授权
  const onApproval = (pool, index, event) => {
    event.stopPropagation();
    fetchApproval({
      address,
      web3,
      tokenAddress: pool.tokenAddress,
      contractAddress: pool.earnContractAddress,
      index
    }).then(
      () => {
        setDialogCtrl({
          ...dialogCtrl,
          opened: true,
          type: 'success',
          contentText: t("Approval-success"),
          // 确认、存入
          cancelButtonText: t('Farm-Dialog-Confirm'),
          buttonText: t('Vault-DepositButton'),
          buttonClickCallback: () => {
            setDialogCtrl({ ...dialogCtrl, opened: false });
            openDialogHandler('deposit', index);
          },
          cancelButtonCallback: () => {
            setDialogCtrl({ ...dialogCtrl, opened: false })
          },
        })
      }
    ).catch(
      error => {
        setDialogCtrl({
          ...dialogCtrl,
          opened: true,
          type: 'warning',
          contentText: t("Approval-error"),
          // 重试
          buttonText: t('v2-ui-3')
        })
      }
    )
  }
  // 存入
  const onDeposit = (pool, index, isAll, balanceSingle, event) => {
    event.stopPropagation();
    if (isAll) {
      setDepositedBalance({
        ...depositedBalance,
        [index]: balanceSingle.toString(10),
        // [`slider-${index}`]: 100,
      })
    }
    let amountValue = depositedBalance[index] ? depositedBalance[index].replace(',', '') : depositedBalance[index];
    if (amountValue == undefined) {
      amountValue = '0';
    }
    fetchDeposit({
      address,
      web3,
      isAll,
      amount: new BigNumber(amountValue).multipliedBy(new BigNumber(10).exponentiatedBy(pool.tokenDecimals)).toString(10),
      contractAddress: pool.earnContractAddress,
      contractToken: pool.tokenAddress,
      index
    }).then(
      ({ transactionHash }) => {
        setDepositedBalance({ ...depositedBalance, [index]: '' })
        setDialogCtrl({
          ...dialogCtrl,
          opened: true,
          type: 'success',
          contentText: t("Deposit-success"),
          // 查看我的交易
          buttonText: <a href={txUrlMap[networkId] + transactionHash} target="_blank">{t("v2-ui-4")}</a>,
          cancelButtonText: t("v2-ui-1"),
          cancelButtonCallback: () => {
            setDialogCtrl({ ...dialogCtrl, opened: false })
          },
          buttonClickCallback: () => {
            setDialogCtrl({ ...dialogCtrl, opened: false });
          }
        })
      }
    ).catch(
      error => {
        {
          setDepositedBalance({ ...depositedBalance, [index]: '' })
          setDialogCtrl({
            ...dialogCtrl,
            opened: true,
            type: 'warning',
            contentText: t("Deposit-error"),
            buttonText: t("v2-ui-3")
          })
        }
      }
    )

  }
  // 提取
  const onWithdraw = (pool, index, isAll, singleDepositedBalance, isWithdrawIncome, event) => {

    event.stopPropagation();
    if (isAll) {
      setWithdrawAmount({
        ...withdrawAmount,
        [index]: String(forMat(singleDepositedBalance, 8)),
        // [`slider-${index}`]: 100,
      })
    }
    let amountValue = withdrawAmount[index] ? withdrawAmount[index].replace(',', '') : withdrawAmount[index];
    if (amountValue == undefined) {
      amountValue = '0';
    }
    fetchWithdraw({
      address,
      web3,
      isAll,
      amount: new BigNumber(amountValue).multipliedBy(new BigNumber(10).exponentiatedBy(pool.tokenDecimals)).toString(10),
      contractAddress: pool.earnContractAddress,
      contractToken: pool.tokenAddress,
      index,
      isWithdrawIncome
    }).then(
      ({ transactionHash }) => {
        setWithdrawAmount({ ...withdrawAmount, [index]: '' })
        setDialogCtrl({
          ...dialogCtrl,
          opened: true,
          type: 'success',
          contentText: isWithdrawIncome ? t("v2-ui-7") : t("v2-ui-5"),
          buttonText: <a href={txUrlMap[networkId] + transactionHash} target="_blank">{t("v2-ui-4")}</a>,
          cancelButtonText: t("v2-ui-1"),
          cancelButtonCallback: () => {
            setDialogCtrl({ ...dialogCtrl, opened: false })
          },
          buttonClickCallback: () => {
            setDialogCtrl({ ...dialogCtrl, opened: false });
          }
        })
      }
    ).catch(
      error => {
        setWithdrawAmount({ ...withdrawAmount, [index]: '' })
        setDialogCtrl({
          ...dialogCtrl,
          opened: true,
          type: 'warning',
          contentText: isWithdrawIncome ? t("v2-ui-8") : t("v2-ui-6"),
          buttonText: t("v2-ui-3")
        })
      }
    )
  }
  // 是否最大
  const isMoreDepostLimit = (value, depostLimit) => {
    if (isEmpty(value) || depostLimit == 0 || value < depostLimit) {
      return false
    }
    return true;
  }
  // 格式化数据
  const forMat = (number, precision = 2) => {
    return new BigNumber(
      number
    ).multipliedBy(
      new BigNumber(10000)
    ).dividedToIntegerBy(
      new BigNumber(1)
    ).dividedBy(
      new BigNumber(10000)
    ).toNumber().toFixed(precision)
  }
  // 千分位格式化
  function formatThousands(num) {
    return num.toString().replace(/\d+/, function (n) { // 先提取整数部分
      return n.replace(/(\d)(?=(\d{3})+$)/g, function ($1) { // 对整数部分添加分隔符
        return $1 + ",";
      });
    });
  }
  // 过滤参与打项目
  const [isPartake, setIsPartake] = useState(false);

  // 拿到WBNB兑U汇率
  const bnbRate = isNaN(coinRate["0xbb4cdb9cbd36b01bd1cbaebf2de08d9173bc095c"] / 1e18) ? 0 : coinRate["0xbb4cdb9cbd36b01bd1cbaebf2de08d9173bc095c"] / 1e18;
  // 所有的项目
  const poolsMap = listData.map((pool, index) => {
    // 余额
    const balanceSingle = byDecimals(pool.balance, pool.tokenDecimals);
    // 已存入
    const deposite = byDecimals(pool.amount, pool.tokenDecimals).toNumber();
    // 已获益
    const finaCompoundEarned = forMat(pool.mdxReward);
    // MDX策略====>收益率 mdx + mdx复利
    const rateMdx = poolRate[pool.tokenAddress];
    const mdxApy = (pool.mdxApy / (100 * rateMdx)) / (10 ** (18 - pool.tokenDecimals));

    // 计算cow与质押币种的汇率
    const cow2Token = cow2Usdt / coinRate[pool.tokenAddress]
    // console.log('cow2Token----',cow2Usdt,cow2Token)
    const cowApy = (pool.cowApy * cow2Token / (100)) / (10 ** (18 - pool.tokenDecimals));
    const depositedMdxApy = forMat(mdxApy);
    const depositedCowApy = forMat(cowApy);
    // 复利 R1/R2*((1+R2/m)^m-1)
    let finaCompound; // 复利新增 =  复利 - 单利
    let finaCompoundTotal; // 外层展示的复利
    let finaCompoundTotalView; // 问号里展示的复利
    // mdx 复利存在 取复利 复利不存在 取单利
    if (pool.mdxCompoundApy <= 0) {
      pool.mdxCompoundApy = pool.mdxCoinwindApy;
    }

    if (pool.mdxCompoundApy > 0 && rateMdx) {
      const depositedCompound = pool.mdxCompoundApy / 100;
      const xCompound = Math.pow((1 + (depositedCompound / 100) / 105120), 105120);
      finaCompound = forMat(((((mdxApy / 100) / (depositedCompound / 100)) * (xCompound - 1)) * 100) - mdxApy);
      finaCompoundTotal = forMat((((mdxApy / 100) / (depositedCompound / 100)) * (xCompound - 1)) * 100);
      finaCompoundTotalView = forMat((((mdxApy / 100) / (depositedCompound / 100)) * (xCompound - 1)) * 100);
    } else {
      finaCompound = 0;
      finaCompoundTotalView = 0;
      finaCompoundTotal = depositedMdxApy;
    }
    // COW 复利
    let finaCowCompound;
    if (cowCompoundApy > 0) {
      const depositedCompound = cowCompoundApy / 100;
      const xCompound = Math.pow((1 + (depositedCompound / 100) / 105120), 105120);
      finaCowCompound = forMat(((((cowApy / 100) / (depositedCompound / 100)) * (xCompound - 1)) * 100) - cowApy);
    } else {
      finaCowCompound = 0
    }
    // COW策略====>收益率 mdx + mdx复利 + cow
    const cowApyView = (depositedMdxApy * 1 + depositedCowApy * 1 + finaCompound * 1 + finaCowCompound * 1).toFixed(2);

    // 计算锁仓量
    const lock = formatThousands((pool.totalAmount / Number(`1e${pool.tokenDecimals}`)).toFixed(2));
    // 剩余可投/剩余可存
    const remainLock = ((pool.totalAmountLimit / Number(`1e${pool.tokenDecimals}`)) - (pool.totalAmount / Number(`1e${pool.tokenDecimals}`))) <= 0
     ? (0).toFixed(2) :
     formatThousands(((pool.totalAmountLimit / Number(`1e${pool.tokenDecimals}`)) - (pool.totalAmount / Number(`1e${pool.tokenDecimals}`))).toFixed(2));
    // 百分比
    const percent = ((pool.totalAmount / Number(`1e${pool.tokenDecimals}`)) / (pool.totalAmountLimit / Number(`1e${pool.tokenDecimals}`))).toFixed(2) * 100 > 100 ? 100 : (pool.totalAmountLimit == 0 ? 0 : String(((pool.totalAmount / Number(`1e${pool.tokenDecimals}`)) / (pool.totalAmountLimit / Number(`1e${pool.tokenDecimals}`)) * 100)).split('.')[0]);

    // cow 可提取收益
    const cowRewards = String((pool.cowReward / 1e18).toFixed(5)).slice(0, -1);

    // 过滤已参与
    if ((isPartake && !Number(deposite))) {
      return null;
    }
    return (
      <div className="pools__item" key={index}>
        {/* <div className={`pools__item${isPartake&&!Number(finaCompoundEarned)?' is-hide':''}`} key={index}> */}
        <div className="pools__box">
          <ul className="pools__rows">
            {/* 币种名称+ICON */}
            <li className="pools__row-1">
              <div className="pools__logo-name">
                <img
                  className="pools__coin-logo"
                  alt={pool.name}
                  src={require(`../../../images/icon2/${pool.name}.svg`)}
                />
                <div className="pools__coin-name">
                  {pool.name}
                </div>
              </div>
              {
                _IS_ETH_ ?
                  <div className="pools__info">
                    {t("Vault-HarvestButton")}COW
                  </div>
                  :
                  <div className="pools__info">
                    {
                      tabActive === 1 ? `${t("Vault-HarvestButton")}MDX、COW` : `${t("Vault-HarvestButton")}MDX`
                    }
                  </div>
              }
            </li>
            {/* 年化收益率 */}
            <li className="pools__row pools__apy">
              <div className="pools__labe-field">
                {t('Vault-ListAPY')}<span className={`color-blue ${theme}-color-blue`}>{t('Vault-ListAPY1')}</span>

                {
                  _IS_ETH_ ?

                  <SectionQuestion value={
                    <div>
                      <div className="question__title">
                        {t("Total-Yield")}
                        <strong style={{ color: "rgb(255, 175, 103)" }}>{isNaN(cowApyView) ? '0.00' : cowApyView}%</strong>
                      </div>
                      <div className="question__item">
                        <div className="ques__labe-field">
                          <span className="ques__labe-line ques__labe-line--first"></span>
                          <span className="ques__labe-child">COW{t("Nav-Farm")}({t('APR')})</span>
                        </div>
                        <strong style={{ color: "rgb(255, 175, 103)" }}>{isNaN(depositedCowApy) ? '0.00' : depositedCowApy}%</strong>
                      </div>
                      <div className="question__item">
                        <div className="ques__labe-field">
                          <span className="ques__labe-line"></span>
                          <span className="ques__labe-child">COW{t("Nav-Farm")}({t('APY')})</span>
                        </div>
                        <strong style={{ color: "rgb(255, 175, 103)" }}>{isNaN(finaCowCompound) ? '0.00' : finaCowCompound}%</strong>
                      </div>
                      <div className="question__item-text" style={{ display: 'block', marginTop: '10px', color: "#131D32" }}>
                        {t('Apr-Tips1')}
                      </div>
                      <div className="question__item-text" style={{ color: "#131D32" }}>
                        {t('Apr-Tips2')}
                      </div>
                    </div>
                  } />
                    :
                    (tabActive === 1 ? 
                    <SectionQuestion value={
                      <div>
                        <div className="question__title">
                          {t("Total-Yield")}
                          <strong style={{ color: "rgb(255, 175, 103)" }}>{isNaN(cowApyView) ? '0.00' : cowApyView}%</strong>
                        </div>
                        <div className="question__item">
                          <div className="ques__labe-field">
                            <span className="ques__labe-line ques__labe-line--first"></span>
                            <span className="ques__labe-child">COW{t("Nav-Farm")}({t('APR')})</span>
                          </div>
                          <strong style={{ color: "rgb(255, 175, 103)" }}>{isNaN(depositedCowApy) ? '0.00' : depositedCowApy}%</strong>
                        </div>
                        <div className="question__item">
                          <div className="ques__labe-field">
                            <span className="ques__labe-line"></span>
                            <span className="ques__labe-child">MDX{t("Nav-Farm")}({t('APR')})</span>
                          </div>
                          <strong style={{ color: "rgb(255, 175, 103)" }}>{isNaN(depositedMdxApy) ? '0.00' : depositedMdxApy}%</strong>
                        </div>
                        <div className="question__item">
                          <div className="ques__labe-field">
                            <span className="ques__labe-line"></span>
                            <span className="ques__labe-child">COW{t("Nav-Farm")}({t('APY')})</span>
                          </div>
                          <strong style={{ color: "rgb(255, 175, 103)" }}>{isNaN(finaCowCompound) ? '0.00' : finaCowCompound}%</strong>
                        </div>
                        <div className="question__item">
                          <div className="ques__labe-field">
                            <span className="ques__labe-line"></span>
                            <span className="ques__labe-child">MDX{t("Nav-Farm")}({t('APY')})</span>
                          </div>
                          <strong style={{ color: "rgb(255, 175, 103)" }}>{isNaN(finaCompound) ? '0.00' : finaCompound}%</strong>
                        </div>
                        <div className="question__item-text" style={{ display: 'block', marginTop: '10px', color: "#131D32" }}>
                          {t('Apr-Tips1')}
                        </div>
                        <div className="question__item-text" style={{ color: "#131D32" }}>
                          {t('Apr-Tips2')}
                        </div>
                      </div>
                    } /> : <SectionQuestion value={
                      <div>
                        <div className="question__title">
                          {t("Total-Yield")}
                          <strong style={{ color: "rgb(255, 175, 103)" }}>{isNaN(finaCompoundTotal) ? '0.00' : finaCompoundTotal}%</strong>
                        </div>
                        <div className="question__item">
                          <div className="ques__labe-field">
                            <span className="ques__labe-line ques__labe-line--first"></span>
                            <span className="ques__labe-child">MDX{t("Nav-Farm")}({t('APR')})</span>
                          </div>
                          <strong style={{ color: "rgb(255, 175, 103)" }}>{isNaN(depositedMdxApy) ? '0.00' : depositedMdxApy}%</strong>
                        </div>
                        <div className="question__item">
                          <div className="ques__labe-field">
                            <span className="ques__labe-line"></span>
                            <span className="ques__labe-child">MDX{t("Nav-Farm")}({t('APY')})</span>
                          </div>
                          <strong style={{ color: "rgb(255, 175, 103)" }}>{isNaN(finaCompound) ? '0.00' : finaCompound}%</strong>
                        </div>
                        <div className="question__item-text" style={{ display: 'block', marginTop: '10px', color: "#131D32" }}>
                          {t('Apr-Tips1')}
                        </div>
                        <div className="question__item-text" style={{ color: "#131D32" }}>
                          {t('Apr-Tips2')}
                        </div>
                      </div>
                    } />)

                }
              </div>
              <div className="pools__apy-value">
                {tabActive === 1 ? (isNaN(cowApyView) ? '0.00' : cowApyView) : ((isNaN(finaCompoundTotalView) ? '0.00' : finaCompoundTotalView))}%
              </div>
            </li>
            {/* 已存入 */}
            <li className="pools__row">
              <div className="pools__labe-field">
                {t('Vault-ListDeposited')}({pool.name})
              </div>
              <div className="pools__label-value pools__label-value--black">
                {String(deposite.toFixed(5)).slice(0, -1)}
              </div>
            </li>
            {/* 锁仓量 */}
            <li className="pools__row">
              <div className="pools__labe-field">{t('Lock-volume')} ({pool.name})</div>
              <div className="pools__label-value">{lock}</div>
            </li>
            {/* 剩余可投 */}
            <li className="pools__row">
              <div className="pools__labe-field">{t('Remaining-investment')} ({pool.name})</div>
              <div className="pools__label-value">
                {remainLock}
              </div>
            </li>
            {/* 百分比 */}
            <li className="pools__row-rate">
              <div className="pools__rate">
                <span className={`${theme}-pools_rate`} style={{ width: `${((pool.totalAmount / Number(`1e${pool.tokenDecimals}`)) / (pool.totalAmountLimit / Number(`1e${pool.tokenDecimals}`))).toFixed(2) * 100}%` }}></span>
              </div>
              <div className="pools__rate-value">
                {percent}%
              </div>
            </li>
            {/* 授权 提取 存入按钮 */}
            <li className="pools__group-buttons">
              {connected ? (pool.allowance == 0 ?
                // 未授权 展示 提取 和 授权
                <div className="pools__button-group">
                  <Button
                    className="pools__dialog__option"
                    type={2}
                    loading={pool.dialog.widthdrawOpened}
                    onClick={() => openDialogHandler('widthdraw', index)}
                  >
                    {pool.dialog.widthdrawOpened ? '' : t('v2-ui-10')}
                  </Button>
                  <Button
                    className="pools__dialog__option"
                    loading={fetchApprovalPending[index]}
                    disabled={fetchApprovalPending[index]}
                    onClick={onApproval.bind(this, pool, index)}
                  >
                    {fetchApprovalPending[index] ? '' : t('Vault-ApproveButton')}
                  </Button>
                </div> :
                // 已授权 展示 提取 和 存入
                <div className="pools__button-group">
                  <Button
                    className="pools__dialog__option"
                    type={2}
                    loading={pool.dialog.widthdrawOpened}
                    onClick={() => openDialogHandler('widthdraw', index)}
                  >
                    {pool.dialog.widthdrawOpened ? '' : t('v2-ui-10')}
                  </Button>
                  <Button
                    className="pools__dialog__option"
                    loading={pool.dialog.depositOpened}
                    onClick={() => openDialogHandler('deposit', index)}
                  >
                    {pool.dialog.depositOpened ? '' : t("Vault-DepositButton")}
                  </Button>
                </div>) :
                <Button className="pools__dialog__option" loading={connectWalletPending} onClick={connectHandler} disabled={connectWalletPending}>
                  {connectWalletPending ? '' : t("v2-ui-17")}
                </Button>
              }
            </li>
          </ul>
          {/* 毛玻璃icon */}
          <div className="pools__mao-logo__wrap">
            <img
              className="pools__mao-logo"
              alt={pool.name}
              src={require(`../../../images/icon2/${pool.name}.svg`)}
            />
          </div>
          {/* 角标 */}
          {
            pool.type === "NEW" ? <img src={require(`../../../images/jiaobiao-${theme}.svg`)} style={{
              position: "absolute",
              right: "-1px",
              top: "-1px",
            }} /> : ""
          }
        </div>

        {/* 弹出框内容 */}
        {pool.dialog.opened ? <div className='pools__dialog'>
          {/* 存入相关 */}
          {
            pool.dialog.depositOpened ? <div className="pools__dialog-inner pools__dialog-deposite">
              <div className="pools__dialog__header">
                {t("Vault-DepositButton")}
                <span className="pools__dialog__header__close" onClick={() => closeDialogHandler('deposit', index)}></span>
              </div>
              <ul className="pools__dialog__fields">
                {/* logo coinname info */}
                <li className="pools__row-1">
                  <div className="pools__logo-name">
                    <img
                      className="pools__coin-logo"
                      alt={pool.name}
                      src={require(`../../../images/icon2/${pool.name}.svg`)}
                    />
                    <div className="pools__coin-name">
                      {pool.name}
                      {/* 存在标签时展示问号悬浮 否则 不展示 */}
                      {/* {pool.type ? <SectionQuestion value={<span className="SectionQuestion-custom-tips">
                    <time>{pool.openDate}</time>
                    <span>{t('Vault-View-9')}<strong>{pool.openAmount}</strong>{t('Vault-View-10')}</span>
                  </span>} label={pool.type == 'NEW' ? "NEW" : t('Vault-View-11')} type={pool.type ? 1 : 0} /> : ''} */}
                    </div>
                  </div>
                  <div className="pools__info">
                    {
                      _IS_ETH_ ? `${t("Vault-HarvestButton")}COW` : (tabActive === 1 ? `${t("Vault-HarvestButton")}MDX、COW` : `${t('Vault-Tips-3')}${i18n.language === 'zh' || i18n.language === 'zh-CN' ? pool.name : ''}${t('Vault-Tips-4')}`)
                    }
                  </div>
                </li>
                {/* 已存入 */}
                <li className="pools__row">
                  <div className="pools__labe-field">
                    {t('Vault-ListDeposited')}({pool.name})
                  </div>
                  <div className="pools__label-value pools__label-value--black">
                    {String(deposite.toFixed(5)).slice(0, -1)}
                  </div>
                </li>
                {/* 剩余可存 */}
                <li className="pools__row">
                  <div className="pools__labe-field">
                    {t('Vault-SYDeposited')}({pool.name})
                  </div>
                  <div className="pools__label-value pools__label-value--black">
                    {remainLock}
                  </div>
                </li>
                {/* 余额 */}
                <li className="pools__row">
                  <div className="pools__labe-field">
                    {t('Vault-Balance')}({pool.name})
                  </div>
                  <div className="pools__label-value pools__label-value--black">
                    {String(balanceSingle.toFormat(5)).slice(0, -1)}
                  </div>
                </li>
                {/* 输入框 */}
                <li className="pools__dialog__input">
                  <input
                    placeholder={t('v2-ui-11')}
                    value={depositedBalance[index] != undefined ? depositedBalance[index] : ''}
                    onChange={changeDetailInputValue.bind(this, 'depositedBalance', index, balanceSingle.toNumber(), pool.tokenDecimals)}
                  />
                  {/* 最大 全部存入按钮 */}
                  <Button
                    className="pools__dialog__deposit-all"
                    onClick={onDeposit.bind(this, pool, index, true, balanceSingle)}
                    loading={fetchDepositPending[index]}
                    type={2}
                    disabled={
                      !balanceSingle.toNumber() || fetchDepositPending[index] || (isMoreDepostLimit(balanceSingle.toNumber(), pool.depostLimit))
                    }>
                    {fetchDepositPending[index] ? '' : t('v2-ui-12')}
                  </Button>
                </li>
                {/* 存入说明 */}
                {theme === "bsc" ? <li className={`gas-line gas-${theme}`}>
                  {t("gasTip1")}<span>{((3000000 * price) / 1e18).toFixed(4).slice(0, -1)}BNB(≈${(((3000000 * price) / 1e18) * bnbRate).toFixed(3).slice(0, -1)})</span>{t("gasTip2")}<span>
                    {(((3000000 * price) / 1e18) * 0.3).toFixed(4).slice(0, -1)}BNB(≈${(((3000000 * price) / 1e18) * bnbRate * 0.3).toFixed(3).slice(0, -1)})
                    - {(((3000000 * price) / 1e18) * 0.65).toFixed(4).slice(0, -1)}BNB(≈${(((3000000 * price) / 1e18) * bnbRate * 0.65).toFixed(3).slice(0, -1)})</span>
                  {t("gasTip3")}
                </li> : ''}
                {/* 存入按钮 */}
                <li>
                  <Button
                    className="pools__dialog__option"
                    onClick={onDeposit.bind(this, pool, index, false, balanceSingle)}
                    loading={fetchDepositPending[index]}
                    disabled={
                      !Number(String((depositedBalance[index])).replace(',', '')) || fetchDepositPending[index] || isMoreDepostLimit(new BigNumber(depositedBalance[index]).toNumber(), pool.depostLimit)
                    }>{fetchDepositPending[index] ? '' : t("Vault-DepositButton")}</Button>
                </li>
              </ul>
            </div> : null
          }
          {/* 提取相关 */}
          {
            pool.dialog.widthdrawOpened ? <div className="pools__dialog-inner pools__dialog-withdraw">
              <div className="pools__dialog__header">
                {t('v2-ui-10')}
                <span className="pools__dialog__header__close" onClick={() => closeDialogHandler('widthdraw', index)}></span>
              </div>
              <ul className="pools__rows">
                {/* 币种名称+ICON */}
                <li className="pools__row-1">
                  <div className="pools__logo-name">
                    <img
                      className="pools__coin-logo"
                      alt={pool.name}
                      src={require(`../../../images/icon2/${pool.name}.svg`)}
                    />
                    <div className="pools__coin-name">
                      {pool.name}
                      {/* 存在标签时展示问号悬浮 否则 不展示 */}
                      {/* {pool.type ? <SectionQuestion value={<span className="SectionQuestion-custom-tips">
                    <time>{pool.openDate}</time>
                    <span>{t('Vault-View-9')}<strong>{pool.openAmount}</strong>{t('Vault-View-10')}</span>
                  </span>} label={pool.type == 'NEW' ? "NEW" : t('Vault-View-11')} type={pool.type ? 1 : 0} /> : ''} */}
                    </div>
                  </div>
                  <div className="pools__info">
                    {
                      _IS_ETH_ ? `${t("Vault-HarvestButton")}COW` : (tabActive === 1 ? `${t("Vault-HarvestButton")}MDX、COW` : `${t('Vault-Tips-3')}${i18n.language === 'zh' || i18n.language === 'zh-CN' ? pool.name : ''}${t('Vault-Tips-4')}`)
                    }
                  </div>
                </li>
                {/* 提取收益*/}
                <li className="pools__income-field">
                  <div className="pools__income-label">
                    <p className="pools__income-title">{t('Vault-WithdrawIncome1')}</p>
                    {
                      _IS_ETH_ ? null : <p className="pools__income-text">{String((finaCompoundEarned / 1e18).toFixed(5)).slice(0, -1)} {pool.earnedToken}</p>
                    }
                    {
                      tabActive == 1 ? <p className="pools__income-text">{cowRewards} COW</p> : null
                    }
                  </div>
                  <div className="pools__income-button">
                    <Button
                      loading={fetchWithdrawIncomePending[index]}
                      disabled={fetchWithdrawIncomePending[index] || (!Number(forMat(finaCompoundEarned / 1e18, 8)) && !Number(pool.cowReward / 1e18))}
                      onClick={onWithdraw.bind(this, pool, index, false, deposite, true)}
                    >{fetchWithdrawIncomePending[index] ? '' : t("Vault-WithdrawIncome")}</Button>
                  </div>
                </li>
                {/* 可提取本金 */}
                <li className="pools__dialog__withdraw-field">
                  <span>{t("v2-ui-14")}({pool.name})</span>
                  <span>{String(deposite.toFixed(5)).slice(0, -1)}</span>
                </li>
                {/* 输入框 */}
                <li className="pools__dialog__input">
                  <input
                    placeholder={t('v2-ui-11')}
                    value={withdrawAmount[index] != undefined ? withdrawAmount[index] : ''}
                    onChange={changeDetailInputValue.bind(this, 'withdrawAmount', index, deposite, pool.itokenDecimals)}
                  />
                  {/* 最大按钮 */}
                  <Button
                    className="pools__dialog__deposit-all"
                    type={2}
                    onClick={onWithdraw.bind(this, pool, index, true, deposite, false)}
                    loading={fetchWithdrawPending[index]}
                    disabled={
                      !deposite || fetchWithdrawPending[index] || (isMoreDepostLimit(deposite, pool.depostLimit))
                    }>
                    {fetchWithdrawPending[index] ? '' : t('v2-ui-13')}
                  </Button>
                </li>
                {/* 提取说明 */}
                {theme === "bsc" ? <li className={`gas-line gas-${theme}`}>
                  {t("gasTip1")}<span>{((6000000 * price) / 1e18).toFixed(4).slice(0, -1)}BNB(≈${(((6000000 * price) / 1e18) * bnbRate).toFixed(3).slice(0, -1)})</span>{t("gasTip2")}<span>
                    {(((6000000 * price) / 1e18) * 0.3).toFixed(4).slice(0, -1)}BNB(≈${(((6000000 * price) / 1e18) * bnbRate * 0.3).toFixed(3).slice(0, -1)})
                    - {(((6000000 * price) / 1e18) * 0.65).toFixed(4).slice(0, -1)}BNB(≈${(((6000000 * price) / 1e18) * bnbRate * 0.65).toFixed(3).slice(0, -1)})</span>
                  {t("gasTip3")}
                </li> : ''}
                {/* 提取本金 */}
                <li>
                  <Button
                    className="pools__dialog__option"
                    loading={fetchWithdrawPending[index]}
                    disabled={!Number(String(withdrawAmount[index]).replace(',', '')) || fetchWithdrawPending[index]}
                    onClick={onWithdraw.bind(this, pool, index, false, deposite, false)}>{fetchWithdrawPending[index] ? '' : t("v2-ui-10")}</Button>
                </li>
              </ul>
            </div> : null
          }
        </div> : null
        }
      </div>
    )
  })

  return (
    <>
      <Dialog
        type={dialogCtrl.type}
        opened={dialogCtrl.opened}
        buttonText={dialogCtrl.buttonText}
        cancelButtonText={dialogCtrl.cancelButtonText}
        cancelButtonCallback={dialogCtrl.cancelButtonCallback}
        buttonClickCallback={dialogCtrl.buttonClickCallback}
      >{dialogCtrl.contentText}</Dialog>
      <H5Tab></H5Tab>
      {/* 说明文字 */}
      <SectionTitle />
      {/* 倒计时 */}
      {/* <SectionCountDown endDate="2021-03-19 14:00:00" date={{ year: 2021, month: 3, day: 19, h: 14, m: 0, s: 0 }} /> */}
      {/* 总锁仓量总收益 */}
      {
        !_IS_ETH_&&<SectionTotal />
      }
      {
        !_IS_ETH_&&<RepurchaseRecord/>
      }
      
      {/* {
        address ? <SectionTotal
          address={address}
        /> : null
      } */}
      {/* <div className={`g-filter${_IS_ETH_ ? " filter-eth" : ""}`}> */}
        {/* Tab切换 */}
        {/* {
          _IS_ETH_ ? null :

            <ul className="tab__container">
              {
                tabList.map((item, ind) => {
                  return (
                    <li
                      className={`${tabActive === item.id ? 'tab__active' : ''} tab__item`}
                      key={ind}
                      onClick={() => {
                        if (item.id === tabActive) return;
                        setTabActive(item.id);
                        dispatch({ type: 'TABACTIVE', data: item.id });
                      }}>
                      <span className={`tab__${theme}`}>{item.name}</span>
                    </li>
                  )
                })
              }
            </ul>
        } */}
        {/* 少选过滤 */}
        {/* <SectionFilter
          isPartake={isPartake}
          filterPartakeHandler={() => setIsPartake(!isPartake)} /> */}
        {/* 币种筛选 */}
        {/* <input type="text" onKeyDown={selectToken()} /> */}
      {/* </div> */}
      {/* <div className="pools-main">
        {networkId in _NETWORK_ID_MAP_NAME_ && poolsMap}
        {!poolsMap.filter(pool => !!pool).length ? <div className="pools__empty">
          <img src={require("../../../assets/img/empty.svg")} />
          <p>{t("v2-ui-15")}</p>
          <Button type={2} onClick={() => setIsPartake(false)}>{t("v2-ui-16")}</Button>
        </div> : null}
      </div> */}
    </>
  )
})
