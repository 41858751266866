/* eslint-disable */
import React, { useState,useEffect } from "react";
import { useSelector } from 'react-redux';
import './Switch.css'
export default function Button({ children, onChange,isPartake }) {
    const [state, setState] = useState(isPartake);
    const theme = useSelector(state => state.common.theme);
    useEffect(()=>{
        setState(isPartake)
    },[isPartake])
    return (
        <div className={`g-switch-wrapper g-switch-wrapper--${theme}`} onClick={() => {
            setState(!state);
            onChange(state)
        }}>
            <div className={`g-switch${state ? ' g-switch--checked' : ''}`}></div>
            {children ? <span className="g-switch-label">{children}</span> : null}
        </div>
    );
}
