/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useCallback, useEffect } from 'react';
import axios from 'axios';
import { CowAirdop, erc20ABI, AggregateHub, AggregateHubNew,StakingHubPool,StakingAgg,CompoundHub } from 'features/configure/abi';
import { useSnackbar } from 'notistack';
import { useConnectWallet } from '../../home/redux/hooks';
import { useSelector } from 'react-redux';
// import cloneDeep from 'lodash.clonedeep'


import { useBasicInfo } from "../../configure/pools";
import { ctrAddressMap,_IS_BSC_,_IS_CURRENT_NETWORK_ID_SUPPORTED_,_IS_ETH_ } from '../../configure/chainsParamsConfig';
import {staking_info} from '../../configure/pools_cow_staking'
import {staking_bsc_info} from '../../configure/pools_bsc_staking'
export { useFetchApproval } from './fetchApproval';
export { useFetchDeposit } from './fetchDeposit';
export { useFetchWithdraw } from './fetchWithdraw';
export { useFetchContractApy } from './fetchContractApy';



// 获取staking项目列表
export function usePoolList(callback) {
    const _CURRENT_NETWORK_ID_ = Number(window.localStorage.getItem("NETWORK_ID_USE"));
    const { earnContractAddress, USDTAddress, pools, aggAddress, MdxAddress,cowAddress } = _CURRENT_NETWORK_ID_===128?staking_info():staking_bsc_info()
    let poolss=pools.map(pool => {
        return {
            ...pool,
            // 对每一个pool增加弹出框状态控制
            dialog: {
                //对话框弹出
                opened: false,
                // 存入是否打开
                widthdrawOpened: false,
                // 策略
                depositOpened: false,
                //提取本金
                principalOpened:false,
                //提取收益
                profitOpened:false
            }
        }
    });
    let EARN_CONTRACT_ADDRESS=earnContractAddress
    let aggAddresss=aggAddress
    const { web3, address } = useConnectWallet();
    // const tabActive = useSelector(state => state.vault.tabActive);
    const getPoolList = useCallback(async () => {
        if(!web3 || !_IS_CURRENT_NETWORK_ID_SUPPORTED_){
            return;
        }
        const contract = new web3.eth.Contract(StakingAgg, aggAddresss);
        const arr = [];
    //     // console.log(pools)
        for (let pool of poolss) {
            const poolInfo = await contract.methods.getPoolInfo(EARN_CONTRACT_ADDRESS, pool.tokenAddress, address).call().catch(e => {
                // console.log(EARN_CONTRACT_ADDRESS, pool.tokenAddress, "EARN_CONTRACT_ADDRESS");
            });
        //    console.log(pool,poolInfo,'111111')
            arr.push({...pool, ...poolInfo});

        }
        const map = {},
         dest = [];
        for(var i = 0; i <arr.length; i++){
            var ai = arr[i];
            if(!map[ai.id]){
                dest.push({
                    id: ai.id,
                    name: ai.name,
                    data: [ai],
                    dialog:ai.dialog,
                    names: ai.names,
                });
                map[ai.id] = ai;
            }else{
                for(var j = 0; j < dest.length; j++){
                    var dj = dest[j];
                    if(dj.id == ai.id){
                        dj.data.push(ai);
                        break;
                    }
                }
            }
        }
        Array.prototype.max = function () {
            // 将数组第一个元素的值赋给max
            var max = this[0].apy;
            // 使用for 循环从数组第一个值开始做遍历
            for (var i = 1; i < this.length; i++) {
                // console.log(this[i])
            // 如果元素当前值大于max,就把这个当前值赋值给max
                if (this[i].apy> max) {
                    max = this[i].apy;
                }
            }
            // 返回最大的值
            return max;
        }
        Array.prototype.min = function () {
            var min = this[0].apy;
            this.forEach(function(ele, index,arr) {
                if(ele.apy < min) {
                    min = ele.apy;
                }
            })
            return min;
        }

        dest.map(item=>{
            item.maxApy = item.data.max()
            item.minApy = item.data.min()
            // console.log(item.data.max(),'222222')
            // console.log(item.data.min(),'111111');
        })
        callback(dest);
    }, [web3, address])
    useEffect(() => {
        getPoolList()
        const timer = setInterval(() => {
            getPoolList();
        }, 5000);
        return () => clearInterval(timer)
    }, [getPoolList])
}
//获取我的持仓列表
export function useStakingList(callback){
    const {pools, earnContractAddress: EARN_CONTRACT_ADDRESS, aggAddress} = useBasicInfo();
    const { web3, address } = useConnectWallet();
    const getPoolLists = useCallback(async () => {
        if(!web3 || !_IS_CURRENT_NETWORK_ID_SUPPORTED_){
            return;
        }
        const contract = new web3.eth.Contract(StakingAgg, aggAddress);
        const arr = [];
        const idList = []
        for (let pool of pools) {
            const tokenIds = await contract.methods.getPositionTokenIds(pool.tokenAddress,address).call().catch(e => {
                console.log(e, "EARN_CONTRACT_ADDRESS");
            });
            idList.push({tokenIds:tokenIds,pool:pool})
        }
        for(let i = 0;i<idList.length;i++){
            const item = idList[i];
            for(let j = 0; j<item.tokenIds.length;j++){
                const poolInfo = await contract.methods.getPositionInfo(EARN_CONTRACT_ADDRESS, item.pool.tokenAddress, item.tokenIds[j]).call().catch(e => {
                    // console.log(EARN_CONTRACT_ADDRESS, pool.tokenAddress, "EARN_CONTRACT_ADDRESS");
                });

                var targetObj = JSON.parse(JSON.stringify(item.pool))
                arr.push({...targetObj, ...poolInfo});
            }
        }
        //  console.log(arr,'11111111111')
        return arr
    }, [web3, address])

    // useEffect(() => {
    //     getPoolLists()
    //     const timer = setInterval(() => {
    //         getPoolLists();
    //     }, 5000);
    //     return () => clearInterval(timer)
    // }, [getPoolLists])
    return getPoolLists
}
// 单个币种对U汇率
export function useCoinRate(tokenAddress) {
    const { web3 } = useConnectWallet();
    const {aggAddress, MdxAddress} = useBasicInfo();
    const [tokenURate, setTokenURate] = useState({});
    const [tokenRate, setTokenRate] = useState({});
    const fetchRate = useCallback(async () => {
        if (!web3 || !_IS_CURRENT_NETWORK_ID_SUPPORTED_) { return }
        const contract = new web3.eth.Contract(AggregateHub, aggAddress);
        const rate = await contract.methods.getUsdtRate(tokenAddress, _IS_BSC_ ? 1 : 0, false).call().catch(e => console.log(e,'11111111111'));
        // mdx兑usdt的价格
        // const mdxUsdtRate = await contract.methods.getUsdtRate(MdxAddress, _IS_BSC_ ? 1 : 0, false).call().catch(e => console.log(e,'12212211'));
        // setTokenURate(rate/mdxUsdtRate);
        setTokenRate(rate);
    })
    useEffect(() => {
        fetchRate();
        let refreshInterval = setInterval(fetchRate, 300000);
        return () => clearInterval(refreshInterval);
    }, [web3, fetchRate])
    return {
        tokenURate,
        tokenRate,
    }
}
// 所有币种汇率
export function useAllRate(isLp) {
    const _CURRENT_NETWORK_ID_ = Number(window.localStorage.getItem("NETWORK_ID_USE"));
    const { web3 } = useConnectWallet();
    // const {pools, aggAddress, MdxAddress,cowAddress,compoundAddress} = useBasicInfo();
    const [poolRate, setPoolRate] = useState(false);
    const [coinRate, setCoinRate] = useState({});
    const [getApys, setGetApys] = useState({});
    const tabActive = useSelector(state => state.vault.tabActive);

    const {compoundAddress,aggAddress,cowAddress,MdxAddress,pools } =  _CURRENT_NETWORK_ID_===128?staking_info():staking_bsc_info()
    const fetchRate = useCallback(async () => {
        if (!web3 || !_IS_CURRENT_NETWORK_ID_SUPPORTED_) { return }
        const contract = new web3.eth.Contract(CompoundHub, compoundAddress);
        const mdxUsdtRate = await contract.methods.getApy().call().catch(e=>{
        });
       setGetApys(mdxUsdtRate)

       const contracts = new web3.eth.Contract(StakingAgg, aggAddress);
       // mdx兑usdt的价格
       console.log(MdxAddress)
       const mdxUsdtRates = await contracts.methods.getUsdtRate(_IS_ETH_?cowAddress:MdxAddress, _IS_BSC_ ? 1 : 0, false).call().catch((e)=>{
           console.log(e)
       });
       if(mdxUsdtRates <= 0){
           return;
       }
       let returnRate = poolRate || {};
       let returnCoinRate = coinRate || {};
       //getTokenUsdtRate 接口返回的汇率放大了1e18倍
       // returnRate[MDXAddress] = mdxUsdtRate/1e18;
       pools.forEach(async pool => {
        //    console.log(pool)
           const rate = await contracts.methods.getUsdtRate(pool.tokenReal, _IS_BSC_ ? 1 : 0, isLp).call().catch((e)=>{
               console.log(e)
           });
           if(rate <= 0){
               return;
           }
        //    console.log(rate)
           //token兑换mdx的价格, 比如一个btc兑换usdt的价格
           const tokenMdxRate = rate/mdxUsdtRates;
           returnRate[pool.tokenAddress] = tokenMdxRate;
           returnCoinRate[pool.tokenAddress] = rate;
       })
       setPoolRate(returnRate);
    //    console.log(returnCoinRate)
       setCoinRate(returnCoinRate);


    }, [web3, isLp, tabActive])

    useEffect(() => {
        fetchRate();
        let refreshInterval = setInterval(fetchRate, 300000);
        return () => clearInterval(refreshInterval);
    }, [fetchRate])

    return {poolRate, coinRate,getApys}
}
// 单链 个人总锁仓  未提取收益
export function useAggregate(isBscChain) {
    const { web3, address } = useConnectWallet();
    const {earnContractAddress: EARN_CONTRACT_ADDRESS, aggAddress: AGG_ADDRESS} = useBasicInfo();
    const [data, setApy] = useState([]);
    let chainType = isBscChain? 1 : 0;
    const fetchApy = useCallback(async () => {
        if (!web3 || !_IS_CURRENT_NETWORK_ID_SUPPORTED_) { return }
        const contract = new web3.eth.Contract(AggregateHub, AGG_ADDRESS);
        contract.options.gas = 18600000;
        const data = await contract.methods.statePoolAndUserTokenUsd(EARN_CONTRACT_ADDRESS,address,chainType,false).call().catch(e => console.log(e))
        setApy(data);
    }, [isBscChain, address, EARN_CONTRACT_ADDRESS])

    useEffect(() => {
        if (web3 && address) {
            fetchApy()
        }
        let refreshInterval = setInterval(fetchApy, 10000)
        return () => clearInterval(refreshInterval)
    }, [web3, fetchApy])

    return data
}
