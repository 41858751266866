import { useState, useCallback, useEffect } from 'react';
import axios from 'axios';
import { HubPool, erc20ABI, ControllerHub, CompoundHub, StrategyMdex, GovernanceHub,AggregateHub,AggregateHubNew } from 'features/configure/abi';
import BigNumber from "bignumber.js";
import { useSnackbar } from 'notistack';
import { useConnectWallet } from '../../home/redux/hooks';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import {
    LP_FETCH_POOL_BALANCES_SUCCESS
} from './constants';
// import  initialState from './initialState'
import {
    pools,
    earnContractAddress as EARN_CONTRACT_ADDRESS,
    USDTAddress as USDT_ADDRESS,
    GOVAddress as GOV_ADDRESS, setGOVAddress,
    aggContract as AGG_CONTRACT
} from "../../configure/pools_LP";
import { mdxAddressMap,_IS_BSC_,aggContractAddressMap,compoundHubAdressMap,_IS_CURRENT_NETWORK_ID_SUPPORTED_,_CURRENT_NETWORK_ID_} from '../../configure/chainsParamsConfig';
export { useFetchBalances } from './fetchBalances';
export { useFetchPoolBalances } from './fetchPoolBalances';
export { useFetchApproval } from './fetchApproval';
export { useFetchDeposit } from './fetchDeposit';
export { useFetchWithdraw } from './fetchWithdraw';
export { useFetchContractApy } from './fetchContractApy';
export { useFilterPools } from './filterPools';
export { useFetchPoolsInfo } from './fetchPoolInfo';
export function useGov() {
    const { enqueueSnackbar } = useSnackbar();
    const { web3, address } = useConnectWallet();
    //获取设置GOV_ADDRESS
    async function getAndSetGOVAddress() {
        if (!web3||!_IS_CURRENT_NETWORK_ID_SUPPORTED_) return;
        const contract = new web3.eth.Contract(HubPool, EARN_CONTRACT_ADDRESS);
        const governanceAddress = await contract.methods.governance().call().catch(() => false)
        governanceAddress && setGOVAddress(governanceAddress)
    }
    async function govWithdrawLp(idx, selectedMatchedPolicyCurrency, tiaocangAmount) {
        const amountByVal = new BigNumber(tiaocangAmount).multipliedBy(new BigNumber(10).exponentiatedBy(18)).toString(10)
        if (idx === -1 || !selectedMatchedPolicyCurrency || isNaN(amountByVal)) {
            enqueueSnackbar(`请选择输入对应的字段`, {
                variant: 'error', anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'center',
                },
                autoHideDuration: 4000
            })
            return;
        }
        let contract = new web3.eth.Contract(HubPool, EARN_CONTRACT_ADDRESS);
        let ctrAddress = await contract.methods.controller().call().catch(e => 0)
        contract = new web3.eth.Contract(GovernanceHub, GOV_ADDRESS);
        contract.options.gas = 8600000;
        // function govWithdrawLp(targetAddress, _sid, token, amount) { 
        contract.methods.govWithdrawLp(ctrAddress, idx, selectedMatchedPolicyCurrency, amountByVal).send({ from: address }).on('transactionHash', function (hash) {
            // resolve(hash);
            enqueueSnackbar(`设置中:${hash}`, {
                variant: 'success', anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'center',
                },
                autoHideDuration: 4000
            })
        })
            .on('receipt', function (receipt) {
                enqueueSnackbar(`设置成功~`, {
                    variant: 'success', anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'center',
                    },
                    autoHideDuration: 4000
                })

            })
            .on('error', function (error) {
                enqueueSnackbar(`错误${JSON.stringify(error)}`, {
                    variant: 'error', anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'center',
                    },
                    autoHideDuration: 4000
                })
            })
            .catch((error) => {
                enqueueSnackbar(`错误${JSON.stringify(error)}`, {
                    variant: 'error', anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'center',
                    },
                    autoHideDuration: 4000
                })
            });
    }
    async function govEarnLp(idx, tiaocangAmountBefore, tiaocangAmountAfter) {
        const amountByVal1 = new BigNumber(tiaocangAmountBefore).multipliedBy(new BigNumber(10).exponentiatedBy(18)).toString(10)
        const amountByVal2 = new BigNumber(tiaocangAmountAfter).multipliedBy(new BigNumber(10).exponentiatedBy(18)).toString(10)
        if (idx === -1 || isNaN(amountByVal1) || isNaN(amountByVal2)) {
            enqueueSnackbar(`请选择输入对应的字段`, {
                variant: 'error', anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'center',
                },
                autoHideDuration: 4000
            })
            return;
        }
        let contract = new web3.eth.Contract(HubPool, EARN_CONTRACT_ADDRESS);
        let ctrAddress = await contract.methods.controller().call().catch(e => 0)
        if (!ctrAddress) {return;}
        contract = new web3.eth.Contract(GovernanceHub, GOV_ADDRESS);
        contract.options.gas = 8600000;
        contract.methods.govEarnLp(ctrAddress, idx, amountByVal1, amountByVal2).send({ from: address }).on('transactionHash', function (hash) {
            enqueueSnackbar(`设置中:${hash}`, {
                variant: 'success', anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'center',
                },
                autoHideDuration: 4000
            })
        })
            .on('receipt', function (receipt) {
                enqueueSnackbar(`设置成功~`, {
                    variant: 'success', anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'center',
                    },
                    autoHideDuration: 4000
                })

            })
            .on('error', function (error) {
                enqueueSnackbar(`错误${JSON.stringify(error)}`, {
                    variant: 'error', anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'center',
                    },
                    autoHideDuration: 4000
                })
            })
            .catch((error) => {
                enqueueSnackbar(`错误${JSON.stringify(error)}`, {
                    variant: 'error', anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'center',
                    },
                    autoHideDuration: 4000
                })
            });
    }
    // 停止所有质押
    async function setPause() {
        if (!web3) return;
        let contract = new web3.eth.Contract(HubPool, EARN_CONTRACT_ADDRESS);
        let ctrAddress = await contract.methods.controller().call().catch(e => 0)
        contract = new web3.eth.Contract(GovernanceHub, GOV_ADDRESS);
        contract.options.gas = 8600000;
        try {
            contract.methods.setPause(ctrAddress).send({ from: address })
                .on('transactionHash', function (hash) {
                    enqueueSnackbar(`停止所有质押中:${hash}`, {
                        variant: 'success', anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'center',
                        },
                        autoHideDuration: 4000
                    })
                })
                .on('receipt', function (receipt) {
                    enqueueSnackbar(`停止所有质押成功:${JSON.stringify(receipt)}`, {
                        variant: 'success', anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'center',
                        },
                        autoHideDuration: 4000
                    })
                })
                .on('error', function (error) {

                })
                .catch((error) => {
                    enqueueSnackbar(`停止所有质押失败:${JSON.stringify(error)}`, {
                        variant: 'error', anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'center',
                        },
                        autoHideDuration: 4000
                    })
                })
        } catch (error) {
            enqueueSnackbar(`停止所有质押失败:${JSON.stringify(error)}`, {
                variant: 'error', anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'center',
                },
                autoHideDuration: 4000
            })

        }
    }
    useEffect(() => {
        //获取设置GOV_ADDRESS
        getAndSetGOVAddress();
        paused()
        const timer = setInterval(() => {
            paused()
        }, 5000);
        // clearInterval(timer)
        return () => clearInterval(timer)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [web3, address])
    // 查询所有质押状态
    const [allPaused, setAllPaused] = useState(false)
    async function paused() {
        if (!web3) { return }
        let contract = new web3.eth.Contract(HubPool, EARN_CONTRACT_ADDRESS);
        let ctrAddress = await contract.methods.controller().call().catch(e => 0)
        if (!ctrAddress) {
            return 0;
        }
        contract = new web3.eth.Contract(ControllerHub, ctrAddress);
        const ret = await contract.methods.paused().call().catch(() => {
            // console.log('paused error')
            return false
        })
        // console.log('paused', ret)
        setAllPaused(ret)
    }

    return {
        govWithdrawLp,
        govEarnLp,
        allPaused,
        setPause
    }
}
// 治理模块
export function useSettingManager() {
    const { enqueueSnackbar } = useSnackbar();
    const { web3, address } = useConnectWallet();
    //获取设置GOV_ADDRESS
    async function getAndSetGOVAddress() {
        if (!web3) return;
        const contract = new web3.eth.Contract(HubPool, EARN_CONTRACT_ADDRESS);
        const governanceAddress = await contract.methods.governance().call().catch(() => false)
        governanceAddress && setGOVAddress(governanceAddress)
    }
    // 获取管理员列表
    const [GovernanceList, setGovernanceList] = useState([]);
    async function getGovernanceList() {
        if (!web3) { return }
        if (!GOV_ADDRESS) { return }
        // console.log(GOV_ADDRESS, 'GOV_ADDRESS GOV_ADDRESS GOV_ADDRESS')
        const contract = new web3.eth.Contract(GovernanceHub, GOV_ADDRESS);
        const ret = await contract.methods.getGovernanceList().call().catch(() => {
            console.log('getGovernanceList error')
            return []
        })
        setGovernanceList(ret.filter(item => item !== '0x0000000000000000000000000000000000000000'))
    }
    // 治理页面设置controller合约 根据选择的地址 请求 原地址 StrategyMdex
    const [selectedController, setController] = useState('');
    const [originalController, setOriginalController] = useState('');

    const [selectedGov, setGov] = useState('');
    const [originalGov, setOriginalGov] = useState('');
    async function setSelectedController(select) {
        // console.log(select, 'select')
        // console.log(select, 'select select')
        if (!web3 || !select || !Number(select)) {
            setController(select);
            setOriginalController('');
            return
        }
        // 输入的金额
        const contract = new web3.eth.Contract(StrategyMdex, select);
        const original = await contract.methods.controller().call().catch(() => {
            enqueueSnackbar('get controller error');
            return '';
        })
        // console.log(original, 'original')
        setController(select);
        setOriginalController(original);
    }
    // 治理页面 修改治理合约合约 根据选择的地址 请求 原地址 StrategyMdex
    async function setSelectedGov(select) {
        // console.log(select, 'select')
        // console.log(select, 'select select')
        if (!web3 || !select || !Number(select)) {
            setGov(select);
            setOriginalGov('');
            return
        }
        // 输入的金额
        const contract = new web3.eth.Contract(HubPool, select);
        const original = await contract.methods.governance().call().catch(() => {
            enqueueSnackbar('get governance error');
            return '';
        })
        // console.log(original, 'original')
        setGov(select);
        setOriginalGov(original);
    }
    useEffect(() => {
        getAndSetGOVAddress();
        getGovernanceList()
        getliquidityAddress()
        rewardAccount()
        const timer = setInterval(() => {
            getGovernanceList()
            getliquidityAddress()
            rewardAccount()
        }, 3000);
        // clearInterval(timer)
        return () => clearInterval(timer)

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [web3, address])
    //判断是否存在管理员
    // function containGovernance() {
    //     if (!web3) { return }
    //     const contract = new web3.eth.Contract(GovernanceHub, GOV_ADDRESS);
    // }
    // 重置管理
    function resetGovernance(userList) {
        if (!web3) { return }
        const contract = new web3.eth.Contract(GovernanceHub, GOV_ADDRESS);
    }
    //删除管理
    function removeGovernance(inputAddress) {
        if (!web3) { return }
        const contract = new web3.eth.Contract(GovernanceHub, GOV_ADDRESS);
        contract.options.gas = 8600000;
        try {
            contract.methods.removeGovernance(inputAddress).send({ from: address })
                .on('transactionHash', function (hash) {
                    enqueueSnackbar(`删除中:${hash}`, {
                        variant: 'success', anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'center',
                        },
                        autoHideDuration: 4000
                    })
                })
                .on('receipt', function (receipt) {
                    enqueueSnackbar(`删除成功:${JSON.stringify(receipt)}`, {
                        variant: 'success', anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'center',
                        },
                        autoHideDuration: 4000
                    })
                })
                .on('error', function (error) {

                })
                .catch((error) => {
                    enqueueSnackbar(`删除失败:${JSON.stringify(error)}`, {
                        variant: 'error', anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'center',
                        },
                        autoHideDuration: 4000
                    })
                })
        } catch (error) {
            enqueueSnackbar(`删除失败:${JSON.stringify(error)}`, {
                variant: 'error', anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'center',
                },
                autoHideDuration: 4000
            })

        }
    }
    // 添加管理
    function addGovernance(inputAddress, GovernanceList) {
        // console.log('inputAddress',inputAddress)
        if (!web3) { return }
        inputAddress = inputAddress.trim()
        if (!inputAddress) {
            enqueueSnackbar(`请输入合法的地址`, {
                variant: 'error', anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'center',
                },
                autoHideDuration: 4000
            })
            return;
        }
        if (GovernanceList.find(item => item === inputAddress)) {
            enqueueSnackbar(`管理员地址已存在`, {
                variant: 'error', anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'center',
                },
                autoHideDuration: 4000
            })
            return;
        }
        const contract = new web3.eth.Contract(GovernanceHub, GOV_ADDRESS);
        contract.options.gas = 8600000;
        try {
            contract.methods.addGovernance(inputAddress).send({ from: address })
                .on('transactionHash', function (hash) {
                    enqueueSnackbar(`地址添加中:${hash}`, {
                        variant: 'success', anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'center',
                        },
                        autoHideDuration: 4000
                    })
                })
                .on('receipt', function (receipt) {
                    enqueueSnackbar(`地址添加成功:${receipt}`, {
                        variant: 'success', anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'center',
                        },
                        autoHideDuration: 4000
                    })
                })
                .on('error', function (error) {

                })
                .catch((error) => {
                    enqueueSnackbar(`地址添加失败:${JSON.stringify(error)}`, {
                        variant: 'error', anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'center',
                        },
                        autoHideDuration: 4000
                    })
                })
        } catch (error) {
            enqueueSnackbar(`地址添加失败:${JSON.stringify(error)}`, {
                variant: 'error', anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'center',
                },
                autoHideDuration: 4000
            })

        }
    }
    // 设置对冲合约地址
    function setLiquidityAddress(inputAddress) {
        if (!web3) { return }
        const contract = new web3.eth.Contract(GovernanceHub, GOV_ADDRESS);
        contract.options.gas = 8600000;
        // 0x254bD44857497F33E630cD24E7B5E6f5688EfD2A
        try {
            contract.methods.setLiquidityAddress(EARN_CONTRACT_ADDRESS, inputAddress).send({ from: address })
                .on('transactionHash', function (hash) {
                    enqueueSnackbar(`对冲地址设置中:${hash}`, {
                        variant: 'success', anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'center',
                        },
                        autoHideDuration: 4000
                    })
                })
                .on('receipt', function (receipt) {
                    enqueueSnackbar(`对冲地址设置成功:${JSON.stringify(receipt)}`, {
                        variant: 'success', anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'center',
                        },
                        autoHideDuration: 4000
                    })
                })
                .on('error', function (error) {

                })
                .catch((error) => {
                    enqueueSnackbar(`对冲地址设置失败:${JSON.stringify(error)}`, {
                        variant: 'error', anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'center',
                        },
                        autoHideDuration: 4000
                    })
                })
        } catch (error) {
            enqueueSnackbar(`对冲地址设置失败:${JSON.stringify(error)}`, {
                variant: 'error', anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'center',
                },
                autoHideDuration: 4000
            })

        }
    }
    // 获取对冲合约地址
    const [liquidityAddress, setGovliquidityAddress] = useState('')
    async function getliquidityAddress(targetAddress) {
        if (!web3) { return }
        const contract = new web3.eth.Contract(HubPool, EARN_CONTRACT_ADDRESS);
        const ret = await contract.methods.liquidityAddress().call().catch(e => {
            console.log('liquidityAddress error')
            return ''
        })
        setGovliquidityAddress(ret)
    }
    // 设置controller合约
    async function submitController(tokenAddress, controllerInput) {
        if (!web3) { return }
        // 输入的金额
        const contract = new web3.eth.Contract(GovernanceHub, GOV_ADDRESS);
        // contract.options.gas = 8600000;
        contract.methods.setController(tokenAddress, controllerInput).send({ from: address }).on('transactionHash', function (hash) {
            // resolve(hash);
        })
            .on('receipt', function (receipt) {
                enqueueSnackbar(`设置成功`, {
                    variant: 'success', anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'center',
                    },
                    autoHideDuration: 4000
                })

            })
            .on('error', function (error) {
                enqueueSnackbar(`错误${JSON.stringify(error)}`, {
                    variant: 'error', anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'center',
                    },
                    autoHideDuration: 4000
                })
            })
            .catch((error) => {
                enqueueSnackbar(`错误${JSON.stringify(error)}`, {
                    variant: 'error', anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'center',
                    },
                    autoHideDuration: 4000
                })
            });
    }
    // 修改治理合约
    async function submitGov(selectedGov, inputGov) {
        if (!web3) { return }
        // console.log(tokenAddress, inputGov, '999')
        // 输入的金额
        const contract = new web3.eth.Contract(HubPool, selectedGov);
        // contract.options.gas = 8600000;
        contract.methods.setGovernance(inputGov).send({ from: address }).on('transactionHash', function (hash) {
            // resolve(hash);
        })
            .on('receipt', function (receipt) {
                enqueueSnackbar(`设置成功`, {
                    variant: 'success', anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'center',
                    },
                    autoHideDuration: 4000
                })

            })
            .on('error', function (error) {
                enqueueSnackbar(`错误${JSON.stringify(error)}`, {
                    variant: 'error', anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'center',
                    },
                    autoHideDuration: 4000
                })
            })
            .catch((error) => {
                enqueueSnackbar(`错误${JSON.stringify(error)}`, {
                    variant: 'error', anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'center',
                    },
                    autoHideDuration: 4000
                })
            });
    }
    // 设置平台分成地址
    const [rewardAccountValue, setRewardAccountAddress] = useState('')
    async function rewardAccount() {
        if (!web3) { return }
        let contract = new web3.eth.Contract(HubPool, EARN_CONTRACT_ADDRESS);

        let ctrAddress = await contract.methods.controller().call().catch(e => 0)
        // console.log('ctrAddress',ctrAddress)
        if (!ctrAddress) {
            return 0;
        }
        contract = new web3.eth.Contract(ControllerHub, ctrAddress);

        const ret = await contract.methods.rewardAccount().call().catch(() => {
            return ''
        })
        setRewardAccountAddress(ret)
    }
    async function setRewardAccount(inputPingdaiLiquidityAddress) {
        if (!web3) { return }
        let contract = new web3.eth.Contract(HubPool, EARN_CONTRACT_ADDRESS);
        let ctrAddress = await contract.methods.controller().call().catch(e => 0)
        contract = new web3.eth.Contract(GovernanceHub, GOV_ADDRESS);
        contract.options.gas = 8600000;
        try {
            contract.methods.setRewardAccount(ctrAddress, inputPingdaiLiquidityAddress).send({ from: address })
                .on('transactionHash', function (hash) {
                    enqueueSnackbar(`设置平台分成地址中:${hash}`, {
                        variant: 'success', anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'center',
                        },
                        autoHideDuration: 4000
                    })
                })
                .on('receipt', function (receipt) {
                    enqueueSnackbar(`设置平台分成地址成功:${receipt}`, {
                        variant: 'success', anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'center',
                        },
                        autoHideDuration: 4000
                    })
                })
                .on('error', function (error) {

                })
                .catch((error) => {
                    enqueueSnackbar(`设置平台分成地址失败:${JSON.stringify(error)}`, {
                        variant: 'error', anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'center',
                        },
                        autoHideDuration: 4000
                    })
                })
        } catch (error) {
            enqueueSnackbar(`设置平台分成地址失败:${JSON.stringify(error)}`, {
                variant: 'error', anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'center',
                },
                autoHideDuration: 4000
            })

        }
    }

    return {
        GovernanceList,
        getGovernanceList,
        setSelectedController,
        setSelectedGov,
        addGovernance,
        removeGovernance,
        liquidityAddress,
        selectedController,
        originalController,
        selectedGov,
        originalGov,
        setLiquidityAddress,
        rewardAccountValue,
        setRewardAccount,
        submitController,
        submitGov,
    }
}
// 池子管理
export function usePoolManager() {
    const { enqueueSnackbar } = useSnackbar();
    const { web3, address } = useConnectWallet();
    const { pools } = useSelector(
        state => ({
            pools: state.lp.pools
        }),
        shallowEqual,
    );
    // if(!web3) return;
    const isPoolAdded = async (contractAddress) => {
        try {
            const contract = new web3.eth.Contract(HubPool, EARN_CONTRACT_ADDRESS);
            return await contract.methods.getPoolId(contractAddress).call().catch(e => false);
        } catch (e) {
            return false
        }
    }
    // const contract = new web3.eth.Contract(erc20ABI, EARN_CONTRACT_ADDRESS);
    // 获取合约名称和精度
    async function checkContract(contractAddress) {
        const contract = new web3.eth.Contract(erc20ABI, contractAddress);
        return Promise.all([contract.methods.name().call(), contract.methods.decimals().call()])
    }
    //获取设置GOV_ADDRESS
    async function getAndSetGOVAddress() {
        if (!web3) { return }
        const contract = new web3.eth.Contract(HubPool, EARN_CONTRACT_ADDRESS);
        const governanceAddress = await contract.methods.governance().call().catch(() => false)
        governanceAddress && setGOVAddress(governanceAddress)
    }
    useEffect(() => {
        //获取设置GOV_ADDRESS
        getAndSetGOVAddress();
        const timer = setInterval(() => {
            getAndSetGOVAddress();
        }, 5000);
        // clearInterval(timer)
        return () => clearInterval(timer)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [web3, address])
    // 新增池子
    // add（代币合约地址, 用户收益分成万分比, 0.001 * 币种精度, 10000, true）
    async function addNewPool({ contractAddress, profit, decimals }) {
        // const contract = new web3.eth.Contract(HubPool, EARN_CONTRACT_ADDRESS);
        const contract = new web3.eth.Contract(GovernanceHub, GOV_ADDRESS);
        return new Promise((resolve, reject) => {
            contract.options.gas = 8600000;
            contract.methods.addPool(EARN_CONTRACT_ADDRESS, contractAddress, 10000 - profit * 100, 0.001 * (Number(`1e${decimals}`)), 10000, true).send({ from: address })
                .on('transactionHash', function (hash) {
                    resolve(hash);
                })
                .on('receipt', function (receipt) {
                    resolve(receipt)
                })
                .on('error', function (error) {
                    reject(error)
                })
                .catch((error) => {
                    reject(error)
                })
        })
    }
    // 编辑策略地址
    async function editPoolControl(contractAddress) {
        let contract = new web3.eth.Contract(HubPool, EARN_CONTRACT_ADDRESS);
        let ctrAddress = await contract.methods.controller().call().catch(e => 0)
        contract = new web3.eth.Contract(GovernanceHub, GOV_ADDRESS);
        contract.options.gas = 8600000;
        return new Promise((resolve, reject) => {
            contract.methods.addStrategy(ctrAddress, contractAddress).send({ from: address })
                .on('transactionHash', function (hash) {
                    resolve(hash);
                })
                .on('receipt', function (receipt) {
                    resolve(receipt)
                })
                .on('error', function (error) {
                    reject(error)
                })
                .catch((error) => {
                    reject(error)
                })
        })
    }
    // 获取策略的长度
    const [strategyPools, setStrategyPools] = useState([])
    const [controllerPools, setControllerArr] = useState([])
    const [goverPools, setGoverArr] = useState([])
    const [nameArr, setNameArr] = useState([])
    const getStrategy = async () => {
        let contract = new web3.eth.Contract(HubPool, EARN_CONTRACT_ADDRESS);
        let ctrAddress = await contract.methods.controller().call().catch(e => 0)
        if (!ctrAddress) {
            return 0;
        }
        contract = new web3.eth.Contract(ControllerHub, ctrAddress);
        // 获取长度
        const length = await contract.methods.strategyLength().call().catch(e => 0);
        const lengthMap = Array(+length).fill();
        // 拿合约地址
        const contractAddressList = await Promise.all(lengthMap.map((item, index) => {
            return contract.methods.strategieList(index).call();
        })).catch(e => [])

        // want() 币对信息
        // paused() 是否
        // setPause() 开启关闭
        // withdraw(代币合约地址，乘以精度后的金额 ) 解除质押
        const contractStrategyList = await Promise.all(contractAddressList.map((address) => {
            const contract = new web3.eth.Contract(StrategyMdex, address);
            return new Promise(async (resolve, reject) => {
                // 获取币对信息
                const want = await contract.methods.want().call().then((ret) => {
                    // console.log('want success',ret)
                    return ret
                }).catch(e => {
                    // console.log('want error',e)
                    return e
                });
                // 获取是否暂停
                const paused = await contract.methods.paused().call().then((ret) => {
                    // console.log('paused success',ret);
                    return ret
                }).catch(e => {
                    // console.log('pused error',e)
                    return e
                });
                resolve({ want, paused, address })
            })
        }))
        // 通过want的两个地址去获取名称
        for (let contractStrategy of contractStrategyList) {
            const want = contractStrategy.want;
            const contract0 = new web3.eth.Contract(erc20ABI, want['0']);
            const contract1 = new web3.eth.Contract(erc20ABI, want['1']);
            const names = await Promise.all([contract0.methods.name().call(), contract1.methods.name().call()]);
            want['00'] = names[0]
            want['11'] = names[1]
        }
        // console.log('contractStrategyList', contractStrategyList)
        // 设置 治理页面 controll合约下拉数据
        const controllerArr = JSON.parse(JSON.stringify(contractStrategyList))
        controllerArr.unshift({ address: EARN_CONTRACT_ADDRESS, want: { "00": 'HubPool', "11": '这个最后改' } })
        // 设置 治理页面 修改治理合约
        const OriginalContract = new web3.eth.Contract(StrategyMdex, EARN_CONTRACT_ADDRESS);
        const controllerOriginal = await OriginalContract.methods.controller().call().catch(() => {
            enqueueSnackbar('get controller error');
            return '';
        })
        const goverArr = JSON.parse(JSON.stringify(contractStrategyList))
        goverArr.unshift(
            { address: EARN_CONTRACT_ADDRESS, want: { "00": 'HubPool', "11": '这个最后改' } },
            { address: controllerOriginal, want: { "00": '现有Controller', "11": '' } },
            { address: "0x068622c39E539d5E3d0d1ADbCC21b90A9c6eDF7a", want: { "00": '新的compoundHub', "11": '' } },
            { address: "0x37fd1227839bbEC416D5Ca436B176EAB034D6853", want: { "00": '存储复利数据', "11": 'compoundStorage' } },
            { address: "0x6Cb7b14A014B99F8578fA485d4482E65d446F3F6", want: { "00": '质押到董事会', "11": 'boardroom' } },
        )

        // console.log(contractStrategyList, 'kkkkkk')
        // 调 strategyName
        let nameTemp = [];
        for (let item of contractStrategyList) {
            nameTemp.push(await strategyName(item.address) || "MDEX");
            // console.log(strategyName(item.address), 'oooo')
        }
        // console.log(nameTemp, 'nameTemp nameTemp nameTemp')
        // 设置侧率池子
        setStrategyPools(contractStrategyList)
        setControllerArr(controllerArr)
        setGoverArr(goverArr)
        setNameArr(nameTemp)
    }
    async function strategyName(address) {
        const contract = new web3.eth.Contract(StrategyMdex, address);
        let ssName = await contract.methods.strategyName().call().catch(e => 0)
        // console.log(ssName, 'llll')
        return ssName;
    }
    // 解除质押
    async function unZhiYa(strategyPool) {
        const contract = new web3.eth.Contract(GovernanceHub, GOV_ADDRESS);
        contract.options.gas = 8600000;
        // return await contract.methods.withdrawAll().send()
        return new Promise((resolve, reject) => {
            contract.methods.withdrawAll(strategyPool.address).send({ from: address })
                .on('transactionHash', function (hash) {
                    resolve(hash);
                })
                .on('receipt', function (receipt) {
                    enqueueSnackbar(`解除质押成功`, {
                        variant: 'success', anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'center',
                        },
                        autoHideDuration: 4000
                    })
                    resolve(receipt)
                })
                .on('error', function (error) {
                    reject(error)
                })
                .catch((error) => {
                    reject(error)
                })
        })
        // console.log('unZhiYa',strategyPool)
    }
    // 开启关闭自动质押
    async function closeAutoZhiYa(strategyPool) {
        // const contract = new web3.eth.Contract(StrategyMdex, strategyPool.address);
        const contract = new web3.eth.Contract(GovernanceHub, GOV_ADDRESS);
        contract.options.gas = 8600000;
        return new Promise((resolve, reject) => {
            contract.methods.setPause(strategyPool.address).send({ from: address })
                .on('transactionHash', function (hash) {
                    resolve(hash);
                })
                .on('receipt', function (receipt) {
                    // alert(10)
                    enqueueSnackbar(`自动质押设置成功`, {
                        variant: 'success', anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'center',
                        },
                        autoHideDuration: 4000
                    })
                    resolve(receipt)
                })
                .on('error', function (error) {
                    reject(error)
                })
                .catch((error) => {
                    reject(error)
                })
        })
        // console.log('closeAutoZhiYa',strategyPool)

    }
    // 设置配对顺序
    async function setMatchQueueCall(matchQueue) {
        if (!web3) { return }
        let contract = new web3.eth.Contract(HubPool, EARN_CONTRACT_ADDRESS);
        let ctrAddress = await contract.methods.controller().call().catch(e => 0)
        contract = new web3.eth.Contract(GovernanceHub, GOV_ADDRESS);
        contract.options.gas = 8600000;
        return new Promise((resolve, reject) => {
            contract.methods.setStrategyList(ctrAddress, matchQueue.split(/\s*,\s*/)).send({ from: address }).on('transactionHash', function (hash) {
                resolve(hash);
            })
                .on('receipt', function (receipt) {
                    enqueueSnackbar(`设置配对顺序成功`, {
                        variant: 'success', anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'center',
                        },
                        autoHideDuration: 4000
                    })
                    resolve(receipt)
                })
                .on('error', function (error) {
                    reject(error)
                })
                .catch((error) => {
                    reject(error)
                })
        })
        // console.log('matchQueue',matchQueue)
    }
    //资金管理-提取金额new
    function adminWithdraw(targetAddress, tokenAddress, amount, tokenDecimals) {
        if (!web3) { return }
        // 输入的金额
        const amountByVal = new BigNumber(amount).multipliedBy(new BigNumber(10).exponentiatedBy(tokenDecimals)).toString(10)
        const contract = new web3.eth.Contract(GovernanceHub, GOV_ADDRESS);
        contract.options.gas = 8600000;
        contract.methods.inCaseTokensGetStuck(targetAddress, tokenAddress, amountByVal).send({ from: address }).on('transactionHash', function (hash) {
            // resolve(hash);
        })
            .on('receipt', function (receipt) {
                enqueueSnackbar(`设置成功`, {
                    variant: 'success', anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'center',
                    },
                    autoHideDuration: 4000
                })

            })
            .on('error', function (error) {
                enqueueSnackbar(`错误${JSON.stringify(error)}`, {
                    variant: 'error', anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'center',
                    },
                    autoHideDuration: 4000
                })
            })
            .catch((error) => {
                enqueueSnackbar(`错误${JSON.stringify(error)}`, {
                    variant: 'error', anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'center',
                    },
                    autoHideDuration: 4000
                })
            });
    }
    // 资金管理-提取金额
    async function withdrawAmount(tokenAddress, amount, tokenDecimals) {
        if (!web3) { return }
        // 输入的金额
        const amountByVal = new BigNumber(amount).multipliedBy(new BigNumber(10).exponentiatedBy(tokenDecimals)).toString(10)
        if (isNaN(amountByVal)) {
            enqueueSnackbar(`请输入合法的数字`, {
                variant: 'error', anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'center',
                },
                autoHideDuration: 4000
            })
            return;
        }
        // defiBank合约
        const defiBankContract = new web3.eth.Contract(HubPool, EARN_CONTRACT_ADDRESS);
        const controllerAddress = await defiBankContract.methods.controller().call().catch(e => 0)
        if (!controllerAddress) {
            return 0;
        }
        // controller 的合约
        const controllerContract = new web3.eth.Contract(ControllerHub, controllerAddress);
        defiBankContract.options.gas = 8600000;
        controllerContract.options.gas = 8600000;
        // 先查余额
        const contract = new web3.eth.Contract(erc20ABI, tokenAddress);
        contract.options.gas = 8600000;
        const balanceOf = await contract.methods.balanceOf(EARN_CONTRACT_ADDRESS).call().catch(e => e);

        // 如果balanceOf比输入的值小酒不用调withdraw
        if (balanceOf >= amountByVal) {
            // 再调defibank的 inCaseTokensGetStuck(代币合约地址, 金额)
            defiBankContract.methods.inCaseTokensGetStuck(tokenAddress, amountByVal).send({ from: address }).on('transactionHash', function (hash) {
                // resolve(hash);
            })
                .on('receipt', function (receipt) {
                    enqueueSnackbar(`设置成功`, {
                        variant: 'success', anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'center',
                        },
                        autoHideDuration: 4000
                    })

                })
                .on('error', function (error) {
                    enqueueSnackbar(`错误${JSON.stringify(error)}`, {
                        variant: 'error', anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'center',
                        },
                        autoHideDuration: 4000
                    })
                })
                .catch((error) => {
                    enqueueSnackbar(`错误${JSON.stringify(error)}`, {
                        variant: 'error', anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'center',
                        },
                        autoHideDuration: 4000
                    })
                });
        } else {
            // 1.先调 controller的withdraw(代币合约地址, 金额)
            controllerContract.methods.withdraw(tokenAddress, amountByVal).send({ from: address }).on('transactionHash', function (hash) {
                // resolve(hash);
            })
                .on('receipt', function (receipt) {
                    // alert(10)
                    enqueueSnackbar(`设置成功`, {
                        variant: 'success', anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'center',
                        },
                        autoHideDuration: 4000
                    })
                    // 2.再调defibank的 inCaseTokensGetStuck(代币合约地址, 金额)
                    defiBankContract.methods.inCaseTokensGetStuck(tokenAddress, amountByVal).send({ from: address }).on('transactionHash', function (hash) {
                        // resolve(hash);
                    })
                        .on('receipt', function (receipt) {
                            enqueueSnackbar(`设置成功`, {
                                variant: 'success', anchorOrigin: {
                                    vertical: 'top',
                                    horizontal: 'center',
                                },
                                autoHideDuration: 4000
                            })

                        })
                        .on('error', function (error) {
                            enqueueSnackbar(`错误${JSON.stringify(error)}`, {
                                variant: 'error', anchorOrigin: {
                                    vertical: 'top',
                                    horizontal: 'center',
                                },
                                autoHideDuration: 4000
                            })
                        })
                        .catch((error) => {
                            enqueueSnackbar(`错误${JSON.stringify(error)}`, {
                                variant: 'error', anchorOrigin: {
                                    vertical: 'top',
                                    horizontal: 'center',
                                },
                                autoHideDuration: 4000
                            })
                        });
                    // resolve(receipt)
                })
                .on('error', function (error) {
                    // reject(error)
                    enqueueSnackbar(`错误${JSON.stringify(error)}`, {
                        variant: 'error', anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'center',
                        },
                        autoHideDuration: 4000
                    })
                })
                .catch((error) => {
                    // reject(error)
                    enqueueSnackbar(`错误${JSON.stringify(error)}`, {
                        variant: 'error', anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'center',
                        },
                        autoHideDuration: 4000
                    })
                });
        }
    }
    return {
        adminWithdraw,
        setMatchQueueCall,
        checkContract,
        addNewPool,
        isPoolAdded,
        editPoolControl,
        getStrategy,
        strategyPools,
        controllerPools,
        goverPools,
        nameArr,
        unZhiYa,
        closeAutoZhiYa,
        withdrawAmount
    }
}

// 获取项目总额度
export function useSetAndGetTotals() {
    const { web3, address } = useConnectWallet();
    const dispatch = useDispatch();
    const { pools } = useSelector(
        state => ({
            pools: state.lp.pools
        }),
        shallowEqual,
    );
    // if (!web3) return;
    const getPoolTotal = async () => {
        if (!web3||!_IS_CURRENT_NETWORK_ID_SUPPORTED_) { return }
        // console.log('pools', pools)
        const contract = new web3.eth.Contract(HubPool, EARN_CONTRACT_ADDRESS);
        for (let pool of pools) {
            const { tokenDecimals } = pool;
            const poolId = await contract.methods.getPoolId(pool.tokenAddress).call().catch(() => {
                console.log('获取poolId出错')
                return 0
            });
            const { totalAmountLimit, profit } = await contract.methods.poolInfo(poolId).call().catch(() => ({ ...pool, totalAmountLimit: pool.total, profit: pool.rateMdx }));
            pool.profit = profit;
            if (poolId) {
                pool.total = (totalAmountLimit / Number(`1e${tokenDecimals}`)).toFixed()
            }
        }

        dispatch({
            type: LP_FETCH_POOL_BALANCES_SUCCESS,
            data: [...pools]
        });
    }
    const intervalProfitAndTotals = () => {
        setInterval(getPoolTotal, 5000)
    }
    return {
        getPoolTotal,
        intervalProfitAndTotals
    }
}

// 总收益
export function useRateAndTotals() {
    const { enqueueSnackbar } = useSnackbar();
    const { web3, address } = useConnectWallet();
    //获取设置GOV_ADDRESS
    async function getAndSetGOVAddress() {
        if (!web3||!_IS_CURRENT_NETWORK_ID_SUPPORTED_) { return }
        const contract = new web3.eth.Contract(HubPool, EARN_CONTRACT_ADDRESS);
        const governanceAddress = await contract.methods.governance().call().catch(() => false)
        governanceAddress && setGOVAddress(governanceAddress)
    }
    useEffect(() => {
        //获取设置GOV_ADDRESS
        getAndSetGOVAddress();
        const timer = setInterval(() => {
            getAndSetGOVAddress();
        }, 5000);
        // clearInterval(timer)
        return () => clearInterval(timer)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [web3, address])
    // 设置匹配100%
    const setMatch100 = () => {
        if (!web3) { return }
        const contract = new web3.eth.Contract(GovernanceHub, GOV_ADDRESS);
        contract.options.gas = 8600000;
        // return 
        return new Promise((resolve, reject) => {
            contract.methods.setMin(EARN_CONTRACT_ADDRESS, '0x0298c2b32eaE4da002a15f36fdf7615BEa3DA047', 10000).send({ from: address })
                .on('transactionHash', function (hash) {
                    enqueueSnackbar(`设置:${hash}`, {
                        variant: 'success', anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'center',
                        },
                        autoHideDuration: 4000,

                    })
                    resolve(hash);
                })
                .on('receipt', function (receipt) {
                    enqueueSnackbar(`设置成功`, {
                        variant: 'success', anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'center',
                        },
                        autoHideDuration: 4000
                    })
                    resolve(receipt)
                })
                .on('error', function (error) {
                    reject(error)
                })
                .catch((error) => {
                    enqueueSnackbar(`设置失败:${JSON.stringify(error)}`, {
                        variant: 'error', anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'center',
                        },
                        autoHideDuration: 4000
                    })
                    reject(error)
                })
        })
    }
    // 设置平台分成比例
    const sendRates = (val, tokenAddress) => {
        // 设置平台分成比例 setProfit(代币地址, 10000 - 输入框的值*100, true)
        if (!web3) { return }
        const contract = new web3.eth.Contract(GovernanceHub, GOV_ADDRESS);
        contract.options.gas = 8600000;
        return new Promise((resolve, reject) => {
            contract.methods.setProfit(EARN_CONTRACT_ADDRESS, tokenAddress, 10000 - val * 100, true).send({ from: address })
                .on('transactionHash', function (hash) {
                    resolve(hash);
                })
                .on('receipt', function (receipt) {
                    resolve(receipt)
                })
                .on('error', function (error) {
                    reject(error)
                })
                .catch((error) => {
                    reject(error)
                })
        })

    }
    // 设置项目总额度
    const sendTotals = async (val, tokenAddress, tokenDecimals) => {
        // 设置 投资上限 setTotalAmountLimit(代币合约地址, 不带小数金额)
        if (!web3) { return }
        const contract = new web3.eth.Contract(GovernanceHub, GOV_ADDRESS);
        contract.options.gas = 8600000;
        return new Promise((resolve, reject) => {
            contract.methods.setTotalAmountLimit(EARN_CONTRACT_ADDRESS, tokenAddress, new BigNumber(val).multipliedBy(new BigNumber(10).exponentiatedBy(tokenDecimals)).toString(10)).send({ from: address })
                .on('transactionHash', function (hash) {
                    resolve(hash);
                })
                .on('receipt', function (receipt) {
                    resolve(receipt)
                })
                .on('error', function (error) {
                    reject(error)
                })
                .catch((error) => {
                    reject(error)
                })
        })
    }
    return {
        sendRates,
        sendTotals,
        setMatch100
    }
}


// 总锁仓
export function useRateAndReward() {
    const { web3, address } = useConnectWallet();
    const [apl, setTvl] = useState([]);
    const fetchTvl = useCallback(async () => {
        if (!web3||!_IS_CURRENT_NETWORK_ID_SUPPORTED_) { return }
        const contract = new web3.eth.Contract(HubPool, EARN_CONTRACT_ADDRESS);
        const apl = await contract.methods.rateAndReward(USDT_ADDRESS).call();
        const returnTvl = apl.map(item => {
            const token = item[0];
            let tokenDecimals = 18;
            pools.forEach(pool => {
                if (pool.tokenAddress === token) {
                    tokenDecimals = pool.tokenDecimals;
                }
            })
            return [...item, tokenDecimals]
        })
        setTvl(returnTvl);
    }, [setTvl, web3])

    useEffect(() => {
        if (web3 && address) {
            fetchTvl()
        }
        let refreshInterval = setInterval(fetchTvl, 5000)
        return () => clearInterval(refreshInterval)
    }, [web3, address, fetchTvl])

    return apl
}
// 总锁仓量
export function useRateAndTvl() {
    const { web3, address } = useConnectWallet();
    const [tvl, setTvl] = useState([]);

    const fetchTvl = useCallback(async () => {
        if (!web3||!_IS_CURRENT_NETWORK_ID_SUPPORTED_) { return }
        const contract = new web3.eth.Contract(HubPool, EARN_CONTRACT_ADDRESS);
        const tvl = await contract.methods.rateAndTvl(USDT_ADDRESS).call();
        const returnTvl = tvl.map(item => {
            const token = item[0];
            let tokenDecimals = 18;
            pools.forEach(pool => {
                if (pool.tokenAddress === token) {
                    tokenDecimals = pool.tokenDecimals;
                }
            })
            return [...item, tokenDecimals]
        })
        setTvl(returnTvl);
    }, [setTvl, web3])

    useEffect(() => {
        if (web3 && address) {
            fetchTvl()
        }
        let refreshInterval = setInterval(fetchTvl, 5000)
        return () => clearInterval(refreshInterval)
    }, [web3, address, fetchTvl])

    return tvl
}
// LP 请求 币种对MDX汇率
// 所有币种汇率
export function useAllRate(isLp) {
    const { web3,networkId, address } = useConnectWallet();
    const [poolRate, setPoolRate] = useState(false);
    const [coinRate, setCoinRate] = useState({});
    const [bnbRate, setBnbRate] = useState();
    const fetchRate = useCallback(async () => {
        if (!web3||!_IS_CURRENT_NETWORK_ID_SUPPORTED_) { return }
        const contract = new web3.eth.Contract(AggregateHub, AGG_CONTRACT);
        // mdx兑usdt的价格
        const MDXAddress = mdxAddressMap[networkId]?mdxAddressMap[networkId]:mdxAddressMap['128']
        const mdxUsdtRate = await contract.methods.getUsdtRate(MDXAddress, _IS_BSC_ ? 1 : 0, false).call().catch(0);
        if(mdxUsdtRate <= 0){
            return;
        }
        let returnRate = poolRate || {};
        let returnCoinRate = coinRate || {};
        // 获取bsc链 bnb 对 u 汇率
        let returnBnbRate = bnbRate || null;
        if (_IS_BSC_) {
            const rate = await contract.methods.getUsdtRate("0xbb4cdb9cbd36b01bd1cbaebf2de08d9173bc095c", _IS_BSC_ ? 1 : 0, false).call().catch(0);
            returnBnbRate = rate;
            if(rate > 0){
                setBnbRate(returnBnbRate);
            }
        }
        //getTokenUsdtRate 接口返回的汇率放大了1e18倍
        for (let tokenAddress in pools) {
            const pool = pools[tokenAddress];
            const rate = await contract.methods.getUsdtRate(pool.tokenAddress, _IS_BSC_ ? 1 : 0, isLp).call().catch(0);
            if(rate <= 0){
                return;
            }
            //token兑换mdx的价格, 比如一个btc兑换usdt的价格
            const tokenMdxRate = rate/mdxUsdtRate;
            returnRate[pool.tokenAddress] = tokenMdxRate;
            returnCoinRate[pool.tokenAddress] = rate;
        }
        setPoolRate(returnRate);
        setCoinRate(returnCoinRate);
    }, [web3, networkId, poolRate, coinRate, bnbRate, isLp])

    useEffect(() => {
        if (web3 ) {
            fetchRate()
        }
        let refreshInterval = setInterval(fetchRate, 300000)
        return () => clearInterval(refreshInterval)
    }, [web3, fetchRate])

    return {poolRate, coinRate, bnbRate}
}
// lp 已存入 折合U
export function useConvertU(tokenAddress, amount) {
    const { web3, address } = useConnectWallet();
    const [lpTokenInfo, setLpTokenInfo] = useState({});
    // console.log(amount, 'amount amount amount amount');
    const fetchTokenInfo = useCallback(async () => {
        // console.log(amount, 'amount amount amount amount');
        if (!web3||!_IS_CURRENT_NETWORK_ID_SUPPORTED_) { return }
        // const aggContract = _IS_BSC_? "0x76a2E234C0301c769e6209b86936306A701d786E":"0x9f374825363cc396Ab828793Bc50cE4Db7548943";
        const contract = new web3.eth.Contract(AggregateHub, AGG_CONTRACT);
        const returnLpTokenInfo = await contract.methods.getLpTokenInfo(tokenAddress, amount, _IS_BSC_ ? 1 : 0).call().catch(0);
        setLpTokenInfo(returnLpTokenInfo);
    }, [setLpTokenInfo, web3, amount, tokenAddress])

    useEffect(() => {
        if (web3 ) {
            fetchTokenInfo()
        }
        let refreshInterval = setInterval(fetchTokenInfo, 10000)
        return () => clearInterval(refreshInterval)
    }, [web3, fetchTokenInfo])

    return lpTokenInfo
}

// 已存入
export function useDeposited(poolAddress, tokenAddress, tokenDecimals) {
    const { web3, address } = useConnectWallet();
    // 单个币种已存入
    const [deposited, setDeposited] = useState("0");
    const fetchDeposited = useCallback(async () => {
        if (!web3||!_IS_CURRENT_NETWORK_ID_SUPPORTED_) { return }
        const contract = new web3.eth.Contract(HubPool, poolAddress);
        const deposited = await contract.methods.getDepositAsset(tokenAddress, address).call()
        // 设置单个币种的已存入
        setDeposited(deposited)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [address, setDeposited, poolAddress, web3])

    useEffect(() => {
        if (web3 && address) {
            fetchDeposited()
        }
        let refreshInterval = setInterval(fetchDeposited, 10000)
        return () => clearInterval(refreshInterval)
    }, [web3, address, fetchDeposited])

    return { deposited }
}
// // 个人累计锁仓
// export function useMeLock() {
//     const { web3, address } = useConnectWallet();
//     // 单个币种已存入
//     const [depositedAll, setDepositedAll] = useState({});
//     const fetchDeposited = useCallback(async () => {
//         if (!web3) { return }
//         const poolsBydeposited = {}
//         for (let pool of pools) {
//             const contract = new web3.eth.Contract(HubPool, pool.earnContractAddress);
//             const deposited = await contract.methods.getDepositAsset(pool.tokenAddress, address).call()
//             poolsBydeposited[pool.tokenAddress] = deposited
//         }
//         setDepositedAll(poolsBydeposited)
//         // eslint-disable-next-line react-hooks/exhaustive-deps
//     }, [address, depositedAll, web3])

//     useEffect(() => {
//         if (web3 && address) {
//             fetchDeposited()
//         }
//         let refreshInterval = null;
//         window.clearInterval(refreshInterval)
//         refreshInterval = setInterval(fetchDeposited, 10000)
//         return () => clearInterval(refreshInterval)
//     }, [web3, address, fetchDeposited])

//     return { depositedAll }
// }
// 收益率
export function useApy(poolAddress, tokenAddress, pool, rateMdx) {
    const { web3, address } = useConnectWallet();
    const [apy, setApy] = useState("0");

    const fetchApy = useCallback(async () => {
        if (!web3||!_IS_CURRENT_NETWORK_ID_SUPPORTED_) { return }
        const prevApy = apy / (100 * rateMdx);
        const contract = new web3.eth.Contract(HubPool, poolAddress);
        const newapy = await contract.methods.getApy(tokenAddress).call()
        // console.log(newapy,poolAddress,tokenAddress,'111111111111111')
        // 6964 0x94ad8542f3F1bBb6D0dFa4B91589a264FF9b0056 0x3F57530bDBA9bCD703c8Ba75c57CF7dE52014036 111111111111111

        // 0x6885754bD5FB210C28C6f4dCf66C2E4480d7ED7f 0x94ad8542f3F1bBb6D0dFa4B91589a264FF9b0056 0x3F57530bDBA9bCD703c8Ba75c57CF7dE52014036 0x174A11c1A1D2e05f13fd1fFa073fc1FC6af7f05B 22222222
        const nextApy = newapy / (100 * rateMdx);
        //console.log(`下一次${pool.token}的Apy`,prevApy,nextApy, (nextApy - prevApy )/prevApy)
        if (nextApy < prevApy && Math.abs(nextApy - prevApy) / prevApy > 0.2) {
            return;
        }
        const localApy = window.localStorage.getItem(tokenAddress);
        setApy(localApy ? localApy : newapy);
        // window.localStorage.setItem(tokenAddress, newapy)
        window.localStorage.setItem(tokenAddress, Number(newapy) ? newapy : localApy)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [address, setApy, poolAddress, web3, apy])

    useEffect(() => {
        if (web3 && address) {
            fetchApy()
        }
        let refreshInterval = setInterval(fetchApy, 10000)
        return () => clearInterval(refreshInterval)
    }, [web3, address, fetchApy])

    return apy
}

export function useAggregate(isBscChain) {
    const { web3,networkId, address } = useConnectWallet();
    const [data, setApy] = useState("0");
    let aggContract = aggContractAddressMap[networkId]?aggContractAddressMap[networkId]:aggContractAddressMap['128']
    let chainType = isBscChain? 1 : 0;
    const fetchApy = useCallback(async () => {
        if (!web3) { return }
        const contract = new web3.eth.Contract(AggregateHub, aggContract);
        contract.options.gas = 18600000;
        const data = await contract.methods.statePoolAndUserTokenUsd(EARN_CONTRACT_ADDRESS,address,chainType,true).call().catch()
        setApy(data);
    }, [web3, aggContract, address, chainType])

    useEffect(() => {
        if (web3 && address) {
            fetchApy()
        }
        let refreshInterval = setInterval(fetchApy, 10000)
        return () => clearInterval(refreshInterval)
    }, [web3, address, fetchApy])

    return data
}

// 复利--复利未提收益
export function useCompound(tokenAddress) {
    const { web3,networkId, address } = useConnectWallet();
    const [compound, setCompound] = useState("0");
    const [compoundEarned, setCompoundEarned] = useState("0");

    const fetchCompound = useCallback(async () => {
        if (!web3||!_IS_CURRENT_NETWORK_ID_SUPPORTED_) { return }
        const  compoundHubAdress = compoundHubAdressMap[networkId]?compoundHubAdressMap[networkId]:compoundHubAdressMap['128'];
        if (compoundHubAdress) {
            let compoundHubClient = new web3.eth.Contract(CompoundHub, compoundHubAdress);
            compoundHubClient.options.gas = 18600000;
            const newCompound = await compoundHubClient.methods.getApy(tokenAddress).call().catch(0)
            setCompound(newCompound)
            const newCompoundEarned = await compoundHubClient.methods.pending(tokenAddress, address, EARN_CONTRACT_ADDRESS).call().catch(0)
            setCompoundEarned(newCompoundEarned)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [address, setCompound, setCompoundEarned, web3, compound, compoundEarned])

    useEffect(() => {
        if (web3 && address) {
            fetchCompound()
        }
        let refreshInterval = setInterval(fetchCompound, 10000)
        return () => clearInterval(refreshInterval)
    }, [web3, address, fetchCompound])

    return { compound, compoundEarned }
}
// 已收益
export function useEarned(poolAddress, tokenAddress) {
    const { web3, address } = useConnectWallet();
    const [earned, setEarned] = useState("0");
    const fetchEarned = useCallback(async () => {
        if (!web3||!_IS_CURRENT_NETWORK_ID_SUPPORTED_) { return }
        const contract = new web3.eth.Contract(HubPool, poolAddress);
        contract.options.gas = 8600000;
        const earned = await contract.methods.earned(tokenAddress, address).call()
        setEarned(earned)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [address, setEarned, poolAddress, web3])

    useEffect(() => {
        if (web3 && address) {
            fetchEarned()
        }
        let refreshInterval = setInterval(fetchEarned, 10000)
        return () => clearInterval(refreshInterval)
    }, [web3, address, fetchEarned])

    return earned
}
// 所有链所有项目 总锁仓量 及 收益
export function useTvlAndProfit() {
    const [tvl, setTvl] = useState('');
    const [profit, setProfit] = useState('');
    const origin = window.location.origin;
    const fetchTvlAndProfit = useCallback(async () => {
        const url = `${origin}/api/defi/tvlAndProfit`;
        axios.get(url).then((res) => {
            // isFetchTvlAndProfitPending = false;
            if(res.data.code==='0'){
                setTvl(res.data.data.tvl);
                setProfit(res.data.data.profit);
            } else {
                tvl ? setTvl(tvl) : setTvl(1703111851.26);
                profit ? setProfit(profit) : setProfit(45202302.67);
            }
        });
    }, [origin, profit, tvl])

    useEffect(() => {
        fetchTvlAndProfit()
        let refreshInterval = null;
        window.clearInterval(refreshInterval)
        refreshInterval = setInterval(() => {
            fetchTvlAndProfit()
        }, 10000)
        return () => clearInterval(refreshInterval)
    }, [fetchTvlAndProfit])
    return {
        tvl,
        profit,
    }
}
let source = null;
// 单链所有项目 总锁仓量 及 收益
export function useDefiChainTvlAndProfit(chain_type, user_addr) {
    // const [chainTvl, setChainTvl] = useState('');
    // const [chainProfit, setChainProfit] = useState('');
    if(source){//需要判断下如果存在实例就进行清空
        source.cancel('自定义错误信息');//中断上一次未完成响应的请求(如果上次请求已经返回结果,则无法进行终止操作)
    }
    source = axios.CancelToken.source(); // 这里初始化source对象
    const [tvlAndProfit,settvlAndProfit] = useState({
        tvl:'',
        profit:''
    })
    const fetchDefiChainTvlAndProfit = useCallback(async () => {
        let paramsStr = '';
        const params = {
            chain_type,
        };
        if (user_addr) {
            params.user_addr = user_addr;
        }
        Object.keys(params).forEach((item) => {
            paramsStr += `&${item}=${params[item]}`;
        });
        const url = `https://www.coinwind.com/api/defi/chainTvlAndProfit${paramsStr.replace('&', '?')}`;
        axios({
            url: url,
            cancelToken: source.token,
        }).then((res) => {
            if(res.data.code==='0'){
                settvlAndProfit({
                    ...tvlAndProfit,
                    tvl:res.data.data.tvl,
                    profit:res.data.data.profit
                })
            }
        });
    }, [chain_type, tvlAndProfit, user_addr])
    useEffect(() => {
        fetchDefiChainTvlAndProfit()
        let refreshInterval = null;
        window.clearInterval(refreshInterval)
        refreshInterval = setInterval(() => {
            fetchDefiChainTvlAndProfit()
        }, 20000)
        return () => clearInterval(refreshInterval)
    }, [fetchDefiChainTvlAndProfit])
    // return {
    //     chainTvl,
    //     chainProfit,
    // }
    return tvlAndProfit
}
// 单链 单项目 总锁仓量 及 收益
// let isFetchTvlAndProfitPending = false;
export function useHubChainTvlAndProfit(chain_type, user_addr) {
    // const [hubTvl, setHubTvl] = useState('');
    // const [hubProfit, setHubProfit] = useState('');
    const [tvlAndProfit,settvlAndProfit] = useState({
        tvl:'',
        profit:''
    })
    // console.log(EARN_CONTRACT_ADDRESS, 'EARN_CONTRACT_ADDRESS EARN_CONTRACT_ADDRESS')
    const fetchHubChainTvlAndProfit = useCallback(() => {
        let paramsStr = '';
        const params = {
            chain_type,
            hub_pool: EARN_CONTRACT_ADDRESS,
        };
        if (user_addr) {
            params.user_addr = user_addr;
        }
        Object.keys(params).forEach((item) => {
            paramsStr += `&${item}=${params[item]}`;
        });
        const url = `https://www.coinwind.com/api/defi/hubpool/chainTvlAndProfit${paramsStr.replace('&', '?')}`;
        // if(isFetchTvlAndProfitPending) return;
        // isFetchTvlAndProfitPending = true;
        axios.get(url).then((res) => {
            // isFetchTvlAndProfitPending = false;
            if(res.data.code==='0'){
                settvlAndProfit({
                    ...tvlAndProfit,
                    tvl:res.data.data.tvl,
                    profit:res.data.data.profit
                })
            }
        });
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [chain_type, user_addr])

    useEffect(() => {
        fetchHubChainTvlAndProfit()
        let refreshInterval = null;
        window.clearInterval(refreshInterval)
        refreshInterval = setInterval(() => {
            fetchHubChainTvlAndProfit()
        }, 20000)
        return () => clearInterval(refreshInterval)
    }, [fetchHubChainTvlAndProfit])
    return tvlAndProfit
}


