/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useCallback, useEffect } from 'react';
import axios from 'axios';
import { CowAirdop, erc20ABI, AggregateHub, AggregateHubNew ,HubPool,CompoundHub,DaoAggregate} from 'features/configure/abi';
import { useSnackbar } from 'notistack';
import { useConnectWallet } from '../../home/redux/hooks';
import { useSelector,useDispatch ,shallowEqual} from 'react-redux';
import {
    pools,
    earnContractAddress as EARN_CONTRACT_ADDRESS,
    USDTAddress as USDT_ADDRESS,
    GOVAddress as GOV_ADDRESS, setGOVAddress,
    aggContract as AGG_CONTRACT
} from "../../configure/pools_LP-dao";
import {
    LP_FETCH_POOL_BALANCES_SUCCESS
} from './constants';
import { useBasicInfo } from "../../configure/pools";
import { ctrAddressMap,_IS_BSC_,_IS_CURRENT_NETWORK_ID_SUPPORTED_,_IS_ETH_ ,compoundHubAdressMap,mdxAddressMap,_CURRENT_NETWORK_ID_} from '../../configure/chainsParamsConfig';
export { useFetchApproval } from './fetchApproval';
export { useFetchDeposit } from './fetchDeposit';
export { useFetchWithdraw } from './fetchWithdraw';
export { useFetchContractApy } from './fetchContractApy';
export { useFetchPoolBalances } from './fetchPoolBalances';
// 获取COW余额
export function useFetchCowBalances() {
    const { web3, address } = useConnectWallet();
    const [cowBalance, setCowBalance] = useState(0);
    const {cowAddress} = useBasicInfo();
    const getBalance = useCallback(async () => {
        if (!web3 || !_IS_CURRENT_NETWORK_ID_SUPPORTED_) return;
        const contract = new web3.eth.Contract(erc20ABI, cowAddress)
        const balance = await contract.methods.balanceOf(address).call({ from: address });
        setCowBalance(balance/1e18)
    }, [web3, address])
    useEffect(() => {
        getBalance()
        const timer = setInterval(() => {
            getBalance()
        }, 5000);
        return () => clearInterval(timer)
    }, [web3, address])
    return {
        cowBalance,
    }
}

// 获取空投 领取空投
export function useFetchDrop() {
    const { web3,networkId, address } = useConnectWallet();
    const { enqueueSnackbar } = useSnackbar();
    const [dropPending, setDropPending] = useState(false)
    const [reward, setReward] = useState(0)
    const ctrAddress = ctrAddressMap[networkId]?ctrAddressMap[networkId]:ctrAddressMap['128'];
    const getDrop = useCallback(async () => {
        if(_IS_ETH_) return;
        const contract = new web3.eth.Contract(CowAirdop, ctrAddress)
        const rewards = await contract.methods.userReward(address).call({ from: address });
        setReward(rewards/1e18)
    }, [web3, address])
    const onDrop = useCallback(() => {
        setDropPending(true)
        const contract = new web3.eth.Contract(CowAirdop, ctrAddress)
        contract.methods.takeReward().send({ from: address }).on('transactionHash', function(hash){
            enqueueSnackbar(`领取中:${hash}`, {
                variant: 'success', anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'center',
                },
                autoHideDuration: 4000
            })
        })
        .on('receipt', function (receipt) {
            enqueueSnackbar(`领取成功`, {
                variant: 'success', anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'center',
                },
                autoHideDuration: 4000
            })
            setDropPending(false);
        })
        .on('error', function (error) {
            enqueueSnackbar(`错误${JSON.stringify(error)}`, {
                variant: 'error', anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'center',
                },
                autoHideDuration: 4000
            })
            setDropPending(false);
        })
        .catch((error) => {
            enqueueSnackbar(`错误${JSON.stringify(error)}`, {
                variant: 'error', anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'center',
                },
                autoHideDuration: 4000
            })
            setDropPending(false);
        });
    })
    useEffect(() => {
        const timer = setInterval(() => {
            getDrop()
        }, 5000);
        return () => clearInterval(timer)
    }, [web3, address])
    return {
        reward,
        dropPending,
        onDrop,
    }
}

// 获取单币列表数据
export function usePoolList(callback) {
    const {pools, earnContractAddress: EARN_CONTRACT_ADDRESS, aggAddress} = useBasicInfo();
    const { web3, address } = useConnectWallet();
    const tabActive = useSelector(state => state.vault.tabActive);
    const getPoolList = useCallback(async () => {
        if(!web3 || !_IS_CURRENT_NETWORK_ID_SUPPORTED_){
            return;
        }
        const contract = new web3.eth.Contract(DaoAggregate, aggAddress);
        const arr = [];
        for (let pool of pools) {
            const poolInfo = await contract.methods.getPoolInfo(EARN_CONTRACT_ADDRESS, pool.tokenAddress, address, tabActive).call().catch(e => {
                // console.log(EARN_CONTRACT_ADDRESS, pool.tokenAddress, "EARN_CONTRACT_ADDRESS");
            });
            arr.push({...pool, ...poolInfo});
        }
        // console.log(arr)
        callback(arr);
    }, [web3, address, tabActive])
    useEffect(() => {
        getPoolList()
        const timer = setInterval(() => {
            getPoolList();
        }, 5000);
        return () => clearInterval(timer)
    }, [getPoolList])
}

// 单个币种对U汇率
export function useCoinRate(tokenAddress) {
    const { web3 } = useConnectWallet();
    const {aggAddress, MdxAddress} = useBasicInfo();
    const [tokenURate, setTokenURate] = useState({});
    const [tokenRate, setTokenRate] = useState({}); 
    const fetchRate = useCallback(async () => {
        if (!web3 || !_IS_CURRENT_NETWORK_ID_SUPPORTED_) { return }
        const contract = new web3.eth.Contract(AggregateHub, aggAddress);
        const rate = await contract.methods.getUsdtRate(tokenAddress, _IS_BSC_ ? 1 : 0, false).call().catch(e => console.log(e));
        // mdx兑usdt的价格
        const mdxUsdtRate = await contract.methods.getUsdtRate(MdxAddress, _IS_BSC_ ? 1 : 0, false).call().catch(0);
        setTokenURate(rate/mdxUsdtRate);
        setTokenRate(rate);
    })
    useEffect(() => {
        fetchRate();
        let refreshInterval = setInterval(fetchRate, 300000);
        return () => clearInterval(refreshInterval);
    }, [web3, fetchRate])
    return {
        tokenURate,
        tokenRate,
    }
}

// 所有币种汇率
export function useAllRateDao(isLp) {
    const { web3 } = useConnectWallet();
    const {pools, aggAddress, MdxAddress,cowAddress} = useBasicInfo();
    const [poolRate, setPoolRate] = useState(false);
    const [coinRate, setCoinRate] = useState({});
    const tabActive = useSelector(state => state.vault.tabActive);

    const fetchRate = useCallback(async () => {
        if (!web3 || !_IS_CURRENT_NETWORK_ID_SUPPORTED_) { return }
        const contract = new web3.eth.Contract(AggregateHub, aggAddress);
        // mdx兑usdt的价格
        const mdxUsdtRate = await contract.methods.getUsdtRate(_IS_ETH_?cowAddress:MdxAddress, _IS_BSC_ ? 1 : 0, false).call().catch(0);
        if(mdxUsdtRate <= 0){
            return;
        }
        let returnRate = poolRate || {};
        let returnCoinRate = coinRate || {};
        //getTokenUsdtRate 接口返回的汇率放大了1e18倍
        // returnRate[MDXAddress] = mdxUsdtRate/1e18;
        pools.forEach(async pool => {
            const rate = await contract.methods.getUsdtRate(pool.tokenAddress, _IS_BSC_ ? 1 : 0, isLp).call().catch(0);
            if(rate <= 0){
                return;
            }
            //token兑换mdx的价格, 比如一个btc兑换usdt的价格
            const tokenMdxRate = rate/mdxUsdtRate;
            returnRate[pool.tokenAddress] = tokenMdxRate;
            returnCoinRate[pool.tokenAddress] = rate;
        })
        setPoolRate(returnRate);
        setCoinRate(returnCoinRate);
    }, [web3, isLp, tabActive])

    useEffect(() => {
        fetchRate();
        let refreshInterval = setInterval(fetchRate, 300000);
        return () => clearInterval(refreshInterval);
    }, [fetchRate])

    return {poolRate, coinRate}
}

// 单链 总锁仓 总收益

export function useAggregate(isBscChain) {
    const { web3, address } = useConnectWallet();
    const {earnContractAddress: EARN_CONTRACT_ADDRESS, aggAddress: AGG_ADDRESS} = useBasicInfo();
    const [data, setApy] = useState([]);
    let chainType = isBscChain? 1 : 0;
    const fetchApy = useCallback(async () => {
        if (!web3 || !_IS_CURRENT_NETWORK_ID_SUPPORTED_) { return }
        const contract = new web3.eth.Contract(AggregateHub, AGG_ADDRESS);
        contract.options.gas = 18600000;
        const data = await contract.methods.statePoolAndUserTokenUsd(EARN_CONTRACT_ADDRESS,address,chainType,false).call().catch(e => console.log(e))
        setApy(data);
    }, [isBscChain, address, EARN_CONTRACT_ADDRESS])

    useEffect(() => {
        if (web3 && address) {
            fetchApy()
        }
        let refreshInterval = setInterval(fetchApy, 10000)
        return () => clearInterval(refreshInterval)
    }, [web3, fetchApy])

    return data
}

// 所有链所有项目 总锁仓量 及 收益
export function useTvlAndProfit() {
    const [tvl, setTvl] = useState('');
    const [profit, setProfit] = useState('');
    const [cowProfit, setCowProfit] = useState('');
    const origin = window.location.origin;
    const fetchTvlAndProfit = useCallback(async () => {
        const url = `${origin}/api/defi/tvlAndProfit`;
        axios.get(url).then((res) => {
            if(res.data.code==='0'){
                setTvl(res.data.data.tvl);
                setProfit(res.data.data.profit);
                setCowProfit(res.data.data.cowProfit);
            } else {
                tvl ? setTvl(tvl) : setTvl(1703111851.26);
                profit ? setProfit(profit) : setProfit(45202302.67);
                cowProfit ? setCowProfit(cowProfit) : setCowProfit(0);
            }
        });
    }, [origin])

    useEffect(() => {
        if(!_IS_CURRENT_NETWORK_ID_SUPPORTED_) return;
        fetchTvlAndProfit()
        let refreshInterval = null;
        window.clearInterval(refreshInterval)
        refreshInterval = setInterval(() => {
            fetchTvlAndProfit()
        }, 10000)
        return () => clearInterval(refreshInterval)
    }, [fetchTvlAndProfit])
    return {
        tvl,
        profit,
        cowProfit,
    }
}
// 董事会分红
export function useBoardDividend() {
    const [mulChainTvl, setMulChainTvl] = useState(0);
    const [mulChainCowTvl, setMulChainCowTvl] = useState(0);
    const [mulChainProfit, setMulChainProfit] = useState(0);
    const [curChainTvl, setCurChainTvl] = useState(0);
    const [curChainCowTvl, setCurChainCowTvl] = useState(0);
    const [curChainProfit, setCurChainProfit] = useState(0);
    const origin = window.location.origin=='http://localhost:3000'? "http://47.93.150.214:9998":window.location.origin;
    const fetchBoardDividend = useCallback(async () => {
        const url = `${origin}/api/defi/dao/tvl?chainId=${_CURRENT_NETWORK_ID_}`;
        const res = {
            code:"0",
            msg:"",
            data:{
                mulChain:{
                    tvl:"100002.2456788",
                    cowTvl:"999921.39223",
                    profit:"100"
                },
                curChain:{
                    tvl:"8002.2456788",
                    cowTvl:"7654.39223",
                    profit:"28.0998"
                }
            }
        }
        
        axios.get(url).then((res) => {
            if(res.data.code==='0'){
                setMulChainTvl(res.data.data.mulChain.tvl);
                setMulChainCowTvl(res.data.data.mulChain.cowTvl);
                setMulChainProfit(res.data.data.mulChain.profit);
                setCurChainTvl(res.data.data.curChain.tvl);
                setCurChainCowTvl(res.data.data.curChain.cowTvl);
                setCurChainProfit(res.data.data.curChain.profit);
            } else {
                mulChainTvl ? setMulChainTvl(mulChainTvl) : setMulChainTvl();
                mulChainCowTvl ? setMulChainCowTvl(mulChainCowTvl) : setMulChainCowTvl();
                mulChainProfit? setMulChainProfit(mulChainProfit) : setMulChainProfit(0);
                curChainTvl? setCurChainTvl(curChainTvl) : setCurChainTvl();
                curChainCowTvl? setCurChainCowTvl(curChainCowTvl) : setCurChainCowTvl();
                curChainProfit? setCurChainProfit(curChainProfit) : setCurChainProfit(0);
            }
        });
    }, [origin])

    useEffect(() => {
        if(!_IS_CURRENT_NETWORK_ID_SUPPORTED_) return;
        fetchBoardDividend()
        let refreshInterval = null;
        window.clearInterval(refreshInterval)
        refreshInterval = setInterval(() => {
            fetchBoardDividend()
        }, 10000)
        return () => clearInterval(refreshInterval)
    }, [fetchBoardDividend])
    return {
        mulChainTvl,
        mulChainCowTvl ,
        mulChainProfit,
        curChainTvl,
        curChainCowTvl,
        curChainProfit,
    }
}








// // 已收益
// export function useEarned(poolAddress, tokenAddress) {
//     const { web3, address } = useConnectWallet();
//     const [earned, setEarned] = useState("0");
//     const fetchEarned = useCallback(async () => {
//         if (!web3||!_IS_CURRENT_NETWORK_ID_SUPPORTED_) { return }
//         const contract = new web3.eth.Contract(HubPool, poolAddress);
//         contract.options.gas = 8600000;
//         const earned = await contract.methods.earned(tokenAddress, address).call()
//         setEarned(earned)
//         // eslint-disable-next-line react-hooks/exhaustive-deps
//     }, [address, setEarned, poolAddress, web3])

//     useEffect(() => {
//         if (web3 && address) {
//             fetchEarned()
//         }
//         let refreshInterval = setInterval(fetchEarned, 10000)
//         return () => clearInterval(refreshInterval)
//     }, [web3, address, fetchEarned])

//     return earned
// }
// // 复利--复利未提收益
// export function useCompound(tokenAddress) {
//     const { web3,networkId, address } = useConnectWallet();
//     const [compound, setCompound] = useState("0");
//     const [compoundEarned, setCompoundEarned] = useState("0");

//     const fetchCompound = useCallback(async () => {
//         if (!web3||!_IS_CURRENT_NETWORK_ID_SUPPORTED_) { return }
//         const  compoundHubAdress = compoundHubAdressMap[networkId]?compoundHubAdressMap[networkId]:compoundHubAdressMap['128'];
//         if (compoundHubAdress) {
//             let compoundHubClient = new web3.eth.Contract(CompoundHub, compoundHubAdress);
//             compoundHubClient.options.gas = 18600000;
//             const newCompound = await compoundHubClient.methods.getApy(tokenAddress).call().catch(0)
//             setCompound(newCompound)
//             const newCompoundEarned = await compoundHubClient.methods.pending(tokenAddress, address, EARN_CONTRACT_ADDRESS).call().catch(0)
//             setCompoundEarned(newCompoundEarned)
//         }
//         // eslint-disable-next-line react-hooks/exhaustive-deps
//     }, [address, setCompound, setCompoundEarned, web3, compound, compoundEarned])

//     useEffect(() => {
//         if (web3 && address) {
//             fetchCompound()
//         }
//         let refreshInterval = setInterval(fetchCompound, 10000)
//         return () => clearInterval(refreshInterval)
//     }, [web3, address, fetchCompound])

//     return { compound, compoundEarned }
// }
// // lp 已存入 折合U
// export function useConvertU(tokenAddress, amount) {
//     const { web3, address } = useConnectWallet();
//     const [lpTokenInfo, setLpTokenInfo] = useState({});
//     // console.log(amount, 'amount amount amount amount');
//     const fetchTokenInfo = useCallback(async () => {
//         // console.log(amount, 'amount amount amount amount');
//         if (!web3||!_IS_CURRENT_NETWORK_ID_SUPPORTED_) { return }
//         // const aggContract = _IS_BSC_? "0x76a2E234C0301c769e6209b86936306A701d786E":"0x9f374825363cc396Ab828793Bc50cE4Db7548943";
//         const contract = new web3.eth.Contract(AggregateHub, AGG_CONTRACT);
//         const returnLpTokenInfo = await contract.methods.getLpTokenInfo(tokenAddress, amount, _IS_BSC_ ? 1 : 0).call().catch(0);
//         setLpTokenInfo(returnLpTokenInfo);
//     }, [setLpTokenInfo, web3, amount, tokenAddress])

//     useEffect(() => {
//         if (web3 ) {
//             fetchTokenInfo()
//         }
//         let refreshInterval = setInterval(fetchTokenInfo, 10000)
//         return () => clearInterval(refreshInterval)
//     }, [web3, fetchTokenInfo])

//     return lpTokenInfo
// }
// // 已存入
// export function useDeposited(poolAddress, tokenAddress, tokenDecimals) {
//     const { web3, address } = useConnectWallet();
//     // 单个币种已存入
//     const [deposited, setDeposited] = useState("0");
//     const fetchDeposited = useCallback(async () => {
//         if (!web3||!_IS_CURRENT_NETWORK_ID_SUPPORTED_) { return }
//         const contract = new web3.eth.Contract(HubPool, poolAddress);
//         const deposited = await contract.methods.getDepositAsset(tokenAddress, address).call()
//         // 设置单个币种的已存入
//         setDeposited(deposited)
//         // eslint-disable-next-line react-hooks/exhaustive-deps
//     }, [address, setDeposited, poolAddress, web3])

//     useEffect(() => {
//         if (web3 && address) {
//             fetchDeposited()
//         }
//         let refreshInterval = setInterval(fetchDeposited, 10000)
//         return () => clearInterval(refreshInterval)
//     }, [web3, address, fetchDeposited])

//     return { deposited }
// }
// // 总锁仓量
// export function useRateAndTvl() {
//     const { web3, address } = useConnectWallet();
//     const [tvl, setTvl] = useState([]);

//     const fetchTvl = useCallback(async () => {
//         if (!web3||!_IS_CURRENT_NETWORK_ID_SUPPORTED_) { return }
//         const contract = new web3.eth.Contract(HubPool, EARN_CONTRACT_ADDRESS);
//         const tvl = await contract.methods.rateAndTvl(USDT_ADDRESS).call();
//         const returnTvl = tvl.map(item => {
//             const token = item[0];
//             let tokenDecimals = 18;
//             pools.forEach(pool => {
//                 if (pool.tokenAddress === token) {
//                     tokenDecimals = pool.tokenDecimals;
//                 }
//             })
//             return [...item, tokenDecimals]
//         })
//         setTvl(returnTvl);
//     }, [setTvl, web3])

//     useEffect(() => {
//         if (web3 && address) {
//             fetchTvl()
//         }
//         let refreshInterval = setInterval(fetchTvl, 5000)
//         return () => clearInterval(refreshInterval)
//     }, [web3, address, fetchTvl])

//     return tvl
// }
// // LP 请求 币种对MDX汇率
// // 所有币种汇率
// export function useAllRate(isLp) {
//     const { web3,networkId, address } = useConnectWallet();
//     const [poolRate, setPoolRate] = useState(false);
//     const [coinRate, setCoinRate] = useState({});
//     const [bnbRate, setBnbRate] = useState();
//     const fetchRate = useCallback(async () => {
//         if (!web3||!_IS_CURRENT_NETWORK_ID_SUPPORTED_) { return }
//         const contract = new web3.eth.Contract(AggregateHub, AGG_CONTRACT);
//         // mdx兑usdt的价格
//         const MDXAddress = mdxAddressMap[networkId]?mdxAddressMap[networkId]:mdxAddressMap['128']
//         const mdxUsdtRate = await contract.methods.getUsdtRate(MDXAddress, _IS_BSC_ ? 1 : 0, false).call().catch(0);
//         if(mdxUsdtRate <= 0){
//             return;
//         }
//         let returnRate = poolRate || {};
//         let returnCoinRate = coinRate || {};
//         // 获取bsc链 bnb 对 u 汇率
//         let returnBnbRate = bnbRate || null;
//         if (_IS_BSC_) {
//             const rate = await contract.methods.getUsdtRate("0xbb4cdb9cbd36b01bd1cbaebf2de08d9173bc095c", _IS_BSC_ ? 1 : 0, false).call().catch(0);
//             returnBnbRate = rate;
//             if(rate > 0){
//                 setBnbRate(returnBnbRate);
//             }
//         }
//         //getTokenUsdtRate 接口返回的汇率放大了1e18倍
//         for (let tokenAddress in pools) {
//             const pool = pools[tokenAddress];
//             const rate = await contract.methods.getUsdtRate(pool.tokenAddress, _IS_BSC_ ? 1 : 0, isLp).call().catch(0);
//             if(rate <= 0){
//                 return;
//             }
//             //token兑换mdx的价格, 比如一个btc兑换usdt的价格
//             const tokenMdxRate = rate/mdxUsdtRate;
//             returnRate[pool.tokenAddress] = tokenMdxRate;
//             returnCoinRate[pool.tokenAddress] = rate;
//         }
//         setPoolRate(returnRate);
//         setCoinRate(returnCoinRate);
//     }, [web3, networkId, poolRate, coinRate, bnbRate, isLp])

//     useEffect(() => {
//         if (web3 ) {
//             fetchRate()
//         }
//         let refreshInterval = setInterval(fetchRate, 300000)
//         return () => clearInterval(refreshInterval)
//     }, [web3, fetchRate])

//     return {poolRate, coinRate, bnbRate}
// }
// // 获取项目总额度
// export function useSetAndGetTotals() {
//     const { web3, address } = useConnectWallet();
//     const dispatch = useDispatch();
//     const { pools } = useSelector(
//         state => ({
//             pools: state.bonus.pools
//         }),
//         shallowEqual,
//     );
//     // if (!web3) return;
//     const getPoolTotal = async () => {
//         if (!web3||!_IS_CURRENT_NETWORK_ID_SUPPORTED_) { return }
//         // console.log('pools', pools)
//         const contract = new web3.eth.Contract(HubPool, EARN_CONTRACT_ADDRESS);
//         for (let pool of pools) {
//             const { tokenDecimals } = pool;
//             const poolId = await contract.methods.getPoolId(pool.tokenAddress).call().catch(() => {
//                 return 0
//             });
//             const { totalAmountLimit, profit } = await contract.methods.poolInfo(poolId).call().catch(() => ({ ...pool, totalAmountLimit: pool.total, profit: pool.rateMdx }));
//             pool.profit = profit;
//             if (poolId) {
//                 pool.total = (totalAmountLimit / Number(`1e${tokenDecimals}`)).toFixed()
//             }
//         }

//         dispatch({
//             type: LP_FETCH_POOL_BALANCES_SUCCESS,
//             data: [...pools]
//         });
//     }
//     const intervalProfitAndTotals = () => {
//         setInterval(getPoolTotal, 5000)
//     }
//     return {
//         getPoolTotal,
//         intervalProfitAndTotals
//     }
// }
// // 收益率
// export function useApy(poolAddress, tokenAddress, pool, rateMdx) {
//     const { web3, address } = useConnectWallet();
//     const [apy, setApy] = useState("0");

//     const fetchApy = useCallback(async () => {
//         if (!web3||!_IS_CURRENT_NETWORK_ID_SUPPORTED_) { return }
//         const prevApy = apy / (100 * rateMdx);
//         const contract = new web3.eth.Contract(HubPool, poolAddress);
//         const newapy = await contract.methods.getApy(tokenAddress).call()
//         const nextApy = newapy / (100 * rateMdx);
//         //console.log(`下一次${pool.token}的Apy`,prevApy,nextApy, (nextApy - prevApy )/prevApy)
//         if (nextApy < prevApy && Math.abs(nextApy - prevApy) / prevApy > 0.2) {
//             return;
//         }
//         const localApy = window.localStorage.getItem(tokenAddress);
//         setApy(localApy ? localApy : newapy);
//         // window.localStorage.setItem(tokenAddress, newapy)
//         window.localStorage.setItem(tokenAddress, Number(newapy) ? newapy : localApy)
//         // eslint-disable-next-line react-hooks/exhaustive-deps
//     }, [address, setApy, poolAddress, web3, apy])

//     useEffect(() => {
//         if (web3 && address) {
//             fetchApy()
//         }
//         let refreshInterval = setInterval(fetchApy, 10000)
//         return () => clearInterval(refreshInterval)
//     }, [web3, address, fetchApy])

//     return apy
// }