import React, { Component,memo,useState } from 'react'
import {
    withRouter
  } from "react-router-dom";
import './staking.css'
import { makeStyles } from "@material-ui/core/styles";
import styles from "assets/jss/HomeStyle.js";
import { useDispatch, useSelector } from 'react-redux';
import BigNumber from "bignumber.js";
import { byDecimals } from 'features/helpers/bignumber';
import { useTranslation } from 'react-i18next';
import { isMobile } from 'features/helpers/utils';
import { usePoolList,
    useFetchApproval,
    useFetchDeposit,
    useFetchWithdraw,
    useAllRate,
    useCoinRate,
    useStakingList,} from '../../staking/redux/hooks'
import { txUrlMap, _NETWORK_ID_MAP_NAME_, cowAddressMap, _IS_ETH_ } from '../../configure/chainsParamsConfig';
import { useConnectWallet } from '../../home/redux/hooks';
import Button from '../../../components/Button/Button'
const useStyles = makeStyles(styles);
export default withRouter(function SectionPools({history}) {


    const classes = useStyles();
    const isH5 = isMobile() ? 'h5' : 'pc';
    const { t } = useTranslation();
    const [listData, setListData] = useState([]);
    const { web3, address, networkId, connected, connectWallet, connectWalletPending } = useConnectWallet();
    // 更新取列表数据
    usePoolList((pools) => {
        // console.log(pools,'sdfghdsfddsfd')
        let list = pools.slice(0,3)
        setListData([...list]);
    });
    // 新增 各币种兑MDX汇率
    const { poolRate, coinRate,getApys } = useAllRate(false);
    const { tokenRate: cow2Usdt } = useCoinRate(cowAddressMap[networkId] ? cowAddressMap[networkId] : cowAddressMap['128']);
    const dispatch = useDispatch();
    const [celueData,setCelueData] = useState({})
     // cow复利 取 cow策略下 cow池子cow单利字段
    const [cowCompoundApy, setCowCompoundApy] = useState("");
        // 格式化数据
    const forMat = (number, precision = 2) => {
        return new BigNumber(
        number
        ).multipliedBy(
        new BigNumber(10000)
        ).dividedToIntegerBy(
        new BigNumber(1)
        ).dividedBy(
        new BigNumber(10000)
        ).toNumber().toFixed(precision)
    }
    return (
        <div>
            <div className={classes.projectWraper}>
              <div className={classes.projectHeader}>
                <p className={classes.projectTitle}>Staking</p>
                <p className={classes.projectMore}>
                  <span className={classes.projectMoreText} onClick={() => {
                    history.push('/staking')
                  }}>{t('notice-more')}</span>
                  <img src={require('../../../images/icon-more.svg')} alt="" />
                </p>
              </div>
              <div className={classes.projectContainer}>
                {listData.map((pool) => {
                  // COW策略====>收益率 mdx + mdx复利 + cow
                  const rateMdx = poolRate[pool.tokenAddress];
                  const mdxApy = (pool.mdxApy / (100 * rateMdx)) / (10 ** (18 - pool.tokenDecimals));
                  // 计算cow与质押币种的汇率
                  const cow2Token = cow2Usdt / coinRate[pool.tokenAddress];
                  const cowApy = (pool.cowApy * cow2Token / (100)) / (10 ** (18 - pool.tokenDecimals));
                  const depositedMdxApy = forMat(mdxApy);
                  const depositedCowApy = forMat(cowApy);
                 
                  let allHuoApys = []
                  let allHuoApysDing = []
                  let cowApyViewDing = null
                  let apyAllList = []
                  let depositedCowApyList = []
                  let depositedCowApyDing= null
                  if(pool.data.length>1){
                    let cowApyLis = []
                    pool.data.map((item,index)=>{
                      let cow2Token = cow2Usdt / coinRate[pool.data[index].tokenAddress]
                      let cowApy = (pool.data[index].cowApy * cow2Token / (100)) / (10 ** (18 - pool.data[index].tokenDecimals));
                      cowApyLis.push(cowApy)
                    })
                    
                    cowApyLis.map((item,index)=>{
                      depositedCowApyList.push(forMat(item))
                    })
                    // const depositedCowApy = forMat(cowApy);
                    let allHuoApyss = []
                    pool.data.map((item,index)=>{
                      cowApyLis.map((el,ind)=>{
                        if(index===ind){
                          let finaCowCompounds;
                          if (getApys > 0) {
                            const depositedCompound = Number(getApys) / 100;
                            const xCompound = Math.pow((1 + (depositedCompound / 100) / 365), 365);
                            // console.log(cowApy,depositedCompound,xCompound,cowApy)
                            finaCowCompounds =forMat(((((el/100) / (depositedCompound / 100)) * (xCompound - 1)) * 100) - el)
                          } else {
                            finaCowCompounds = 0
                          }
                          const cowApyView = Number(item.apy/100)+Number(forMat(el))+Number(finaCowCompounds)
                          // console.log(cowApyView,'2222222')
                          allHuoApys.push({
                            allApy:cowApyView,
                            time:Number(item.fixedPeriod)/28800,
                            finaCowCompound:finaCowCompounds
                          })
                          allHuoApyss.push({
                            allApy:cowApyView,
                            time:Number(item.fixedPeriod)/28800,
                            finaCowCompound:finaCowCompounds
                          })
                        }
                      })
                    })
                    let bb = []
                    allHuoApyss.map(item=>{
                      bb.push(item.allApy)
                    })
                    for(var i=0;i<bb.length-1;i++){
                      for(var j=0;j<bb.length-i-1;j++){
                          if(bb[j]>bb[j+1]){// 相邻元素两两对比
                              var hand = bb[j];
                              bb[j]=bb[j+1];
                              bb[j+1]=hand;
                              
                          }
                          apyAllList = bb
                      }
                    }
                  }else{
                    const cow2Token = cow2Usdt / coinRate[pool.data[0].tokenAddress]
                    const cowApy = (pool.data[0].cowApy * cow2Token / (100)) / (10 ** (18 - pool.data[0].tokenDecimals));
                    depositedCowApyDing= forMat(cowApy);
                    // console.log(depositedCowApyDing)
                    // COW 复利
                    let finaCowCompound;
                    if (getApys > 0) {
                      const depositedCompound = Number(getApys) / 100;
                      const xCompound = Math.pow((1 + (depositedCompound / 100) / 365), 365);
                      // console.log(cowApy,depositedCompound,xCompound)
                      finaCowCompound =(pool.data.length===1?forMat(((((cowApy/100) / (depositedCompound / 100)) * (xCompound - 1)) * 100) - cowApy): forMat(((((cowApy/100) / (depositedCompound / 100)) * (xCompound - 1)) * 100) - cowApy))
                    } else {
                      finaCowCompound = 0
                    }
                    // console.log(pool.data[0].apy,depositedCowApyDing,finaCowCompound)
                    // const aa = Number(pool.data[0].apy/100)+Number(forMat(depositedCowApyDing))+Number(finaCowCompound)
                    // console.log(aa)
                    cowApyViewDing = Number(pool.data[0].apy/100)+Number(forMat(depositedCowApyDing))+Number(finaCowCompound)
                    const cowApyView= Number(pool.data[0].apy/100)+Number(forMat(depositedCowApyDing))+Number(finaCowCompound)
                    allHuoApysDing.push({
                      allApy:cowApyView,
                      time:Number(pool.data[0].fixedPeriod)/28800,
                      finaCowCompound:finaCowCompound
                    })
                    // console.log(cowApyViewDing)
                  }
                  return (
                    <>
                    <div className={classes.projectItem} key={pool.name}>
                      <div className={classes.projectCoin}>
                        <img className={classes.projectCoinImg} src={require(`../../../images/icon2/${pool.name}.svg`)} alt="" />
                        <span className={classes.projectCoinText}>{pool.names}</span>
                      </div>
                      <div className={classes.projectRight}>
                        <div className={`${classes.projectApy} project__apy`}>
                          {/* <i>{t('Home-Project-Text')}</i> */}
                          {/* <span>{pool.minApy/100}% ~ {pool.maxApy/100}%</span> */}
                         <span>{pool.data.length===1?Number(cowApyViewDing)>999999?<>999999+</>:isNaN(Number(cowApyViewDing))?'0.00':Number(cowApyViewDing).toFixed(2):
                         Number(apyAllList[apyAllList.length-1]).toFixed(2)>999999?<>999999+</>:isNaN(Number(apyAllList[apyAllList.length-1]))?'0.00':Number(apyAllList[apyAllList.length-1]).toFixed(2)}%</span>
                        </div>
                        <div className={classes.projectButton}>
                          <Button
                            className={classes.projectLink}
                            round
                            type="button"
                            color="primary"
                            onClick={() => {
                              history.push('/staking')
                            }}
                          >
                            {t('Home-btn')}
                          </Button>
                        </div>
                      </div>
                    </div>
                    </>
                  )
                })}
                {
                  listData.length<3&&<div className={classes.projectItem}>
                  <div className={classes.projectCoin}>
                    <img className={classes.projectCoinImg} src={require(`../../../images/icon2/coinwind_normal.svg`)} alt="" />
                    <span className={classes.projectCoinText}>???</span>
                  </div>
                  <div className={classes.projectRight}>
                    <div className={`${classes.projectApy} project__apy`}>
                      <span>???</span> 
                    </div>
                    <div className={classes.projectButton}>
                      <Button
                        className={`home-btn-hui`}
                        round
                        type="button"
                        color="primary"
                        disabled={true}
                      >
                        {t('Home-btn')}
                      </Button>
                    </div>
                  </div>
                </div>
                }
              </div>
            </div>
        </div>
    )
})
