const initialState = {
  contractApy: {},
  fetchContractApyPending: false,
  fetchPoolBalancesPending: false,
  fetchBalancesPending: false,
  fetchApprovalPending: {},
  fetchDepositPending: {},
  fetchWithdrawPending: {},
  fetchWithdrawIncomePending: {},
  tabActive: 1,
  tabActives: 1,
};

export default initialState;