
export function bsc_info() {
    const earnContractAddress = "0x6bA7d75eC6576F88a10bE832C56F0F27DC040dDD";
    const USDTAddress = '0x55d398326f99059fF775485246999027B3197955';
    const aggAddress = "0xfb03520e5a38d3e59F4cA213c712eAC2092A0c5c";
    const MdxAddress = "0x9C65AB58d8d978DB963e63f2bfB7121627e3a739";
    const pools = [
        {
          // 标识新的，表示开放额度
          type: '',
          // 开放额度
          openAmount: "2,400,000",
          // 开放时间
          openDate: "2021.04.12(GTM+8)",
          // 开放了吗
          disabled: false,
          id: 'MDX',
          name: 'MDX',
          token: 'MDX',
          tokenDescription: 'MDX',
          tokenAddress: '0x9C65AB58d8d978DB963e63f2bfB7121627e3a739',
          tokenDecimals: 18,
          itokenDecimals: 18,
          depostLimit: 0,
          tokenDescriptionUrl: '',
          tokenDescriptionUrl2: '',
          earnedToken: 'MDX',
          earnedTokenAddress: '0x9C65AB58d8d978DB963e63f2bfB7121627e3a739',
          earnContractAddress,
          defaultApy: "405.30",
          pricePerFullShare: 1,
          pastPricePerFullShare: 1,
          rateMdx: 1,
          total: 2400000,
          // 是否上首页
          topHome: true,
          conduct: true,
        },
        {
          // 标识新的，表示开放额度
          type: '',
          // 开放额度
          openAmount: "500,000",
          // 开放时间
          openDate: "2021.04.12(GTM+8)",
          // 开放了吗
          disabled: false,
          id: 'HMDX',
          name: 'HMDX',
          token: 'HMDX',
          tokenDescription: 'HMDX',
          tokenAddress: '0xAEE4164c1ee46ed0bbC34790f1a3d1Fc87796668',
          tokenDecimals: 18,
          itokenDecimals: 18,
          depostLimit: 0,
          tokenDescriptionUrl: '',
          tokenDescriptionUrl2: '',
          earnedToken: 'MDX',
          earnedTokenAddress: '0x9C65AB58d8d978DB963e63f2bfB7121627e3a739',
          earnContractAddress,
          defaultApy: "405.30",
          pricePerFullShare: 1,
          pastPricePerFullShare: 1,
          rateMdx: 1,
          total: 500000,
          conduct: false,
        },
        {
          // 标识新的，表示开放额度
          type: '',
          // 开放额度
          openAmount: "20,000,000",
          // 开放时间
          openDate: "2021.04.12(GTM+8)",
          // 开放了吗
          disabled: false,
          id: 'BUSD',
          name: 'BUSD',
          token: 'BUSD',
          tokenDescription: 'BUSD',
          tokenAddress: '0xe9e7cea3dedca5984780bafc599bd69add087d56',
          tokenDecimals: 18,
          itokenDecimals: 18,
          depostLimit: 0,
          tokenDescriptionUrl: '',
          tokenDescriptionUrl2: '',
          earnedToken: 'MDX',
          earnedTokenAddress: '0x9C65AB58d8d978DB963e63f2bfB7121627e3a739',
          earnContractAddress,
          defaultApy: "63.00",
          pricePerFullShare: 1,
          pastPricePerFullShare: 1,
          rateMdx: 391,
          total: 20000000,
          // 是否上首页
          topHome: true,
          conduct: false,
        },
        {
          // 标识新的，表示开放额度
          type: '',
          // 开放额度
          openAmount: "63,000",
          // 开放时间
          openDate: "2021.04.12(GTM+8)",
          // 开放了吗
          disabled: false,
          id: 'WBNB',
          name: 'WBNB',
          token: 'WBNB',
          tokenDescription: 'WBNB',
          tokenAddress: '0xbb4cdb9cbd36b01bd1cbaebf2de08d9173bc095c',
          tokenDecimals: 18,
          itokenDecimals: 18,
          depostLimit: 0,
          tokenDescriptionUrl: '',
          tokenDescriptionUrl2: '',
          earnedToken: 'MDX',
          earnedTokenAddress: '0x9C65AB58d8d978DB963e63f2bfB7121627e3a739',
          earnContractAddress,
          defaultApy: "130.10",
          pricePerFullShare: 1,
          pastPricePerFullShare: 1,
          rateMdx: 1,
          total: 63000,
          // 是否上首页
          topHome: true,
          conduct: false,
        },
        {
          // 标识新的，表示开放额度
          type: '',
          // 开放额度
          openAmount: "82,000,000",
          // 开放时间
          openDate: "2021.04.12",
          // 开放了吗
          disabled: false,
          id: 'USDT',
          name: 'USDT',
          token: 'USDT',
          tokenDescription: 'USDT',
          tokenAddress: USDTAddress,
          tokenDecimals: 18,
          itokenDecimals: 18,
          depostLimit: 0,
          tokenDescriptionUrl: '',
          tokenDescriptionUrl2: '',
          earnedToken: 'MDX',
          earnedTokenAddress: '0x9C65AB58d8d978DB963e63f2bfB7121627e3a739',
          earnContractAddress,
          defaultApy: "64.30",
          pricePerFullShare: 1,
          pastPricePerFullShare: 1,
          rateMdx: 0.169315,
          total: 82000000,
          conduct: false,
        },
        {
          // 标识新的，表示开放额度
          type: 'NEW',
          // 开放额度
          openAmount: "500,000",
          // 开放时间
          openDate: "2021.05.06",
          // 开放了吗
          disabled: false,
          id: 'DOGE',
          name: 'DOGE',
          token: 'DOGE',
          tokenDescription: 'DOGE',
          tokenAddress: "0xbA2aE424d960c26247Dd6c32edC70B295c744C43",
          tokenDecimals: 8,
          itokenDecimals: 8,
          depostLimit: 0,
          tokenDescriptionUrl: '',
          tokenDescriptionUrl2: '',
          earnedToken: 'MDX',
          earnedTokenAddress: '0x9C65AB58d8d978DB963e63f2bfB7121627e3a739',
          earnContractAddress,
          defaultApy: "64.30",
          pricePerFullShare: 1,
          pastPricePerFullShare: 1,
          rateMdx: 0.169315,
          total: 500000,
          conduct: false,
        },
        {
          // 标识新的，表示开放额度
          type: '',
          // 开放额度
          openAmount: "100,000",
          // 开放时间
          openDate: "2021.04.20",
          // 开放了吗
          disabled: false,
          id: 'CAKE',
          name: 'CAKE',
          token: 'CAKE',
          tokenDescription: 'CAKE',
          tokenAddress: "0x0E09FaBB73Bd3Ade0a17ECC321fD13a19e81cE82",
          tokenDecimals: 18,
          itokenDecimals: 18,
          depostLimit: 0,
          tokenDescriptionUrl: '',
          tokenDescriptionUrl2: '',
          earnedToken: 'MDX',
          earnedTokenAddress: '0x9C65AB58d8d978DB963e63f2bfB7121627e3a739',
          earnContractAddress,
          defaultApy: "64.30",
          pricePerFullShare: 1,
          pastPricePerFullShare: 1,
          rateMdx: 0.169315,
          total: 100000,
          conduct: false,
        },
        {
          // 标识新的，表示开放额度
          type: '',
          // 开放额度
          openAmount: "30,000",
          // 开放时间
          openDate: "2021.04.12",
          // 开放了吗
          disabled: false,
          id: 'LTC',
          name: 'LTC',
          token: 'LTC',
          tokenDescription: 'LTC',
          tokenAddress: "0x4338665CBB7B2485A8855A139b75D5e34AB0DB94",
          tokenDecimals: 18,
          itokenDecimals: 18,
          depostLimit: 0,
          tokenDescriptionUrl: '',
          tokenDescriptionUrl2: '',
          earnedToken: 'MDX',
          earnedTokenAddress: '0x9C65AB58d8d978DB963e63f2bfB7121627e3a739',
          earnContractAddress,
          defaultApy: "64.30",
          pricePerFullShare: 1,
          pastPricePerFullShare: 1,
          rateMdx: 0.169315,
          total: 30000,
          conduct: false,
        },
        {
          // 标识新的，表示开放额度
          type: '',
          // 开放额度
          openAmount: "30,000",
          // 开放时间
          openDate: "2021.04.12",
          // 开放了吗
          disabled: false,
          id: 'FIL',
          name: 'FIL',
          token: 'FIL',
          tokenDescription: 'FIL',
          tokenAddress: "0x0D8Ce2A99Bb6e3B7Db580eD848240e4a0F9aE153",
          tokenDecimals: 18,
          itokenDecimals: 18,
          depostLimit: 0,
          tokenDescriptionUrl: '',
          tokenDescriptionUrl2: '',
          earnedToken: 'MDX',
          earnedTokenAddress: '0x9C65AB58d8d978DB963e63f2bfB7121627e3a739',
          earnContractAddress,
          defaultApy: "64.30",
          pricePerFullShare: 1,
          pastPricePerFullShare: 1,
          rateMdx: 0.169315,
          total: 30000,
          conduct: false,
        },
        {
          // 标识新的，表示开放额度
          type: '',
          // 开放额度
          openAmount: "200,000",
          // 开放时间
          openDate: "2021.04.12",
          // 开放了吗
          disabled: false,
          id: 'INJ',
          name: 'INJ',
          token: 'INJ',
          tokenDescription: 'INJ',
          tokenAddress: "0xa2B726B1145A4773F68593CF171187d8EBe4d495",
          tokenDecimals: 18,
          itokenDecimals: 18,
          depostLimit: 0,
          tokenDescriptionUrl: '',
          tokenDescriptionUrl2: '',
          earnedToken: 'MDX',
          earnedTokenAddress: '0x9C65AB58d8d978DB963e63f2bfB7121627e3a739',
          earnContractAddress,
          defaultApy: "64.30",
          pricePerFullShare: 1,
          pastPricePerFullShare: 1,
          rateMdx: 0.169315,
          total: 200000,
          conduct: false,
        },
        {
          // 标识新的，表示开放额度
          type: '',
          // 开放额度
          openAmount: "70,000",
          // 开放时间
          openDate: "2021.04.12",
          // 开放了吗
          disabled: false,
          id: 'DOT',
          name: 'DOT',
          token: 'DOT',
          tokenDescription: 'DOT',
          tokenAddress: "0x7083609fCE4d1d8Dc0C979AAb8c869Ea2C873402",
          tokenDecimals: 18,
          itokenDecimals: 18,
          depostLimit: 0,
          tokenDescriptionUrl: '',
          tokenDescriptionUrl2: '',
          earnedToken: 'MDX',
          earnedTokenAddress: '0x9C65AB58d8d978DB963e63f2bfB7121627e3a739',
          earnContractAddress,
          defaultApy: "64.30",
          pricePerFullShare: 1,
          pastPricePerFullShare: 1,
          rateMdx: 0.169315,
          total: 70000,
          conduct: false,
        },
        {
          // 标识新的，表示开放额度
          type: '',
          // 开放额度
          openAmount: "220,000",
          // 开放时间
          openDate: "2021.04.12",
          // 开放了吗
          disabled: false,
          id: 'EOS',
          name: 'EOS',
          token: 'EOS',
          tokenDescription: 'EOS',
          tokenAddress: "0x56b6fB708fC5732DEC1Afc8D8556423A2EDcCbD6",
          tokenDecimals: 18,
          itokenDecimals: 18,
          depostLimit: 0,
          tokenDescriptionUrl: '',
          tokenDescriptionUrl2: '',
          earnedToken: 'MDX',
          earnedTokenAddress: '0x9C65AB58d8d978DB963e63f2bfB7121627e3a739',
          earnContractAddress,
          defaultApy: "64.30",
          pricePerFullShare: 1,
          pastPricePerFullShare: 1,
          rateMdx: 0.169315,
          total: 220000,
          conduct: false,
        },
        {
          // 标识新的，表示开放额度
          type: '',
          // 开放额度
          openAmount: "3,000,000",
          // 开放时间
          openDate: "2021.04.12(GTM+8)",
          // 开放了吗
          disabled: false,
          id: 'USDC',
          name: 'USDC',
          token: 'USDC',
          tokenDescription: 'USDC',
          tokenAddress: '0x8AC76a51cc950d9822D68b83fE1Ad97B32Cd580d',
          tokenDecimals: 18,
          itokenDecimals: 18,
          depostLimit: 0,
          tokenDescriptionUrl: '',
          tokenDescriptionUrl2: '',
          earnedToken: 'MDX',
          earnedTokenAddress: '0x9C65AB58d8d978DB963e63f2bfB7121627e3a739',
          earnContractAddress,
          defaultApy: "47.53",
          pricePerFullShare: 1,
          pastPricePerFullShare: 1,
          rateMdx: 391,
          total: 3000000,
          conduct: false,
        },
        {
          // 标识新的，表示开放额度
          type: '',
          // 开放额度
          openAmount: "400",
          // 开放时间
          openDate: "2021.04.12(GTM+8)",
          // 开放了吗
          disabled: false,
          id: 'BTCB',
          name: 'BTCB',
          token: 'BTCB',
          tokenDescription: 'BTCB',
          tokenAddress: '0x7130d2a12b9bcbfae4f2634d864a1ee1ce3ead9c',
          tokenDecimals: 18,
          itokenDecimals: 18,
          depostLimit: 0,
          tokenDescriptionUrl: '',
          tokenDescriptionUrl2: '',
          earnedToken: 'MDX',
          earnedTokenAddress: '0x9C65AB58d8d978DB963e63f2bfB7121627e3a739',
          earnContractAddress,
          defaultApy: "48.04",
          pricePerFullShare: 1,
          pastPricePerFullShare: 1,
          rateMdx: 391,
          total: 400,
          conduct: false,
        },
        {
          // 标识新的，表示开放额度
          type: '',
          // 开放额度
          openAmount: "19,000",
          // 开放时间
          openDate: "2021.04.12(GTM+8)",
          // 开放了吗
          disabled: false,
          id: 'ETH',
          name: 'ETH',
          token: 'ETH',
          tokenDescription: 'ETH',
          tokenAddress: '0x2170ed0880ac9a755fd29b2688956bd959f933f8',
          tokenDecimals: 18,
          itokenDecimals: 18,
          depostLimit: 0,
          tokenDescriptionUrl: '',
          tokenDescriptionUrl2: '',
          earnedToken: 'MDX',
          earnedTokenAddress: '0x9C65AB58d8d978DB963e63f2bfB7121627e3a739',
          earnContractAddress,
          defaultApy: "60.04",
          pricePerFullShare: 1,
          pastPricePerFullShare: 1,
          rateMdx: 391,
          total: 19000,
          conduct: false,
        },
    ]

    return {
        earnContractAddress,
        USDTAddress,
        aggAddress,
        MdxAddress,
        pools,
    }
}