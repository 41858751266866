import { StakingPage } from './';

export const StakingRoute =  {
  path: 'staking',
  childRoutes: [
    { path: 'staking', component: StakingPage, isIndex: true },
  ],
};
// export const ControlRoute =  {
//   path: 'control',
//   childRoutes: [
//     { path: 'control', component: ControlPage, isIndex: true },
//   ],
// };
