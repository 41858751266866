/* eslint-disable */
import React, { memo } from "react";
import Button from '../Button/Button'
import './Dialog.css'
export default memo(function Dialog({ opened,type,children,buttonText,buttonClickCallback,cancelButtonCallback,cancelButtonText }) {
    return <>
        {opened ? <div className="g-dialog">
            <div className="g-dialog__inner">
                <div className={`g-dialog__content${type==='warning'?' g-dialog__icon--warning':''}`}>{children}</div>
                <div className="g-dialog__button">
                    <Button type={2} onClick={buttonClickCallback}>{buttonText}</Button>
                    {cancelButtonCallback?<Button type={2} onClick={cancelButtonCallback}>{cancelButtonText}</Button>:null}
                </div>
            </div>
        </div> : null}
    </>


})
