export function cow_heco_info_exchange() {
  const earnContractAddress = "0x048B0949B619b476F4EFC6B7924cdfF881cE0EdC";
  const USDTAddress = '0xa71EdC38d189767582C38A3145b5873052c3e47a';
  const aggAddress = "0x61866752Ac7FA1f9B7fAF43f659440920A296dd9";
  const MdxAddress = "0x25D2e80cB6B86881Fd7e07dd263Fb79f4AbE033c";
  const cowAddress = "0x80861A817106665bcA173DB6AC2ab628a738c737";
  const migrator = "0xc40287d05413aFa8ca68C4Be2f02A7fC61DD593B";
  const delayAddress = "0x9b1caF282AC0cdaCd71ef19b017883C8ca3beD19";
  const pools = [
    {
      // 标识新的，表示开放额度
      // type: 'NEW',
      // 开放额度
      openAmount: "2,000",
      // 开放时间
      openDate: "2021.03.17(GTM+8)",
      // 开放了吗
      disabled: false,
      id: 'DBTC',
      name: 'DBTC',
      names:'HBTC',
      token: 'DBTC',
      tokenDescription: 'DBTC',
      tokenAddress: '0x66a79d23e58475d2738179ca52cd0b41d73f0bea',
      tokenDecimals: 18,
      itokenDecimals: 18,
      depostLimit: 0,
      tokenDescriptionUrl: '',
      tokenDescriptionUrl2: '',
      earnedToken: 'MDX',
      earnedTokenAddress: '0x25D2e80cB6B86881Fd7e07dd263Fb79f4AbE033c',
      earnContractAddress,
      defaultApy: "35.95",
      pricePerFullShare: 1,
      pastPricePerFullShare: 1,
      rateMdx: 10614.3,
      total: 2000,
      // 是否上首页
      // topHome: true,
      conduct: true,
    },
    {
      // 标识新的，表示开放额度
      // type: '三期',
      // 开放额度
      openAmount: "100,000,000",
      // 开放时间
      openDate: "2021.03.19 14:00(GTM+8)",
      // 开放了吗
      disabled: false,
      id: 'DUSDT',
      name: 'DUSDT',
      names: 'USDT',
      token: 'DUSDT',
      tokenDescription: 'DUSDT',
      tokenAddress: USDTAddress,
      tokenDecimals: 18,
      itokenDecimals: 18,
      depostLimit: 0,
      tokenDescriptionUrl: '',
      tokenDescriptionUrl2: '',
      earnedToken: 'MDX',
      earnedTokenAddress: '0x25D2e80cB6B86881Fd7e07dd263Fb79f4AbE033c',
      earnContractAddress,
      defaultApy: "58.06",
      pricePerFullShare: 1,
      pastPricePerFullShare: 1,
      rateMdx: 0.169315,
      total: 100000000,
      // 是否上首页
      topHome: true,
      conduct: true,
    },
    {
      // 标识新的，表示开放额度
      // type: 'NEW',
      // 开放额度
      openAmount: "100,000",
      // 开放时间
      openDate: "2021.03.29(GTM+8)",
      // 开放了吗
      disabled: false,
      id: 'DFIL',
      name: 'DFIL',
      names:'HFIL',
      token: 'DFIL',
      tokenDescription: 'DFIL',
      tokenAddress: '0xae3a768f9ab104c69a7cd6041fe16ffa235d1810',
      tokenDecimals: 18,
      itokenDecimals: 18,
      depostLimit: 0,
      tokenDescriptionUrl: '',
      tokenDescriptionUrl2: '',
      earnedToken: 'MDX',
      earnedTokenAddress: '0x25D2e80cB6B86881Fd7e07dd263Fb79f4AbE033c',
      earnContractAddress,
      defaultApy: "120.23",
      pricePerFullShare: 1,
      pastPricePerFullShare: 1,
      rateMdx: 1,
      total: 100000,
      topHome: true,
      conduct: true,
    },
    // {
    //   // 标识新的，表示开放额度
    //   // type: '',
    //   // 开放额度
    //   openAmount: "1,000,000",
    //   // 开放时间
    //   openDate: "2021.05.27(GTM+8)",
    //   // 开放了吗
    //   disabled: false,
    //   id: 'COW',
    //   name: 'COW',
    //   token: 'COW',
    //   tokenDescription: 'COW',
    //   tokenAddress: '0x80861A817106665bcA173DB6AC2ab628a738c737',
    //   tokenDecimals: 18,
    //   itokenDecimals: 18,
    //   depostLimit: 0,
    //   tokenDescriptionUrl: '',
    //   tokenDescriptionUrl2: '',
    //   earnedToken: 'MDX',
    //   earnedTokenAddress: '0x25D2e80cB6B86881Fd7e07dd263Fb79f4AbE033c',
    //   earnContractAddress,
    //   defaultApy: "120.23",
    //   pricePerFullShare: 1,
    //   pastPricePerFullShare: 1,
    //   rateMdx: 1,
    //   total: 1000000,
    //   // 是否上首页
    //   topHome: true,
    //   conduct: true,
    // },
    // {
    //   // 标识新的，表示开放额度
    //   // type: 'NEW',
    //   // 开放额度
    //   openAmount: "45,000,000",
    //   // 开放时间
    //   openDate: "2021.03.17(GTM+8)",
    //   // 开放了吗
    //   // disabled: true,
    //   id: 'HUSD',
    //   name: 'HUSD',
    //   token: 'HUSD',
    //   tokenDescription: 'HUSD',
    //   tokenAddress: '0x0298c2b32eaE4da002a15f36fdf7615BEa3DA047',
    //   tokenDecimals: 8,
    //   itokenDecimals: 8,
    //   depostLimit: 0,
    //   tokenDescriptionUrl: '',
    //   tokenDescriptionUrl2: '',
    //   earnedToken: 'MDX',
    //   earnedTokenAddress: '0x25D2e80cB6B86881Fd7e07dd263Fb79f4AbE033c',
    //   earnContractAddress,
    //   defaultApy: "58.06",
    //   pricePerFullShare: 1,
    //   pastPricePerFullShare: 1,
    //   rateMdx: 0.169315,
    //   total: 45000000,
    //   // 是否上首页
    //   // topHome: true,
    //   conduct: true,
    // },
    
    // {
    //   // 标识新的，表示开放额度
    //   // type: 'NEW',
    //   // 开放额度
    //   openAmount: "35,500",
    //   // 开放时间
    //   openDate: "2021.03.17(GTM+8)",
    //   // 开放了吗
    //   disabled: false,
    //   id: 'ETH',
    //   name: 'ETH',
    //   token: 'ETH',
    //   tokenDescription: 'ETH',
    //   tokenAddress: '0x64FF637fB478863B7468bc97D30a5bF3A428a1fD',
    //   tokenDecimals: 18,
    //   itokenDecimals: 18,
    //   depostLimit: 0,
    //   tokenDescriptionUrl: '',
    //   tokenDescriptionUrl2: '',
    //   earnedToken: 'MDX',
    //   earnedTokenAddress: '0x25D2e80cB6B86881Fd7e07dd263Fb79f4AbE033c',
    //   earnContractAddress,
    //   defaultApy: "30.03",
    //   pricePerFullShare: 1,
    //   pastPricePerFullShare: 1,
    //   rateMdx: 391,
    //   total: 35500,
    //   conduct: true,
    // },
    // {
    //   // 标识新的，表示开放额度
    //   type: 'NEW',
    //   // 开放额度
    //   openAmount: "10,000,000",
    //   // 开放时间
    //   openDate: "2021.03.17(GTM+8)",
    //   // 开放了吗
    //   disabled: false,
    //   id: 'MDX',
    //   name: 'MDX',
    //   token: 'MDX',
    //   tokenDescription: 'MDX',
    //   tokenAddress: '0x25D2e80cB6B86881Fd7e07dd263Fb79f4AbE033c',
    //   tokenDecimals: 18,
    //   itokenDecimals: 18,
    //   depostLimit: 0,
    //   tokenDescriptionUrl: '',
    //   tokenDescriptionUrl2: '',
    //   earnedToken: 'MDX',
    //   earnedTokenAddress: '0x25D2e80cB6B86881Fd7e07dd263Fb79f4AbE033c',
    //   earnContractAddress,
    //   defaultApy: "120.23",
    //   pricePerFullShare: 1,
    //   pastPricePerFullShare: 1,
    //   rateMdx: 1,
    //   total: 10000000,
    //   // 是否上首页
    //   // topHome: true,
    //   conduct: true,
    // },
    // {
    //   // 标识新的，表示开放额度
    //   // type: '三期',
    //   // 开放额度
    //   openAmount: "4,000,000",
    //   // 开放时间
    //   openDate: "2021.03.29 (GTM+8)",
    //   // 开放了吗
    //   disabled: false,
    //   id: 'WHT',
    //   name: 'WHT',
    //   token: 'WHT',
    //   tokenDescription: 'WHT',
    //   tokenAddress: '0x5545153ccfca01fbd7dd11c0b23ba694d9509a6f',
    //   tokenDecimals: 18,
    //   itokenDecimals: 18,
    //   depostLimit: 0,
    //   tokenDescriptionUrl: '',
    //   tokenDescriptionUrl2: '',
    //   earnedToken: 'MDX',
    //   earnedTokenAddress: '0x25D2e80cB6B86881Fd7e07dd263Fb79f4AbE033c',
    //   earnContractAddress,
    //   defaultApy: "56.51",
    //   pricePerFullShare: 1,
    //   pastPricePerFullShare: 1,
    //   rateMdx: 4.04,
    //   total: 4000000,
    //   conduct: true,
    // },
    
    // {
    //   // 标识新的，表示开放额度
    //   // type: 'NEW',
    //   // 开放额度
    //   openAmount: "1,000,000",
    //   // 开放时间
    //   openDate: "2021.05.07(GTM+8)",
    //   // 开放了吗
    //   // disabled: true,
    //   id: 'USDC',
    //   name: 'USDC',
    //   token: 'USDC',
    //   tokenDescription: 'USDC',
    //   tokenAddress: '0x9362Bbef4B8313A8Aa9f0c9808B80577Aa26B73B',
    //   tokenDecimals: 6,
    //   itokenDecimals: 6,
    //   depostLimit: 0,
    //   tokenDescriptionUrl: '',
    //   tokenDescriptionUrl2: '',
    //   earnedToken: 'MDX',
    //   earnedTokenAddress: '0x25D2e80cB6B86881Fd7e07dd263Fb79f4AbE033c',
    //   earnContractAddress,
    //   defaultApy: "58.06",
    //   pricePerFullShare: 1,
    //   pastPricePerFullShare: 1,
    //   rateMdx: 0.169315,
    //   total: 1000000
    // },
    // {
    //   // 标识新的，表示开放额度
    //   // type: 'NEW',
    //   // 开放额度
    //   openAmount: "1,000,000",
    //   // 开放时间
    //   openDate: "2021.05.07(GTM+8)",
    //   // 开放了吗
    //   // disabled: true,
    //   id: 'DAI',
    //   name: 'DAI',
    //   token: 'DAI',
    //   tokenDescription: 'DAI',
    //   tokenAddress: '0x3d760a45d0887dfd89a2f5385a236b29cb46ed2a',
    //   tokenDecimals: 18,
    //   itokenDecimals: 18,
    //   depostLimit: 0,
    //   tokenDescriptionUrl: '',
    //   tokenDescriptionUrl2: '',
    //   earnedToken: 'MDX',
    //   earnedTokenAddress: '0x25D2e80cB6B86881Fd7e07dd263Fb79f4AbE033c',
    //   earnContractAddress,
    //   defaultApy: "58.06",
    //   pricePerFullShare: 1,
    //   pastPricePerFullShare: 1,
    //   rateMdx: 0.169315,
    //   total: 1000000
    // },
    // {
    //   // 标识新的，表示开放额度
    //   type: '',
    //   // 开放额度
    //   openAmount: "10,000",
    //   // 开放时间
    //   openDate: "2021.04.20",
    //   // 开放了吗
    //   disabled: false,
    //   id: 'UNI',
    //   name: 'UNI',
    //   token: 'UNI',
    //   tokenDescription: 'UNI',
    //   tokenAddress: "0x22C54cE8321A4015740eE1109D9cBc25815C46E6",
    //   tokenDecimals: 18,
    //   itokenDecimals: 18,
    //   depostLimit: 0,
    //   tokenDescriptionUrl: '',
    //   tokenDescriptionUrl2: '',
    //   earnedToken: 'MDX',
    //   earnedTokenAddress: '0x9C65AB58d8d978DB963e63f2bfB7121627e3a739',
    //   earnContractAddress,
    //   defaultApy: "64.30",
    //   pricePerFullShare: 1,
    //   pastPricePerFullShare: 1,
    //   rateMdx: 0.169315,
    //   total: 10000,
    // },
    // {
    //   // 标识新的，表示开放额度
    //   type: '',
    //   // 开放额度
    //   openAmount: "150,000",
    //   // 开放时间
    //   openDate: "2021.04.20",
    //   // 开放了吗
    //   disabled: false,
    //   id: 'DOGE',
    //   name: 'DOGE',
    //   token: 'DOGE',
    //   tokenDescription: 'DOGE',
    //   tokenAddress: "0x40280E26A572745B1152A54D1D44F365DaA51618",
    //   tokenDecimals: 8,
    //   itokenDecimals: 8,
    //   depostLimit: 0,
    //   tokenDescriptionUrl: '',
    //   tokenDescriptionUrl2: '',
    //   earnedToken: 'MDX',
    //   earnedTokenAddress: '0x9C65AB58d8d978DB963e63f2bfB7121627e3a739',
    //   earnContractAddress,
    //   defaultApy: "64.30",
    //   pricePerFullShare: 1,
    //   pastPricePerFullShare: 1,
    //   rateMdx: 0.169315,
    //   total: 150000,
    // },
    // {
    //   // 标识新的，表示开放额度
    //   // type: 'NEW',
    //   // 开放额度
    //   openAmount: "500,000",
    //   // 开放时间
    //   openDate: "2021.04.06(GTM+8)",
    //   // 开放了吗
    //   disabled: false,
    //   id: 'FEI',
    //   name: 'FEI',
    //   token: 'FEI',
    //   tokenDescription: 'FEI',
    //   tokenAddress: '0x485cdbff08a4f91a16689e73893a11ae8b76af6d',
    //   tokenDecimals: 18,
    //   itokenDecimals: 18,
    //   depostLimit: 0,
    //   tokenDescriptionUrl: '',
    //   tokenDescriptionUrl2: '',
    //   earnedToken: 'MDX',
    //   earnedTokenAddress: '0x25D2e80cB6B86881Fd7e07dd263Fb79f4AbE033c',
    //   earnContractAddress,
    //   defaultApy: "120.23",
    //   pricePerFullShare: 1,
    //   pastPricePerFullShare: 1,
    //   rateMdx: 1,
    //   total: 500000
    // },
    // {
    //   // 标识新的，表示开放额度
    //   // type: 'NEW',
    //   // 开放额度
    //   openAmount: "100,000",
    //   // 开放时间
    //   openDate: "2021.04.06(GTM+8)",
    //   // 开放了吗
    //   disabled: false,
    //   id: 'TRIBE',
    //   name: 'TRIBE',
    //   token: 'TRIBE',
    //   tokenDescription: 'TRIBE',
    //   tokenAddress: '0x38999fa3a7320bd2c3609bf0f8cb5cd4c11d3fe1',
    //   tokenDecimals: 18,
    //   itokenDecimals: 18,
    //   depostLimit: 0,
    //   tokenDescriptionUrl: '',
    //   tokenDescriptionUrl2: '',
    //   earnedToken: 'MDX',
    //   earnedTokenAddress: '0x25D2e80cB6B86881Fd7e07dd263Fb79f4AbE033c',
    //   earnContractAddress,
    //   defaultApy: "120.23",
    //   pricePerFullShare: 1,
    //   pastPricePerFullShare: 1,
    //   rateMdx: 1,
    //   total: 100000
    // },
    // {
    //   // 标识新的，表示开放额度
    //   // type: 'NEW',
    //   // 开放额度
    //   openAmount: "3000",
    //   // 开放时间
    //   openDate: "2021.04.01(GTM+8)",
    //   // 开放了吗
    //   disabled: false,
    //   id: 'AAVE',
    //   name: 'AAVE',
    //   token: 'AAVE',
    //   tokenDescription: 'AAVE',
    //   tokenAddress: '0x202b4936fe1a82a4965220860ae46d7d3939bb25',
    //   tokenDecimals: 18,
    //   itokenDecimals: 18,
    //   depostLimit: 0,
    //   tokenDescriptionUrl: '',
    //   tokenDescriptionUrl2: '',
    //   earnedToken: 'MDX',
    //   earnedTokenAddress: '0x25D2e80cB6B86881Fd7e07dd263Fb79f4AbE033c',
    //   earnContractAddress,
    //   defaultApy: "120.23",
    //   pricePerFullShare: 1,
    //   pastPricePerFullShare: 1,
    //   rateMdx: 1,
    //   total: 3000
    // },
    // {
    //   // 标识新的，表示开放额度
    //   // type: 'NEW',
    //   // 开放额度
    //   openAmount: "20,000",
    //   // 开放时间
    //   openDate: "2021.04.01(GTM+8)",
    //   // 开放了吗
    //   disabled: false,
    //   id: 'LINK',
    //   name: 'LINK',
    //   token: 'LINK',
    //   tokenDescription: 'LINK',
    //   tokenAddress: '0x9e004545c59d359f6b7bfb06a26390b087717b42',
    //   tokenDecimals: 18,
    //   itokenDecimals: 18,
    //   depostLimit: 0,
    //   tokenDescriptionUrl: '',
    //   tokenDescriptionUrl2: '',
    //   earnedToken: 'MDX',
    //   earnedTokenAddress: '0x25D2e80cB6B86881Fd7e07dd263Fb79f4AbE033c',
    //   earnContractAddress,
    //   defaultApy: "120.23",
    //   pricePerFullShare: 1,
    //   pastPricePerFullShare: 1,
    //   rateMdx: 1,
    //   total: 20000
    // },
    // {
    //   // 标识新的，表示开放额度
    //   // type: '三期',
    //   // 开放额度
    //   openAmount: "5,000",
    //   // 开放时间
    //   openDate: "2021.03.29 (GTM+8)",
    //   // 开放了吗
    //   // disabled: true,
    //   id: 'HLTC',
    //   name: 'HLTC',
    //   token: 'HLTC',
    //   tokenDescription: 'HLTC',
    //   tokenAddress: '0xecb56cf772b5c9a6907fb7d32387da2fcbfb63b4',
    //   tokenDecimals: 18,
    //   itokenDecimals: 18,
    //   depostLimit: 0,
    //   tokenDescriptionUrl: '',
    //   tokenDescriptionUrl2: '',
    //   earnedToken: 'MDX',
    //   earnedTokenAddress: '0x25D2e80cB6B86881Fd7e07dd263Fb79f4AbE033c',
    //   earnContractAddress,
    //   defaultApy: "58.06",
    //   pricePerFullShare: 1,
    //   pastPricePerFullShare: 1,
    //   rateMdx: 50,
    //   total: 19000
    // },
    // {
    //   // 标识新的，表示开放额度
    //   // type: '三期',
    //   // 开放额度
    //   openAmount: "10,000",
    //   // 开放时间
    //   openDate: "2021.03.19 14:00(GTM+8)",
    //   // 开放了吗
    //   // disabled: true,
    //   id: 'HBCH',
    //   name: 'HBCH',
    //   token: 'HBCH',
    //   tokenDescription: 'HBCH',
    //   tokenAddress: '0xef3cebd77e0c52cb6f60875d9306397b5caca375',
    //   tokenDecimals: 18,
    //   itokenDecimals: 18,
    //   depostLimit: 0,
    //   tokenDescriptionUrl: '',
    //   tokenDescriptionUrl2: '',
    //   earnedToken: 'MDX',
    //   earnedTokenAddress: '0x25D2e80cB6B86881Fd7e07dd263Fb79f4AbE033c',
    //   earnContractAddress,
    //   defaultApy: "58.06",
    //   pricePerFullShare: 1,
    //   pastPricePerFullShare: 1,
    //   rateMdx: 133.25,
    //   total: 17000,
    // },
    // {
    //   // 标识新的，表示开放额度
    //   // type: '三期',
    //   // 开放额度
    //   openAmount: "50,000",
    //   // 开放时间
    //   openDate: "2021.03.29 (GTM+8)",
    //   // 开放了吗
    //   // disabled: true,
    //   id: 'HDOT',
    //   name: 'HDOT',
    //   token: 'HDOT',
    //   tokenDescription: 'HDOT',
    //   tokenAddress: '0xa2c49cee16a5e5bdefde931107dc1fae9f7773e3',
    //   tokenDecimals: 18,
    //   itokenDecimals: 18,
    //   depostLimit: 0,
    //   tokenDescriptionUrl: '',
    //   tokenDescriptionUrl2: '',
    //   earnedToken: 'MDX',
    //   earnedTokenAddress: '0x25D2e80cB6B86881Fd7e07dd263Fb79f4AbE033c',
    //   earnContractAddress,
    //   defaultApy: "38.06",
    //   pricePerFullShare: 1,
    //   pastPricePerFullShare: 1,
    //   rateMdx: 8.75,
    //   total: 160000
    // },
  ]

  return {
    earnContractAddress,
    USDTAddress,
    aggAddress,
    MdxAddress,
    cowAddress,
    pools,
    migrator,
    delayAddress
  }
}