import React from 'react';
import { useTranslation } from 'react-i18next';
import Switch from '../../../components/Switch/Switch'
import Switchs from '../../../components/Switch/SwitchStats'
import '../jss/sections/SectionFilter.css'
export default ({filterPartakeHandler,isPartake,filterPartakeHandlers,isProjectStatus}) => {
    const { t } = useTranslation();
    return <div className="g-filter-lp">
        <Switch onChange={filterPartakeHandler} isPartake={isPartake}>{t('v2-ui-21')}</Switch>
        {/* <Switchs onChange={filterPartakeHandlers} isProjectStatus={isProjectStatus}>{isProjectStatus?t('State-state'):t('State-end')}</Switchs> */}
    </div>

}