import {TABACTIVE} from "./constants";
import { useCallback } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';

export function useTabActive() {
    // args: false value or array
    // if array, means args passed to the action creator
    const dispatch = useDispatch();

    const { tabActive } = useSelector(state => ({tabActive :state.vault.tabActive}), shallowEqual);
  
    const setTabActive = useCallback(
      (data) => {
        dispatch({type:TABACTIVE,data:tabActive});
      },
      [dispatch, tabActive],
    );
  
    return {
        setTabActive
    };
  }
  
export function reducer(state, action) {
  switch (action.type) {
    case TABACTIVE:
      state.tabActive = action.data;
      return {
        ...state,
      };
    default:
      return state;
  }
}
