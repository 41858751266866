import {
    primaryColor,
//   grayColor,
    whiteColor,
    netStyle,
//   mlAuto,
//   hexToRgb
} from "../material-kit-pro-react.js";

// import { netStyle } from "../material-kit-pro-react"

const headerLinksStyle = theme => ({
    networkDrop: {
        // padding: "10px 40px",
        // background: netStyle.buttonBg,
        // border: netStyle.buttonBorder,
        // borderRadius: "22px",
    },
    navLink: {
        padding: "0 40px",
        height: "36px",
        background: netStyle.buttonBg,
        border: netStyle.buttonBorder,
        borderRadius: "22px",
        cursor: "pointer",
        color: primaryColor[0],
        fontSize: "14px",
        "&:hover,&:focus": {
            color: whiteColor,
            backgroundColor: primaryColor[0],
        },
        "&:hover b,&:focus b": {
            color: whiteColor,
        },
        "& .MuiButton-label": {
            fontSize: "14px",
            fontFamily: "DINPro-Bold",
        },
        [theme.breakpoints.down('sm')]: {
            padding: "0 22px",
            height: "32px",
            background: primaryColor[6],
            border: "1px solid #FFFFFF",
            boxShadow: "0 4px 16px 0 #F6F6F6",
            borderRadius: "16px",
            color: primaryColor[0],
            fontSize: "10px",
        },
    },
    popperClass: {
        // background: whiteColor,
        // boxShadow: "0 4px 12px 0 #EDEFF2",
        // borderRadius: "16px",
    },  
    // list: {
    //     [theme.breakpoints.up("md")]: {
    //         WebkitBoxAlign: "center",
    //         MsFlexAlign: "center",
    //         alignItems: "center",
    //         WebkitBoxOrient: "horizontal",
    //         WebkitBoxDirection: "normal",
    //         MsFlexDirection: "row",
    //         flexDirection: "row"
    //     },
    //     [theme.breakpoints.down("sm")]: {
    //         display: "block"
    //     },
    //     marginTop: "0px",
    //     display: "flex",
    //     paddingLeft: "0",
    //     marginBottom: "0",
    //     listStyle: "none",
    //     padding: "0"
    // },
    // listItem: {
    //     float: "left",
    //     color: "inherit",
    //     position: "relative",
    //     display: "block",
    //     width: "auto",
    //     margin: "0",
    //     padding: "0",
    //     [theme.breakpoints.down("sm")]: {
    //         "& ul": {
    //             maxHeight: "400px",
    //             overflow: "scroll"
    //         },
    //         width: "100%",
    //         // "&:not(:last-child)": {
    //         //   "&:after": {
    //         //     width: "calc(100% - 30px)",
    //         //     content: '""',
    //         //     display: "block",
    //         //     height: "1px",
    //         //     marginLeft: "15px",
    //         //     backgroundColor: grayColor[14]
    //         //   }
    //         // }
    //     }
    // },
    // listItemText: {
    //     padding: "0 !important"
    // },
    // navLinkJustIcon: {
    //     "& .fab,& .far,& .fal,& .fas,& .material-icons": {
    //         marginRight: "0px"
    //     },
    //     "& svg": {
    //         marginRight: "0px"
    //     }
    // },
    // navButton: {
    //     position: "relative",
    //     fontWeight: "400",
    //     fontSize: "12px",
    //     textTransform: "uppercase",
    //     lineHeight: "20px",
    //     textDecoration: "none",
    //     margin: "0px",
    //     display: "inline-flex",
    //     [theme.breakpoints.down("sm")]: {
    //         width: "calc(100% - 30px)",
    //         marginLeft: "15px",
    //         marginBottom: "5px",
    //         marginTop: "5px",
    //         textAlign: "left",
    //         "& > span:first-child": {
    //             justifyContent: "flex-start"
    //         }
    //     },
    //     "& $icons": {
    //         marginRight: "3px"
    //     }
    // },
    // notificationNavLink: {
    //     color: "inherit",
    //     padding: "0.9375rem",
    //     fontWeight: "400",
    //     fontSize: "12px",
    //     textTransform: "uppercase",
    //     lineHeight: "20px",
    //     textDecoration: "none",
    //     margin: "0px",
    //     display: "inline-flex"
    // },
    // registerNavLink: {
    //     position: "relative",
    //     fontWeight: "400",
    //     fontSize: "12px",
    //     textTransform: "uppercase",
    //     lineHeight: "20px",
    //     textDecoration: "none",
    //     margin: "0px",
    //     display: "inline-flex"
    // },
    // navLinkActive: {
    //     "&, &:hover, &:focus,&:active ": {
    //         color: "inherit",
    //         backgroundColor: "rgba(" + hexToRgb(whiteColor) + ", 0.1)"
    //     }
    // },
    // icons: {
    //     width: "20px",
    //     height: "20px",
    //     marginRight: "14px"
    // },
    // dropdownIcons: {
    //     width: "24px",
    //     height: "24px",
    //     marginRight: "14px",
    //     opacity: "0.5",
    //     marginTop: "-4px",
    //     top: "1px",
    //     verticalAlign: "middle",
    //     fontSize: "24px",
    //     position: "relative"
    // },
    // socialIcons: {
    //     position: "relative",
    //     fontSize: "1.25rem",
    //     maxWidth: "24px"
    // },
    // dropdownLink: {
    //     "&,&:hover,&:focus": {
    //         color: "inherit",
    //         textDecoration: "none",
    //         display: "flex",
    //         padding: "0.75rem 1.25rem 0.75rem 0.75rem"
    //     }
    // },
    // ...tooltip,
    // marginRight5: {
    //     marginRight: "5px"
    // },
    // collapse: {
    //     [theme.breakpoints.up("md")]: {
    //         display: "flex !important",
    //         MsFlexPreferredSize: "auto",
    //         flexBasis: "auto"
    //     },
    //     WebkitBoxFlex: "1",
    //     MsFlexPositive: "1",
    //     flexGrow: "1",
    //     WebkitBoxAlign: "center",
    //     MsFlexAlign: "center",
    //     alignItems: "center"
    // },
    // mlAuto,
    // popover: {
    //     pointerEvents: 'none',
    // },
    // paper: {
    //     padding: theme.spacing(1),
    // },
    // nowShowPage: {
    //     borderBottom: '1px solid #01A2FE',
    //     [theme.breakpoints.down("sm")]: {
    //         border: '0'
    //     },
    // },
});

export default headerLinksStyle;
