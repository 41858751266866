import { useState, useCallback, useEffect } from 'react';
import axios from 'axios';
export { useConnectWallet } from './connectWallet';
export { useDisconnectWallet } from './disconnectWallet';
export function useAppIp() {
    const [appDis, setAppDis] = useState()
    const origin = window.location.origin;
    // let origin ='https://www.coinwind.pro'
    const [dataAll,setDataAll] = useState({})
    const fetchTvlAndProfit = useCallback(async () => {
        const url = `${origin}/api/defi/checkIp  `;
        axios.get(url).then((res) => {
            // console.log(res)
            if(res.data.code==='0'){
                setDataAll(res.data.data)
                const ipDIs = window.sessionStorage.getItem('isIp')
                // console.log(ipDIs)
                if(ipDIs===null){
                    setAppDis(true)
                    window.sessionStorage.setItem('isIp',res.data.data.disable)
                }else{
                    if((eval(ipDIs.toLowerCase())===res.data.data.disable)&&res.data.data.disable!=true){
                        setAppDis(false)
                        window.sessionStorage.setItem('isIp',res.data.data.disable)
                    }else{
                        setAppDis(true)
                        window.sessionStorage.setItem('isIp',res.data.data.disable)
                    }
                }
            }
        });
    }, [origin])
    return {
        fetchTvlAndProfit,
        dataAll,
        appDis,
        setAppDis
    }
}