/* eslint-disable */
import React, { useState, useEffect, useCallback, memo } from 'react';
import { useTranslation } from 'react-i18next';
//  i18n
import i18next from 'i18next';
import { useDispatch, useSelector } from 'react-redux';
import BigNumber from "bignumber.js";
import { byDecimals } from 'features/helpers/bignumber';
// import { isMobile } from 'features/helpers/utils';
import Web3Modal from "web3modal";
import WalletConnectProvider from "@walletconnect/web3-provider";
import Button from '../../../components/Button/Button'
import Dialog from '../../../components/Dialog/Dialog'
import SectionQuestion from "../../../components/Question/Question";
import SectionTwoTip from "../../../components/QuestionTwoTip/Question";
import SectionTwoTips from "../../../components/QuestionTwoTips/Question";
import SectionTwoXu from "../../../components/QuestionTwoXu/Question";
import SectionTwoXuss from "../../../components/QuestionTwoXuss/Questionss";
import SectionTwoXus from "../../../components/QuestionTwoXus/Question";
import SectionStaking from "../../../components/QuestionStaking/Question";
import SectionStakings from "../../../components/QuestionStakings/QuestionStakings";
//  hooks
import { useConnectWallet } from '../../home/redux/hooks';
import '../jss/sections/SectionPools.css';
import '../jss/sections/SectionFilter.css';
import DropDownStaking from "../../../components/DropDownStaking/DropDownStaking.js"
import DropDownStakingState from "../../../components/DropDownStakingState/DropDownStakingState.js"
import DropDownStakingType from "../../../components/DropDownStakingType/DropDownStakingType.js"
import axios from 'axios';
import {
  usePoolList,
  useFetchApproval,
  useFetchDeposit,
  useFetchWithdraw,
  useAllRate,
  useCoinRate,
  useStakingList,
} from '../redux/hooks';

// import SectionTitle from "./SectionTitle";
import SectionTotal from "./SectionTotal";
import SectionTitle from "./SectionTitle";
import SectionFilter from "./SectionFilter";
import SectionFilters from "./SectionFilters";
// import SectionCountDown from "./SectionCountDown";
import { inputLimitPass, inputFinalVal, isEmpty } from 'features/helpers/utils';
import { useBasicInfo } from 'features/configure/pools';
import { txUrlMap, _NETWORK_ID_MAP_NAME_, cowAddressMap, _IS_ETH_ } from '../../configure/chainsParamsConfig';
import { isMobile } from 'features/helpers/utils';

import Switch from '../../../components/Switch/Switch'
import { poolsLP } from 'features/configure';

export default memo(function SectionPools() {
  const { t, i18n } = useTranslation();
  const { web3, address, networkId, connected, connectWallet, connectWalletPending } = useConnectWallet();
  const theme = useSelector(state => state.common.theme);
  const [price, setPrice] = useState();
  // // Tab 切换
  const [tabActive, setTabActive] = useState(1);
  const [listData, setListData] = useState([]);
  const [stakingData, setStakingData] = useState([]);
  // cow复利 取 cow策略下 cow池子cow单利字段
  const [cowCompoundApy, setCowCompoundApy] = useState("");
  // 赋值默认值
  const { pools } = useBasicInfo();
  const _CURRENT_NETWORK_ID_ = Number(window.localStorage.getItem("NETWORK_ID_USE"));
  const isH5 = isMobile() ? 'h5' : 'pc';
  //项目类型
  const [projectType, setProjectType] = useState('1');
  const [listDataOne, setListDataOne] = useState([]);
  const [listDataTwo, setListDataTwo] = useState([]);
  const [shouQuan, setShouQuan] = useState(false);
  useEffect(() => {
    const temp = [];
    for (let pool of pools) {
      const poolInfo = {
        token: pool.tokenAddress,
        amount: 0,
        balance: 0,
        allowance: 0,
        mdxApy: 0,
        cowApy: 0,
        mdxCompoundApy: 0,
        mdxReward: 0,
        cowReward: 0,
        totalAmount: 0,
        totalAmountLimit: 0,
      }
      temp.push({ ...pool, ...poolInfo });
    }
    const map = {},
         dest = [];
        for(var i = 0; i < temp.length; i++){
            var ai = temp[i];
            if(!map[ai.id]){
                dest.push({
                    id: ai.id,
                    name: ai.name,
                    data: [ai],
                    dialog:ai.dialog
                });
                map[ai.id] = ai;
            }else{
                for(var j = 0; j < dest.length; j++){
                    var dj = dest[j];
                    if(dj.id == ai.id){
                        dj.data.push(ai);
                        break;
                    }
                }
            }
        }
    setListData(dest);

  }, [tabActive])

  const tabList = [
    {
      id: 1,
      name: `${t('staking-tab-1')}`,
    },
    {
      id: 0,
      name: `${t('staking-tab-2')}`,
    },
  ]
  // 连接钱包弹框
  function connectHandler() {
    const newModal = new Web3Modal({
      network: process.env.NETWORK ? process.env.NETWORK : "mainet",
      cacheProvider: true,
      providerOptions: {
        injected: {
          display: {
            name: "MetaMask",
            description: t('Home-BrowserWallet')
          },
        }
        ,
        walletconnect: {
          package: WalletConnectProvider,
          options: {
            infuraId: process.env.INFURA_ID
          }
        }
      }
    })
    connectWallet(newModal);
  }
  // useStakingList((pools) => {
  //   // console.log(pools,'12345432')
  //   setStakingData([...pools]);
  // })
  const a = useStakingList()
  // 更新取列表数据
  usePoolList((pools) => {
    let listOne = []
    let listTwo = []
    pools.map(item=>{
      if((Number(item.data[0].fixedPeriod)===0&&Number(item.data[0].unlockPeriod)>0)){
        // console.log(item)
        listTwo.push(item)
      }else{
        listOne.push(item)
      }
    })
    setListDataOne(listOne)
    setListDataTwo(listTwo)
    setListData([...pools]);
  });

  // 授权 存入 提取
  const { fetchApproval, fetchApprovalPending } = useFetchApproval();
  const { fetchDeposit, fetchDepositPending } = useFetchDeposit();
  const { fetchWithdraw, fetchWithdrawPending, fetchWithdrawIncomePending,fetchWithdrawReward,fetchWithdrawRewards } = useFetchWithdraw();
  const [depositedBalance, setDepositedBalance] = useState({});
  const [withdrawAmount, setWithdrawAmount] = useState({});
  // 新增 各币种兑MDX汇率
  const { poolRate, coinRate,getApys } = useAllRate(false);
  const { tokenRate: cow2Usdt } = useCoinRate(cowAddressMap[networkId] ? cowAddressMap[networkId] : cowAddressMap['128']);
  const dispatch = useDispatch();
  const [celueData,setCelueData] = useState({})



  const [opened, setOpened] = useState(false);
  const [cangType, setCangType] = useState('1');
  const [cangState, setCangState] = useState('1');
  const [huoList, setHuoList] = useState([]);
  const [dingList, setDingList] = useState([]);

  const [profitState, setProfitState] = useState([]);
  const [expireState, setExpireState] = useState([]);
  const [unlockState, setUnlockState] = useState([]);

  const [profitHuoState, setProfitHuoState] = useState([]);
  const [expireHuoState, setExpireHuoState] = useState([]);
  const [unlockHuoState, setUnlockHuoState] = useState([]);

  const [profitDingState, setProfitDingState] = useState([]);
  const [expireDingState, setExpireDingState] = useState([]);
  const [unlockDingState, setUnlockDingState] = useState([]);

  const [openeState, setOpenedState] = useState(false);
  const [openeType, setOpenedType] = useState(false);
  const [timeTab, setTimeTab] = useState(0);

  const [isLoding, setIsLoding] = useState(true);
  // const [apyAllList, setApyAllList] = useState([]);
  //  提取收益按钮
  const [isLodingTiShou, setIsLodingTiShou] = useState(false);

  const openDialogHandler = (type, index,token) => {
    if (type === 'widthdraw') {
      listData[index].dialog.opened = true;
      listData[index].dialog.widthdrawOpened = true
    }
    if (type === 'deposit') {
      const origin = window.location.origin;
      let url = null
      if(origin.indexOf("rdc01.cacheily.com") != -1){
        url = `http://rdc01.cacheily.com/api/defi/stakingMiner?token=${token}&chainId=${_CURRENT_NETWORK_ID_}`;
      }else{
        url = `${origin}/api/defi/stakingMiner?token=${token}&chainId=${_CURRENT_NETWORK_ID_}`;
        // url = `http://192.168.53.7:9998/api/defi/stakingMiner?token=0x3ee2200efb3400fabb9aacf31297cbdd1d435d47&chainId=56`;
      }
      // console.log(url)
      // const url = `http://rdc01.cacheily.com/api/defi/stakingMiner?token=${token}&chainId=${_CURRENT_NETWORK_ID_}`;
      // const url = `${origin}?token=${token}&chainId=${_CURRENT_NETWORK_ID_}`;
      listData[index].dialog.opened = true;
      axios.get(url).then((res) => {
        // console.log(res)
        listData[index].dialog.depositOpened = true
          if(res.status===200){
            setCelueData(res)
            listData[index].dialog.opened = true;

            // listData[index].dialog.depositOpened = true
          }else{
            listData[index].dialog.opened = false;
          }
      });
    }
    if (type === 'principal') {
      stakingData[index].dialog.opened = true;
      stakingData[index].dialog.principalOpened = true
    }
    if (type === 'profit') {
      stakingData[index].dialog.opened = true;
      stakingData[index].dialog.profitOpened = true
    }
    // 深拷贝 更新视图
    // console.log(stakingData)
    setListData([...listData]);
    setStakingData([...stakingData])
  }
  // 关闭对话框
  const closeDialogHandler = (type, index) => {
    if (type === 'widthdraw') {
      listData[index].dialog.opened = false;
      listData[index].dialog.widthdrawOpened = false
      setTimeTab(0)
    }
    if (type === 'deposit') {
      listData[index].dialog.opened = false;
      listData[index].dialog.depositOpened = false
    }
    if (type === 'principal') {
      stakingData[index].dialog.opened = false;
      stakingData[index].dialog.principalOpened = false
    }
    if (type === 'profit') {
      stakingData[index].dialog.opened = false;
      stakingData[index].dialog.profitOpened = false
    }
    // 深拷贝 更新视图
    setListData([...listData]);
    setStakingData([...stakingData])
  }
  // 所有状态的对话操作
  const [dialogCtrl, setDialogCtrl] = useState({
    opened: false,
    type: 'success',
    // "我知道了"
    buttonText: t('v2-ui-1'),
    // 授权成功
    contentText: t('v2-ui-2'),
    buttonClickCallback: () => {
      setDialogCtrl({ ...dialogCtrl, opened: false })
    }
  })
  // 格式化数据
  const forMat = (number, precision = 2) => {
    return new BigNumber(
      number
    ).multipliedBy(
      new BigNumber(10000)
    ).dividedToIntegerBy(
      new BigNumber(1)
    ).dividedBy(
      new BigNumber(10000)
    ).toNumber().toFixed(precision)
  }
  // 千分位格式化
  function formatThousands(num) {
    return num.toString().replace(/\d+/, function (n) { // 先提取整数部分
      return n.replace(/(\d)(?=(\d{3})+$)/g, function ($1) { // 对整数部分添加分隔符
        return $1 + ",";
      });
    });
  }
  //时间戳转化时间
  function getTime(dateStr){
    var date = new Date(dateStr);
    var Month = date.getMonth() + 1;
    var Day = date.getDate();
    var Y = date.getFullYear() + '-';
    var M = Month < 10 ? '0' + Month + '-' : Month + '-';
    var D = Day + 1 < 10 ? '0' + Day : Day;
    return Y + M + D;
  }
  function getTimes(dateStr){
    var date = new Date(dateStr);
    var Month = date.getMonth() + 1;
    var Day = date.getDate();
    var Y = date.getFullYear() + '-';
    var M = Month < 10 ? '0' + Month + '-' : Month + '-';
    var D = Day + 1 < 10 ? '0' + Day : Day;
    var h = date.getHours();
    var mm = date.getMinutes();
    var s = date.getSeconds();
    return Y + M + D+' '+h+":"+mm;
  }
  // 输入框change
  const changeDetailInputValue = (type, index, total, tokenDecimals, event) => {
    total = Number(total)
    let value = event.target.value;
    if (!inputLimitPass(value, tokenDecimals)) {
      return;
    }
    if (isNaN(Number(value))) {
      value = ''
    }
    switch (type) {
      case 'depositedBalance':
        setDepositedBalance({
          ...depositedBalance,
          [index]: inputFinalVal(value, total, tokenDecimals),
          // [`slider-${index}`]: sliderNum,
        });
        break;
      case 'withdrawAmount':
        setWithdrawAmount({
          ...withdrawAmount,
          [index]: inputFinalVal(value, total, tokenDecimals),
          // [`slider-${index}`]: sliderNum,
        });
        break;
      default:
        break;
    }
  }
  // 授权
  const onApproval = (pool, index, event) => {
    setShouQuan(true)
    event.stopPropagation();
    fetchApproval({
      address,
      web3,
      tokenAddress: pool.token,
      contractAddress: pool.earnContractAddress,
      index
    }).then(
      () => {
        setShouQuan(false)
        setIsDia(false)
        setIsDiaS(false)
        closeDialogHandler('widthdraw', index);
        setDialogCtrl({
          ...dialogCtrl,
          opened: true,
          type: 'success',
          contentText: t("Approval-success"),
          // 确认、存入
          cancelButtonText: t('Farm-Dialog-Confirm'),
          buttonText: t('Vault-DepositButton'),
          buttonClickCallback: () => {
            setDialogCtrl({ ...dialogCtrl, opened: false });
            openDialogHandler('widthdraw', index);
          },
          cancelButtonCallback: () => {
            setDialogCtrl({ ...dialogCtrl, opened: false })
          },
        })
      }
    ).catch(
      error => {
        const errorMessage = JSON.stringify(error);
        const isEthError = _IS_ETH_ && errorMessage.includes('Failed to check for transaction receipt')
        setShouQuan(false)
        setDialogCtrl({
          ...dialogCtrl,
          opened: true,
          type: isEthError ? 'success' : 'warning',
          contentText: isEthError ? t("error-message") : t("Approval-error"),
          // 重试
          buttonText: isEthError ? t('v2-ui-1') : t('v2-ui-3')
        })
      }
    )
  }
  const [isDia,setIsDia] = useState(false)
  const [isDiaS,setIsDiaS] = useState(false)

  const [isHuoCun,setIsHuoCun] = useState(false)
  const [isHuoAll,setIsHuoAll] = useState(false)
  const allBtn =async function(){
    let b = await a()
                          // console.log(b)
                          let dingList =[]
                          let huoList = []
                          b.map(item=>{
                            if((Number(item.fixedPeriod)===0&&Number(item.unlockPeriod)>0)){
                              dingList.push(item)
                            }else{
                              huoList.push(item)
                            }
                          })
                          let profit = []
                          let expire = []
                          let unlock = []
                          b.map(item =>{
                            if(item.extend==='0'||item.extend==='2'){
                              profit.push(item)
                            }else if(item.extend==='1'||item.extend==='4'){
                              expire.push(item)
                            }else if(item.extend==='3'){
                              unlock.push(item)
                            }
                          })
                          let profitHuo = []
                          let expireHuo = []
                          let unlockHuo = []
                          huoList.map(item =>{
                            if(item.extend==='0'||item.extend==='2'){
                              profitHuo.push(item)
                            }else if(item.extend==='1'||item.extend==='4'){
                              expireHuo.push(item)
                            }else if(item.extend==='3'){
                              unlockHuo.push(item)
                            }
                          })
                          let profitDing = []
                          let expireDing = []
                          let unlockDing = []
                          dingList.map(item =>{
                            if(item.extend==='0'||item.extend==='2'){
                              profitDing.push(item)
                            }else if(item.extend==='1'||item.extend==='4'){
                              expireDing.push(item)
                            }else if(item.extend==='3'){
                              unlockDing.push(item)
                            }
                          })
                          setProfitState(profit)
                          setExpireState(expire)
                          setUnlockState(unlock)

                          setProfitHuoState(profitHuo)
                          setExpireHuoState(expireHuo)
                          setUnlockHuoState(unlockHuo)

                          setProfitDingState(profitDing)
                          setExpireDingState(expireDing)
                          setUnlockDingState(unlockDing)

                          setHuoList(huoList)
                          setDingList(dingList)
                          setStakingData(b)
  }
  // 存入
  const onDeposit = (pool, index, isAll, balanceSingle,type, event) => {
    // console.log(type)
    if(type==='huoCun'){
      setIsHuoCun(true)
    }else{
      setIsHuoAll(true)
    }
    // console.log(pool, index, isAll, balanceSingle, event)
    event.stopPropagation();
    let amountValue;
    if (isAll) {
      setDepositedBalance({
        ...depositedBalance,
        [index]: balanceSingle.toString(10),
        // [`slider-${index}`]: 100,
      })
      amountValue=balanceSingle.toString(10)
    }else{
      amountValue = depositedBalance[index] ? depositedBalance[index].replace(',', '') : depositedBalance[index];
    }
    // let amountValue = depositedBalance[index] ? depositedBalance[index].replace(',', '') : depositedBalance[index];
    if (amountValue == undefined) {
      amountValue = '0';
    }
    // console.log(amountValue)
    // // console.log(new BigNumber(amountValue).multipliedBy(new BigNumber(10).exponentiatedBy(pool.tokenDecimals)).toString(10),Number(pool.allowance/1e18),pool.allowance)
    // console.log(new BigNumber(amountValue).multipliedBy(new BigNumber(10).exponentiatedBy(pool.tokenDecimals)).toString(10))
    // console.log(pool.allowance)
    // console.log(pool.name)
    // if(new BigNumber(amountValue).multipliedBy(new BigNumber(10).exponentiatedBy(pool.tokenDecimals)).toString(10)>Number(pool.allowance/1e18)){
    if(Number(new BigNumber(amountValue).multipliedBy(new BigNumber(10).exponentiatedBy(pool.tokenDecimals)).toString(10))>Number((pool.allowance))){
      // console.log('比你大')
      if(pool.name==='COW'){
        setIsDiaS(true)
      }else{
        setIsDia(true)
      }
    }else{
      // console.log('比你小')



      fetchDeposit({
        address,
        web3,
        isAll,
        amount: new BigNumber(amountValue).multipliedBy(new BigNumber(10).exponentiatedBy(pool.tokenDecimals)).toString(10),
        contractAddress: pool.earnContractAddress,
        contractToken: pool.tokenAddress,
        index
      }).then(
        ({ transactionHash }) => {
          setDepositedBalance({ ...depositedBalance, [index]: '' })
          setDialogCtrl({
            ...dialogCtrl,
            opened: true,
            type: 'success',
            contentText: t("Deposit-success"),
            // 查看我的交易
            buttonText: <a href={txUrlMap[networkId] + transactionHash} target="_blank">{t("v2-ui-4")}</a>,
            cancelButtonText: t("v2-ui-1"),
            cancelButtonCallback: () => {
              setDialogCtrl({ ...dialogCtrl, opened: false })
            },
            buttonClickCallback: () => {
              setDialogCtrl({ ...dialogCtrl, opened: false });
            }
          })
          if(type==='huoCun'){
            setIsHuoCun(false)
          }else{
            setIsHuoAll(false)
          }
        }
      ).catch(
        error => {
          {
            const errorMessage = JSON.stringify(error);
            const isEthError = _IS_ETH_ && errorMessage.includes('Failed to check for transaction receipt');
            setDepositedBalance({ ...depositedBalance, [index]: '' })
            setDialogCtrl({
              ...dialogCtrl,
              opened: true,
              type: isEthError ? 'success' : 'warning',
              contentText: isEthError ? t("error-message") : t("Deposit-error"),
              buttonText: isEthError ? t('v2-ui-1') : t("v2-ui-3")
            })
            if(type==='huoCun'){
              setIsHuoCun(false)
            }else{
              setIsHuoAll(false)
            }
          }
        }
      )



    }
  }
  const [isDingTiQu,setIsDingTiQu] = useState(false)
  const [isDingAllTiQu,setIsDingAllTiQu] = useState(false)
  const [isJieSuo,setIsJieSuo] = useState(false)
  const [isTiQu,setIsTiQu] = useState(false)
  const [isTiQuId,setIsTiQuId] = useState(false)
  const stateAll = (index,bool)=>{
    // console.log(index,bool)
      if(stakingData.length>0){
        stakingData[index].dialog.tiQu = bool
      }else if(profitState.length>0){
        profitState[index].dialog.tiQu = bool
      }else if(expireState.length>0){
        expireState[index].dialog.tiQu = bool;
      }else if(unlockState.length>0){
        unlockState[index].dialog.tiQu = bool
      }else if(profitHuoState.length>0){
        profitHuoState[index].dialog.tiQu = bool
      }else if(expireHuoState.length>0){
        expireHuoState[index].dialog.tiQu = bool
      }else if(unlockHuoState.length>0){
        unlockHuoState[index].dialog.tiQu = bool
      }else if(profitDingState.length>0){
        profitDingState[index].dialog.tiQu = bool
      }else if(expireDingState.length>0){
        expireDingState[index].dialog.tiQu = bool
      }else if(unlockDingState.length>0){
        unlockDingState[index].dialog.tiQu = bool
      }else if(huoList.length>0){
        huoList[index].dialog.tiQu = bool
      }else if(dingList.length>0){
        dingList[index].dialog.tiQu = bool
      }
      setProfitState(profitState)
      setExpireState(expireState)
      setUnlockState(unlockState)

      setProfitHuoState(profitHuoState)
      setExpireHuoState(expireHuoState)
      setUnlockHuoState(unlockHuoState)

      setProfitDingState(profitDingState)
      setExpireDingState(expireDingState)
      setUnlockDingState(unlockDingState)

      setHuoList(huoList)
      setDingList(dingList)
      setStakingData(stakingData)
  }
  // 提取
  const onWithdraw = (pool, index, isAll, singleDepositedBalance, isWithdrawIncome,amount,type, event) => {
    // console.log(pool, index, isAll, singleDepositedBalance, isWithdrawIncome, event,'123')
    // console.log(singleDepositedBalance,amount)
    // console.log(type)
    // event.stopPropagation();
    if(type==='jieSuo'){
      setIsJieSuo(true)
    }else if(type==='tiQu'){
      setIsLodingTiShou(true)
      setIsTiQu(true)
      stateAll(index,true)
    }
    if (isAll) {
      setWithdrawAmount({
        ...withdrawAmount,
        [index]: String(forMat(singleDepositedBalance, 8)),
        // [`slider-${index}`]: 100,
      })
    }
    let amountValue = withdrawAmount[index] ? withdrawAmount[index].replace(',', '') : withdrawAmount[index];
    if (amountValue == undefined) {
      amountValue = '0';
    }

    fetchWithdrawReward({
      address,
      web3,
      isAll,
      amount: new BigNumber(amountValue).multipliedBy(new BigNumber(10).exponentiatedBy(pool.tokenDecimals)).toString(10),
      contractAddress: pool.earnContractAddress,
      contractToken: pool.tokenAddress,
      tokenId:pool.tokenId,
      index,
      isWithdrawIncome,
      amounts:new BigNumber(amount).multipliedBy(new BigNumber(10).exponentiatedBy(pool.tokenDecimals)).toString(10)
    }).then(
      ({ transactionHash }) => {
        // console.log(transactionHash,'1234432123')
        setWithdrawAmount({ ...withdrawAmount, [index]: '' })
        allBtn()
        setDialogCtrl({
          ...dialogCtrl,
          opened: true,
          type: 'success',
          contentText: isWithdrawIncome ? t("v2-ui-7") :type==='jieSuo'?t("v2-ui-5-1"): t("v2-ui-5"),
          buttonText: <a href={txUrlMap[networkId] + transactionHash} target="_blank">{t("v2-ui-4")}</a>,
          cancelButtonText: t("v2-ui-1"),
          cancelButtonCallback: () => {
            setDialogCtrl({ ...dialogCtrl, opened: false })
          },
          buttonClickCallback: () => {
            setDialogCtrl({ ...dialogCtrl, opened: false });
          }
        })
        if(type==='jieSuo'){
          setIsJieSuo(false)
        }else if(type==='tiQu'){
          setIsTiQu(false)
          setIsLodingTiShou(false)
          stateAll(index,false)
        }
      }
    ).catch(
      error => {
        // console.log(error)
        allBtn()
        const errorMessage = JSON.stringify(error);
        const isEthError = _IS_ETH_ && errorMessage.includes('Failed to check for transaction receipt');
        setWithdrawAmount({ ...withdrawAmount, [index]: '' })
        setDialogCtrl({
          ...dialogCtrl,
          opened: true,
          type: isEthError ? 'success' : 'warning',
          contentText: isEthError ? t("error-message") : (isWithdrawIncome ? t("v2-ui-8"):type==='jieSuo'?t("v2-ui-6-1"):  t("v2-ui-6")),
          buttonText: isEthError ? t('v2-ui-1') : t("v2-ui-3")
        })
        if(type==='jieSuo'){
          setIsJieSuo(false)
        }else if(type==='tiQu'){
          setIsTiQu(false)
          setIsLodingTiShou(false)
          stateAll(index,false)
        }
      }
    )
  }
  // 提取
  const onWithdraws = (pool, index, isAll, singleDepositedBalance, isWithdrawIncome,type, event) => {
    // console.log(type)
    setIsTiQuId(index)
    if(type==='DingTiQu'){
      setIsDingTiQu(true)
    }else if(type==='dingAllTiQu'){
      setIsDingAllTiQu(true)
    }
    // console.log(pool, index, isAll, singleDepositedBalance, isWithdrawIncome, event,'123')
    // event.stopPropagation();
    if (isAll) {
      setWithdrawAmount({
        ...withdrawAmount,
        [index]: String(forMat(singleDepositedBalance, 8)),
        // [`slider-${index}`]: 100,
      })
    }
    let amountValue = withdrawAmount[index] ? withdrawAmount[index].replace(',', '') : withdrawAmount[index];
    if (amountValue == undefined) {
      amountValue = '0';
    }
    fetchWithdrawRewards({
      address,
      web3,
      isAll,
      amount: new BigNumber(amountValue).multipliedBy(new BigNumber(10).exponentiatedBy(pool.tokenDecimals)).toString(10),
      contractAddress: pool.earnContractAddress,
      contractToken: pool.tokenAddress,
      tokenId:pool.tokenId,
      index,
      isWithdrawIncome
    }).then(
      ({ transactionHash }) => {
        // console.log(transactionHash,'1234432123')
        allBtn()
        setWithdrawAmount({ ...withdrawAmount, [index]: '' })
        setDialogCtrl({
          ...dialogCtrl,
          opened: true,
          type: 'success',
          contentText: isWithdrawIncome ? t("v2-ui-7") :type==='dingAllTiQu'?t("v2-ui-5-1"): t("v2-ui-5"),
          buttonText: <a href={txUrlMap[networkId] + transactionHash} target="_blank">{t("v2-ui-4")}</a>,
          cancelButtonText: t("v2-ui-1"),
          cancelButtonCallback: () => {
            setDialogCtrl({ ...dialogCtrl, opened: false })
          },
          buttonClickCallback: () => {
            setDialogCtrl({ ...dialogCtrl, opened: false });
          }
        })
        if(type==='DingTiQu'){
          setIsDingTiQu(false)
        }else if(type==='dingAllTiQu'){
          setIsDingAllTiQu(false)
        }
      }
    ).catch(
      error => {
        allBtn()
        const errorMessage = JSON.stringify(error);
        const isEthError = _IS_ETH_ && errorMessage.includes('Failed to check for transaction receipt');
        setWithdrawAmount({ ...withdrawAmount, [index]: '' })
        setDialogCtrl({
          ...dialogCtrl,
          opened: true,
          type: isEthError ? 'success' : 'warning',
          contentText: isEthError ? t("error-message") : (isWithdrawIncome ? t("v2-ui-8"):type==='dingAllTiQu'?t("v2-ui-6-1"): t("v2-ui-6")),
          buttonText: isEthError ? t('v2-ui-1') : t("v2-ui-3")
        })
        if(type==='DingTiQu'){
          setIsDingTiQu(false)
        }else if(type==='dingAllTiQu'){
          setIsDingAllTiQu(false)
        }
      }
    )
  }
  // 是否最大
  const isMoreDepostLimit = (value, depostLimit) => {
    if (isEmpty(value) || depostLimit == 0 || value < depostLimit) {
      return false
    }
    return true;
  }

  const poolsMap = (projectType==='1'?listData:projectType==='2'?listDataOne:listDataTwo).map((pool, index) => {
    // console.log(timeTab>pool.data.length-1)
    // console.log(pool)
    let allDeposited = 0
    let lockVolume = 0
    let timeList = []
    pool.data&&pool.data.forEach(item=>{
      allDeposited = (allDeposited+Number(item.amount))
      lockVolume = lockVolume+Number(item.totalAmount)+Number(item.totalUnlocking)
      timeList.push(Number(item.fixedPeriod)/28800)
    })
    // 计算锁仓量
    const lock = formatThousands((lockVolume / Number(`1e${pool.data&&pool.data[0].tokenDecimals}`)).toFixed(2));
    //获取当前时间
    const timestamp = Date.parse(new Date());
    //到期时间
    const expireTime =pool.data.length===1?Number((pool.data[0].fixedPeriod/28800))*24*60*60*1000: Number((pool.data[timeTab].fixedPeriod/28800))*24*60*60*1000
    let allTime = timestamp+expireTime
    //续存时间
    const xuTime =pool.data.length===1?(Number(pool.data[0].extendPeriod/28800))*24*60*60*1000: (Number(pool.data[timeTab].extendPeriod/28800))*24*60*60*1000
    let xuTimes = xuTime+allTime
    // 余额
    const balanceSingle =pool.data.length===1?byDecimals(pool.data[0].balance, pool.data[0].tokenDecimals) :byDecimals(pool.data[timeTab].balance, pool.data[timeTab].tokenDecimals);
    // 剩余可投/剩余可存
    const remainLock =(pool.data.length===1?(pool.data[0].totalAmountLimit / Number(`1e${pool.data[0].tokenDecimals}`))<=0?'0.00':((pool.data[0].totalAmountLimit / Number(`1e${pool.data[0].tokenDecimals}`)) - (pool.data[0].totalAmount / Number(`1e${pool.data[0].tokenDecimals}`))):
    (pool.data[timeTab].totalAmountLimit / Number(`1e${pool.data[timeTab].tokenDecimals}`))<=0?'0.00':
     ((pool.data[timeTab].totalAmountLimit / Number(`1e${pool.data[timeTab].tokenDecimals}`)) - (pool.data[timeTab].totalAmount / Number(`1e${pool.data[timeTab].tokenDecimals}`))))
    let allHuoApys = []
    let allHuoApysDing = []
    let cowApyViewDing = null
    let apyAllList = []
    let depositedCowApyList = []
    let depositedCowApyDing= null
    if(pool.data.length>1){
      let cowApyLis = []
      pool.data.map((item,index)=>{
        let cow2Token = cow2Usdt / coinRate[pool.data[index].tokenAddress]
        let cowApy = (pool.data[index].cowApy * cow2Token / (100)) / (10 ** (18 - pool.data[index].tokenDecimals));
        cowApyLis.push(cowApy)
      })
      cowApyLis.map((item,index)=>{
        depositedCowApyList.push(forMat(item))
      })
      // const depositedCowApy = forMat(cowApy);
      let allHuoApyss = []
      pool.data.map((item,index)=>{
        cowApyLis.map((el,ind)=>{
          if(index===ind){
            let finaCowCompounds;
            if (getApys > 0) {
              const depositedCompound = Number(getApys) / 100;
              const xCompound = Math.pow((1 + (depositedCompound / 100) / 365), 365);
              // console.log(cowApy,depositedCompound,xCompound,cowApy)
              finaCowCompounds =forMat(((((el/100) / (depositedCompound / 100)) * (xCompound - 1)) * 100) - el)
            } else {
              finaCowCompounds = 0
            }
            const cowApyView = Number(item.apy/100)+Number(forMat(el))+Number(finaCowCompounds)
            // console.log(cowApyView,'2222222')
            allHuoApys.push({
              allApy:cowApyView,
              time:Number(item.fixedPeriod)/28800,
              finaCowCompound:finaCowCompounds
            })
            allHuoApyss.push({
              allApy:cowApyView,
              time:Number(item.fixedPeriod)/28800,
              finaCowCompound:finaCowCompounds
            })
          }
        })
      })
      let bb = []
      allHuoApyss.map(item=>{
        bb.push(item.allApy)
      })
      for(var i=0;i<bb.length-1;i++){
        for(var j=0;j<bb.length-i-1;j++){
            if(bb[j]>bb[j+1]){// 相邻元素两两对比
                var hand = bb[j];
                bb[j]=bb[j+1];
                bb[j+1]=hand;

            }
            apyAllList = bb
        }
      }
    }else{
      // console.log(cow2Usdt,coinRate)
      const cow2Token = cow2Usdt / coinRate[pool.data[0].tokenAddress]
      const cowApy = (pool.data[0].cowApy * cow2Token / (100)) / (10 ** (18 - pool.data[0].tokenDecimals));
      depositedCowApyDing= forMat(cowApy);
      // COW 复利
      let finaCowCompound;
      if (getApys > 0) {//7217
        const depositedCompound = Number(getApys) / 100;
        const xCompound = Math.pow((1 + (depositedCompound / 100) / 365), 365);
        // console.log(cowApy,depositedCompound,xCompound)
        finaCowCompound =(pool.data.length===1?forMat(((((cowApy/100) / (depositedCompound / 100)) * (xCompound - 1)) * 100) - cowApy): forMat(((((cowApy/100) / (depositedCompound / 100)) * (xCompound - 1)) * 100) - cowApy))
      } else {
        finaCowCompound = 0
      }
      // console.log(pool.data[0].apy,depositedCowApyDing,finaCowCompound)
      // const aa = Number(pool.data[0].apy/100)+Number(forMat(depositedCowApyDing))+Number(finaCowCompound)
      // console.log(aa)
      // console.log(Number(pool.data[0].apy/100),Number(forMat(depositedCowApyDing)),Number(finaCowCompound))
      cowApyViewDing = Number(pool.data[0].apy/100)+Number(forMat(depositedCowApyDing))+Number(finaCowCompound)
      const cowApyView= Number(pool.data[0].apy/100)+Number(forMat(depositedCowApyDing))+Number(finaCowCompound)
      allHuoApysDing.push({
        allApy:cowApyView,
        time:Number(pool.data[0].fixedPeriod)/28800,
        finaCowCompound:finaCowCompound
      })
      // console.log(cowApyViewDing)
    }
    //策略已质押
    let yi = celueData.data&&(celueData.data.data===null?undefined:formatThousands(Number(celueData.data.data.totalPledge).toFixed(2)))
    return (
      <div className='staking_li_content'>
          <div>
            <div className={`staking_li_content-title ${theme}-staking_title`}>
              <div className='staking_title-li'>{pool.id}</div>
              <img
                    className="pools__coin-logos"
                    alt={pool.id}
                    src={require(`../../../images/icon2/${pool.id}.svg`)}
                  />
            </div>
            <div className='staking_content'>
              <div className='staking_li_time'>
                {
                  (pool.data.length===1?(Number(pool.data[0].fixedPeriod)===0&&Number(pool.data[0].unlockPeriod)>0):(Number(pool.data[timeTab].fixedPeriod)===0&&Number(pool.data[timeTab].unlockPeriod)>0))?<div className='staking_li_time-li'>{t('Cang_dao_title')}</div>:<>
                     {
                      timeList.map(item=>{
                        return <div className='staking_li_time-li'>{Math.floor(item*100)/100} {t('staking-deposit-time')}</div>
                      })
                    }
                  </>
                }

              </div>
              <div className='staking_yield'>
                <div className='staking_yield-title'>{t('staking-apy')}</div>
                <div className='staking_yield-data'>{pool.data.length>1?
                <>{isNaN(Number(apyAllList[0]))?'0.00':Number(apyAllList[0]).toFixed(2)}% ~ {Number(apyAllList[apyAllList.length-1])>999999?<>999999+</>:isNaN(Number(apyAllList[apyAllList.length-1]))?'0.00':(Number(apyAllList[apyAllList.length-1])).toFixed(2)}</>
                :Number(cowApyViewDing)>999999?<>999999+</>:isNaN(Number(cowApyViewDing))?"0.00":Number(cowApyViewDing).toFixed(2)}%</div>
              </div>
              <div className={`staking_data ${theme}-staking_data`}>
                <div>
                  <div className='staking_data-li'>
                    <div>{lock}</div>
                    <div>{t("Lock-volume")}({pool.id})</div>
                  </div>
                  <div className='staking_data-lis'>
                    <div>{String((allDeposited/ Number(`1e${pool.data&&pool.data[0].tokenDecimals}`)).toFixed(5)).slice(0, -1)}</div>
                    <div>{t('v2-ui-14')}({pool.id})
                      {/* <SectionQuestion value={
                        <div>11</div>
                      } /> */}
                    </div>
                  </div>
                </div>
              </div>
              <div className='staking_btn'>
                <div className="pools__button-group">
                    <Button
                      className="pools__dialog__option"
                      type={2}
                      loading={pool.dialog.depositOpened}
                      onClick={() => openDialogHandler('deposit', index,pool.data.length===1?pool.data[0].token:pool.data[timeTab].token)}
                    >
                      {t('staking-tab1-btn1')}
                    </Button>
                    <Button
                      className="pools__dialog__option"
                      loading={pool.dialog.widthdrawOpened}
                      onClick={() => openDialogHandler('widthdraw', index,pool.data.length===1?pool.data[0].token:pool.data[timeTab].token)}
                    >
                      {t('Vault-DepositButton')}
                    </Button>
                  </div>
              </div>

            </div>
            <div className="pools__mao-logo__wraps">
                <img
                  className="pools__mao-logos"
                  alt={pool.id}
                  src={require(`../../../images/icon2/${pool.id}.svg`)}
                />
              </div>
            {
              pool.dialog.opened? <div className='pools__dialog'>
                {/* 存入 */}
                {
                  pool.dialog.widthdrawOpened&&<div className='staking_dialog-deposit'>
                  <div className={`staking__dialog__name ${theme}-staking__dialog__name`}>
                    <div className="staking__dialog__header">
                      {t("Vault-DepositButton")}
                      {/* <span className="staking__dialog__header__close" onClick={() => closeDialogHandler('widthdraw', index)}></span> */}
                      <img  className="staking__dialog__header__closeone" src={require('../../../images/ic_close.svg')} alt="" onClick={() => closeDialogHandler('widthdraw', index)}/>
                    </div>
                    <div className='staking_width_top'>
                      <div className='staking__dialog__name-li'>
                        <img
                          className="pools__coin-logs"
                          alt={pool.id}
                          src={require(`../../../images/icon2/${pool.id}.svg`)}
                        />
                        <div className='staking__name_title'>{pool.id}</div>
                      </div>
                      <div className='staking__dialog__name-right'>
                        <div>{t('v2-ui-14')}</div>
                        <div>{String((allDeposited/ Number(`1e${pool.data&&pool.data[0].tokenDecimals}`)).toFixed(5)).slice(0, -1)}</div>
                      </div>
                    </div>
                  </div>
                  <div className='staking__dialog__middle'>
                    <div>
                      {
                        pool.data.length===1?<>
                          {
                            (Number(pool.data[0].fixedPeriod)===0&&Number(pool.data[0].unlockPeriod)>0)?<div className='staking__dialog__time'>
                                <div className='staking__times'>{t('Cang_dao_title')}
                                  <div className={`staking__time-percentage  ${theme}_staking__time-percentage ${`${pool.id}`==='DOT'?`${theme}_dot`:null}`}>{Number(cowApyViewDing)>999999?<>999999+</>:Math.floor(Number(cowApyViewDing)*100)/100}%</div>
                                </div>
                              </div>:<div className={(allHuoApys.length>2&&isH5==='h5')?`staking__dialog__times`:`staking__dialog__time`}>
                              {
                                allHuoApysDing.map((item,index)=>{
                                  return <div className={(index==timeTab)?`staking__times`:isH5==='h5'?`staking__time_token`:`staking__time`} onClick={()=>setTimeTab(index)}>{Math.floor(Number(item.time)*100)/100}{t('staking-deposit-time')}
                                  <div className={`staking__time-percentage  ${theme}_staking__time-percentage ${`${pool.id}`==='DOT'?`${theme}_dot`:null}`}>{Number(item.allApy).toFixed(2)>999999?<>999999+</>:(item.allApy).toFixed(2)}%</div>
                                </div>
                                })
                              }
                            </div>
                          }
                        </>:<>
                          {
                            (Number(pool.data[timeTab].fixedPeriod)===0&&Number(pool.data[timeTab].unlockPeriod)>0)?<div className='staking__dialog__time'>
                              <div className='staking__times'>{t('Cang_dao_title')}
                                <div className='staking__time-percentage'>{Math.floor(Number(cowApyViewDing)*100)/100}%</div>
                              </div>
                            </div>:<div className={(allHuoApys.length>2&&isH5==='h5')?`staking__dialog__times`:`staking__dialog__time`}>
                              {
                                allHuoApys.map((item,index)=>{
                                  return <div className={(index==timeTab)?`staking__times`:isH5==='h5'?`staking__time_token`:`staking__time`} onClick={()=>setTimeTab(index)}>{Math.floor(Number(item.time)*100)/100}{t('staking-deposit-time')}
                                  <div className='staking__time-percentage'>{Number(item.allApy).toFixed(2)>999999?<>999999+</>:(item.allApy).toFixed(2)}%</div>
                                </div>
                                })
                              }
                            </div>
                          }
                        </>
                      }
                      <div className='staking__dialog__apy'>
                        <div>APY{
                              <SectionStaking value={
                                <div>
                                  <div className="question__title">
                                    {t("Total-Yield")}
                                    {
                                      pool.data.length===1?<>{
                                        (Number(pool.data[0].fixedPeriod)===0&&Number(pool.data[0].unlockPeriod)>0)?
                                        <strong style={{ color: "rgb(255, 175, 103)" }}>{Number(cowApyViewDing)>999999?<>999999+</>:Number(cowApyViewDing).toFixed(2)}%</strong>:
                                        <strong style={{ color: "rgb(255, 175, 103)" }}>{Number(cowApyViewDing)>999999?<>999999+</>:Number(cowApyViewDing).toFixed(2)}%</strong>
                                      }</>:null
                                    }
                                    {
                                      pool.data.length>1?<>
                                      {
                                        (Number(pool.data[0].fixedPeriod)===0&&Number(pool.data[0].unlockPeriod)>0)?
                                        <strong style={{ color: "rgb(255, 175, 103)" }}>{Number(cowApyViewDing)>999999?<>999999+</>:Number(cowApyViewDing).toFixed(2)}%</strong>:
                                        <strong style={{ color: "rgb(255, 175, 103)" }}>{Number(allHuoApys[timeTab].allApy)>999999?<>999999+</>:Number(allHuoApys[timeTab].allApy).toFixed(2)}%</strong>
                                      }
                                      </>:null
                                    }

                                  </div>
                                  <div className="question__item_Staking">
                                    <div className="ques__labe-field">
                                      <span className="ques__labe-line ques__labe-line--first"></span>
                                      <span className="ques__labe-child">{pool.id}{t("Nav-Farm")}({t('APR')})</span>
                                    </div>
                                    {
                                      pool.data.length===1?<strong style={{ color: "rgb(255, 175, 103)" }}>{(pool.data[0].apy/100).toFixed(2)}%</strong>:null
                                    }
                                    {
                                      pool.data.length>1?<>
                                      {
                                        (Number(pool.data[0].fixedPeriod)===0&&Number(pool.data[0].unlockPeriod)>0)?
                                        <strong style={{ color: "rgb(255, 175, 103)" }}>{(pool.data[0].apy/100).toFixed(2)}%</strong>:
                                        <strong style={{ color: "rgb(255, 175, 103)" }}>{Number(pool.data[timeTab].apy/100)>999999?<>999999+</>:Number(pool.data[timeTab].apy/100).toFixed(2)}%</strong>
                                      }
                                      </>:null
                                    }
                                    {/* <strong style={{ color: "rgb(255, 175, 103)" }}>{pool.data[timeTab].apy/100}%</strong> */}
                                  </div>
                                  <div className="question__item_Staking">
                                    <div className="ques__labe-field">
                                      <span className="ques__labe-line"></span>
                                      <span className="ques__labe-child">{pool.id}{t("Nav-Farm")}({t('APY')})</span>
                                    </div>
                                    <strong style={{ color: "rgb(255, 175, 103)" }}>0.00%</strong>
                                  </div>
                                  <div className="question__item_Staking">
                                    <div className="ques__labe-field">
                                      <span className="ques__labe-line"></span>
                                      <span className="ques__labe-child">COW{t("Nav-Farm")}({t('APR')})</span>
                                    </div>
                                    {
                                      pool.data.length===1?<>
                                      <strong style={{ color: "rgb(255, 175, 103)" }}>{Number(depositedCowApyDing)>999999?<>999999+</>:Number(depositedCowApyDing).toFixed(2)}%</strong>
                                      </>:<>
                                            {
                                              depositedCowApyList.map((item,index)=>{
                                                if(index===timeTab){
                                                  return <strong style={{ color: "rgb(255, 175, 103)" }}>{Number(item)>999999?<>999999+</>:Number(item).toFixed(2)}%</strong>
                                                }
                                              })
                                            }
                                      </>
                                    }
                                    {/* <strong style={{ color: "rgb(255, 175, 103)" }}>{Number(depositedCowApy)>999999?<>999999+</>:Number(depositedCowApy)}%</strong> */}
                                  </div>
                                  <div className="question__item_Staking">
                                    <div className="ques__labe-field">
                                      <span className="ques__labe-line"></span>
                                      <span className="ques__labe-child">COW{t("Nav-Farm")}({t('APY')})</span>
                                    </div>
                                    {
                                      pool.data.length===1?<>
                                        {
                                              allHuoApysDing.map(item=>{
                                                return <strong style={{ color: "rgb(255, 175, 103)" }}>{isNaN(item.finaCowCompound) ? '0.00' : Number(item.finaCowCompound)>999999?<>999999+</>:item.finaCowCompound}%</strong>
                                              })
                                            }
                                      </>:<>
                                        {
                                          (Number(pool.data[0].fixedPeriod)===0&&Number(pool.data[0].unlockPeriod)>0)?<>
                                          {
                                            allHuoApysDing.map(item=>{
                                              return <strong style={{ color: "rgb(255, 175, 103)" }}>{isNaN(item.finaCowCompound) ? '0.00' : Number(item.finaCowCompound)>999999?<>999999+</>:item.finaCowCompound}%</strong>
                                            })
                                          }
                                        </>:<>{
                                            allHuoApys.map((item,index)=>{
                                              if(index===timeTab){
                                                return <strong style={{ color: "rgb(255, 175, 103)" }}>{Number(item.finaCowCompound)>999999?<>999999+</>:Number(item.finaCowCompound).toFixed(2)}%</strong>
                                              }
                                            })
                                          }
                                        </>
                                        }
                                      </>
                                    }
                                    {/* <strong style={{ color: "rgb(255, 175, 103)" }}>{isNaN(finaCowCompound) ? '0.00' : Number(finaCowCompound)>999999?<>999999+</>:finaCowCompound}%</strong> */}
                                  </div>
                                  <div className="question__item-text_staking" style={{ display: 'block', marginTop: '10px', color: "#131D32" }}>
                                  {t('deposit-apy')}
                                  </div>
                                </div>
                              } />
                        }</div>
                        {
                          pool.data.length===1?<div>{Number(cowApyViewDing)>999999?<>999999+</>:Number(cowApyViewDing).toFixed(2)}%</div>:<>
                            {
                              (Number(pool.data[0].fixedPeriod)===0&&Number(pool.data[0].unlockPeriod)>0)?
                              <div>{Number(cowApyViewDing)>999999?<>999999+</>:Number(cowApyViewDing).toFixed(2)}%</div>:
                              <>
                                {
                                  allHuoApys.map((item,index)=>{
                                    if(index===timeTab){
                                      return <div>{Number(item.allApy)>999999?<>999999+</>:Number(item.allApy).toFixed(2)}%</div>
                                    }
                                  })
                                }
                              </>
                            }
                          </>
                        }
                      </div>
                      <div className='staking__dialog__progress-bar'>
                        <div className='staking__progress-bar'>
                          <div><img src={require('../../../images/ic_lock.svg')} alt=""/></div>
                          <div></div>
                          {
                            (Number(pool.data[timeTab].fixedPeriod)===0&&Number(pool.data[timeTab].unlockPeriod)>0)?<div><img src={require('../../../images/ic_lock.svg')} alt=""/></div>:
                            <div><img src={require('../../../images/ic_unlock.svg')} alt=""/></div>
                          }

                          <div></div>
                          {
                            (Number(pool.data[timeTab].fixedPeriod)===0&&Number(pool.data[timeTab].unlockPeriod)>0)?<div><img src={require('../../../images/ic_unlock.svg')} alt=""/></div>:
                            <div><img src={require('../../../images/ic_lock.svg')} alt=""/></div>
                          }
                        </div>
                      </div>
                      {
                        (Number(pool.data[timeTab].fixedPeriod)===0&&Number(pool.data[timeTab].unlockPeriod)>0)?<div className='staking__dialog__day'>
                        <div>{getTime(timestamp)}</div>
                        <div>{t('deposit-huo-4')}</div>
                        <div>{Math.floor((Number(pool.data[timeTab].unlockPeriod)/28800)*100)/100}{t('staking-deposit-time')}{t('deposit-huo-3')}</div>
                      </div>:<div className='staking__dialog__day'>
                        <div>{getTime(timestamp)}</div>
                        <div>{getTime(allTime)}</div>
                        <div>{getTime(xuTimes)}</div>
                      </div>
                      }
                      {
                        (Number(pool.data[timeTab].fixedPeriod)===0&&Number(pool.data[timeTab].unlockPeriod)>0)?<div className='staking__progress-bar-title'>
                            <div>{t('staking-deposit-title1')}</div>
                            <div className='staking_lists-jie'>
                              <div>{t('deposit-huo-suo')}</div>
                              <SectionTwoXuss value={
                                <div>
                                  {/* <div style={{color:'#8391A8'}}>{t('deposit-huo-7')}</div> */}
                                  <div style={{color:'#8391A8'}}>{t('Staking_huo_tip')}</div>
                                  <div style={{color:'#8391A8'}}>{t('Staking_huo_tip1')}</div>
                                </div>
                              } />
                            </div>
                            <div className='staking_lists'>
                              <div>{t('Total-title')}</div>
                              {/* <SectionTwoXus value={
                                <div  style={{color:'#8391A8'}}>
                                  <div>1.{t('deposit-huo-8')}</div>
                                  <div>2.{t('Staking_ding_tiqu')}{pool.data[0].unlockPeriod/28800}{t('Staking_ding_tiqu1')} </div>
                                </div>
                              } /> */}
                            </div>
                        </div>:<div className='staking__progress-bar-title'>
                            <div>{t('staking-deposit-title1')}</div>
                            <div className='staking_lists'>
                              <div>{t('staking-deposit-title2')}</div>
                              <SectionTwoXu value={
                                  <div>
                                    <div style={{color:'#8391A8'}}>{t('deposit-expire-1')}{getTime(allTime)}，{t('deposit-expire-2')}；</div>
                                  </div>
                                } />
                            </div>
                            <div className='staking_lists'>
                              <div>{t('staking-deposit-title3')}</div>
                              <SectionTwoXus value={
                                <div  style={{color:'#8391A8'}}>
                                  <div>1.{t('deposit-renew-1')}{getTime(xuTimes)}，{t('deposit-expire-2')}；</div>
                                  <div>2.{t('Staking_huo_xu')}{Math.floor(Number(pool.data[timeTab].extendPeriod/28800)*100)/100}{t('Staking_huo_xu1')}</div>
                                  <div>3.{t('Staking_huo_xu2')}{Math.floor(Number(pool.data[timeTab].extendPeriod/28800)*100)/100}{t('Staking_huo_xu3')}</div>
                                </div>
                              } />
                            </div>
                        </div>
                      }
                    </div>
                  </div>
                  <div className={(allHuoApys.length>2&&isH5==='h5')?`staking__dialog__bottons`:`staking__dialog__botton`}>
                    <li className='staking__dialog__survivable'>
                      <div>{t('Vault-SYDeposited')}({pool.id})</div>
                      <div>{remainLock==='0.00'?'MAX':remainLock.toFixed(2)}</div>
                    </li>
                    <li className='staking__dialog__balance'>
                      <div>{t('Vault-Balance')}({pool.id})</div>
                      <div>{String(balanceSingle.toFormat(5)).slice(0, -1)}</div>
                    </li>
                    <li className={`pools__dialog__input pools__dialog__input-staking`}>
                      <input
                        placeholder={t('v2-ui-11')}
                        value={depositedBalance[index] != undefined ? depositedBalance[index] : ''}
                        onChange={changeDetailInputValue.bind(this, 'depositedBalance', index, balanceSingle.toNumber(), pool.data[timeTab].tokenDecimals)}
                      />
                      {/* 最大 全部存入按钮 */}
                      {
                        pool.data[timeTab].allowance == 0? <Button
                        className="pools__dialog__deposit-all"
                        type={2}
                        loading={shouQuan}
                        disabled={shouQuan}
                        onClick={onApproval.bind(this, pool.data[timeTab], index)}
                      >
                        {shouQuan ? '' : t('Vault-ApproveButton')}
                      </Button>:<Button
                        className="pools__dialog__deposit-all"
                        onClick={onDeposit.bind(this, pool.data[timeTab], index, true, balanceSingle,'huoCun')}
                        loading={isHuoCun}
                        type={2}
                        disabled={
                          !balanceSingle.toNumber() || fetchDepositPending[index] || (isMoreDepostLimit(balanceSingle.toNumber(), pool.data[timeTab].depostLimit))
                        }
                      >
                        {
                          isHuoCun?null:<>{t('v2-ui-12')}</>
                        }

                      </Button>
                      }


                    </li>
                    <li className='staking__dialog__top'>
                      {
                        (Number(pool.data[timeTab].fixedPeriod)===0&&Number(pool.data[timeTab].unlockPeriod)>0)?<div>{t('deposit-huo-10')}</div>:
                        <div>{t('deposit-huo-6')}</div>
                      }

                    </li>
                    <li className='staking__dialog__btn'>
                      {
                        pool.data[timeTab].allowance == 0?<Button
                        className="pools__dialog__option"
                        loading={shouQuan}
                        disabled={shouQuan}
                        onClick={onApproval.bind(this, pool.data[timeTab], index)}
                        >{shouQuan ? '' : t('Vault-ApproveButton')}</Button>:
                        <Button
                        className="pools__dialog__option"
                        onClick={onDeposit.bind(this, pool.data[timeTab], index, false, balanceSingle,'huoAll')}
                        loading={isHuoAll}
                        disabled={
                          !Number(String((depositedBalance[index])).replace(',', '')) || fetchDepositPending[index] || isMoreDepostLimit(new BigNumber(depositedBalance[index]).toNumber(), pool.data[timeTab].depostLimit)
                        }
                        >
                          {
                            isHuoAll?null:<>{t("Vault-DepositButton")}</>
                          }
                        </Button>
                      }
                    </li>
                  </div>
                </div>
                }
                {
                  pool.dialog.depositOpened&& <div className='staking_dialog-strategy'>
                  <div className={`staking__dialog__header staking_color`}>
                    {t('staking-tab1-btn1')}
                    <span className={`staking__dialog__header__close staking_close`} onClick={() => closeDialogHandler('deposit', index)}></span>
                  </div>
                  <div className='staking_strategy_name'>
                    <div className='staking__dialog__name-li'>
                      {
                        celueData.data.data!=null&&<>
                          <img
                        className="pools__coin-logo"
                        alt={celueData.data.data.symbol}
                        src={require(`../../../images/icon2/${celueData.data.data.symbol}.svg`)}
                      />
                      <div className={`staking__name_title staking__name_title-1`}>{celueData.data.data.symbol}</div>
                        </>
                      }

                    </div>
                    <div className='staking_strategy_name-li'>
                      <div>{yi}</div>
                      <div>{t('Position-17')}({yi===undefined?1:celueData.data.data.symbol})</div>
                    </div>
                  </div>
                  {
                    (Number(pool.data[timeTab].fixedPeriod)===0&&Number(pool.data[timeTab].unlockPeriod)>0)?
                    (pool.data[0].name==='ATOM')?<div className='staking_ce_tip'>{t('Ce-ATOM')}</div>:
                    (pool.data[0].name==='KSM')?<div className='staking_ce_tip'>{t('Ce-KSM')}</div>:
                    (pool.data[0].name==='ADA')?<div className='staking_ce_tip'>{t('Ce-ADA')}</div>:
                    <div className='staking_ce_tip'>{t('Ce-ding')}</div>:
                    <div className='staking_ce_tip'>{t('Ce-fil')}</div>
                  }

                  <div className={celueData.data.data.stakingMiner.length>0?`staking_strategy_content`:`staking_strategy_contents`}>
                    {
                      (Number(pool.data[timeTab].fixedPeriod)===0&&Number(pool.data[timeTab].unlockPeriod)>0)?<>
                      {
                        celueData.data.data!=null&&celueData.data.data.stakingMiner.map(item=>{
                          return  <div className='staking_strategy_content-lis'>
                          <div>
                            <div className='strategy_li-one' onClick={()=>window.open(`${item.nameUrl}`)}><img style={{marginRight:"5px"}} src={require('../../../images/ic_jd.svg')} alt=""/><div className='optim_name'>{item.name}</div><img style={{marginLeft:"5px"}} src={require('../../../images/ic_jd_into.svg')} alt=""/></div>
                            <div className='strategy_li-one_content'>
                              <div className='strategy_li-two'>
                                <div>{t('Staking-strategy1')}({celueData.data.data.symbol})</div>
                                <div>{formatThousands(Number(item.pledge).toFixed(2))}</div>
                              </div>
                              <div className='strategy_li-three'>
                                <div>{t('Staking-strategy2')}</div>
                                <div>{formatThousands(Number(item.proportion).toFixed(2))}%</div>
                              </div>
                              <div className='strategy_li-four'>
                                {
                                  pool.data[0].name==='ADA'?<div>{t("Staking-strategy11-s")}</div>:<div>{t("Staking-strategy11")}</div>
                                }
                                <div  onClick={()=>window.open(`${item.pledgeAddressUrl}`)}>{item.pledgeAddress}</div>
                              </div>
                              <div className='strategy_li-five'></div>
                              <div className='strategy_li-six'>
                                <div>{t('Staking-strategy12')}</div>
                                <div>{formatThousands(Number(item.pledgeTotal).toFixed(2))}</div>
                              </div>
                              {
                                pool.data[0].name==='ADA'?null:<>
                                  {
                                    pool.data[0].name==='ATOM'?null: <div className='strategy_li-seven'>
                                      <div>{t('Staking-strategy13')}</div>
                                      <div>{item.countNominators}</div>
                                    </div>
                                  }
                                </>
                              }
                              <div className='strategy_li-eight'>
                                <div>{t('Staking-strategy14')}</div>
                                <div>{formatThousands(Number(item.pledgeShare).toFixed(2))}%</div>
                              </div>
                              {
                                pool.data[0].name==='ADA'?<div className='strategy_li-nine'>
                                  <div>{t('Staking-strategy15')}</div>
                                  <div>{formatThousands(Number(item.minerEfficiency24H).toFixed(2))}%</div>
                                </div>:null
                              }
                              <div className='strategy_li-ten'>
                                <div>{t('Staking-strategy8')}</div>
                                {/* <div>{item.status==='0'?`{t('Staking-strategy9)}`:`{t('Staking-strategy9)}`}</div> */}
                                <div><img
                                    className="pools__coin-logo-ces"
                                    alt=''
                                    src={require(`../../../images/normal.svg`)}
                                  />{t('Staking-strategy9')}</div>
                              </div>
                            </div>
                          </div>
                        </div>
                        })
                      }
                    </>:<>
                        {
                          celueData.data.data!=null&&celueData.data.data.stakingMiner.map(item=>{
                            return  <div className='staking_strategy_content-li'>
                            <div>
                              <div className='strategy_li-one' onClick={()=>window.open(`${item.nameUrl}`)}><img style={{marginRight:"5px"}} src={require('../../../images/ic_jd.svg')} alt=""/><div className='optim_name'>{item.name}</div><img style={{marginLeft:"5px"}} src={require('../../../images/ic_jd_into.svg')} alt=""/></div>
                              <div className='strategy_li-one_content'>
                                <div className='strategy_li-two'>
                                  <div>{t('Staking-strategy1')}({celueData.data.data.symbol})</div>
                                  <div>{formatThousands(Number(item.pledge).toFixed(2))}</div>
                                </div>
                                <div className='strategy_li-three'>
                                  <div>{t('Staking-strategy2')}</div>
                                  <div>{item.proportion}%</div>
                                </div>
                                <div className='strategy_li-four'>
                                  <div>{t('Staking-strategy3')}</div>
                                  <div  onClick={()=>window.open(`${item.addressUrl}`)}>{item.address}</div>
                                </div>
                                <div className='strategy_li-five'></div>
                                <div className='strategy_li-six'>
                                  <div>{t('Staking-strategy4')}</div>
                                  <div>{item.hashRank}</div>
                                </div>
                                <div className='strategy_li-seven'>
                                  <div>{t('Staking-strategy5')}</div>
                                  <div>{item.hashProportion}%</div>
                                </div>
                                <div className='strategy_li-eight'>
                                  <div>{t('Staking-strategy6')}</div>
                                  <div>{formatThousands(Number(item.HashRateValid).toFixed(2))} {item.HashRateValidUnit}</div>
                                </div>
                                <div className='strategy_li-nine'>
                                  <div>{t('Staking-strategy7')}(24h)</div>
                                  <div>{formatThousands(Number(item.minerEfficiency24H).toFixed(2))} {item.MinerEfficiency24HUnit}</div>
                                </div>
                                <div className='strategy_li-ten'>
                                  <div>{t('Staking-strategy8')}</div>
                                  {/* <div>{item.status==='0'?`{t('Staking-strategy9)}`:`{t('Staking-strategy9)}`}</div> */}
                                  <div><img
                                      className="pools__coin-logo-ces"
                                      alt=''
                                      src={require(`../../../images/normal.svg`)}
                                    />{t('Staking-strategy9')}</div>
                                </div>
                              </div>
                            </div>
                          </div>
                          })
                        }
                      </>
                    }
                  </div>
                </div>
                }
                {
                  isDia&& <div className='pools__dialog' style={{zIndex:'4000'}}>
                    <div className='pools__dialog-inners pools__dialog-deposite'>
                      <div className='pools__dialog__header'>{t('Tips')}<span className='pools__dialog__header__closeIsDia' onClick={()=>setIsDia(false)}></span></div>
                      <div className='pools__dialog__fields'>
                        <div style={{textAlign:'center'}}>{t('Tips-content')}</div>
                        <div style={{marginTop:'30px'}} className="pools__button-group">
                            <Button
                              className="pools__dialog__option"
                              type={2}
                              onClick={()=>setIsDia(false)}
                            >
                              {t('Tips-button')}
                            </Button>
                            <Button
                              className="pools__dialog__option"
                                  loading={fetchApprovalPending[index]}
                                  disabled={fetchApprovalPending[index]}
                                  onClick={onApproval.bind(this, pool.data[0], index)}
                            >
                                  {fetchApprovalPending[index] ? '' : t('Vault-ApproveButton')}
                            </Button>
                        </div>
                      </div>
                    </div>
                  </div>
                }
                {
                  isDiaS&& <div className='pools__dialog' style={{zIndex:'4000'}}>
                    <div className='pools__dialog-inners pools__dialog-deposite'>
                      <div className='pools__dialog__header'>{t('Tips')}<span className='pools__dialog__header__closeIsDiaS' onClick={()=>setIsDiaS(false)}></span></div>
                      <div className='pools__dialog__fields'>
                        <div className='money_content'>
                          <div>{t('Dis-1')} {pool.allowance/1e18}，{t('Dis-2')}</div>
                          <div>
                            <p>1、{t('Dis-3')}</p>
                            <p>2、{t('Dis-4')}</p>
                          </div>
                        </div>
                        <div style={{marginTop:'30px'}} className="pools__button-group">
                            <Button
                              className="pools__dialog__option"
                              type={2}
                              onClick={()=>setIsDiaS(false)}
                            >
                              {t('Tips-button')}
                            </Button>
                            <Button
                              className="pools__dialog__option"
                                  loading={fetchApprovalPending[index]}
                                  disabled={fetchApprovalPending[index]}
                                  onClick={onApproval.bind(this, pool.data[0], index)}
                            >
                                  {fetchApprovalPending[index] ? '' : t('Vault-ApproveButton')}
                            </Button>
                        </div>
                      </div>
                    </div>
                  </div>
                }
              </div>:null
            }
          </div>
        </div>
    )
  })
  // cangState==='1'||cangType==="1"   stakingData
  // cangState==='2'||cangType==="1"   profitState
  // cangState==='3'||cangType==="1"   expireState
  // cangState==='4'||cangType==="1"   unlockState

  // cangState==='1'||cangType==="2"   dingList
  // cangState==='2'||cangType==="2"   profitDingState
  // cangState==='3'||cangType==="2"   expireDingState
  // cangState==='4'||cangType==="2"   unlockDingState

  // cangState==='1'||cangType==="3"   huoList
  // cangState==='2'||cangType==="3"   profitHuoState
  // cangState==='3'||cangType==="3"   expireHuoState
  // cangState==='4'||cangType==="3"   unlockHuoState


  const poolsMaps = ((cangState==='1'&&cangType==="1")?stakingData:
    (cangState==='2'&&cangType==="1")?profitState:
    (cangState==='3'&&cangType==="1")?expireState:
    (cangState==='4'&&cangType==="1" )?unlockState:
    (cangState==='1'&&cangType==="2" )?dingList:
    (cangState==='2'&&cangType==="2")?profitDingState:
    (cangState==='3'&&cangType==="2" )?expireDingState:
    (cangState==='4'&&cangType==="2")?unlockDingState:
    (cangState==='1'&&cangType==="3" )?huoList:
    (cangState==='2'&&cangType==="3" )?profitHuoState:
    (cangState==='3'&&cangType==="3" )?expireHuoState:
    (cangState==='4'&&cangType==="3")?unlockHuoState:null
  ).map((pool, index) => {
    // console.log(Math.ceil((Number(pool.depositBlockNum) + Number(pool.fixedPeriod) + Number(pool.extendPeriod) - Number(pool.blockNumber))))
    // if(index===0){
      // console.log(pool,pool.extend)
    //   console.log(String((pool.cowReward / 1e18).toFixed(5)).slice(0, -1))
    // }
    // 已获益
    const finaCompoundEarned = forMat(pool.cowReward);
    // 已存入
    const deposite = byDecimals(pool.amount, pool.tokenDecimals).toNumber();
    //剩余天数
    const surplusTime = ((Number(pool.depositBlockNum)+Number(pool.fixedPeriod)-Number(pool.blockNumber))*3/3600)
    let proportion =null
    if(pool.extend==='1'){
      proportion = Math.ceil(Number(pool.depositBlockNum) + Number(pool.fixedPeriod) + Number(pool.extendPeriod) - Number(pool.blockNumber))/pool.extendPeriod*100
    }else if(pool.extend==='0'||pool.extend==='2'){
      proportion =( Math.ceil(surplusTime<0?0:surplusTime)/3*3600)/pool.fixedPeriod*100
    }else if(pool.extend==='3'){
      proportion =((Number(pool.expireBlockNum)-Number(pool.blockNumber))/pool.unlockPeriod)*100
    }
    //获取当前时间
    const timestamp = Date.parse(new Date());
    //到期时间
    const expireTime = ((Number(pool.depositBlockNum)+Number(pool.fixedPeriod)-Number(pool.blockNumber))/28800)*24*60*60*1000
    let allTime = timestamp+expireTime
    //提取到期时间
    const extractExpireTime = ((Number(pool.blockNumber)-Number(pool.depositBlockNum))/28800)*24*60*60*1000
    let extractAllTime = timestamp-extractExpireTime
    //活期存入时间
    let dingCun =timestamp- (((Number(pool.blockNumber)-Number(pool.depositBlockNum))/28800)*24*60*60*1000)
    // let dingDao=timestamp-((Number(pool.blockNumber)-(Number(pool.depositBlockNum)+Number(pool.fixedPeriod)))*3)
    //提取续存时间
    const depositExpireTime = ((Number(pool.depositBlockNum)+Number(pool.fixedPeriod)-Number(pool.blockNumber)+Number(pool.extendPeriod))/28800)*24*60*60*1000
    let depositAllTime = timestamp+depositExpireTime
    //申请时间
    let shenTime = timestamp-(((pool.blockNumber - pool.withdrawBlockNum) / 28800)*24*60*60*1000)
    let daoTime = null
    if(pool.extend==='3'||pool.extend==='4'){
      daoTime = ((Number(pool.expireBlockNum)-Number(pool.blockNumber))/28800)*24*60*60*1000
    }
    let huoJie =Math.ceil ((Number(pool.expireBlockNum)-Number(pool.blockNumber))/28800)
    // else if(pool.extend==='4'){
    //   daoTime = ((Number(pool.blockNumber)-Number(pool.expireBlockNum))/28800)*24*60*60*1000
    // }
    // console.log(Number(pool.depositBlockNum) , Number(pool.fixedPeriod) , Number(pool.extendPeriod) , Number(pool.blockNumber))
    // console.log(Math.ceil((Number(pool.depositBlockNum) + Number(pool.fixedPeriod) + Number(pool.extendPeriod) - Number(pool.blockNumber))/28800))
    let daoTims = timestamp+daoTime
    const rateMdx = poolRate[pool.tokenAddress];
    const mdxApy = (pool.mdxApy / (100 * rateMdx)) / (10 ** (18 - pool.tokenDecimals));
    // 计算cow与质押币种的汇率
    const cow2Token = cow2Usdt / coinRate[pool.tokenAddress]
    const cowApy = (pool.cowApy * cow2Token / (100)) / (10 ** (18 - pool.tokenDecimals));
    const depositedMdxApy = forMat(mdxApy);//mdx单利
    const depositedCowApy = forMat(cowApy);
    // COW 复利
    let finaCowCompound;
    if (getApys > 0) {
      const depositedCompound = getApys / 100;
      const xCompound = Math.pow((1 + (depositedCompound / 100) / 105120), 105120);
      // console.log(cowApy,depositedCompound,xCompound,cowApy)
      finaCowCompound = forMat(((((cowApy / 100) / (depositedCompound / 100)) * (xCompound - 1)) * 100) - cowApy);
    } else {
      finaCowCompound = 0
    }
    const cowApyView = ((isNaN(pool.apy)?'0':pool.apy/100) * 1  + (isNaN(depositedCowApy)?'0':depositedCowApy) * 1 + (isNaN(finaCowCompound)?'0':finaCowCompound) * 1).toFixed(2);

    return (
        <div className='staking_li_content-two'>
          <div>
            <div className={`staking_li_content-title-two ${theme}_staking_li_content-title-two`}>
              <div className='staking_title-two-top'>
                <div className='staking_title-li-two'>
                  <img
                    className="pools__coin-logo-tow"
                    alt={pool.name}
                    src={require(`../../../images/icon2/${pool.name}.svg`)}
                  />
                  <div className='staking_two_name'>{pool.name}</div>
                  {
                    (Number(pool.fixedPeriod)===0&&Number(pool.unlockPeriod)>0)?<div className='staking_biao'>{t('Cang_dao_title')}</div>:
                    <div className='staking_biao'>{Math.floor((Number(pool.fixedPeriod)/28800)*100)/100}{t('staking-deposit-time')}</div>
                  }
                  {
                    (Number(pool.fixedPeriod)===0&&Number(pool.unlockPeriod)>0)?<>
                      {
                        pool.extend==='0'&&<div className='staking_biao'>{t('Cang-tip')}</div>
                      }
                      {
                        pool.extend==='3'&&<div className='staking_biao'>{t('Cang-jie')}</div>
                      }
                      {
                        pool.extend==='4'&&<div className='staking_biao'>{t('Cang-dao')}</div>
                      }
                    </>:<>
                      {
                        pool.extend==='0'&&<div className='staking_biao'>{t('Cang-tip')}</div>
                      }
                      {
                        pool.extend==='1'&&<div className='staking_biao'>{t('Cang-dao')}</div>
                      }
                      {
                        pool.extend==='2'&&<><div className='staking_biao'>{t('staking-deposit-title3')}</div><div className='staking_biao'>{t('Cang-tip')}</div></>
                      }
                    </>
                  }
                  {/* {
                    pool.extend==='2'&&<div className='staking_top_three'>{t('staking-deposit-title3')}</div>
                  }
                  {
                    (Number(pool.fixedPeriod)===0&&Number(pool.unlockPeriod)>0)?
                    (pool.extend==='3')?<><div className='staking_biao'>{t('Cang_dao_title')}</div><div className='staking_biao'>{t('Cang-jie')}</div></>:
                    (pool.extend==='4')?<><div className='staking_biao'>{t('Cang_dao_title')}</div><div className='staking_biao'>{t('Cang-dao')}</div></>:
                    <><div className='staking_biao'>{t('Cang_dao_title')}</div><div className='staking_biao'>{t('Cang-tip')}</div></>:
                    <div className='staking_biao'>{Number(pool.fixedPeriod)/28800}{t('staking-deposit-time')}</div>
                  }
                  {
                    (Number(pool.fixedPeriod)===0&&Number(pool.unlockPeriod)>0)?null:
                    (pool.extend==='3'|| pool.extend==='4')?null:pool.extend==='2'?<div className='staking_biao'>{t('Cang-dao')}</div>:<div className='staking_biao'>{t('Cang-tip')}</div>
                  } */}
                </div>
              </div>
              <div className='staking_title-two-bottom'>
                <div className='progress_bar'>
                  {/* {
                    (Number(pool.fixedPeriod)===0&&Number(pool.unlockPeriod)>0)?<div>{t('Position-1')}</div>:
                    pool.extend==='2'? <div>{t('Cang_title_xu')}</div>: <div>{t('Position-1')}</div>
                  } */}
                  {
                    (Number(pool.fixedPeriod)===0&&Number(pool.unlockPeriod)>0)?<div>{t('Position-1')}</div>:
                   <>
                    {
                      (pool.extend==='0'||pool.extend==='2')&&<div>{t('Position-1')}</div>
                    }
                    {
                      pool.extend==='1'&&<div>{t('Cang_title_xu')}</div>
                    }
                   </>
                  }
                  <div>
                    <div style={{
                      width: `${100-proportion}%`,
                      height: '4px',
                      background: ' linear-gradient(270deg, #5EB0FF 0%, #3078FF 100%)',
                      borderRadius: '2px',
                    }}></div>
                  </div>
                </div>
                {
                (Number(pool.fixedPeriod)===0&&Number(pool.unlockPeriod)>0)?
                  <div className='days_remaining'>
                    {
                      pool.extend==='3'?<><span className={huoJie<=3?`hot`:null}>{huoJie}</span><span className='blue_title'>{t('staking-deposit-time')}</span></>:
                      <><span className={huoJie<=3?`hot`:null}>0</span><span className='hot_title'>{t('staking-deposit-time')}</span></>
                    }
                  </div>:
                  <div className='days_remaining'>
                    {
                      pool.extend==='1'&&<span className={Math.ceil((Number(pool.depositBlockNum) + Number(pool.fixedPeriod) + Number(pool.extendPeriod) - Number(pool.blockNumber))/28800)<=3?`hot`:null}>
                        {Math.ceil((Number(pool.depositBlockNum) + Number(pool.fixedPeriod) + Number(pool.extendPeriod) - Number(pool.blockNumber))/28800)}
                      </span>
                    }

                    {
                      (pool.extend==='0'||pool.extend==='2')&&<span className={Math.ceil(surplusTime*3600/3/28800)<=3?`hot`:null}>{Math.ceil(surplusTime*3600/3/28800)}</span>
                    }
                    <span className={Math.ceil(surplusTime*3600/3/28800)<=3?`hot_title`:`blue_title`}>{t('staking-deposit-time')}</span>
                  </div>
                }
              </div>
            </div>
            <div className='staking_content-two'>
              <div className='staking_two-deposits'>
                <div className='staking_two-deposits_content'>
                  <div>{t('Position-2')}</div>
                  {
                      (Number(pool.fixedPeriod)===0&&Number(pool.unlockPeriod)>0)?(pool.extend==='3'|| pool.extend==='4')&&<SectionStakings value={
                        <div className='tip_staking_two'>
                          <div>{t('Cang_huo_dao')}</div>
                          <div>{t('Cang_huo_dao1')}{Number(pool.expireBlockNum)}({t('Position-5')} {getTimes(daoTims)})</div>
                        </div>
                      } />:<SectionTwoTip value={
                        <div className='tip_staking_two'>
                          <div>{t('Position-3')}</div>
                          <div>
                            {t('Position-4')}{Number(pool.depositBlockNum)+Number(pool.fixedPeriod)}({t('Position-5')} {getTimes(allTime)})
                          </div>
                        </div>
                      } />
                  }
                </div>
                <div>{(Number(pool.fixedPeriod)===0&&Number(pool.unlockPeriod)>0)?(pool.extend==='3'|| pool.extend==='4')?getTime(daoTims):<>{t('Cang_dao_title')}</>:getTime(allTime)}</div>
              </div>
              {
                (pool.extend==='3')?<>
                  <div className='staking_two-deposit'>
                    <div>{t('Cang_huo_jie')}</div>
                    <div>{getTime(shenTime)}</div>
                  </div>
                  <div className='staking_two-slide'></div>
                  <div className='staking_two-profit'>
                    <div>{t('Cang_huo_jie1')}({pool.name})</div>
                    {
                      pool.extend==='3'?<div>{((pool.amount/`1e${pool.tokenDecimals}`).toFixed(4))}</div>:<div>0</div>
                    }
                  </div>
                  <div className='staking_two-profit-new'>
                    <div>{t('Vault-WithdrawButtonAll')}({pool.name})</div>
                    {
                      pool.extend==='4'?<div>{pool.amount/`1e${pool.tokenDecimals}`}</div>:<div>0</div>
                    }
                  </div>
                </>:
                (pool.extend==='4')?<>
                <div className='staking_two-deposit'>
                  <div>{t('Cang_huo_dao_title')}</div>
                  <div>{getTime(shenTime)}</div>
                </div>
                <div className='staking_two-slide'></div>
                <div className='staking_two-profit'>
                  <div>{t('Cang-jie')}</div>
                  {
                    pool.extend==='3'?<div>{((pool.amount/`1e${pool.tokenDecimals}`))}</div>:<div>0</div>
                  }
                </div>
                <div className='staking_two-profit-new'>
                  <div>{t('Cang_huo_dao_title1')}</div>
                  {
                    pool.extend==='4'?<div>{pool.amount/`1e${pool.tokenDecimals}`}</div>:<div>0</div>
                  }
                </div>
              </>:
                <>
                  <div className='staking_two-deposit'>
                    <div>{t('v2-ui-14')}({pool.name})</div>
                    <div>{String(deposite.toFixed(5)).slice(0, -1)}</div>
                  </div>
                  <div className='staking_two-slide'></div>
                  <div className='staking_two-profit'>
                    <div>{t('Position-6')}(COW)</div>
                    <div>{String((pool.cowReward / 1e18).toFixed(5)).slice(0, -1)}</div>
                  </div>
                  <div className='staking_two-profit-new'>
                    <div>{t('Position-6')}({pool.name})</div>
                    <div>{String((pool.reward / 1e18).toFixed(5)).slice(0, -1)}</div>
                  </div>
                </>
              }

              <div className='staking_two-btn'>
                {
                  (pool.extend==='3'|| pool.extend==='4')?<div className="pools__button-group staking_two-btn-group">
                  <Button
                    className={`pools__dialog__option staking_two_btns ${pool.extend==='3'&&`btn_hui`}`}
                    type={2}
                    loading={pool.extend==='3'?null:(isDingTiQu&&index===isTiQuId)}
                    disabled={pool.extend==='3'}
                    onClick={onWithdraws.bind(this, pool, index, true, deposite, false,'DingTiQu')}
                  >
                    {
                      pool.extend==='3'?<>{t("v2-ui-10")}</>:<>{
                        (isDingTiQu&&index===isTiQuId)?null:<>{t("v2-ui-10")}</>
                      }</>
                    }
                  </Button>
                </div> :<div className="pools__button-group staking_two-btn-group">
                  {
                    (Number(pool.fixedPeriod)===0&&Number(pool.unlockPeriod)>0)?<Button
                    className={`pools__dialog__option staking_two_btn`}
                    type={2}
                    loading={pool.dialog.principalOpened}
                    onClick={() => openDialogHandler('principal', index)}
                  >
                    {pool.dialog.principalOpened?null:<>{t('Position-ben')}</>}
                  </Button>:<Button
                    className={`pools__dialog__option staking_two_btn`}
                    type={2}
                    loading={pool.dialog.principalOpened}
                    onClick={() => openDialogHandler('principal', index)}
                  >
                    {pool.dialog.principalOpened?null:<>{t('Position-7')}</>}
                  </Button>
                  }

                  <Button
                    className={`pools__dialog__option staking_two_btn`}
                    loading={pool.dialog.tiQu}
                    disabled={pool.dialog.tiQu}
                    // onClick={() => openDialogHandler('profit', index)}

                    onClick={onWithdraw.bind(this, pool, index, false, deposite, true,'0','tiQu')}
                  >
                    {pool.dialog.tiQu?null:t("Vault-WithdrawIncome")}
                  </Button>
                </div>
                }

              </div>
              <div className='staking_two_id'>{(Number(pool.fixedPeriod)===0&&Number(pool.unlockPeriod)>0)?<>{`#${pool.name}`}-{pool.tokenId}</>:<>{`#${pool.name}`}-{Math.floor((Number(pool.fixedPeriod)/28800)*100)/100}-{pool.tokenId}</>}</div>
              <div className="pools__mao-logo__wraps">
                <img
                  className="pools__mao-logos"
                  alt={pool.id}
                  src={require(`../../../images/icon2/${pool.id}.svg`)}
                />
              </div>
            </div>
            {
              pool.dialog.opened? <div className='pools__dialog'>
                {
                  pool.dialog.principalOpened&&<div className='staking_dialog-extract'>
                  <div className='staking_extract_top'>
                    <div className="staking__dialog__header">
                      {
                        (Number(pool.fixedPeriod)===0&&Number(pool.unlockPeriod)>0)?`${t('deposit-huo-5')}`:`${t('v2-ui-10')}`
                      }
                      <span className="staking__dialog__header__close" onClick={() => closeDialogHandler('principal', index)}></span>
                    </div>
                    <div className='staking_extract_name'>
                      <div>
                        <div className='staking__dialog__name-lis'>
                          <img
                            className="pools__coin-logo-right"
                            alt={pool.name}
                            src={require(`../../../images/icon2/${pool.name}.svg`)}
                          />
                          <div className='staking__name_title'>{pool.name}</div>
                          {/* {
                            (Number(pool.fixedPeriod)===0&&Number(pool.unlockPeriod)>0)?<div className='three_day'>{t('Cang_dao_title')}</div>:
                            <div className='three_day'>{(pool.fixedPeriod/28800)}{t('staking-deposit-time')}</div>
                          } */}

                          {/* {
                            (pool.extend==='0'||pool.extend==='1')?null:<div className='day_btn'>{t('staking-deposit-title3')}</div>
                          } */}
                           {
                            (Number(pool.fixedPeriod)===0&&Number(pool.unlockPeriod)>0)?<div className='three_day'>{t('Cang_dao_title')}</div>:
                            <div className='three_day'>{Math.floor((Number(pool.fixedPeriod)/28800)*100)/100}{t('staking-deposit-time')}</div>
                          }
                          {
                            (Number(pool.fixedPeriod)===0&&Number(pool.unlockPeriod)>0)?<>
                              {
                                pool.extend==='0'&&<div className='three_day'>{t('Cang-tip')}</div>
                              }
                              {
                                pool.extend==='3'&&<div className='three_day'>{t('Cang-jie')}</div>
                              }
                              {
                                pool.extend==='4'&&<div className='three_day'>{t('Cang-dao')}</div>
                              }
                            </>:<>
                              {
                                pool.extend==='0'&&<div className='three_day'>{t('Cang-tip')}</div>
                              }
                              {
                                pool.extend==='1'&&<div className='three_day'>{t('Cang-dao')}</div>
                              }
                              {
                                pool.extend==='2'&&<><div className='three_day'>{t('staking-deposit-title3')}</div><div className='three_day'>{t('Cang-tip')}</div></>
                              }
                            </>
                          }
                        </div>
                      </div>
                      <div className='staking_extract_name-right'>
                        <div>{(Number(pool.fixedPeriod)===0&&Number(pool.unlockPeriod)>0)?<>{`#${pool.name}`}-{pool.tokenId}</>:<>{`#${pool.name}`}-{Math.floor((Number(pool.fixedPeriod)/28800)*100)/100}-{pool.tokenId}</>}</div>
                      </div>
                    </div>
                  </div>
                  <div className='staking_extract_middle'>
                    <div>
                    <div className='staking_extract_time'>
                          <div className='staking_extract_apy'>
                          <div>APY{
                            <SectionStaking value={
                              <div>
                                <div className="question__title">
                                  {t("Total-Yield")}
                                  <strong style={{ color: "rgb(255, 175, 103)" }}>{isNaN(cowApyView) ? '0.00' : cowApyView}%</strong>
                                </div>
                                <div className="question__item_Staking">
                                  <div className="ques__labe-field">
                                    <span className="ques__labe-line ques__labe-line--first"></span>
                                    <span className="ques__labe-child">{pool.id}{t("Nav-Farm")}({t('APR')})</span>
                                  </div>
                                  <strong style={{ color: "rgb(255, 175, 103)" }}>{pool.apy/100}%</strong>
                                </div>
                                <div className="question__item_Staking">
                                  <div className="ques__labe-field">
                                    <span className="ques__labe-line"></span>
                                    <span className="ques__labe-child">{pool.id}{t("Nav-Farm")}({t('APY')})</span>
                                  </div>
                                  <strong style={{ color: "rgb(255, 175, 103)" }}>0.00%</strong>
                                </div>
                                <div className="question__item_Staking">
                                  <div className="ques__labe-field">
                                    <span className="ques__labe-line"></span>
                                    <span className="ques__labe-child">COW{t("Nav-Farm")}({t('APR')})</span>
                                  </div>
                                  <strong style={{ color: "rgb(255, 175, 103)" }}>{depositedCowApy}%</strong>
                                </div>
                                <div className="question__item_Staking">
                                  <div className="ques__labe-field">
                                    <span className="ques__labe-line"></span>
                                    <span className="ques__labe-child">COW{t("Nav-Farm")}({t('APY')})</span>
                                  </div>
                                  <strong style={{ color: "rgb(255, 175, 103)" }}>{isNaN(finaCowCompound) ? '0.00' : finaCowCompound}%</strong>
                                </div>
                                <div className="question__item-text_staking" style={{ display: 'block', marginTop: '10px', color: "#131D32" }}>
                                  {t('deposit-apy')}
                                </div>
                              </div>
                            } />
                          }</div>
                          {/* <div>{pool.apy/10000*100}%</div> */}
                          <div>{isNaN(cowApyView) ? '0.00' : Number(cowApyView)>999999?<>999999+</>:cowApyView}%</div>
                          </div>
                          <div className='time_middle'>
                            <div><img src={require('../../../images/ic_lock.svg')} alt=""/></div>
                            <div></div>
                            <div><img src={require('../../../images/ic_unlock.svg')} alt=""/></div>
                            <div></div>
                            {
                              (Number(pool.fixedPeriod)===0&&Number(pool.unlockPeriod)>0)?<div><img src={require('../../../images/ic_unlock.svg')} alt=""/></div>:
                              <div><img src={require('../../../images/ic_lock.svg')} alt=""/></div>
                            }

                          </div>
                          {
                            (Number(pool.fixedPeriod)===0&&Number(pool.unlockPeriod)>0)?<>
                               <div className='time_bottoms'>
                                <div>{getTime(timestamp)}</div>
                                {/* <div>{T + {pool.unlockPeriod/28800}}</div> */}
                                <div>{getTime(timestamp+((pool.unlockPeriod/28800)*24*60*60*1000))}</div>
                                <div>{t('deposit-huo-4')}</div>
                              </div>
                              <div className='time_top'>
                                <div className='time_top-one'>
                                  <div>{t('Position-12')}</div>
                                  <SectionTwoTips value={
                                    <div>
                                      <div>{t('Jie_shen')}</div>
                                      <div>{t('Jie_shen1')}</div>
                                    </div>
                                  } />
                                </div>
                                <div className='deposit-huo-five'>{t('deposit-huo-5')}</div>
                                <div className='time_top-one'>
                                  <div>{t('v2-ui-10')}</div>
                                  {/* <SectionTwoXu value={
                                    <div>
                                      <div>1.{t('deposit-renew-1')}{getTime(depositAllTime)}{t('deposit-expire-2')}；{t('Position-15')}{Number(pool.blockNumber)+Number(pool.fixedPeriod)+Number(pool.extendPeriod)}</div>
                                      <div>2.{t('deposit-renew-2')}</div>
                                      <div>3.{t('deposit-renew-3')}</div>
                                    </div>
                                  } /> */}
                                </div>
                              </div>
                            </>:<>
                              <div className='time_bottom'>
                                <div>{getTime(dingCun)}</div>
                                <div>{getTime(allTime)}</div>
                                <div>{getTime(depositAllTime)}</div>
                              </div>
                              <div className='time_top'>
                                <div className='time_top-one'><div>{t('Vault-DepositButton')}</div> <SectionTwoTips value={
                                  <div>
                                    {/* <div>{t('Position-13')}{getTime(extractAllTime)}{t('deposit-expire-2')}；{t('Position-15')}{Number(pool.blockNumber)+Number(pool.fixedPeriod)}</div> */}
                                    <div>{t('Cang_xin_1')}</div>
                                    <div>{t('Cang_xin_2')}{Number(pool.depositBlockNum)}（{getTimes(dingCun)}）</div>
                                  </div>
                                } /></div>
                                <div className='time_top-one'>
                                  <div>{t('staking-deposit-title2')}</div>
                                  <SectionTwoTips value={
                                  <div>
                                    {/* <div>{t('Position-13')}{getTime(extractAllTime)}{t('deposit-expire-2')}；{t('Position-15')}{Number(pool.blockNumber)+Number(pool.fixedPeriod)}</div> */}
                                    <div>{t('Cang_xin_3')}</div>
                                    <div>{t('Cang_xin_4')}{Number(pool.depositBlockNum)+Number(pool.fixedPeriod)}({getTimes(allTime)})</div>
                                  </div>
                                } />
                                </div>
                                <div className='time_top-one'><div>{t('staking-deposit-title3')}</div> <SectionTwoXu value={
                                  <div>
                                    {/* <div>1.{t('deposit-renew-1')}{getTime(depositAllTime)}{t('deposit-expire-2')}；{t('Position-15')}{Number(pool.blockNumber)+Number(pool.fixedPeriod)+Number(pool.extendPeriod)}</div> */}
                                    <div>1.{t('Cang_xin_5')}{t('Cang_xin_6')}{Number(pool.depositBlockNum)+Number(pool.fixedPeriod)+Number(pool.extendPeriod)}（{getTimes(depositAllTime)}）</div>
                                    <div>2.{t('Staking_huo_xu')}{Math.floor((Number(pool.extendPeriod)/28800)*100)/100}{t('Staking_huo_xu1')}</div>
                                    <div>3.{t('Staking_huo_xu2')}{Math.floor((Number(pool.extendPeriod)/28800)*100)/100}{t('Staking_huo_xu3')}</div>
                                  </div>
                                  } />
                                </div>
                              </div>
                            </>
                          }
                    </div>
                  </div>
                  </div>
                  <div className='staking_extract_botton'>
                      <div className='staking_extract_cun'>
                        {
                          (Number(pool.fixedPeriod)===0&&Number(pool.unlockPeriod)>0)?<div>{t("Vault-ListDepositeds")}({pool.id})</div>:<div>{t("Vault-ListDeposited")}({pool.id})</div>
                        }
                        <div>{String(deposite.toFixed(5)).slice(0, -1)}</div>
                      </div>
                      {pool.token === '0xae3a768f9aB104c69A7CD6041fE16fFa235d1810' &&  <div className={`pools__dialog__input staking_extract_input pools__dialog__input-staking`}>
                        <input
                          placeholder={t('v2-ui-11')}
                          value={withdrawAmount[index] != undefined ? withdrawAmount[index] : ''}
                          onChange={changeDetailInputValue.bind(this, 'withdrawAmount', index, deposite, pool.itokenDecimals)}
                        />
                        {/* 最大 全部存入按钮 */}
                        <Button
                          className={`pools__dialog__deposit-all pools__dialog__extract-all`}
                          type={2}
                          onClick={onWithdraws.bind(this, pool, index, true, deposite, false)}
                          loading={fetchWithdrawPending[index]}
                          disabled={
                            !deposite || fetchWithdrawPending[index] || (isMoreDepostLimit(deposite, pool.depostLimit))
                          }
                        >
                          {t('Position-8')}
                        </Button>
                      </div>}
                      {
                        (Number(pool.fixedPeriod)===0&&Number(pool.unlockPeriod)>0)?<>
                          <div className={`pools__dialog__input staking_extract_input pools__dialog__input-staking`}>
                            <input
                              placeholder={t('v2-ui-11')}
                              value={withdrawAmount[index] != undefined ? withdrawAmount[index] : ''}
                              onChange={changeDetailInputValue.bind(this, 'withdrawAmount', index, deposite, pool.tokenDecimals)}
                            />
                            {/* 最大 全部存入按钮 */}
                            <Button
                              className={`pools__dialog__deposit-all pools__dialog__extract-all`}
                              type={2}
                              onClick={onWithdraws.bind(this, pool, index, true, deposite, false,'dingAllTiQu')}
                              loading={isDingAllTiQu}
                              disabled={
                                !deposite || fetchWithdrawPending[index] || (isMoreDepostLimit(deposite, pool.depostLimit))
                              }
                            >
                               {
                                isDingAllTiQu?null:<>{t('Position-8')}</>
                              }
                            </Button>
                          </div>
                        </>:(pool.extend==='0'||pool.extend==='2' || pool.token === '0xae3a768f9aB104c69A7CD6041fE16fFa235d1810')?null:<>
                          <div className={`pools__dialog__input staking_extract_input pools__dialog__input-staking`}>
                            <input
                              placeholder={t('v2-ui-11')}
                              value={withdrawAmount[index] != undefined ? withdrawAmount[index] : ''}
                              onChange={changeDetailInputValue.bind(this, 'withdrawAmount', index, deposite, pool.itokenDecimals)}
                            />
                            {/* 最大 全部存入按钮 */}
                            <Button
                              className={`pools__dialog__deposit-all pools__dialog__extract-all`}
                              type={2}
                              onClick={onWithdraws.bind(this, pool, index, true, deposite, false)}
                              loading={fetchWithdrawPending[index]}
                              disabled={
                                !deposite || fetchWithdrawPending[index] || (isMoreDepostLimit(deposite, pool.depostLimit))
                              }
                            >
                              {t('Position-8')}
                            </Button>
                          </div>
                        </>
                      }
                      {
                            ((Number(pool.fixedPeriod)===0&&Number(pool.unlockPeriod)>0) || pool.token === '0xae3a768f9aB104c69A7CD6041fE16fFa235d1810')?null:<>
                              {
                                (pool.extend==='0'||pool.extend==='2')&&<div className='staking_extract_wei'>
                                <div>
                                  <div>{t('Position-9')}</div>
                                  <div>{getTimes(allTime)} {t('Position-10')}</div>
                                </div>
                              </div>
                              }
                            </>
                      }
                      {
                        (Number(pool.fixedPeriod)===0&&Number(pool.unlockPeriod)>0)?null:<div className='staking_extract_tip'>{t('Cang_tiqu_tip')}{Math.floor((Number(pool.extendPeriod)/28800)*100)/100}{t('Cang_tiqu_tip1')}</div>
                      }
                      <div className='staking_extract_btn'>
                        {
                          (Number(pool.fixedPeriod)===0&&Number(pool.unlockPeriod)>0)?<Button
                          className={`pools__dialog__option`}
                          type={1}
                          loading={isJieSuo}
                          disabled={!Number(String(withdrawAmount[index]).replace(',', '')) || fetchWithdrawPending[index]}
                          onClick={onWithdraw.bind(this, pool, index, false, deposite, false,withdrawAmount[index],'jieSuo')}
                          // onClick={onWithdraw.bind(this, pool, index, false, deposite, true,'0')}
                        >
                          {
                            isJieSuo?null:<>{t('deposit-huo-5')}</>
                          }
                        </Button>:<Button
                                    className={`pools__dialog__option`}
                                    type={1}
                                    loading={isTiQu}
                                    disabled={!Number(String(withdrawAmount[index]).replace(',', '')) || fetchWithdrawPending[index]}
                                    onClick={onWithdraw.bind(this, pool, index, false, deposite, false,withdrawAmount[index],'tiQu')}
                                    // onClick={onWithdraw.bind(this, pool, index, false, deposite, true,'0')}
                                  >
                                    {
                                      isTiQu?null:<>{t('v2-ui-10')}</>
                                    }
                                  </Button>
                        }

                      </div>
                    </div>
                </div>
                }
                {
                  pool.dialog.profitOpened&&<div className='staking_dialog_all-extract'>
                  <div className="staking__dialog__headers" style={{marginBottom:'30px'}}>
                    {t('Vault-WithdrawIncome')}
                    <span className="staking__dialog__header__closes" onClick={() => closeDialogHandler('profit', index)}></span>
                  </div>
                  <div className='staking_all-extract-content'>
                    <div>
                      <div className='extract-title'>{t('Position-16')}</div>
                      <div className='extract-content_top'>
                        <div className='extract-content_li'>
                          <div>{String((pool.reward / 1e18).toFixed(5)).slice(0, -1)}</div>
                          <div>{pool.name}</div>
                        </div>
                        <div className='extract-content_li'>
                          <div>{String((pool.cowReward / 1e18).toFixed(5)).slice(0, -1)}</div>
                          <div>COW</div>
                        </div>
                      </div>
                      <div className='extract-content_buttom'>
                      {/* <div className='extract-content_li'>
                        <div>12.000</div>
                        <div>FIL</div>
                      </div>
                      <div className='extract-content_li'>
                        <div>112.000</div>
                        <div>COW</div>
                      </div> */}
                    </div>
                    </div>
                  </div>
                  <div className='extract_btn'>
                      <Button
                        className={`pools__dialog__option`}
                        loading={fetchWithdrawIncomePending[index]}
                        disabled={fetchWithdrawIncomePending[index] || (!Number(forMat(finaCompoundEarned / 1e18, 8)) && !Number(pool.cowReward / 1e18))}
                        onClick={onWithdraw.bind(this, pool, index, false, deposite, true)}
                        type={1}
                      >
                        {t("v2-ui-10")}
                      </Button>
                    </div>
                </div>
                }
              </div>:null
            }
          </div>
        </div>
    )
  })
  const dropDownList = [
    {
      text: `${t('Position-8')}`,
      id: "1",
    },
    {
      text: `${t('Staking-tab1')}`,
      id: "2",
    },
    {
      text:  `${t('Cang_dao_title')}`,
      id: "3",
    }

  ]
  const dropDownListState = [
    {
      text:  `${t('Position-8')}`,
      id: "1",
    },
    {
      text: `${t('Cang-tip')}`,
      id: "2",
    },
    {
      text:`${t('Cang-dao')}`,
      id: "3",
    },
    {
      text: `${t('Cang-jie')}`,
      id: "4",
    }
  ]
  const dropDownListType = [
    {
      text:  `${t('Position-8')}`,
      id: "1",
    },
    {
      text:`${t('Staking-tab1')}`,
      id: "3",
    },
    {
      text: `${t('Cang_dao_title')}`,
      id: "2",
    }
  ]
  return (
    <>
      {
        _IS_ETH_?null:<>
        <Dialog
          type={dialogCtrl.type}
          opened={dialogCtrl.opened}
          buttonText={dialogCtrl.buttonText}
          cancelButtonText={dialogCtrl.cancelButtonText}
          cancelButtonCallback={dialogCtrl.cancelButtonCallback}
          buttonClickCallback={dialogCtrl.buttonClickCallback}
        >{dialogCtrl.contentText}</Dialog>
        {/* 说明文字 */}
        <SectionTitle />
        {/* 倒计时 */}
        {/* <SectionCountDown endDate="2021-03-19 14:00:00" date={{ year: 2021, month: 3, day: 19, h: 14, m: 0, s: 0 }} /> */}
        {/* 总锁仓量总收益 */}
        {/* <SectionTotal /> */}
        {
          address ? <SectionTotal
            address={address}
          /> : null
        }
        <div className={`g-filter${_IS_ETH_ ? " filter-eth" : ""}`}>
          {/* Tab切换 */}
          {
            _IS_ETH_ ? null :

              <ul className="tab__containers">
                {
                  tabList.map((item, ind) => {
                    return (
                      <li
                        className={`${tabActive === item.id ? 'tab__active' : ''} tab__item`}
                        key={ind}
                        onClick={async() => {
                          if (item.id === tabActive) return;
                          // console.log(item.id)
                          setTabActive(item.id);
                          if(item.id===0){
                            setOpenedState(false);
                            setOpenedType(false);
                            let b = await a()

                            console.log(b, 'bbbbbbb')
                            let dingList =[]
                            let huoList = []
                            b.map(item=>{
                              if((Number(item.fixedPeriod)===0&&Number(item.unlockPeriod)>0)){
                                dingList.push(item)
                              }else{
                                huoList.push(item)
                              }
                            })
                            let profit = []
                            let expire = []
                            let unlock = []
                            b.map(item =>{
                              if(item.extend==='0'||item.extend==='2'){
                                profit.push(item)
                              }else if(item.extend==='1'||item.extend==='4'){
                                expire.push(item)
                              }else if(item.extend==='3'){
                                unlock.push(item)
                              }
                            })
                            let profitHuo = []
                            let expireHuo = []
                            let unlockHuo = []
                            huoList.map(item =>{
                              if(item.extend==='0'||item.extend==='2'){
                                profitHuo.push(item)
                              }else if(item.extend==='1'||item.extend==='4'){
                                expireHuo.push(item)
                              }else if(item.extend==='3'){
                                unlockHuo.push(item)
                              }
                            })
                            let profitDing = []
                            let expireDing = []
                            let unlockDing = []
                            dingList.map(item =>{
                              if(item.extend==='0'||item.extend==='2'){
                                profitDing.push(item)
                              }else if(item.extend==='1'||item.extend==='4'){
                                expireDing.push(item)
                              }else if(item.extend==='3'){
                                unlockDing.push(item)
                              }
                            })
                            setProfitState(profit)
                            setExpireState(expire)
                            setUnlockState(unlock)

                            setProfitHuoState(profitHuo)
                            setExpireHuoState(expireHuo)
                            setUnlockHuoState(unlockHuo)

                            setProfitDingState(profitDing)
                            setExpireDingState(expireDing)
                            setUnlockDingState(unlockDing)

                            setHuoList(huoList)
                            setDingList(dingList)
                            setStakingData(b)
                            setIsLoding(false)
                          }else if(item.id===1){
                            setOpened(false);
                          }
                          dispatch({ type: 'TABACTIVE', data: item.id });
                        }}>
                        <span className={`tab__${theme}`}>{item.name}</span>
                      </li>
                    )
                  })
                }
              </ul>
          }
          <div className='search_options'>
            {/* 少选过滤 */}
              {
                 tabActive===1?<>
                  <div className='search_option_one'>{t('Staking-tab')}</div>
                  <div className='select_type-content'>
                    <div className="select_type" onClick={() => {
                          setOpened(!opened);
                      }}>
                          <DropDownStaking
                              className={`nav__language nav__language-${theme} nav_style`}
                              buttonText={projectType==='1'?`${t('Position-8')}`:projectType==='2'?`${t('Staking-tab1')}`: `${t('Cang_dao_title')}`}
                              opened={opened}
                              dropDownList={dropDownList}
                              position="top"
                              buttonClickCallback={(props) => {
                                console.log(props)
                                console.log((props==='全部'||props==='All'))
                                  // getLanguage(props);
                                  setProjectType((props==='全部'||props==='All')?'1':(props==='定期'||props==='Regular')?'2':'3')
                                  setOpened(!opened);
                              }}
                          >
                          </DropDownStaking>
                          <strong style={{
                              // color: primaryColor[2],
                              width: "0",
                              height: "0",
                              display: "inline-block",
                              borderTop: "4px solid",
                              transition: "all 150ms ease-in",
                              borderLeft: "4px solid transparent",
                              marginLeft: "4px",
                              borderRight: "4px solid transparent",
                              verticalAlign: "middle",
                              position: "absolute",
                              right: "15px",
                              top: "13px",
                              transform: opened ? "rotate(180deg)" : ''
                          }}
                          // className={ `fang_${theme}`}
                          ></strong>
                    </div>
                  </div>
                 </>:<div className='cang_tab'>
                   <div className='tab_list'>
                    <div className='tab_list-title'>{t('Cang_tab2')}</div>
                    <div className='select_type-content'>
                      <div className="select_type" onClick={() => {
                            setOpenedState(!openeState);
                            setOpenedType(false);
                        }}>
                            <DropDownStakingState
                                className={`nav__language nav__language-${theme} nav_style`}
                                buttonText={cangState==='1'?`${t('Position-8')}`:cangState==='2'?`${t('Cang-tip')}`:cangState==='3'?`${t('Cang-dao')}`:`${t('Cang-jie')}`}
                                opened={openeState}
                                dropDownList={dropDownListState}
                                position="top"
                                buttonClickCallback={(props) => {
                                    // getLanguage(props);
                                    setCangState((props==='全部'||props==='All')?'1':(props==='收益中'||props==="Staking")?"2":(props==='已到期'||props==='Expired')?'3':'4')
                                    setOpenedState(!openeState);
                                }}
                            >
                            </DropDownStakingState>
                            <strong style={{
                                // color: primaryColor[2],
                                width: "0",
                                height: "0",
                                display: "inline-block",
                                borderTop: "4px solid",
                                transition: "all 150ms ease-in",
                                borderLeft: "4px solid transparent",
                                marginLeft: "4px",
                                borderRight: "4px solid transparent",
                                verticalAlign: "middle",
                                position: "absolute",
                                right: "15px",
                                top: "13px",
                                transform: openeState ? "rotate(180deg)" : ''
                            }}
                            // className={ `fang_${theme}`}
                            ></strong>
                      </div>
                    </div>
                  </div>
                   <div className='tab_list'>
                    <div className='tab_list-title'>{t('Cang_tab1')}</div>
                    <div className='select_type-content'>
                      <div className="select_type" onClick={() => {
                            setOpenedType(!openeType);
                            setOpenedState(false);
                        }}>
                            <DropDownStakingType
                                className={`nav__language nav__language-${theme} nav_style`}
                                buttonText={cangType==='1'?`${t('Position-8')}`:cangType==='3'?`${t('Staking-tab1')}`: `${t('Cang_dao_title')}`}
                                opened={openeType}
                                dropDownList={dropDownListType}
                                position="top"
                                buttonClickCallback={(props) => {
                                    // getLanguage(props);
                                    setCangType((props==='全部'||props==='All')?'1':(props==='定期'||props==='Regular')?'3':'2')
                                    setOpenedType(!openeType);
                                }}
                            >
                            </DropDownStakingType>
                            <strong style={{
                                // color: primaryColor[2],
                                width: "0",
                                height: "0",
                                display: "inline-block",
                                borderTop: "4px solid",
                                transition: "all 150ms ease-in",
                                borderLeft: "4px solid transparent",
                                marginLeft: "4px",
                                borderRight: "4px solid transparent",
                                verticalAlign: "middle",
                                position: "absolute",
                                right: "15px",
                                top: "13px",
                                transform: openeType ? "rotate(180deg)" : ''
                            }}
                            // className={ `fang_${theme}`}
                            ></strong>
                      </div>
                    </div>
                   </div>
                 </div>
                //  <>
                //  {
                //    isH5==='pc'&&<div className='search_option_one'>排序</div>
                //  }
                //   <select className='search_option_two'>
                //     <option>默认</option>
                //   </select>
                //  </>
              }

          </div>
          {/* 币种筛选 */}
          {/* <input type="text" onKeyDown={selectToken()} /> */}
        </div>
        {/* {
          (tabActive===1||isH5==='h5')?null:<div className='staking_tip'>
          <div>!</div>
          <div>项目到期后可继续享受收益，到期7日后未提取的本金合约将执行续存操作。</div>
        </div>
        } */}
        <div className="pools-main">
           {
             tabActive===1?<>
              {networkId in _NETWORK_ID_MAP_NAME_ && poolsMap}
              {!poolsMap.filter(pool => !!pool).length ? <div className="pools__empty">
                <img src={require("../../../assets/img/empty.svg")} />
                {/* {
                  (isPartake&&isProjectStatus)?<p>{t("v2-ui-15-2")}</p> :isPartake? <p>{t("v2-ui-15")}</p>:<p>{t("v2-ui-15-1")}</p>
                } */}
                <p>{t("v2-ui-15")}</p>
                {/* <Button type={2} onClick={() => {setIsPartake(false),setProjectStatus(true)}}>{t("v2-ui-16")}</Button> */}
              </div> : null}
             </>:<>
              {networkId in _NETWORK_ID_MAP_NAME_ && poolsMaps}
              {!poolsMaps.filter(pool => !!pool).length ? <div className="pools__empty">

                {/* {
                  (isPartake&&isProjectStatus)?<p>{t("v2-ui-15-2")}</p> :isPartake? <p>{t("v2-ui-15")}</p>:<p>{t("v2-ui-15-1")}</p>
                } */}
                {
                  isLoding==true? <>
                  <img src={require("../../../assets/img/x.png")} />
                  <p>{t("v2-ui-15-3")}</p>
                </>: <>
                  <img src={require("../../../assets/img/empty.svg")} />
                  <p>{t("v2-ui-15")}</p>
                </>
                }


                {/* <Button type={2} onClick={() => {setIsPartake(false),setProjectStatus(true)}}>{t("v2-ui-16")}</Button> */}
              </div> : null}
             </>
           }
        </div>
      </>
      }
    </>

  )
})
