import { HubPool } from "../configure";
import { enqueueSnackbar } from '../common/redux/actions';
import { gasMap, _CURRENT_NETWORK_ID_, _IS_ETH_ } from '../configure/chainsParamsConfig'

export const deposit = async ({ web3, address, isAll, amount, contractAddress, contractToken, dispatch }) => {
  // console.log(`=====================================deposit begin=====================================`)
  const contract = new web3.eth.Contract(HubPool, contractAddress);
  // contract.options.gas = 8600000;
  if (!_IS_ETH_) {
    contract.options.gas = gasMap[_CURRENT_NETWORK_ID_] ? (_CURRENT_NETWORK_ID_ === 56 ? gasMap[_CURRENT_NETWORK_ID_].deposit : gasMap[_CURRENT_NETWORK_ID_]) : 8600000;
  }
  const data = await _deposit({ web3, contract, isAll, amount, address, dispatch, contractToken });
  // console.log(`=====================================deposit success=====================================`)
  return data;
}

const _deposit = ({ web3, contract, amount, isAll, address, dispatch, contractToken }) => {
  // console.log(parseInt(amount),isAll,contractToken,amount)
  // console.log(amount)
  // let amounts = parseInt(amount).toString()
  // console.log(amounts)
  return new Promise((resolve, reject) => {
    if (isAll) {
      contract.methods.depositAll(contractToken).send({ from: address }).on('transactionHash', function (hash) {
        console.log(hash)
        dispatch(enqueueSnackbar({
          message: hash,
          options: {
            key: new Date().getTime() + Math.random(),
            variant: 'success'
          },
          hash
        }));
      })
        .on('receipt', function (receipt) {
          console.log('depositAll:', receipt);
          resolve(receipt)
        })
        .on('error', function (error) {
          console.log(error)
          reject(error)
        })
        .catch((error) => {
          console.log(error)
          reject(error)
        })
    } else {
      contract.methods.deposit(contractToken,amount).send({ from: address }).on('transactionHash', function (hash) {
        dispatch(enqueueSnackbar({
          message: hash,
          options: {
            key: new Date().getTime() + Math.random(),
            variant: 'success'
          },
          hash
        }));
      })
        .on('receipt', function (receipt) {
          console.log('deposit:', receipt);
          resolve(receipt)
        })
        .on('error', function (error) {
          console.log(error)
          reject(error)
        })
        .catch((error) => {
          console.log(error)
          reject(error)
        })
    }
  })
}