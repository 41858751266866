// const initialState = {
//   contractApy: {},
//   fetchContractApyPending: false,
//   fetchPoolBalancesPending: false,
//   fetchBalancesPending: false,
//   fetchApprovalPending: {},
//   fetchDepositPending: {},
//   fetchWithdrawPending: {},
//   fetchWithdrawIncomePending: {},
//   tabActive: 1,
// };

// export default initialState;
import { poolsLPDAO as pools } from "../../configure";
const tokens = {};

pools.map(({ token, tokenAddress, earnedToken, earnedTokenAddress }) => {
  tokens[token] = {
    tokenAddress: tokenAddress,
    tokenBalance: 0
  }
  tokens[earnedToken] = {
    tokenAddress: earnedTokenAddress,
    tokenBalance: 0
  }
  return '';
})


const initialState = {
  pools: pools.map(pool => {
    return {
      ...pool,
      // 对每一个pool增加弹出框状态控制
      dialog: {
        //对话框弹出
        opened: false,
        // 提现是否打开
        widthdrawOpened: false,
        // 存入是否打开
        depositOpened: false
      }
    }
  }),
  tokens,
  contractApy: {},
  fetchContractApyPending: false,
  fetchPoolBalancesPending: false,
  fetchBalancesPending: false,
  fetchApprovalPending: {},
  fetchDepositPending: {},
  fetchWithdrawPending: {},
  fetchWithdrawIncomePending: {},
  tabActive: 2,
};

export default initialState;