import React, { memo,useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
// import { useConnectWallet } from '../../home/redux/hooks';
import {useBoardDividend} from '../redux/hooks';
// import { useHubChainTvlAndProfit } from '../redux/hooks';
import '../jss/sections/SectionTotal.css'
import BigNumber from "bignumber.js";
// 千分位格式化
function formatThousands(num) {
    return num.toString().replace(/\d+/, function (n) { // 先提取整数部分
        return n.replace(/(\d)(?=(\d{3})+$)/g, function ($1) { // 对整数部分添加分隔符
            return $1 + ",";
        });
    });
}
const forMat = (number, precision = 2) => {
    return new BigNumber(
      number
    ).multipliedBy(
      new BigNumber(10000)
    ).dividedToIntegerBy(
      new BigNumber(1)
    ).dividedBy(
      new BigNumber(10000)
    ).toNumber().toFixed(precision)
}
export default memo(({address}) => {
    const { t } = useTranslation();
    const theme = useSelector(state => state.common.theme);
    const [isBonus, setIsBonus] = useState(false);
    // const [mulChainProfit, setIsBonusmulChainProfit] = useState(11111111);
    const {mulChainTvl,
      mulChainCowTvl ,
      mulChainProfit,
      curChainTvl,
      curChainCowTvl,
      curChainProfit,}=useBoardDividend()

      // {isNaN(cowProfit) ? 0.0000 : formatThousands((cowProfit / 1e18).toFixed(0))}
      // {isNaN(cow2Usdt) ? 0.0000 : (cow2Usdt / 1e18).toFixed(4)}
      // console.log(forMat(mulChainProfit,2))
      // console.log(isNaN(mulChainProfit),'11')

    return <>
    <div className={`bonus`}>
      <div className={`bonus-title ${theme}-back`}>
          <div className={`bonus-title-start`}>{t('Board-Bonus')}</div>
          <div className={`bonus-title-end`} onClick={
            ()=>setIsBonus(true)
          }>{t('Bonus-rule')}>></div>
      </div>
      <div className={`bonus-content`}>
        <div className={`bonus-content-top`}>
          <ul className={`pool__total-tvls pool__total-${theme}-tvls-b`}>
            <li>
              <div className='li_content'>{t('Multi-Bonus-value')}</div>
              <div className='li_content'>{isNaN(mulChainProfit) ? 0.0000 :  formatThousands(forMat(mulChainProfit,2))}</div>
            </li>
            <li>
              <div className='li_content'>{t('Multi-value')}</div>
              <div className='li_content'>{isNaN(mulChainTvl) ? 0.0000 :  formatThousands(forMat(mulChainTvl,2))}</div>
            </li>
            <li>
              <div className='li_content'>{t('Multi-Quantity')}</div>
              <div className='li_content'>{isNaN(mulChainCowTvl) ? 0.0000 : formatThousands(forMat(mulChainCowTvl,2))}</div>
            </li>
          </ul>
        </div>
        <div className={`bonus-content-bottom`}>
          <ul className={`pool__total-tvls`}>
            <li>
              <div>{theme==="heco"?'HECO':(theme==="bsc"?"BNB Chain":'ETH')} {t('Current-Bonus-value')}</div>
              <div>{isNaN(curChainProfit) ? 0.0000 :  formatThousands(forMat(curChainProfit,2))}</div>
            </li>
            <li>
              <div>{theme==="heco"?'HECO':(theme==="bsc"?"BNB Chain":'ETH')} {t('Current-value')}</div>
              <div>{isNaN(curChainTvl) ? 0.0000 :  formatThousands(forMat(curChainTvl,2))}</div>
            </li>
            <li>
              <div>{theme==="heco"?'HECO':(theme==="bsc"?"BNB Chain":'ETH')} {t('Current-Quantity')}</div>
              <div>{isNaN(curChainCowTvl) ? 0.0000 : formatThousands(forMat(curChainCowTvl,2))}</div>
            </li>
          </ul>
        </div>
      </div>
    </div>
    {
      isBonus&&<div className={`bonus-dialog`}>
        <div className={`bonus-dialog-inner`}>
          <div className={`bonus_dialog_header`}>{t('Bonus-rule')}
          <span className="bonus__dialog__header__close"  onClick={() => setIsBonus(false)}></span>
          </div>
          <div className={`bonus_dialog-capital`}>
            <div className={`bonus_dialog-capital-title`}>{t('Bonus-rule-1')}：</div>
            <div className={`bonus_dialog-capital-content`}>{t('Bonus-rule-2')}</div>
          </div>
          <div className={`bonus_dialog-rule`}>
            <div className={`bonus_dialog-rule-title`}>{t('Bonus-rule')}：</div>
            <div className={`bonus_dialog-rule-content`}>{t('Bonus-rule-3')}</div>
            <div className={`bonus_dialog-rule-content`}>{t('Bonus-rule-4')}</div>
          </div>
        </div>
      </div>
    }
    </>
})
