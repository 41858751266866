export const isBSC = window.localStorage.getItem("NETWORK_ID_USE") && Number(window.localStorage.getItem("NETWORK_ID_USE")) === 56
// 线上
export const earnContractAddress = isBSC ? "0xAdA5598d0E19B4d3C64585b4135c5860d4A0881F" : "0x94ad8542f3F1bBb6D0dFa4B91589a264FF9b0056";
export const USDTAddress = isBSC ? '0x55d398326f99059fF775485246999027B3197955' : '0xa71EdC38d189767582C38A3145b5873052c3e47a';
export const aggContract = isBSC ? '0x76868c4bBF3fa350CF584e0598AD074d9AF8f95E' : '0x6885754bD5FB210C28C6f4dCf66C2E4480d7ED7f';
export let GOVAddress = "";
export const setGOVAddress = (address) => GOVAddress = address;
export const pools = isBSC ? [
  {
    // 标识新的，表示开放额度
    type: 'New',
    // 开放额度
    openAmount: "3,000,000",
    // 开放时间
    openDate: "2021.04.26(GTM+8)",
    // 开放了吗
    disabled: false,
    id: 'COW-USDT',
    name: 'COW-USDT',
    token: 'COW-USDT',
    tokenOne: "COW",
    tokenTwo: "USDT",
    tokenDescription: 'COW-USDT',
    tokenOneAddress: "0x422E3aF98bC1dE5a1838BE31A56f75DB4Ad43730",
    tokenTwoAddress: "0x55d398326f99059fF775485246999027B3197955",
    tokenAddress: "0xF16D5142086DBF7723B0a57B8D96979810E47448",
    tokenDecimals: 18,
    itokenDecimals: 18,
    depostLimit: 0,
    tokenDescriptionUrl: '',
    tokenDescriptionUrl2: '',
    earnedToken: 'MDX',
    earnedTokenAddress: '0x25D2e80cB6B86881Fd7e07dd263Fb79f4AbE033c',
    earnContractAddress,
    defaultApy: "120.23",
    pricePerFullShare: 1,
    pastPricePerFullShare: 1,
    rateMdx: 1,
    total: 11000000,
    // 是否上首页
    topHome: true,
  },

  {
    // 标识新的，表示开放额度
    // type: '',
    // 开放额度
    openAmount: "3,000,000",
    // 开放时间
    openDate: "2021.04.26(GTM+8)",
    // 开放了吗
    disabled: false,
    id: 'MDX-USDT',
    name: 'MDX-USDT',
    token: 'MDX-USDT',
    tokenOne: "MDX",
    tokenTwo: "USDT",
    tokenDescription: 'MDX-USDT',
    tokenOneAddress: "0x9C65AB58d8d978DB963e63f2bfB7121627e3a739",
    tokenTwoAddress: "0x55d398326f99059fF775485246999027B3197955",
    tokenAddress: "0xe1cBe92b5375ee6AfE1B22b555D257B4357F6C68",
    tokenDecimals: 18,
    itokenDecimals: 18,
    depostLimit: 0,
    tokenDescriptionUrl: '',
    tokenDescriptionUrl2: '',
    earnedToken: 'MDX',
    earnedTokenAddress: '0x25D2e80cB6B86881Fd7e07dd263Fb79f4AbE033c',
    earnContractAddress,
    defaultApy: "120.23",
    pricePerFullShare: 1,
    pastPricePerFullShare: 1,
    rateMdx: 1,
    total: 11000000,
    // 是否上首页
    topHome: true,
  },
  {
    // 标识新的，表示开放额度
    // type: '',
    // 开放额度
    openAmount: "3,000,000",
    // 开放时间
    openDate: "2021.04.26(GTM+8)",
    // 开放了吗
    disabled: false,
    id: 'MDX-BUSD',
    name: 'MDX-BUSD',
    token: 'MDX-BUSD',
    tokenOne: "MDX",
    tokenTwo: "BUSD",
    tokenDescription: 'MDX-BUSD',
    tokenOneAddress: "0x9C65AB58d8d978DB963e63f2bfB7121627e3a739",
    tokenTwoAddress: "0xe9e7cea3dedca5984780bafc599bd69add087d56",
    tokenAddress: "0x223740a259E461aBeE12D84A9FFF5Da69Ff071dD",
    tokenDecimals: 18,
    itokenDecimals: 18,
    depostLimit: 0,
    tokenDescriptionUrl: '',
    tokenDescriptionUrl2: '',
    earnedToken: 'MDX',
    earnedTokenAddress: '0x25D2e80cB6B86881Fd7e07dd263Fb79f4AbE033c',
    earnContractAddress,
    defaultApy: "120.23",
    pricePerFullShare: 1,
    pastPricePerFullShare: 1,
    rateMdx: 1,
    total: 11000000,
  },
  {
    // 标识新的，表示开放额度
    // type: '',
    // 开放额度
    openAmount: "3,000,000",
    // 开放时间
    openDate: "2021.04.26(GTM+8)",
    // 开放了吗
    disabled: false,
    id: 'MDX-WBNB',
    name: 'MDX-WBNB',
    token: 'MDX-WBNB',
    tokenOne: "MDX",
    tokenTwo: "WBNB",
    tokenDescription: 'MDX-WBNB',
    tokenOneAddress: "0x9C65AB58d8d978DB963e63f2bfB7121627e3a739",
    tokenTwoAddress: "0xbb4cdb9cbd36b01bd1cbaebf2de08d9173bc095c",
    tokenAddress: "0xAf9Aa53146C5752BF6068A84B970E9fBB22a87bc",
    tokenDecimals: 18,
    itokenDecimals: 18,
    depostLimit: 0,
    tokenDescriptionUrl: '',
    tokenDescriptionUrl2: '',
    earnedToken: 'MDX',
    earnedTokenAddress: '0x25D2e80cB6B86881Fd7e07dd263Fb79f4AbE033c',
    earnContractAddress,
    defaultApy: "120.23",
    pricePerFullShare: 1,
    pastPricePerFullShare: 1,
    rateMdx: 1,
    total: 11000000,
    // 是否上首页
    topHome: true,
  },
  {
    // 标识新的，表示开放额度
    // type: 'New',
    // 开放额度
    openAmount: "3,000,000",
    // 开放时间
    openDate: "2021.04.26(GTM+8)",
    // 开放了吗
    disabled: false,
    id: 'DEK-USDT',
    name: 'DEK-USDT',
    token: 'DEK-USDT',
    tokenOne: "DEK",
    tokenTwo: "USDT",
    tokenDescription: 'DEK-USDT',
    tokenOneAddress: "0xe52C5A3590952F3ed6fcCf89A0bD7F38e11C5B98",
    tokenTwoAddress: "0x55d398326f99059fF775485246999027B3197955",
    tokenAddress: "0xaC98337f319E92F3b0cfEf9755a7596Dd48c1b00",
    tokenDecimals: 18,
    itokenDecimals: 18,
    depostLimit: 0,
    tokenDescriptionUrl: '',
    tokenDescriptionUrl2: '',
    earnedToken: 'MDX',
    earnedTokenAddress: '0x25D2e80cB6B86881Fd7e07dd263Fb79f4AbE033c',
    earnContractAddress,
    defaultApy: "120.23",
    pricePerFullShare: 1,
    pastPricePerFullShare: 1,
    rateMdx: 1,
    total: 11000000,
    // 是否上首页
    // topHome: true,
  },
  {
    // 标识新的，表示开放额度
    // type: 'New',
    // 开放额度
    openAmount: "3,000,000",
    // 开放时间
    openDate: "2021.04.26(GTM+8)",
    // 开放了吗
    disabled: false,
    id: 'HMDX-MDX',
    name: 'HMDX-MDX',
    token: 'HMDX-MDX',
    tokenOne: "HMDX",
    tokenTwo: "MDX",
    tokenDescription: 'HMDX-MDX',
    tokenOneAddress: "0xAEE4164c1ee46ed0bbC34790f1a3d1Fc87796668",
    tokenTwoAddress: "0x9C65AB58d8d978DB963e63f2bfB7121627e3a739",
    tokenAddress: "0x5E23fADEE9d8b5689F89104f10118C956Df3a286",
    tokenDecimals: 18,
    itokenDecimals: 18,
    depostLimit: 0,
    tokenDescriptionUrl: '',
    tokenDescriptionUrl2: '',
    earnedToken: 'MDX',
    earnedTokenAddress: '0x25D2e80cB6B86881Fd7e07dd263Fb79f4AbE033c',
    earnContractAddress,
    defaultApy: "120.23",
    pricePerFullShare: 1,
    pastPricePerFullShare: 1,
    rateMdx: 1,
    total: 11000000,
  },
  {
    // 标识新的，表示开放额度
    // type: 'New',
    // 开放额度
    openAmount: "3,000,000",
    // 开放时间
    openDate: "2021.04.26(GTM+8)",
    // 开放了吗
    disabled: false,
    id: 'MDX-BTCB',
    name: 'MDX-BTCB',
    token: 'MDX-BTCB',
    tokenOne: "MDX",
    tokenTwo: "BTCB",
    tokenDescription: 'MDX-BTCB',
    tokenOneAddress: "0x9C65AB58d8d978DB963e63f2bfB7121627e3a739",
    tokenTwoAddress: "0x7130d2a12b9bcbfae4f2634d864a1ee1ce3ead9c",
    tokenAddress: "0x1c0276642f2A7cbcf6624d511F34811cDC65212C",
    tokenDecimals: 18,
    itokenDecimals: 18,
    depostLimit: 0,
    tokenDescriptionUrl: '',
    tokenDescriptionUrl2: '',
    earnedToken: 'MDX',
    earnedTokenAddress: '0x25D2e80cB6B86881Fd7e07dd263Fb79f4AbE033c',
    earnContractAddress,
    defaultApy: "120.23",
    pricePerFullShare: 1,
    pastPricePerFullShare: 1,
    rateMdx: 1,
    total: 11000000,
  },
  {
    // 标识新的，表示开放额度
    // type: 'New',
    // 开放额度
    openAmount: "3,000,000",
    // 开放时间
    openDate: "2021.04.26(GTM+8)",
    // 开放了吗
    disabled: false,
    id: 'WBNB-USDT',
    name: 'WBNB-USDT',
    token: 'WBNB-USDT',
    tokenOne: "WBNB",
    tokenTwo: "USDT",
    tokenDescription: 'WBNB-USDT',
    tokenOneAddress: "0xbb4cdb9cbd36b01bd1cbaebf2de08d9173bc095c",
    tokenTwoAddress: "0x55d398326f99059fF775485246999027B3197955",
    tokenAddress: "0x09CB618bf5eF305FadfD2C8fc0C26EeCf8c6D5fd",
    tokenDecimals: 18,
    itokenDecimals: 18,
    depostLimit: 0,
    tokenDescriptionUrl: '',
    tokenDescriptionUrl2: '',
    earnedToken: 'MDX',
    earnedTokenAddress: '0x25D2e80cB6B86881Fd7e07dd263Fb79f4AbE033c',
    earnContractAddress,
    defaultApy: "120.23",
    pricePerFullShare: 1,
    pastPricePerFullShare: 1,
    rateMdx: 1,
    total: 11000000,
  },
  {
    // 标识新的，表示开放额度
    // type: 'New',
    // 开放额度
    openAmount: "3,000,000",
    // 开放时间
    openDate: "2021.04.26(GTM+8)",
    // 开放了吗
    disabled: false,
    id: 'FIL-USDT',
    name: 'FIL-USDT',
    token: 'FIL-USDT',
    tokenOne: "FIL",
    tokenTwo: "USDT",
    tokenDescription: 'FIL-USDT',
    tokenOneAddress: "0x0D8Ce2A99Bb6e3B7Db580eD848240e4a0F9aE153",
    tokenTwoAddress: "0x55d398326f99059fF775485246999027B3197955",
    tokenAddress: "0xCAABda10a3ac99Fc15f5B636Aa18E6B4Fd8db16D",
    tokenDecimals: 18,
    itokenDecimals: 18,
    depostLimit: 0,
    tokenDescriptionUrl: '',
    tokenDescriptionUrl2: '',
    earnedToken: 'MDX',
    earnedTokenAddress: '0x25D2e80cB6B86881Fd7e07dd263Fb79f4AbE033c',
    earnContractAddress,
    defaultApy: "120.23",
    pricePerFullShare: 1,
    pastPricePerFullShare: 1,
    rateMdx: 1,
    total: 11000000,
  },
  {
    // 标识新的，表示开放额度
    // type: 'New',
    // 开放额度
    openAmount: "3,000,000",
    // 开放时间
    openDate: "2021.04.26(GTM+8)",
    // 开放了吗
    disabled: false,
    id: 'BUSD-USDT',
    name: 'BUSD-USDT',
    token: 'BUSD-USDT',
    tokenOne: "BUSD",
    tokenTwo: "USDT",
    tokenDescription: 'BUSD-USDT',
    tokenOneAddress: "0xe9e7cea3dedca5984780bafc599bd69add087d56",
    tokenTwoAddress: "0x55d398326f99059fF775485246999027B3197955",
    tokenAddress: "0x62c1dEC1fF328DCdC157Ae0068Bb21aF3967aCd9",
    tokenDecimals: 18,
    itokenDecimals: 18,
    depostLimit: 0,
    tokenDescriptionUrl: '',
    tokenDescriptionUrl2: '',
    earnedToken: 'MDX',
    earnedTokenAddress: '0x25D2e80cB6B86881Fd7e07dd263Fb79f4AbE033c',
    earnContractAddress,
    defaultApy: "120.23",
    pricePerFullShare: 1,
    pastPricePerFullShare: 1,
    rateMdx: 1,
    total: 11000000,
  },
  {
    // 标识新的，表示开放额度
    // type: '',
    // 开放额度
    openAmount: "3,000,000",
    // 开放时间
    openDate: "2021.04.26(GTM+8)",
    // 开放了吗
    disabled: false,
    id: 'ETH-BTCB',
    name: 'ETH-BTCB',
    token: 'ETH-BTCB',
    tokenOne: "ETH",
    tokenTwo: "BTCB",
    tokenDescription: 'ETH-BTCB',
    tokenOneAddress: "0x2170ed0880ac9a755fd29b2688956bd959f933f8",
    tokenTwoAddress: "0x7130d2a12b9bcbfae4f2634d864a1ee1ce3ead9c",
    tokenAddress: "0x577d005912C49B1679B4c21E334FdB650E92C077",
    tokenDecimals: 18,
    itokenDecimals: 18,
    depostLimit: 0,
    tokenDescriptionUrl: '',
    tokenDescriptionUrl2: '',
    earnedToken: 'MDX',
    earnedTokenAddress: '0x25D2e80cB6B86881Fd7e07dd263Fb79f4AbE033c',
    earnContractAddress,
    defaultApy: "120.23",
    pricePerFullShare: 1,
    pastPricePerFullShare: 1,
    rateMdx: 1,
    total: 11000000,
  },
  {
    // 标识新的，表示开放额度
    // type: '',
    // 开放额度
    openAmount: "3,000,000",
    // 开放时间
    openDate: "2021.04.26(GTM+8)",
    // 开放了吗
    disabled: false,
    id: 'DOGE-USDT',
    name: 'DOGE-USDT',
    token: 'DOGE-USDT',
    tokenOne: "DOGE",
    tokenTwo: "USDT",
    tokenDescription: 'DOGE-USDT',
    tokenOneAddress: "0xbA2aE424d960c26247Dd6c32edC70B295c744C43",
    tokenTwoAddress: "0x55d398326f99059fF775485246999027B3197955",
    tokenAddress: "0xF8E9b725e0De8a9546916861c2904b0Eb8805b96",
    tokenDecimals: 18,
    itokenDecimals: 18,
    depostLimit: 0,
    tokenDescriptionUrl: '',
    tokenDescriptionUrl2: '',
    earnedToken: 'MDX',
    earnedTokenAddress: '0x25D2e80cB6B86881Fd7e07dd263Fb79f4AbE033c',
    earnContractAddress,
    defaultApy: "120.23",
    pricePerFullShare: 1,
    pastPricePerFullShare: 1,
    rateMdx: 1,
    total: 11000000,
    // 是否上首页
    // topHome: true,
  },
  {
    // 标识新的，表示开放额度
    // type: '',
    // 开放额度
    openAmount: "3,000,000",
    // 开放时间
    openDate: "2021.04.26(GTM+8)",
    // 开放了吗
    disabled: false,
    id: 'ETH-USDT',
    name: 'ETH-USDT',
    token: 'ETH-USDT',
    tokenOne: "ETH",
    tokenTwo: "USDT",
    tokenDescription: 'ETH-USDT',
    tokenOneAddress: "0x2170ed0880ac9a755fd29b2688956bd959f933f8",
    tokenTwoAddress: "0x55d398326f99059fF775485246999027B3197955",
    tokenAddress: "0x0FB881c078434b1C0E4d0B64d8c64d12078b7Ce2",
    tokenDecimals: 18,
    itokenDecimals: 18,
    depostLimit: 0,
    tokenDescriptionUrl: '',
    tokenDescriptionUrl2: '',
    earnedToken: 'MDX',
    earnedTokenAddress: '0x25D2e80cB6B86881Fd7e07dd263Fb79f4AbE033c',
    earnContractAddress,
    defaultApy: "120.23",
    pricePerFullShare: 1,
    pastPricePerFullShare: 1,
    rateMdx: 1,
    total: 11000000,
  },
  
  {
    // 标识新的，表示开放额度
    // type: '',
    // 开放额度
    openAmount: "3,000,000",
    // 开放时间
    openDate: "2021.04.26(GTM+8)",
    // 开放了吗
    disabled: false,
    id: 'WBNB-ETH',
    name: 'WBNB-ETH',
    token: 'WBNB-ETH',
    tokenOne: "WBNB",
    tokenTwo: "ETH",
    tokenDescription: 'WBNB-ETH',
    tokenOneAddress: "0xbb4cdb9cbd36b01bd1cbaebf2de08d9173bc095c",
    tokenTwoAddress: "0x2170ed0880ac9a755fd29b2688956bd959f933f8",
    tokenAddress: "0x82E8F9e7624fA038DfF4a39960F5197A43fa76aa",
    tokenDecimals: 18,
    itokenDecimals: 18,
    depostLimit: 0,
    tokenDescriptionUrl: '',
    tokenDescriptionUrl2: '',
    earnedToken: 'MDX',
    earnedTokenAddress: '0x25D2e80cB6B86881Fd7e07dd263Fb79f4AbE033c',
    earnContractAddress,
    defaultApy: "120.23",
    pricePerFullShare: 1,
    pastPricePerFullShare: 1,
    rateMdx: 1,
    total: 11000000,
  },
 
  {
    // 标识新的，表示开放额度
    // type: '',
    // 开放额度
    openAmount: "3,000,000",
    // 开放时间
    openDate: "2021.04.26(GTM+8)",
    // 开放了吗
    disabled: false,
    id: 'BTCB-USDT',
    name: 'BTCB-USDT',
    token: 'BTCB-USDT',
    tokenOne: "BTCB",
    tokenTwo: "USDT",
    tokenDescription: 'BTCB-USDT',
    tokenOneAddress: "0x7130d2a12b9bcbfae4f2634d864a1ee1ce3ead9c",
    tokenTwoAddress: "0x55d398326f99059fF775485246999027B3197955",
    tokenAddress: "0xda28Eb7ABa389C1Ea226A420bCE04Cb565Aafb85",
    tokenDecimals: 18,
    itokenDecimals: 18,
    depostLimit: 0,
    tokenDescriptionUrl: '',
    tokenDescriptionUrl2: '',
    earnedToken: 'MDX',
    earnedTokenAddress: '0x25D2e80cB6B86881Fd7e07dd263Fb79f4AbE033c',
    earnContractAddress,
    defaultApy: "120.23",
    pricePerFullShare: 1,
    pastPricePerFullShare: 1,
    rateMdx: 1,
    total: 11000000,
  },
  {
    // 标识新的，表示开放额度
    // type: 'New',
    // 开放额度
    openAmount: "3,000,000",
    // 开放时间
    openDate: "2021.04.26(GTM+8)",
    // 开放了吗
    disabled: false,
    id: 'COW-MDX',
    name: 'COW-MDX',
    token: 'COW-MDX',
    tokenOne: "COW",
    tokenTwo: "MDX",
    tokenDescription: 'COW-MDX',
    tokenOneAddress: "0x422E3aF98bC1dE5a1838BE31A56f75DB4Ad43730",
    tokenTwoAddress: "0x9C65AB58d8d978DB963e63f2bfB7121627e3a739",
    tokenAddress: "0xE24cAb13128352572d68AE4da1Abe73d5AcD820E",
    tokenDecimals: 18,
    itokenDecimals: 18,
    depostLimit: 0,
    tokenDescriptionUrl: '',
    tokenDescriptionUrl2: '',
    earnedToken: 'MDX',
    earnedTokenAddress: '0x25D2e80cB6B86881Fd7e07dd263Fb79f4AbE033c',
    earnContractAddress,
    defaultApy: "120.23",
    pricePerFullShare: 1,
    pastPricePerFullShare: 1,
    rateMdx: 1,
    total: 11000000,
    // 是否上首页
    // topHome: true,
    modify:true
  },
  {
      // 标识新的，表示开放额度
    // type: '',
    // 开放额度
    openAmount: "3,000,000",
    // 开放时间
    openDate: "2021.04.26(GTM+8)",
    // 开放了吗
    disabled: false,
    id: 'WBNB-BTCB',
    name: 'WBNB-BTCB',
    token: 'WBNB-BTCB',
    tokenOne: "WBNB",
    tokenTwo: "BTCB",
    tokenDescription: 'WBNB-BTCB',
    tokenOneAddress: "0xbb4cdb9cbd36b01bd1cbaebf2de08d9173bc095c",
    tokenTwoAddress: "0x7130d2a12b9bcbfae4f2634d864a1ee1ce3ead9c",
    tokenAddress: "0x969f2556F786a576F32AeF6c1D6618f0221Ec70e",
    tokenDecimals: 18,
    itokenDecimals: 18,
    depostLimit: 0,
    tokenDescriptionUrl: '',
    tokenDescriptionUrl2: '',
    earnedToken: 'MDX',
    earnedTokenAddress: '0x25D2e80cB6B86881Fd7e07dd263Fb79f4AbE033c',
    earnContractAddress,
    defaultApy: "120.23",
    pricePerFullShare: 1,
    pastPricePerFullShare: 1,
    rateMdx: 1,
    total: 11000000,
    modify:true
  },
  {
    // 标识新的，表示开放额度
    // type: '',
    // 开放额度
    openAmount: "3,000,000",
    // 开放时间
    openDate: "2021.04.26(GTM+8)",
    // 开放了吗
    disabled: false,
    id: 'BTCB-BUSD',
    name: 'BTCB-BUSD',
    token: 'BTCB-BUSD',
    tokenOne: "BTCB",
    tokenTwo: "BUSD",
    tokenDescription: 'BTCB-BUSD',
    tokenOneAddress: "0x7130d2a12b9bcbfae4f2634d864a1ee1ce3ead9c",
    tokenTwoAddress: "0xe9e7cea3dedca5984780bafc599bd69add087d56",
    tokenAddress: "0x4fb8253432FB3e92109c91E3Ff2b85FfA0f6A1F4",
    tokenDecimals: 18,
    itokenDecimals: 18,
    depostLimit: 0,
    tokenDescriptionUrl: '',
    tokenDescriptionUrl2: '',
    earnedToken: 'MDX',
    earnedTokenAddress: '0x25D2e80cB6B86881Fd7e07dd263Fb79f4AbE033c',
    earnContractAddress,
    defaultApy: "120.23",
    pricePerFullShare: 1,
    pastPricePerFullShare: 1,
    rateMdx: 1,
    total: 11000000,
    modify:true
  },
] : [
  {
    // 标识新的，表示开放额度
    type: 'New',
    // 开放额度
    openAmount: "3,000,000",
    // 开放时间
    openDate: "2021.04.26(GTM+8)",
    // 开放了吗
    disabled: false,
    id: 'COW-USDT',
    name: 'COW-USDT',
    token: 'COW-USDT',
    tokenOne: "COW",
    tokenTwo: "USDT",
    tokenDescription: 'COW-USDT',
    tokenOneAddress: "0x80861A817106665bcA173DB6AC2ab628a738c737",
    tokenTwoAddress: "0xa71EdC38d189767582C38A3145b5873052c3e47a",
    tokenAddress: "0x3F57530bDBA9bCD703c8Ba75c57CF7dE52014036",
    tokenDecimals: 18,
    itokenDecimals: 18,
    depostLimit: 0,
    tokenDescriptionUrl: '',
    tokenDescriptionUrl2: '',
    earnedToken: 'MDX',
    earnedTokenAddress: '0x25D2e80cB6B86881Fd7e07dd263Fb79f4AbE033c',
    earnContractAddress,
    defaultApy: "120.23",
    pricePerFullShare: 1,
    pastPricePerFullShare: 1,
    rateMdx: 1,
    total: 11000000,
    // 是否上首页
    topHome: true,
    conduct: true,
  },
  {
    // 标识新的，表示开放额度
    type: 'New',
    // 开放额度
    openAmount: "3,000,000",
    // 开放时间
    openDate: "2021.04.26(GTM+8)",
    // 开放了吗
    disabled: false,
    id: 'hCFX-USDT',
    name: 'hCFX-USDT',
    token: 'hCFX-USDT',
    tokenOne: "hCFX",
    tokenTwo: "USDT",
    tokenDescription: 'hCFX-USDT',
    tokenOneAddress: "0x045c4324039da91c52c55df5d785385aab073dcf",
    tokenTwoAddress: "0xa71EdC38d189767582C38A3145b5873052c3e47a",
    tokenAddress: "0xe4cc6a68dab4725fb132777814dfe178a5edfe66",
    tokenDecimals: 18,
    itokenDecimals: 18,
    depostLimit: 0,
    tokenDescriptionUrl: '',
    tokenDescriptionUrl2: '',
    earnedToken: 'MDX',
    earnedTokenAddress: '0x25D2e80cB6B86881Fd7e07dd263Fb79f4AbE033c',
    earnContractAddress,
    defaultApy: "120.23",
    pricePerFullShare: 1,
    pastPricePerFullShare: 1,
    rateMdx: 1,
    total: 11000000,
    // 是否上首页
    // topHome: true,
    conduct: true,
  },
  {
    // 标识新的，表示开放额度
    type: 'New',
    // 开放额度
    openAmount: "3,000,000",
    // 开放时间
    openDate: "2021.04.26(GTM+8)",
    // 开放了吗
    disabled: false,
    id: 'NT-USDT',
    name: 'NT-USDT',
    token: 'NT-USDT',
    tokenOne: "NT",
    tokenTwo: "USDT",
    tokenDescription: 'NT-USDT',
    tokenOneAddress: "0x8b70512b5248e7c1f0f6996e2fde2e952708c4c9",
    tokenTwoAddress: "0xa71EdC38d189767582C38A3145b5873052c3e47a",
    tokenAddress: "0x3d415085381fd8fced8c6b19b2605ede9c231eb5",
    tokenDecimals: 18,
    itokenDecimals: 18,
    depostLimit: 0,
    tokenDescriptionUrl: '',
    tokenDescriptionUrl2: '',
    earnedToken: 'MDX',
    earnedTokenAddress: '0x25D2e80cB6B86881Fd7e07dd263Fb79f4AbE033c',
    earnContractAddress,
    defaultApy: "120.23",
    pricePerFullShare: 1,
    pastPricePerFullShare: 1,
    rateMdx: 1,
    total: 11000000,
    // 是否上首页
    // topHome: true,
    conduct: true,
  },
  
  {
    // 标识新的，表示开放额度
    // type: '',
    // 开放额度
    openAmount: "3,000,000",
    // 开放时间
    openDate: "2021.04.26(GTM+8)",
    // 开放了吗
    disabled: false,
    id: 'MDX-USDT',
    name: 'MDX-USDT',
    token: 'MDX-USDT',
    tokenOne: "MDX",
    tokenTwo: "USDT",
    tokenDescription: 'MDX-USDT',
    tokenOneAddress: "0x25D2e80cB6B86881Fd7e07dd263Fb79f4AbE033c",
    tokenTwoAddress: "0xa71edc38d189767582c38a3145b5873052c3e47a",
    tokenAddress: "0x615E6285c5944540fd8bd921c9c8c56739Fd1E13",
    tokenDecimals: 18,
    itokenDecimals: 18,
    depostLimit: 0,
    tokenDescriptionUrl: '',
    tokenDescriptionUrl2: '',
    earnedToken: 'MDX',
    earnedTokenAddress: '0x25D2e80cB6B86881Fd7e07dd263Fb79f4AbE033c',
    earnContractAddress,
    defaultApy: "120.23",
    pricePerFullShare: 1,
    pastPricePerFullShare: 1,
    rateMdx: 1,
    total: 11000000,
    // 是否上首页
    topHome: true,
    conduct: false,
  },
  {
    // 标识新的，表示开放额度
    // type: '',
    // 开放额度
    openAmount: "3,000,000",
    // 开放时间
    openDate: "2021.04.26(GTM+8)",
    // 开放了吗
    disabled: false,
    id: 'MDX-WHT',
    name: 'MDX-WHT',
    token: 'MDX-WHT',
    tokenOne: "MDX",
    tokenTwo: "WHT",
    tokenDescription: 'MDX-WHT',
    tokenOneAddress: "0x25D2e80cB6B86881Fd7e07dd263Fb79f4AbE033c",
    tokenTwoAddress: "0x5545153ccfca01fbd7dd11c0b23ba694d9509a6f",
    tokenAddress: "0x6Dd2993B50b365c707718b0807fC4e344c072eC2",
    tokenDecimals: 18,
    itokenDecimals: 18,
    depostLimit: 0,
    tokenDescriptionUrl: '',
    tokenDescriptionUrl2: '',
    earnedToken: 'MDX',
    earnedTokenAddress: '0x25D2e80cB6B86881Fd7e07dd263Fb79f4AbE033c',
    earnContractAddress,
    defaultApy: "120.23",
    pricePerFullShare: 1,
    pastPricePerFullShare: 1,
    rateMdx: 1,
    total: 11000000,
    // 是否上首页
    // topHome: true,
    conduct: false,
  },
  {
    // 标识新的，表示开放额度
    // type: 'New',
    // 开放额度
    openAmount: "3,000,000",
    // 开放时间
    openDate: "2021.04.26(GTM+8)",
    // 开放了吗
    disabled: false,
    id: 'HBCH-MDX',
    name: 'HBCH-MDX',
    token: 'HBCH-MDX',
    tokenOne: "HBCH",
    tokenTwo: "MDX",
    tokenDescription: 'HBCH-MDX',
    tokenOneAddress: "0xef3cebd77e0c52cb6f60875d9306397b5caca375",
    tokenTwoAddress: "0x25D2e80cB6B86881Fd7e07dd263Fb79f4AbE033c",
    tokenAddress: "0x1986309EC4f23883EA4a407C38ca161C526B9A08",
    tokenDecimals: 18,
    itokenDecimals: 18,
    depostLimit: 0,
    tokenDescriptionUrl: '',
    tokenDescriptionUrl2: '',
    earnedToken: 'MDX',
    earnedTokenAddress: '0x25D2e80cB6B86881Fd7e07dd263Fb79f4AbE033c',
    earnContractAddress,
    defaultApy: "120.23",
    pricePerFullShare: 1,
    pastPricePerFullShare: 1,
    rateMdx: 1,
    total: 11000000,
    conduct: false,
  },
  
  {
    // 标识新的，表示开放额度
    // type: 'New',
    // 开放额度
    openAmount: "3,000,000",
    // 开放时间
    openDate: "2021.04.26(GTM+8)",
    // 开放了吗
    disabled: false,
    id: 'SHIB-USDT',
    name: 'SHIB-USDT',
    token: 'SHIB-USDT',
    tokenOne: "SHIB",
    tokenTwo: "USDT",
    tokenDescription: 'SHIB-USDT',
    tokenOneAddress: "0xC38072AA3F8E049De541223A9c9772132bB48634",
    tokenTwoAddress: "0xa71EdC38d189767582C38A3145b5873052c3e47a",
    tokenAddress: "0x1c85dD9E5FeE4c40786bd6278255D977946A364b",
    tokenDecimals: 18,
    itokenDecimals: 18,
    depostLimit: 0,
    tokenDescriptionUrl: '',
    tokenDescriptionUrl2: '',
    earnedToken: 'MDX',
    earnedTokenAddress: '0x25D2e80cB6B86881Fd7e07dd263Fb79f4AbE033c',
    earnContractAddress,
    defaultApy: "120.23",
    pricePerFullShare: 1,
    pastPricePerFullShare: 1,
    rateMdx: 1,
    total: 11000000,
    conduct: false,
  },
  {
    // 标识新的，表示开放额度
    // type: 'New',
    // 开放额度
    openAmount: "3,000,000",
    // 开放时间
    openDate: "2021.04.26(GTM+8)",
    // 开放了吗
    disabled: false,
    id: 'HUSD-USDT',
    name: 'HUSD-USDT',
    token: 'HUSD-USDT',
    tokenOne: "HUSD",
    tokenTwo: "USDT",
    tokenDescription: 'HUSD-USDT',
    tokenOneAddress: "0x0298c2b32eaE4da002a15f36fdf7615BEa3DA047",
    tokenTwoAddress: "0xa71EdC38d189767582C38A3145b5873052c3e47a",
    tokenAddress: "0xdff86B408284dff30A7CAD7688fEdB465734501C",
    tokenDecimals: 18,
    itokenDecimals: 18,
    depostLimit: 0,
    tokenDescriptionUrl: '',
    tokenDescriptionUrl2: '',
    earnedToken: 'MDX',
    earnedTokenAddress: '0x25D2e80cB6B86881Fd7e07dd263Fb79f4AbE033c',
    earnContractAddress,
    defaultApy: "120.23",
    pricePerFullShare: 1,
    pastPricePerFullShare: 1,
    rateMdx: 1,
    total: 11000000,
    conduct: false,
  },
  {
    // 标识新的，表示开放额度
    // type: 'New',
    // 开放额度
    openAmount: "3,000,000",
    // 开放时间
    openDate: "2021.04.26(GTM+8)",
    // 开放了吗
    disabled: false,
    id: 'HLTC-USDT',
    name: 'HLTC-USDT',
    token: 'HLTC-USDT',
    tokenOne: "HLTC",
    tokenTwo: "USDT",
    tokenDescription: 'HLTC-USDT',
    tokenOneAddress: "0xecb56cf772b5c9a6907fb7d32387da2fcbfb63b4",
    tokenTwoAddress: "0xa71EdC38d189767582C38A3145b5873052c3e47a",
    tokenAddress: "0x060B4bfcE16D15A943ec83C56C87940613e162eB",
    tokenDecimals: 18,
    itokenDecimals: 18,
    depostLimit: 0,
    tokenDescriptionUrl: '',
    tokenDescriptionUrl2: '',
    earnedToken: 'MDX',
    earnedTokenAddress: '0x25D2e80cB6B86881Fd7e07dd263Fb79f4AbE033c',
    earnContractAddress,
    defaultApy: "120.23",
    pricePerFullShare: 1,
    pastPricePerFullShare: 1,
    rateMdx: 1,
    total: 11000000,
    conduct: false,
  },
  {
    // 标识新的，表示开放额度
    // type: '',
    // 开放额度
    openAmount: "3,000,000",
    // 开放时间
    openDate: "2021.04.26(GTM+8)",
    // 开放了吗
    disabled: false,
    id: 'HFIL-USDT',
    name: 'HFIL-USDT',
    token: 'HFIL-USDT',
    tokenOne: "HFIL",
    tokenTwo: "USDT",
    tokenDescription: 'HFIL-USDT',
    tokenOneAddress: "0xae3a768f9ab104c69a7cd6041fe16ffa235d1810",
    tokenTwoAddress: "0xa71EdC38d189767582C38A3145b5873052c3e47a",
    tokenAddress: "0x600072aF0470d9Ed1D83885D03d17368943fF22A",
    tokenDecimals: 18,
    itokenDecimals: 18,
    depostLimit: 0,
    tokenDescriptionUrl: '',
    tokenDescriptionUrl2: '',
    earnedToken: 'MDX',
    earnedTokenAddress: '0x25D2e80cB6B86881Fd7e07dd263Fb79f4AbE033c',
    earnContractAddress,
    defaultApy: "120.23",
    pricePerFullShare: 1,
    pastPricePerFullShare: 1,
    rateMdx: 1,
    total: 11000000,
    // 是否上首页
    topHome: true,
    conduct: false,
  },
  {
    // 标识新的，表示开放额度
    // type: '',
    // 开放额度
    openAmount: "3,000,000",
    // 开放时间
    openDate: "2021.04.26(GTM+8)",
    // 开放了吗
    disabled: false,
    id: 'HBTC-ETH',
    name: 'HBTC-ETH',
    token: 'HBTC-ETH',
    tokenOne: "HBTC",
    tokenTwo: "ETH",
    tokenDescription: 'HBTC-ETH',
    tokenOneAddress: "0x66a79d23e58475d2738179ca52cd0b41d73f0bea",
    tokenTwoAddress: "0x64FF637fB478863B7468bc97D30a5bF3A428a1fD",
    tokenAddress: "0x793c2a814e23EE38aB46412Be65E94Fe47D4B397",
    tokenDecimals: 18,
    itokenDecimals: 18,
    depostLimit: 0,
    tokenDescriptionUrl: '',
    tokenDescriptionUrl2: '',
    earnedToken: 'MDX',
    earnedTokenAddress: '0x25D2e80cB6B86881Fd7e07dd263Fb79f4AbE033c',
    earnContractAddress,
    defaultApy: "120.23",
    pricePerFullShare: 1,
    pastPricePerFullShare: 1,
    rateMdx: 1,
    total: 11000000,
    conduct: false,
  },
  
  {
    // 标识新的，表示开放额度
    // type: '',
    // 开放额度
    openAmount: "3,000,000",
    // 开放时间
    openDate: "2021.04.26(GTM+8)",
    // 开放了吗
    disabled: false,
    id: 'HBTC-USDT',
    name: 'HBTC-USDT',
    token: 'HBTC-USDT',
    tokenOne: "HBTC",
    tokenTwo: "USDT",
    tokenDescription: 'HBTC-USDT',
    tokenOneAddress: "0x66a79d23e58475d2738179ca52cd0b41d73f0bea",
    tokenTwoAddress: "0xa71edc38d189767582c38a3145b5873052c3e47a",
    tokenAddress: "0xFBe7b74623e4be82279027a286fa3A5b5280F77c",
    tokenDecimals: 18,
    itokenDecimals: 18,
    depostLimit: 0,
    tokenDescriptionUrl: '',
    tokenDescriptionUrl2: '',
    earnedToken: 'MDX',
    earnedTokenAddress: '0x25D2e80cB6B86881Fd7e07dd263Fb79f4AbE033c',
    earnContractAddress,
    defaultApy: "120.23",
    pricePerFullShare: 1,
    pastPricePerFullShare: 1,
    rateMdx: 1,
    total: 11000000,
    conduct: false,
  },
  {
    // 标识新的，表示开放额度
    // type: '',
    // 开放额度
    openAmount: "3,000,000",
    // 开放时间
    openDate: "2021.04.26(GTM+8)",
    // 开放了吗
    disabled: false,
    id: 'ETH-USDT',
    name: 'ETH-USDT',
    token: 'ETH-USDT',
    tokenOne: "ETH",
    tokenTwo: "USDT",
    tokenDescription: 'ETH-USDT',
    tokenOneAddress: "0x64FF637fB478863B7468bc97D30a5bF3A428a1fD",
    tokenTwoAddress: "0xa71edc38d189767582c38a3145b5873052c3e47a",
    tokenAddress: "0x78C90d3f8A64474982417cDB490E840c01E516D4",
    tokenDecimals: 18,
    itokenDecimals: 18,
    depostLimit: 0,
    tokenDescriptionUrl: '',
    tokenDescriptionUrl2: '',
    earnedToken: 'MDX',
    earnedTokenAddress: '0x25D2e80cB6B86881Fd7e07dd263Fb79f4AbE033c',
    earnContractAddress,
    defaultApy: "120.23",
    pricePerFullShare: 1,
    pastPricePerFullShare: 1,
    rateMdx: 1,
    total: 11000000,
    conduct: false,
  },
 
  {
    // 标识新的，表示开放额度
    // type: '',
    // 开放额度
    openAmount: "3,000,000",
    // 开放时间
    openDate: "2021.04.26(GTM+8)",
    // 开放了吗
    disabled: false,
    id: 'WHT-USDT',
    name: 'WHT-USDT',
    token: 'WHT-USDT',
    tokenOne: "WHT",
    tokenTwo: "USDT",
    tokenDescription: 'WHT-USDT',
    tokenOneAddress: "0x5545153ccfca01fbd7dd11c0b23ba694d9509a6f",
    tokenTwoAddress: "0xa71edc38d189767582c38a3145b5873052c3e47a",
    tokenAddress: "0x499B6E03749B4bAF95F9E70EeD5355b138EA6C31",
    tokenDecimals: 18,
    itokenDecimals: 18,
    depostLimit: 0,
    tokenDescriptionUrl: '',
    tokenDescriptionUrl2: '',
    earnedToken: 'MDX',
    earnedTokenAddress: '0x25D2e80cB6B86881Fd7e07dd263Fb79f4AbE033c',
    earnContractAddress,
    defaultApy: "120.23",
    pricePerFullShare: 1,
    pastPricePerFullShare: 1,
    rateMdx: 1,
    total: 11000000,
    conduct: false,
  },
  {
    // 标识新的，表示开放额度
    // type: 'New',
    // 开放额度
    openAmount: "3,000,000",
    // 开放时间
    openDate: "2021.04.26(GTM+8)",
    // 开放了吗
    disabled: false,
    id: 'COW-MDX',
    name: 'COW-MDX',
    token: 'COW-MDX',
    tokenOne: "COW",
    tokenTwo: "MDX",
    tokenDescription: 'COW-MDX',
    tokenOneAddress: "0x80861A817106665bcA173DB6AC2ab628a738c737",
    tokenTwoAddress: "0x25D2e80cB6B86881Fd7e07dd263Fb79f4AbE033c",
    tokenAddress: "0x31e6C3d4bDef2768b0276dEcc82B022aB50B88D8",
    tokenDecimals: 18,
    itokenDecimals: 18,
    depostLimit: 0,
    tokenDescriptionUrl: '',
    tokenDescriptionUrl2: '',
    earnedToken: 'MDX',
    earnedTokenAddress: '0x25D2e80cB6B86881Fd7e07dd263Fb79f4AbE033c',
    earnContractAddress,
    defaultApy: "120.23",
    pricePerFullShare: 1,
    pastPricePerFullShare: 1,
    rateMdx: 1,
    total: 11000000,
    // 是否上首页
    // topHome: true,
    //修改收益率为0
    modify:true,
    conduct: false,
  },
  {
    // 标识新的，表示开放额度
    // type: 'New',
    // 开放额度
    openAmount: "3,000,000",
    // 开放时间
    openDate: "2021.04.26(GTM+8)",
    // 开放了吗
    disabled: false,
    id: 'MDX-HBTC',
    name: 'MDX-HBTC',
    token: 'MDX-HBTC',
    tokenOne: "MDX",
    tokenTwo: "HBTC",
    tokenDescription: 'MDX-HBTC',
    tokenOneAddress: "0x25D2e80cB6B86881Fd7e07dd263Fb79f4AbE033c",
    tokenTwoAddress: "0x66a79d23e58475d2738179ca52cd0b41d73f0bea",
    tokenAddress: "0x2Fb4bE0F2785bD6009A383f3290CC97A4e3bD46B",
    tokenDecimals: 18,
    itokenDecimals: 18,
    depostLimit: 0,
    tokenDescriptionUrl: '',
    tokenDescriptionUrl2: '',
    earnedToken: 'MDX',
    earnedTokenAddress: '0x25D2e80cB6B86881Fd7e07dd263Fb79f4AbE033c',
    earnContractAddress,
    defaultApy: "120.23",
    pricePerFullShare: 1,
    pastPricePerFullShare: 1,
    rateMdx: 1,
    total: 11000000,
    modify:true,
    conduct: false,
  },
  {
    // 标识新的，表示开放额度
    // type: 'New',
    // 开放额度
    openAmount: "3,000,000",
    // 开放时间
    openDate: "2021.04.26(GTM+8)",
    // 开放了吗
    disabled: false,
    id: 'MDX-ETH',
    name: 'MDX-ETH',
    token: 'MDX-ETH',
    tokenOne: "MDX",
    tokenTwo: "ETH",
    tokenDescription: 'MDX-ETH',
    tokenOneAddress: "0x25D2e80cB6B86881Fd7e07dd263Fb79f4AbE033c",
    tokenTwoAddress: "0x64FF637fB478863B7468bc97D30a5bF3A428a1fD",
    tokenAddress: "0xb55569893b397324c0d048c9709F40c23445540E",
    tokenDecimals: 18,
    itokenDecimals: 18,
    depostLimit: 0,
    tokenDescriptionUrl: '',
    tokenDescriptionUrl2: '',
    earnedToken: 'MDX',
    earnedTokenAddress: '0x25D2e80cB6B86881Fd7e07dd263Fb79f4AbE033c',
    earnContractAddress,
    defaultApy: "120.23",
    pricePerFullShare: 1,
    pastPricePerFullShare: 1,
    rateMdx: 1,
    total: 11000000,
    modify:true,
    conduct: false,
  },
  {
    // 标识新的，表示开放额度
    // type: 'New',
    // 开放额度
    openAmount: "3,000,000",
    // 开放时间
    openDate: "2021.04.26(GTM+8)",
    // 开放了吗
    disabled: false,
    id: 'MX-USDT',
    name: 'MX-USDT',
    token: 'MX-USDT',
    tokenOne: "MX",
    tokenTwo: "USDT",
    tokenDescription: 'MX-USDT',
    tokenOneAddress: "0x8d854e603dc777337134286f5b3408261736a88F",
    tokenTwoAddress: "0xa71EdC38d189767582C38A3145b5873052c3e47a",
    tokenAddress: "0x6819F7a528DCaa2EDaDD6412265d11C6e65ba89d",
    tokenDecimals: 18,
    itokenDecimals: 18,
    depostLimit: 0,
    tokenDescriptionUrl: '',
    tokenDescriptionUrl2: '',
    earnedToken: 'MDX',
    earnedTokenAddress: '0x25D2e80cB6B86881Fd7e07dd263Fb79f4AbE033c',
    earnContractAddress,
    defaultApy: "120.23",
    pricePerFullShare: 1,
    pastPricePerFullShare: 1,
    rateMdx: 1,
    total: 11000000,
    modify:true,
    conduct: false,
  },
  {
    // 标识新的，表示开放额度
    // type: '',
    // 开放额度
    openAmount: "3,000,000",
    // 开放时间
    openDate: "2021.04.26(GTM+8)",
    // 开放了吗
    disabled: false,
    id: 'WHT-HUSD',
    name: 'WHT-HUSD',
    token: 'WHT-HUSD',
    tokenOne: "WHT",
    tokenTwo: "HUSD",
    tokenDescription: 'WHT-HUSD',
    tokenOneAddress: "0x5545153ccfca01fbd7dd11c0b23ba694d9509a6f",
    tokenTwoAddress: "0x0298c2b32eaE4da002a15f36fdf7615BEa3DA047",
    tokenAddress: "0x3375afF2CAcF683b8FC34807B9443EB32e7Afff6",
    tokenDecimals: 18,
    itokenDecimals: 18,
    depostLimit: 0,
    tokenDescriptionUrl: '',
    tokenDescriptionUrl2: '',
    earnedToken: 'MDX',
    earnedTokenAddress: '0x25D2e80cB6B86881Fd7e07dd263Fb79f4AbE033c',
    earnContractAddress,
    defaultApy: "120.23",
    pricePerFullShare: 1,
    pastPricePerFullShare: 1,
    rateMdx: 1,
    total: 11000000,
    modify:true,
    conduct: false,
  },
  {
    // 标识新的，表示开放额度
    // type: '',
    // 开放额度
    openAmount: "3,000,000",
    // 开放时间
    openDate: "2021.04.26(GTM+8)",
    // 开放了吗
    disabled: false,
    id: 'WHT-ETH',
    name: 'WHT-ETH',
    token: 'WHT-ETH',
    tokenOne: "WHT",
    tokenTwo: "ETH",
    tokenDescription: 'WHT-ETH',
    tokenOneAddress: "0x5545153ccfca01fbd7dd11c0b23ba694d9509a6f",
    tokenTwoAddress: "0x64FF637fB478863B7468bc97D30a5bF3A428a1fD",
    tokenAddress: "0x53E458aD1CFEB9582736db6BdE9aF89948e3bc3d",
    tokenDecimals: 18,
    itokenDecimals: 18,
    depostLimit: 0,
    tokenDescriptionUrl: '',
    tokenDescriptionUrl2: '',
    earnedToken: 'MDX',
    earnedTokenAddress: '0x25D2e80cB6B86881Fd7e07dd263Fb79f4AbE033c',
    earnContractAddress,
    defaultApy: "120.23",
    pricePerFullShare: 1,
    pastPricePerFullShare: 1,
    rateMdx: 1,
    total: 11000000,
    modify:true,
    conduct: false,
  },
  {
    // 标识新的，表示开放额度
    // type: '',
    // 开放额度
    openAmount: "3,000,000",
    // 开放时间
    openDate: "2021.04.26(GTM+8)",
    // 开放了吗
    disabled: false,
    id: 'HBTC-WHT',
    name: 'HBTC-WHT',
    token: 'HBTC-WHT',
    tokenOne: "HBTC",
    tokenTwo: "WHT",
    tokenDescription: 'HBTC-WHT',
    tokenOneAddress: "0x66a79d23e58475d2738179ca52cd0b41d73f0bea",
    tokenTwoAddress: "0x5545153ccfca01fbd7dd11c0b23ba694d9509a6f",
    tokenAddress: "0xBFff969A85e355eE0851b019Dba1e87C7780F40d",
    tokenDecimals: 18,
    itokenDecimals: 18,
    depostLimit: 0,
    tokenDescriptionUrl: '',
    tokenDescriptionUrl2: '',
    earnedToken: 'MDX',
    earnedTokenAddress: '0x25D2e80cB6B86881Fd7e07dd263Fb79f4AbE033c',
    earnContractAddress,
    defaultApy: "120.23",
    pricePerFullShare: 1,
    pastPricePerFullShare: 1,
    rateMdx: 1,
    total: 11000000,
    modify:true,
    conduct: false,
  },
]