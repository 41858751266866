
export function cow_eth_info() {
    const earnContractAddress = "0x8ddc12f593F1c92122D5dda9244b5e749cBFB2e4";
    const USDTAddress = '0xdAC17F958D2ee523a2206206994597C13D831ec7';
    const aggAddress = "0xec0eddeabd103966195dfe3178123a97a4870c87";
    const MdxAddress = "0x34965f73Cfa05Bf8d8aF37cB4af64Fa950605Ea8";
    const cowAddress = "0x34965f73Cfa05Bf8d8aF37cB4af64Fa950605Ea8";
    const migrator = "0xB10214B7aC94ECE69124B0c46fF5059f5C50091A";
    const pools = [
        {
          // 标识新的，表示开放额度
          // type: 'NEW',
          // 开放额度
          openAmount: "100,000",
          // 开放时间
          openDate: "2021.05.27(GTM+8)",
          // 开放了吗
          disabled: false,
          id: 'COW',
          name: 'COW',
          token: 'COW',
          tokenDescription: 'COW',
          tokenAddress: cowAddress,
          tokenDecimals: 18,
          itokenDecimals: 18,
          depostLimit: 0,
          tokenDescriptionUrl: '',
          tokenDescriptionUrl2: '',
          earnedToken: 'COW',
          earnedTokenAddress:cowAddress,
          earnContractAddress,
          defaultApy: "120.23",
          pricePerFullShare: 1,
          pastPricePerFullShare: 1,
          rateMdx: 1,
          total: 100000,
          // 是否上首页
          topHome: true,
        },
        {
          // 标识新的，表示开放额度
          // type: '',
          // 开放额度
          openAmount: "10,000,000",
          // 开放时间
          openDate: "2021.05.27(GTM+8)",
          // 开放了吗
          disabled: false,
          id: 'USDT',
          name: 'USDT',
          token: 'USDT',
          tokenDescription: 'USDT',
          tokenAddress: '0xdAC17F958D2ee523a2206206994597C13D831ec7',
          tokenDecimals: 6,
          itokenDecimals: 6,
          depostLimit: 0,
          tokenDescriptionUrl: '',
          tokenDescriptionUrl2: '',
          earnedToken: 'COW',
          earnedTokenAddress:cowAddress,
          earnContractAddress,
          defaultApy: "120.23",
          pricePerFullShare: 1,
          pastPricePerFullShare: 1,
          rateMdx: 1,
          total: 10000000,
          // 是否上首页
          topHome: true,
        },
        {
          // 标识新的，表示开放额度
          type: 'NEW',
          // 开放额度
          openAmount: "100,000",
          // 开放时间
          openDate: "2021.05.27(GTM+8)",
          // 开放了吗
          disabled: false,
          id: 'EPK',
          name: 'EPK',
          token: 'EPK',
          tokenDescription: 'EPK',
          tokenAddress: '0xdaf88906ac1de12ba2b1d2f7bfc94e9638ac40c4',
          tokenDecimals: 18,
          itokenDecimals: 18,
          depostLimit: 0,
          tokenDescriptionUrl: '',
          tokenDescriptionUrl2: '',
          earnedToken: 'COW',
          earnedTokenAddress:cowAddress,
          earnContractAddress,
          defaultApy: "120.23",
          pricePerFullShare: 1,
          pastPricePerFullShare: 1,
          rateMdx: 1,
          total: 100000,
          // 是否上首页
          // topHome: true,
        },
        {
          // 标识新的，表示开放额度
          // type: '',
          // 开放额度
          openAmount: "13,890",
          // 开放时间
          openDate: "2021.05.27(GTM+8)",
          // 开放了吗
          disabled: false,
          id: 'WETH',
          name: 'WETH',
          token: 'WETH',
          tokenDescription: 'WETH',
          tokenAddress: '0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2',
          tokenDecimals: 18,
          itokenDecimals: 18,
          depostLimit: 0,
          tokenDescriptionUrl: '',
          tokenDescriptionUrl2: '',
          earnedToken: 'COW',
          earnedTokenAddress:cowAddress,
          earnContractAddress,
          defaultApy: "120.23",
          pricePerFullShare: 1,
          pastPricePerFullShare: 1,
          rateMdx: 1,
          total: 13890,
          // 是否上首页
          topHome: true,
        },
        {
          // 标识新的，表示开放额度
          // type: '',
          // 开放额度
          openAmount: "20,000,000",
          // 开放时间
          openDate: "2021.05.27(GTM+8)",
          // 开放了吗
          disabled: false,
          id: 'USDC',
          name: 'USDC',
          token: 'USDC',
          tokenDescription: 'USDC',
          tokenAddress: '0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48',
          tokenDecimals: 6,
          itokenDecimals: 6,
          depostLimit: 0,
          tokenDescriptionUrl: '',
          tokenDescriptionUrl2: '',
          earnedToken: 'COW',
          earnedTokenAddress:cowAddress,
          earnContractAddress,
          defaultApy: "120.23",
          pricePerFullShare: 1,
          pastPricePerFullShare: 1,
          rateMdx: 1,
          total: 20000000,
          // 是否上首页
          topHome: false,
        },
        {
          // 标识新的，表示开放额度
          // type: '',
          // 开放额度
          openAmount: "490",
          // 开放时间
          openDate: "2021.05.27(GTM+8)",
          // 开放了吗
          disabled: false,
          id: 'WBTC',
          name: 'WBTC',
          token: 'WBTC',
          tokenDescription: 'WBTC',
          tokenAddress: '0x2260FAC5E5542a773Aa44fBCfeDf7C193bc2C599',
          tokenDecimals: 8,
          itokenDecimals: 8,
          depostLimit: 0,
          tokenDescriptionUrl: '',
          tokenDescriptionUrl2: '',
          earnedToken: 'COW',
          earnedTokenAddress:cowAddress,
          earnContractAddress,
          defaultApy: "120.23",
          pricePerFullShare: 1,
          pastPricePerFullShare: 1,
          rateMdx: 1,
          total: 490,
          // 是否上首页
          topHome: false,
        }
    ]
    return {
        earnContractAddress,
        USDTAddress,
        aggAddress,
        MdxAddress,
        cowAddress,
        pools,
        migrator
    }
}