import React, { memo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
// import { useConnectWallet } from '../../home/redux/hooks';
import {useAggregate,useFetchWithdraw,useStakingList} from '../redux/hooks';
// import { useHubChainTvlAndProfit } from '../redux/hooks';
import '../jss/sections/SectionTotal.css'
import BigNumber from "bignumber.js";
import { isMobile } from 'features/helpers/utils';
import Button from '../../../components/Button/Button'
import { useConnectWallet } from '../../home/redux/hooks';
import { txUrlMap, _NETWORK_ID_MAP_NAME_, cowAddressMap, _IS_ETH_,_CURRENT_NETWORK_ID_ } from '../../configure/chainsParamsConfig';
import { StakingHubPool } from "../../configure/abi";
// 千分位格式化
function formatThousands(num) {
    return num.toString().replace(/\d+/, function (n) { // 先提取整数部分
        return n.replace(/(\d)(?=(\d{3})+$)/g, function ($1) { // 对整数部分添加分隔符
            return $1 + ",";
        });
    });
}
const forMat = (number, precision = 2) => {
    return new BigNumber(
      number
    ).multipliedBy(
      new BigNumber(10000)
    ).dividedToIntegerBy(
      new BigNumber(1)
    ).dividedBy(
      new BigNumber(10000)
    ).toNumber().toFixed(precision)
  }
export default memo(({}) => {
  // console.log(address)
    const { t } = useTranslation();
    const theme = useSelector(state => state.common.theme);
    const { web3, address, networkId, connected, connectWallet, connectWalletPending } = useConnectWallet();
    const aggregateData = useAggregate(theme==='bsc');
    const isH5 = isMobile() ? 'h5' : 'pc';
    const rateAndTvl = aggregateData ? aggregateData[1]/1e18 : 0;
    const rateAndReward = aggregateData ? aggregateData[0]/1e18 : 0;
    const depositedAll = aggregateData ? aggregateData[3]/1e18 : 0;
    const allEarned = aggregateData ? aggregateData[2]/1e18 : 0;
    // console.log(rateAndTvl,'123212')
    const { fetchWithdraw, fetchWithdrawPending, fetchWithdrawIncomePending,fetchWithdrawAllReward } = useFetchWithdraw();
    const [isDia,setIsdia] = useState(false)
    const [onError,setOnError] = useState(false)
    const [onSuccess,setOnSuccess] = useState(false)
    const [withdrawAmount, setWithdrawAmount] = useState({});
    const [stakingData, setStakingData] = useState([]);
    const [onHash,setOnHash] = useState()
    const [priceAllList,setPriceAllList] = useState([])
    // useStakingList((pools) => {
    //   console.log(pools,'234432')
    //   setStakingData([...pools]);
    // })
    const a = useStakingList()
    // // 所有状态的对话操作
    // const [dialogCtrl, setDialogCtrl] = useState({
    //   opened: false,
    //   type: 'success',
    //   // "我知道了"
    //   buttonText: t('v2-ui-1'),
    //   // 授权成功
    //   contentText: t('v2-ui-2'),
    //   buttonClickCallback: () => {
    //     setDialogCtrl({ ...dialogCtrl, opened: false })
    //   }
    // })
    // 提取
    const onWithdraw = (pool, index, isAll, singleDepositedBalance, isWithdrawIncome, event) => {
      const contract = new web3.eth.Contract(StakingHubPool, pool.earnContractAddress);
      new Promise((resolve, reject) => {
        contract.methods.withdrawAllReward(address).send({ from: address }).on('transactionHash', function(hash){
          // dispatch(enqueueSnackbar({
          //   message: hash,
          //   options: {
          //     key: new Date().getTime() + Math.random(),
          //     variant: 'success'
          //   },
          //   hash
          // }));
        })
        .on('receipt', function(receipt){
          // console.log(receipt,'123432123e')
          resolve(receipt)
          setOnSuccess(true)
          setOnHash(receipt.transactionHash)
        })
        .on('error', function(error) {
          reject(error)
          setOnError(true)
        })
        .catch((error) => {
          reject(error)
          setOnError(true)
        })
      })
    }
    // console.log(stakingData,'qwertrewqwertre')
    // let cowRe = 0
    // stakingData.forEach(item=>{
    //   cowRe=cowRe+Number(item.cowReward)
    // })
    // let allList = []
    // stakingData.forEach(item=>{
    //   if(item.name ==="FIL"){
    //     allList.push(item)
    //   }
    // })
    // let filRe = 0
    // allList.forEach(item=>{
    //   filRe=filRe+Number(item.reward)
    // })
    function transactionAddress(){
      if(_CURRENT_NETWORK_ID_===128){
        window.open(`https://hecoinfo.com/tx/${onHash}`)
      }else if(_CURRENT_NETWORK_ID_===56){
        window.open( `https://bscscan.com/tx/${onHash}`)
      }else if(_CURRENT_NETWORK_ID_===1){
        window.open(`https://etherscan.io/tx/${onHash}`)
      }
    }
    async function tiQu(){
      let b = await a()
      setIsdia(true)
      setStakingData([...b]);
      let cowRe = 0
      let cowList = []
      b.forEach(item=>{
        cowRe=cowRe+Number(item.cowReward)
      })
      cowList.push({
        name:'COW',
        price:cowRe,
        decimals:18
      })
      let all = []
      b.map(item=>{
        all.push({
          danReward:item.reward,
          name:item.name,
          cowReward:item.cowReward,
          tokenDecimals:item.tokenDecimals
        })
      })
      var obj={},newArr=[];
      all.forEach(function(item,suffix){
        //根据对象的属性是唯一的，将值作为对象的属性名
        if(!obj[item.name]){
          var arr=[];
          arr.push(item);
          newArr.push(arr);
          obj[item.name]=item;
        }else{
          newArr.forEach(function(value,index){
          //如果已经存在 就循环新组的值将值插入属性相同的数组里 为了防止重复添加 只要和第一个比较就可以了
            if(value[0].name==item.name){
              value.push(item)
            }
          })
        }
      })
      let allPriceList = []
      newArr.map(item=>{
        let allPrice = 0
        let danName = null
        let decimals = null
        item.map(el=>{
          allPrice=allPrice+Number(el.danReward)
          danName=el.name
          decimals=el.tokenDecimals
        })
        allPriceList.push({
          price:allPrice,
          name:danName,
          decimals:decimals
        })
      })
      let priceAll =cowList.concat(allPriceList)
      setPriceAllList(priceAll)
    } 
    return <>
      <ul className={`pool__total-tvl pool__total-tvl-${theme} new_total-${theme} new-top`}>
        <li>
            <div>{t('Total-lock-in')}($)</div>
            <div>{formatThousands(forMat(rateAndTvl,2))}</div>
        </li>
        <li>
            <div>{t('v2-ui-20')}($)</div>
            <div>{formatThousands(forMat(rateAndReward,2))}</div>
        </li>
        <li>
            <div>{t('v2-ui-19')}($)</div>
            <div>{formatThousands(forMat(depositedAll,2))}</div>
        </li>
        <li className="drop__info-items">
              <p className="drop__info-item-title">
                {t('v2-ui-18')}($)
                {/* {isH5 === "h5" ? <span className={`${theme}-spans spans-font`} onClick={()=>tiQu()}>{t("Total-title")}</span> : null} */}
              </p>
              <p className={`drop__info-item-texts ${theme}-texts`}>
                {formatThousands(forMat(allEarned,2))}
                {/* {isH5 === "pc" ? <span className={`${theme}-spans spans-font`} onClick={()=>tiQu()}>{t("Total-title")}</span> : null} */}
                <span className={`${theme}-spans spans-font`} onClick={()=>tiQu()}>{t("Total-title")}</span>
              </p>
            </li>
        </ul>
        {
          isDia&& <div className='pools__dialog'>
            <div className='staking_dialog_all-extract'>
                      <div className="staking__dialog__headers" style={{marginBottom:'30px'}}>
                        {t('Position-6')}
                        <span className="staking__dialog__header__closes" onClick={() => setIsdia(false)}></span>
                      </div>
                      <div className='staking_all-extract-content'>
                        <div>
                          <div className='extract-title'>{t('Position-16')}</div>
                          <div className='extract-content_tops'>
                            {
                              priceAllList.map(item=>{
                                return <div className='extract-content_lis'>
                                  <div>{String((item.price/ `1e${item.decimals}`).toFixed(5)).slice(0, -1)}</div>
                                  <div>{item.name}</div>
                                </div>
                              })
                            }
                          </div>
                        </div>
                      </div>
                      <div className='extract_btn'>
                          <Button
                            className={`pools__dialog__option`}
                            // loading={fetchWithdrawIncomePending[index]}
                            // disabled={fetchWithdrawIncomePending[index] || (!Number(forMat(finaCompoundEarned / 1e18, 8)) && !Number(pool.cowReward / 1e18))}
                            onClick={onWithdraw.bind(this, stakingData[0], 1, false, '', true)}
                            type={1}
                          >
                            {t('Staking-tab2')}
                          </Button>
                        </div>
                    </div>
          </div>
        }
        {
          onError&&<div className='g-dialog'>
          <div className='g-dialog__inner'>
            <div className={`g-dialog__content g-dialog__icon--warning`}>{t('v2-ui-8')}</div>
            <div className='g-dialog__button'>
              <Button className={`g-button g-button-${theme}-theme  g-button--normal`} onClick={()=>setOnError(false)}>
                {t('v2-ui-3')}
              </Button>
            </div>
          </div>
        </div>
        }
        {
          onSuccess&&<div className='g-dialog'>
          <div className='g-dialog__inner'>
            <div className='g-dialog__content'>{t('v2-ui-7')}</div>
            <div className='g-dialog__button'>
              <Button className={`g-button g-button-${theme}-theme  g-button--normal`} onClick={()=>transactionAddress()}>
                {t('v2-ui-4')}
              </Button>
              <Button className={`g-button g-button-${theme}-theme  g-button--normal`} onClick={()=>setOnSuccess(false)}>
                {t('v2-ui-1')}
              </Button>
            </div>
          </div>
        </div>
        }
        
    </>
})
