/* eslint-disable */
import React from "react";
import { useSelector } from 'react-redux';
import './Button.css'
export default function Button(props) {
    const theme = useSelector(state => state.common.theme);
    const { children, type = 1, loading, disabled, onClick, className } = props;
    return (
        <button
            onClick={onClick}
            disabled={disabled}
            className={`g-button${className ? ` ${className}` : ''} g-button-${theme}-theme ${type === 2 ? ' g-button--normal' : ''}${loading ? ' g-button--loading' : ''}`}
        >{children}</button>
    );
}
