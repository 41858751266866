import { App } from '../features/home';
import { PageNotFound } from '../features/common';
import HomeRoute from '../features/home/route';
import { VaultRoute } from '../features/vault/route';
import {  LPRoute } from '../features/lp/route';
import {  BonusRoute } from '../features/bonus/route';
import {  DestructionRoute } from '../features/destruction/route';
import {  AssetExchangeRoute } from '../features/assetExchange/route';
import {StakingRoute} from '../features/staking/route'
import _ from 'lodash';

// NOTE: DO NOT CHANGE the 'childRoutes' name and the declaration pattern.
// This is used for Rekit cmds to register routes config for new features, and remove config when remove features, etc.
const childRoutes = [
  // 首页
  HomeRoute,
  // 控制
  // ControlRoute,
  // v2普通改版
  VaultRoute,
  LPRoute,
  BonusRoute,
  DestructionRoute,
  AssetExchangeRoute,
  StakingRoute
];

const routes = [{
  path: '/',
  component: App,
  childRoutes: [
    ...childRoutes,
    { path: '*', name: 'Page not found', component: PageNotFound },
  ].filter(r => r.component || (r.childRoutes && r.childRoutes.length > 0)),
}];

// Handle isIndex property of route config:
//  Dupicate it and put it as the first route rule.
function handleIndexRoute(route) {
  if (!route.childRoutes || !route.childRoutes.length) {
    return;
  }

  const indexRoute = _.find(route.childRoutes, (child => child.isIndex));
  if (indexRoute) {
    const first = { ...indexRoute };
    first.path = '';
    first.exact = true;
    first.autoIndexRoute = true; // mark it so that the simple nav won't show it.
    route.childRoutes.unshift(first);
  }
  route.childRoutes.forEach(handleIndexRoute);
}

routes.forEach(handleIndexRoute);
export default routes;
