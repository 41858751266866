import React from 'react';
import { useTranslation } from 'react-i18next';
import { isMobile } from 'features/helpers/utils';
import { useSelector } from 'react-redux';
import '../jss/sections/SectionTitle.css'
const pic = {
    "zh-eth-pc":require("../../../assets/img/ethbanner/web/banner-wusun-eth-zhong.png"),
    "en-eth-pc":require("../../../assets/img/ethbanner/web/banner-wusun-eth-english.png"),
    "zh-heco-pc":require("../../../assets/img/banner-wusun.png"),
    "en-heco-pc":require("../../../assets/img/banner-wusun-english.png"),
    "zh-bsc-pc":require("../../../assets/img/banner-wusun-bsc.png"),
    "en-bsc-pc":require("../../../assets/img/banner-wusun-english-bsc.png"),
    "zh-eth-h5":require("../../../assets/img/ethbanner/h5/h5banner-wusun-eth-zhong@3x.png"),
    "en-eth-h5":require("../../../assets/img/ethbanner/h5/h5banner-wusun-eth-english@3x.png"),
    "zh-heco-h5":require("../../../assets/img/banner-h5-wusun-heco.png"),
    "en-heco-h5":require("../../../assets/img/banner-h5-wusun-english-heco.png"),
    "zh-bsc-h5":require("../../../assets/img/banner-h5-wusun-bsc.png"),
    "en-bsc-h5":require("../../../assets/img/banner-h5-wusun-english-bsc.png"),
}
export default props => {
    const isH5 = isMobile()?'h5':'pc';
    const { i18n } = useTranslation();
    const lang = (i18n.language==='zh-CN' || i18n.language==='zh')?'zh':'en'
    const theme = useSelector(state => state.common.theme);
    return <div className="section-banner">
        <img src={pic[`${lang}-${theme}-${isH5}`]} alt=""/>
    </div>
}