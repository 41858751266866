/* eslint-disable */
import React, { memo } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from 'react-redux';
import './DropDownStakingState.css'
export default memo(function DrowDown({ opened,buttonText, buttonClickCallback, dropDownList, className, position }) {
    const {t,i18n} = useTranslation()
    const theme = useSelector(state => state.common.theme);
    const lang = (i18n.language === 'zh' || i18n.language === 'zh-CN') ? 'zh' : 'en';
    // console.log(lang,'456789098')
    return <>
        <div className={`dropdown__button`}>
            <span>{buttonText}</span>
        </div>
        <div className={`${opened ? 'option__show-content' : 'option__show_dis'}`}>
            {
                dropDownList.map((item, ind) => {
                    return (
                        <div className='zi_content' key={ind} onClick={(e)=>{
                            e.stopPropagation();
                            buttonClickCallback(item.text);
                        }}>
                            {item.text}
                        </div>
                    )
                })
            }
        </div>
    </>


})
