import { useCallback } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';

import { useBasicInfo } from 'features/configure/pools';
import {
  OPEN_POOL_DIALOG
} from './constants';

export function useFilterPools() {
  // args: false value or array
  // if array, means args passed to the action creator
  const dispatch = useDispatch();

  // const { tokens, pools } = useSelector(
  //   state => ({
  //     tokens: state.vault.tokens,
  //     pools: state.vault.pools,
  //   }),
  //   shallowEqual,
  // );
  const { tokens, pools } = useBasicInfo();

  const setPools = useCallback(
    (data) => {
      // console.log('setPools',pools)

      dispatch({type:OPEN_POOL_DIALOG,data:pools.slice(0,3)});
    },
    [dispatch, pools],
  );

  return {
    setPools
  };
}

export function reducer(state, action) {
  switch (action.type) {
    case OPEN_POOL_DIALOG:
      return {
        ...state,
        // pools:action.data
      };
    default:
      return state;
  }
}