export function cow_bsc_info_ctoken() {
  const earnContractAddress = "0x410f3E7B68A934e76BBB6fd7fd9E0663ea75da27";
  const USDTAddress = '0x55d398326f99059fF775485246999027B3197955';
  const aggAddress = "0xfb03520e5a38d3e59F4cA213c712eAC2092A0c5c";
  const MdxAddress = "0x9C65AB58d8d978DB963e63f2bfB7121627e3a739";
  const cowAddress = "0x422E3aF98bC1dE5a1838BE31A56f75DB4Ad43730";
  const migrator = "0x8B7a78A8ECdC97a16a1b46939771C09D277D2267";
  const pools = [
    {
      // 标识新的，表示开放额度
      type: '',
      // 开放额度
      openAmount: "3,000",
      // 开放时间
      openDate: "2021.04.12(GTM+8)",
      // 开放了吗
      disabled: false,
      id: 'BTCB',
      name: 'BTCB',
      names: 'CBTCB',
      token: 'BTCB',
      tokenDescription: 'BTCB',
      tokenAddress: '0x7130d2a12b9bcbfae4f2634d864a1ee1ce3ead9c',
      ctokenAddress: '0xfc4703D7feC63E9241b68ce0B7D746882655d683',
      tokenDecimals: 18,
      itokenDecimals: 18,
      depostLimit: 0,
      tokenDescriptionUrl: '',
      tokenDescriptionUrl2: '',
      earnedToken: 'MDX',
      earnedTokenAddress: '0x9C65AB58d8d978DB963e63f2bfB7121627e3a739',
      earnContractAddress,
      defaultApy: "48.04",
      pricePerFullShare: 1,
      pastPricePerFullShare: 1,
      rateMdx: 391,
      total: 3000,
      // 是否上首页
      // topHome: true,
      conduct: true,
    },
    {
      // 标识新的，表示开放额度
      type: '',
      // 开放额度
      openAmount: "150,000,000",
      // 开放时间
      openDate: "2021.04.12",
      // 开放了吗
      disabled: false,
      id: 'USDT',
      name: 'USDT',
      names: 'CUSDT',
      token: 'USDT',
      tokenDescription: 'USDT',
      tokenAddress: USDTAddress,
      ctokenAddress: "0x15339d57FBf0b06de5c5e39c756954813732Ab9a",
      tokenDecimals: 18,
      itokenDecimals: 18,
      depostLimit: 0,
      tokenDescriptionUrl: '',
      tokenDescriptionUrl2: '',
      earnedToken: 'MDX',
      earnedTokenAddress: '0x9C65AB58d8d978DB963e63f2bfB7121627e3a739',
      earnContractAddress,
      defaultApy: "64.30",
      pricePerFullShare: 1,
      pastPricePerFullShare: 1,
      rateMdx: 0.169315,
      total: 150000000,
      // 是否上首页
      // topHome: true,
      conduct: true,
    },
    {
      // 标识新的，表示开放额度
      type: '',
      // 开放额度
      openAmount: "40,000",
      // 开放时间
      openDate: "2021.04.12(GTM+8)",
      // 开放了吗
      disabled: false,
      id: 'ETH',
      name: 'ETH',
      names: 'CETH',
      token: 'ETH',
      tokenDescription: 'ETH',
      tokenAddress: '0x2170ed0880ac9a755fd29b2688956bd959f933f8',
      ctokenAddress: '0x9A50c643D62433660dC9bAcDFB28AbB52F0C0140',
      tokenDecimals: 18,
      itokenDecimals: 18,
      depostLimit: 0,
      tokenDescriptionUrl: '',
      tokenDescriptionUrl2: '',
      earnedToken: 'MDX',
      earnedTokenAddress: '0x9C65AB58d8d978DB963e63f2bfB7121627e3a739',
      earnContractAddress,
      defaultApy: "60.04",
      pricePerFullShare: 1,
      pastPricePerFullShare: 1,
      rateMdx: 391,
      total: 40000,
      conduct: true,
    },
    {
      // 标识新的，表示开放额度
      type: '',
      // 开放额度
      openAmount: "60,000,000",
      // 开放时间
      openDate: "2021.04.12(GTM+8)",
      // 开放了吗
      disabled: false,
      id: 'BUSD',
      name: 'BUSD',
      names: 'CBUSD',
      token: 'BUSD',
      tokenDescription: 'BUSD',
      tokenAddress: '0xe9e7cea3dedca5984780bafc599bd69add087d56',
      ctokenAddress: '0xc06a860a7d68eEF9628d6196Eaf310A8A683670A',
      tokenDecimals: 18,
      itokenDecimals: 18,
      depostLimit: 0,
      tokenDescriptionUrl: '',
      tokenDescriptionUrl2: '',
      earnedToken: 'MDX',
      earnedTokenAddress: '0x9C65AB58d8d978DB963e63f2bfB7121627e3a739',
      earnContractAddress,
      defaultApy: "63.00",
      pricePerFullShare: 1,
      pastPricePerFullShare: 1,
      rateMdx: 391,
      total: 60000000,
      // 是否上首页
      topHome: true,
      conduct: true,
    },
    {
      // 标识新的，表示开放额度
      // type: 'NEW',
      // 开放额度
      openAmount: "10,000,000",
      // 开放时间
      openDate: "2021.06.18(GTM+8)",
      // 开放了吗
      disabled: false,
      id: 'USDC',
      name: 'USDC',
      names: 'CUSDC',
      token: 'USDC',
      tokenDescription: 'USDC',
      tokenAddress: '0x8AC76a51cc950d9822D68b83fE1Ad97B32Cd580d',
      ctokenAddress: '0x4227dBE49c9974605916f7FBAB24a0076F8d8C1E',
      tokenDecimals: 18,
      itokenDecimals: 18,
      depostLimit: 0,
      tokenDescriptionUrl: '',
      tokenDescriptionUrl2: '',
      earnedToken: 'MDX',
      earnedTokenAddress: '0x9C65AB58d8d978DB963e63f2bfB7121627e3a739',
      earnContractAddress,
      defaultApy: "130.10",
      pricePerFullShare: 1,
      pastPricePerFullShare: 1,
      rateMdx: 1,
      total: 10000000,
      // 是否上首页
      // topHome: true,
      conduct: true,
    },


    // {
    //   // 标识新的，表示开放额度
    //   type: '',
    //   // 开放额度
    //   openAmount: "120,000",
    //   // 开放时间
    //   openDate: "2021.04.12(GTM+8)",
    //   // 开放了吗
    //   disabled: false,
    //   id: 'WBNB',
    //   name: 'WBNB',
    //   token: 'WBNB',
    //   tokenDescription: 'WBNB',
    //   tokenAddress: '0xbb4cdb9cbd36b01bd1cbaebf2de08d9173bc095c',
    //   tokenDecimals: 18,
    //   itokenDecimals: 18,
    //   depostLimit: 0,
    //   tokenDescriptionUrl: '',
    //   tokenDescriptionUrl2: '',
    //   earnedToken: 'MDX',
    //   earnedTokenAddress: '0x9C65AB58d8d978DB963e63f2bfB7121627e3a739',
    //   earnContractAddress,
    //   defaultApy: "130.10",
    //   pricePerFullShare: 1,
    //   pastPricePerFullShare: 1,
    //   rateMdx: 1,
    //   total: 120000,
    //   // 是否上首页
    //   topHome: true,
    //   conduct: true,
    // },

   
   
   
    // {
    //   // 标识新的，表示开放额度
    //   type: '',
    //   // 开放额度
    //   openAmount: "50,000",
    //   // 开放时间
    //   openDate: "2021.04.12",
    //   // 开放了吗
    //   disabled: false,
    //   id: 'FIL',
    //   name: 'FIL',
    //   token: 'FIL',
    //   tokenDescription: 'FIL',
    //   tokenAddress: "0x0D8Ce2A99Bb6e3B7Db580eD848240e4a0F9aE153",
    //   tokenDecimals: 18,
    //   itokenDecimals: 18,
    //   depostLimit: 0,
    //   tokenDescriptionUrl: '',
    //   tokenDescriptionUrl2: '',
    //   earnedToken: 'MDX',
    //   earnedTokenAddress: '0x9C65AB58d8d978DB963e63f2bfB7121627e3a739',
    //   earnContractAddress,
    //   defaultApy: "64.30",
    //   pricePerFullShare: 1,
    //   pastPricePerFullShare: 1,
    //   rateMdx: 0.169315,
    //   total: 50000,
    //   // 是否上首页
    //   topHome: true,
    //   conduct: true,
    // },
    // {
    //   // 标识新的，表示开放额度
    //   type: '',
    //   // 开放额度
    //   openAmount: "10,000,000",
    //   // 开放时间
    //   openDate: "2021.04.12(GTM+8)",
    //   // 开放了吗
    //   disabled: false,
    //   id: 'CAKE',
    //   name: 'CAKE',
    //   token: 'CAKE',
    //   tokenDescription: 'CAKE',
    //   tokenAddress: '0x0E09FaBB73Bd3Ade0a17ECC321fD13a19e81cE82',
    //   tokenDecimals: 18,
    //   itokenDecimals: 18,
    //   depostLimit: 0,
    //   tokenDescriptionUrl: '',
    //   tokenDescriptionUrl2: '',
    //   earnedToken: 'MDX',
    //   earnedTokenAddress: '0x9C65AB58d8d978DB963e63f2bfB7121627e3a739',
    //   earnContractAddress,
    //   defaultApy: "405.30",
    //   pricePerFullShare: 1,
    //   pastPricePerFullShare: 1,
    //   rateMdx: 1,
    //   total: 500000,
    //   // 是否上首页
    //   topHome: false,
    //   conduct: true,
    // },
   
    // {
    //   // 标识新的，表示开放额度
    //   type: '',
    //   // 开放额度
    //   openAmount: "10,000,000",
    //   // 开放时间
    //   openDate: "2021.04.12(GTM+8)",
    //   // 开放了吗
    //   disabled: false,
    //   id: 'MDX',
    //   name: 'MDX',
    //   token: 'MDX',
    //   tokenDescription: 'MDX',
    //   tokenAddress: '0x9C65AB58d8d978DB963e63f2bfB7121627e3a739',
    //   tokenDecimals: 18,
    //   itokenDecimals: 18,
    //   depostLimit: 0,
    //   tokenDescriptionUrl: '',
    //   tokenDescriptionUrl2: '',
    //   earnedToken: 'MDX',
    //   earnedTokenAddress: '0x9C65AB58d8d978DB963e63f2bfB7121627e3a739',
    //   earnContractAddress,
    //   defaultApy: "405.30",
    //   pricePerFullShare: 1,
    //   pastPricePerFullShare: 1,
    //   rateMdx: 1,
    //   total: 10000000,
    //   // 是否上首页
    //   // topHome: true,
    //   conduct: true,
    // },
    // {
    //   // 标识新的，表示开放额度
    //   type: '',
    //   // 开放额度
    //   openAmount: "10,000,000",
    //   // 开放时间
    //   openDate: "2021.04.12(GTM+8)",
    //   // 开放了吗
    //   disabled: false,
    //   id: 'DOT',
    //   name: 'DOT',
    //   token: 'DOT',
    //   tokenDescription: 'DOT',
    //   tokenAddress: '0x7083609fCE4d1d8Dc0C979AAb8c869Ea2C873402',
    //   tokenDecimals: 18,
    //   itokenDecimals: 18,
    //   depostLimit: 0,
    //   tokenDescriptionUrl: '',
    //   tokenDescriptionUrl2: '',
    //   earnedToken: 'MDX',
    //   earnedTokenAddress: '0x9C65AB58d8d978DB963e63f2bfB7121627e3a739',
    //   earnContractAddress,
    //   defaultApy: "405.30",
    //   pricePerFullShare: 1,
    //   pastPricePerFullShare: 1,
    //   rateMdx: 1,
    //   total: 200000,
    //   // 是否上首页
    //   topHome: false,
    //   conduct: true,
    // },
    // {
    //   // 标识新的，表示开放额度
    //   type: '',
    //   // 开放额度
    //   openAmount: "1,000,000",
    //   // 开放时间
    //   openDate: "2021.04.12(GTM+8)",
    //   // 开放了吗
    //   disabled: false,
    //   id: 'COW',
    //   name: 'COW',
    //   token: 'COW',
    //   tokenDescription: 'COW',
    //   tokenAddress: '0x422E3aF98bC1dE5a1838BE31A56f75DB4Ad43730',
    //   tokenDecimals: 18,
    //   itokenDecimals: 18,
    //   depostLimit: 0,
    //   tokenDescriptionUrl: '',
    //   tokenDescriptionUrl2: '',
    //   earnedToken: 'MDX',
    //   earnedTokenAddress: '0x9C65AB58d8d978DB963e63f2bfB7121627e3a739',
    //   earnContractAddress,
    //   defaultApy: "405.30",
    //   pricePerFullShare: 1,
    //   pastPricePerFullShare: 1,
    //   rateMdx: 1,
    //   total: 1000000,
    //   conduct: true,
    // },
  ]

  return {
    earnContractAddress,
    USDTAddress,
    aggAddress,
    MdxAddress,
    cowAddress,
    pools,
    migrator
  }
}