/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useCallback, useEffect } from 'react';
import axios from 'axios';
import { CowAirdop, erc20ABI, AggregateHub, AggregateHubNew,DaoConfigure } from 'features/configure/abi';
import { useSnackbar } from 'notistack';
import { useConnectWallet } from '../../home/redux/hooks';
import { useSelector } from 'react-redux';

import { useBasicInfo } from "../../configure/pools";
import { ctrAddressMap,_IS_BSC_,_IS_CURRENT_NETWORK_ID_SUPPORTED_,_IS_ETH_ ,_CURRENT_NETWORK_ID_} from '../../configure/chainsParamsConfig';

export { useFetchApproval } from './fetchApproval';
export { useFetchDeposit } from './fetchDeposit';
export { useFetchWithdraw } from './fetchWithdraw';
export { useFetchContractApy } from './fetchContractApy';

// 获取COW余额
export function useFetchCowBalances() {
    const { web3, address } = useConnectWallet();
    const [cowBalance, setCowBalance] = useState(0);
    const {cowAddress} = useBasicInfo();
    const getBalance = useCallback(async () => {
        if (!web3 || !_IS_CURRENT_NETWORK_ID_SUPPORTED_) return;
        const contract = new web3.eth.Contract(erc20ABI, cowAddress)
        const balance = await contract.methods.balanceOf(address).call({ from: address });
        setCowBalance(balance/1e18)
    }, [web3, address])
    useEffect(() => {
        getBalance()
        const timer = setInterval(() => {
            getBalance()
        }, 5000);
        return () => clearInterval(timer)
    }, [web3, address])
    return {
        cowBalance,
    }
}

// 获取空投 领取空投
export function useFetchDrop() {
    const { web3,networkId, address } = useConnectWallet();
    const { enqueueSnackbar } = useSnackbar();
    const [dropPending, setDropPending] = useState(false)
    const [reward, setReward] = useState(0)
    const ctrAddress = ctrAddressMap[networkId]?ctrAddressMap[networkId]:ctrAddressMap['128'];
    const getDrop = useCallback(async () => {
        if(_IS_ETH_) return;
        const contract = new web3.eth.Contract(CowAirdop, ctrAddress)
        const rewards = await contract.methods.userReward(address).call({ from: address });
        setReward(rewards/1e18)
    }, [web3, address])
    const onDrop = useCallback(() => {
        setDropPending(true)
        const contract = new web3.eth.Contract(CowAirdop, ctrAddress)
        contract.methods.takeReward().send({ from: address }).on('transactionHash', function(hash){
            enqueueSnackbar(`领取中:${hash}`, {
                variant: 'success', anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'center',
                },
                autoHideDuration: 4000
            })
        })
        .on('receipt', function (receipt) {
            enqueueSnackbar(`领取成功`, {
                variant: 'success', anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'center',
                },
                autoHideDuration: 4000
            })
            setDropPending(false);
        })
        .on('error', function (error) {
            enqueueSnackbar(`错误${JSON.stringify(error)}`, {
                variant: 'error', anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'center',
                },
                autoHideDuration: 4000
            })
            setDropPending(false);
        })
        .catch((error) => {
            enqueueSnackbar(`错误${JSON.stringify(error)}`, {
                variant: 'error', anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'center',
                },
                autoHideDuration: 4000
            })
            setDropPending(false);
        });
    })
    useEffect(() => {
        const timer = setInterval(() => {
            getDrop()
        }, 5000);
        return () => clearInterval(timer)
    }, [web3, address])
    return {
        reward,
        dropPending,
        onDrop,
    }
}

// 获取单币列表数据
export function usePoolList(callback) {
    const {pools, earnContractAddress: EARN_CONTRACT_ADDRESS, aggAddress} = useBasicInfo();
    const { web3, address } = useConnectWallet();
    const tabActive = useSelector(state => state.vault.tabActive);
    const getPoolList = useCallback(async () => {
        if(!web3 || !_IS_CURRENT_NETWORK_ID_SUPPORTED_){
            return;
        }
        const contract = new web3.eth.Contract(AggregateHubNew, aggAddress);
        const arr = [];
        for (let pool of pools) {
            const poolInfo = await contract.methods.getPoolInfo(EARN_CONTRACT_ADDRESS, pool.tokenAddress, address, tabActive).call().catch(e => {
                // console.log(EARN_CONTRACT_ADDRESS, pool.tokenAddress, "EARN_CONTRACT_ADDRESS");
            });
            arr.push({...pool, ...poolInfo});
        }
        callback(arr);
    }, [web3, address, tabActive])
    useEffect(() => {
        getPoolList()
        const timer = setInterval(() => {
            getPoolList();
        }, 5000);
        return () => clearInterval(timer)
    }, [getPoolList])
}

// 单个币种对U汇率
export function useCoinRate(tokenAddress) {
    const { web3 } = useConnectWallet();
    const {aggAddress, MdxAddress} = useBasicInfo();
    const [tokenURate, setTokenURate] = useState({});
    const [tokenRate, setTokenRate] = useState({}); 
    const fetchRate = useCallback(async () => {
        if (!web3 || !_IS_CURRENT_NETWORK_ID_SUPPORTED_) { return }
        const contract = new web3.eth.Contract(AggregateHub, aggAddress);
        const rate = await contract.methods.getUsdtRate(tokenAddress, _IS_BSC_ ? 1 : 0, false).call().catch(e => console.log(e));
        // mdx兑usdt的价格
        const mdxUsdtRate = await contract.methods.getUsdtRate(MdxAddress, _IS_BSC_ ? 1 : 0, false).call().catch(0);
        setTokenURate(rate/mdxUsdtRate);
        setTokenRate(rate);
    })
    useEffect(() => {
        fetchRate();
        let refreshInterval = setInterval(fetchRate, 300000);
        return () => clearInterval(refreshInterval);
    }, [web3, fetchRate])
    return {
        tokenURate,
        tokenRate,
    }
}

// 所有币种汇率
export function useAllRate(isLp) {
    const { web3 } = useConnectWallet();
    const {pools, aggAddress, MdxAddress,cowAddress} = useBasicInfo();
    const [poolRate, setPoolRate] = useState(false);
    const [coinRate, setCoinRate] = useState({});
    const tabActive = useSelector(state => state.vault.tabActive);

    const fetchRate = useCallback(async () => {
        if (!web3 || !_IS_CURRENT_NETWORK_ID_SUPPORTED_) { return }
        const contract = new web3.eth.Contract(AggregateHub, aggAddress);
        // mdx兑usdt的价格
        const mdxUsdtRate = await contract.methods.getUsdtRate(_IS_ETH_?cowAddress:MdxAddress, _IS_BSC_ ? 1 : 0, false).call().catch(0);
        if(mdxUsdtRate <= 0){
            return;
        }
        let returnRate = poolRate || {};
        let returnCoinRate = coinRate || {};
        //getTokenUsdtRate 接口返回的汇率放大了1e18倍
        // returnRate[MDXAddress] = mdxUsdtRate/1e18;
        pools.forEach(async pool => {
            const rate = await contract.methods.getUsdtRate(pool.tokenAddress, _IS_BSC_ ? 1 : 0, isLp).call().catch(0);
            if(rate <= 0){
                return;
            }
            //token兑换mdx的价格, 比如一个btc兑换usdt的价格
            const tokenMdxRate = rate/mdxUsdtRate;
            returnRate[pool.tokenAddress] = tokenMdxRate;
            returnCoinRate[pool.tokenAddress] = rate;
        })
        setPoolRate(returnRate);
        setCoinRate(returnCoinRate);
    }, [web3, isLp, tabActive])

    useEffect(() => {
        fetchRate();
        let refreshInterval = setInterval(fetchRate, 300000);
        return () => clearInterval(refreshInterval);
    }, [fetchRate])

    return {poolRate, coinRate}
}

// 单链 总锁仓 总收益

export function useAggregate(isBscChain) {
    const { web3, address } = useConnectWallet();
    const {earnContractAddress: EARN_CONTRACT_ADDRESS, aggAddress: AGG_ADDRESS} = useBasicInfo();
    const [data, setApy] = useState([]);
    let chainType = isBscChain? 1 : 0;
    const fetchApy = useCallback(async () => {
        if (!web3 || !_IS_CURRENT_NETWORK_ID_SUPPORTED_) { return }
        const contract = new web3.eth.Contract(AggregateHub, AGG_ADDRESS);
        contract.options.gas = 18600000;
        const data = await contract.methods.statePoolAndUserTokenUsd(EARN_CONTRACT_ADDRESS,address,chainType,false).call().catch(e => console.log(e))
        setApy(data);
    }, [isBscChain, address, EARN_CONTRACT_ADDRESS])

    useEffect(() => {
        if (web3 && address) {
            fetchApy()
        }
        let refreshInterval = setInterval(fetchApy, 10000)
        return () => clearInterval(refreshInterval)
    }, [web3, fetchApy])

    return data
}

// 所有链所有项目 总锁仓量 及 收益
export function useTvlAndProfit() {
    const [tvl, setTvl] = useState('');
    const [profit, setProfit] = useState('');
    const [cowProfit, setCowProfit] = useState('');
    const origin = window.location.origin;
    const fetchTvlAndProfit = useCallback(async () => {
        const url = `${origin}/api/defi/tvlAndProfit`;
        axios.get(url).then((res) => {
            if(res.data.code==='0'){
                setTvl(res.data.data.tvl);
                setProfit(res.data.data.profit);
                setCowProfit(res.data.data.cowProfit);
            } else {
                tvl ? setTvl(tvl) : setTvl(1703111851.26);
                profit ? setProfit(profit) : setProfit(45202302.67);
                cowProfit ? setCowProfit(cowProfit) : setCowProfit(0);
            }
        });
    }, [origin])

    useEffect(() => {
        if(!_IS_CURRENT_NETWORK_ID_SUPPORTED_) return;
        fetchTvlAndProfit()
        let refreshInterval = null;
        window.clearInterval(refreshInterval)
        refreshInterval = setInterval(() => {
            fetchTvlAndProfit()
        }, 10000)
        return () => clearInterval(refreshInterval)
    }, [fetchTvlAndProfit])
    return {
        tvl,
        profit,
        cowProfit,
    }
}
// 回购销毁数据
export function useRepurchaseDestruction() {
    const [mulChainBurnTvl, setMulChainBurnTvl] = useState(0);
    const [mulChainBurnCowTvl, setMulChainBurnCowTvl] = useState(0);
    const [mulChainUnUseTvl, setMulChainUnUseTvl] = useState(0);
    const [curChainBurnTvl, setCurChainBurnTvl] = useState(0);
    const [curChainBurnCowTvl, setCurChainBurnCowTvl] = useState(0);
    const [curChainUnUseTvl, setCurChainUnUseTvl] = useState(0);
    const [historyCow2Usdt, setHistoryCow2Usdt] = useState(0);
    const [cow2Usdt, setCow2Usdt] = useState(0);
    const [averageTime, setAverageTime] = useState(0);
    const origin = window.location.origin=='http://localhost:3000'? "http://47.93.150.214:9998":window.location.origin;
    const fetchRepurchaseDestruction = useCallback(async () => {
        const url = `${origin}/api/defi/dao/burn?chainId=${_CURRENT_NETWORK_ID_}`;
        const res ={
            code:"0",
            msg:"",
            data:{
                mulChain:{
                    burnTvl:"111.3",
                    burnCowTvl:"100.2",
                    unUseTvl:"10000"
                },
                curChain:{
                    burnTvl:"11.3",
                    burnCowTvl:"10.2",
                    unUseTvl:"700"
                },
                historyCow2Usdt:"1.3",
                cow2Usdt:"1.2"
            }
        }
        axios.get(url).then((res) => {
            // console.log(res)
            if(res.data.code==='0'){
                setMulChainBurnTvl(res.data.data.mulChain.burnTvl);
                setMulChainBurnCowTvl(res.data.data.mulChain.burnCowTvl);
                setMulChainUnUseTvl(res.data.data.mulChain.unUseTvl);
                setCurChainBurnTvl(res.data.data.curChain.burnTvl);
                setCurChainBurnCowTvl(res.data.data.curChain.burnCowTvl);
                setCurChainUnUseTvl(res.data.data.curChain.unUseTvl);
                setHistoryCow2Usdt(res.data.data.historyCow2Usdt);
                setCow2Usdt(res.data.data.cow2Usdt);
                setAverageTime(res.data.data.averageTime)
            } else {
                mulChainBurnTvl ? setMulChainBurnTvl(mulChainBurnTvl) : setMulChainBurnTvl();
                mulChainBurnCowTvl ? setMulChainBurnCowTvl(mulChainBurnCowTvl) : setMulChainBurnCowTvl();
                mulChainUnUseTvl ? setMulChainUnUseTvl(mulChainUnUseTvl) : setMulChainUnUseTvl();
                curChainBurnTvl ? setCurChainBurnTvl(curChainBurnTvl) : setCurChainBurnTvl();
                curChainBurnCowTvl ? setCurChainBurnCowTvl(curChainBurnCowTvl) : setCurChainBurnCowTvl();
                curChainUnUseTvl ? setCurChainUnUseTvl(curChainUnUseTvl) : setCurChainUnUseTvl();
                historyCow2Usdt ? setHistoryCow2Usdt(historyCow2Usdt) : setHistoryCow2Usdt();
                cow2Usdt ? setCow2Usdt(cow2Usdt) : setCow2Usdt();
                averageTime ? setAverageTime(averageTime) : setAverageTime();
            }
        });
    }, [origin])

    useEffect(() => {
        if(!_IS_CURRENT_NETWORK_ID_SUPPORTED_) return;
        fetchRepurchaseDestruction()
        let refreshInterval = null;
        window.clearInterval(refreshInterval)
        refreshInterval = setInterval(() => {
            fetchRepurchaseDestruction()
        }, 10000)
        return () => clearInterval(refreshInterval)
    }, [fetchRepurchaseDestruction])
    return {
        mulChainBurnTvl,
        mulChainBurnCowTvl,
        mulChainUnUseTvl ,
        curChainBurnTvl,
        curChainBurnCowTvl,
        curChainUnUseTvl,
        historyCow2Usdt ,
        cow2Usdt,
        averageTime
    }
}
// 回购记录
export function useRepurchaseRecord() {
    const [dataList, setDataList] = useState([]);
    const origin = window.location.origin=='http://localhost:3000'? "http://47.93.150.214:9998":window.location.origin;
    const fetchBoardDividend = useCallback(async () => {
        const url = `${origin}/api/defi/dao/burnTx?chainId=${_CURRENT_NETWORK_ID_}`;
        const res = {
                code:'0',
                data:{
                    list:[{"txid":"0x5283fc5c88c7ee7ffc2182a9517a390de2c45e0ecdac92eaa9f2ad6dc785abf5","blcokNum":"6681097","cow2Usdt":"1.232","burnCow":"100.2122127644562","burnCow2Usdt":"110.00"},
                    {"txid":"0x5283fc5c88c7ee7ffc2182a9517a390de2c45e0ecdac92eaa9f2ad6dc785abf5","blcokNum":"6681097","cow2Usdt":"1.232","burnCow":"100.2122127644562","burnCow2Usdt":"110.00"},
                    {"txid":"0x5283fc5c88c7ee7ffc2182a9517a390de2c45e0ecdac92eaa9f2ad6dc785abf5","blcokNum":"6681097","cow2Usdt":"1.232","burnCow":"100.2122127644562","burnCow2Usdt":"110.00"},
                    {"txid":"0x5283fc5c88c7ee7ffc2182a9517a390de2c45e0ecdac92eaa9f2ad6dc785abf5","blcokNum":"6681097","cow2Usdt":"1.232","burnCow":"100.2122127644562","burnCow2Usdt":"110.00"},
                    {"txid":"0x5283fc5c88c7ee7ffc2182a9517a390de2c45e0ecdac92eaa9f2ad6dc785abf5","blcokNum":"6681097","cow2Usdt":"1.232","burnCow":"100.2122127644562","burnCow2Usdt":"110.00"},
                    {"txid":"0x5283fc5c88c7ee7ffc2182a9517a390de2c45e0ecdac92eaa9f2ad6dc785abf5","blcokNum":"6681097","cow2Usdt":"1.232","burnCow":"100.2122127644562","burnCow2Usdt":"110.00"},
                    {"txid":"0x5283fc5c88c7ee7ffc2182a9517a390de2c45e0ecdac92eaa9f2ad6dc785abf5","blcokNum":"6681097","cow2Usdt":"1.232","burnCow":"100.2122127644562","burnCow2Usdt":"110.00"},
                    {"txid":"0x5283fc5c88c7ee7ffc2182a9517a390de2c45e0ecdac92eaa9f2ad6dc785abf5","blcokNum":"6681097","cow2Usdt":"1.232","burnCow":"100.2122127644562","burnCow2Usdt":"110.00"},
                    {"txid":"0x5283fc5c88c7ee7ffc2182a9517a390de2c45e0ecdac92eaa9f2ad6dc785abf5","blcokNum":"6681097","cow2Usdt":"1.232","burnCow":"100.2122127644562","burnCow2Usdt":"110.00"},
                    {"txid":"0x5283fc5c88c7ee7ffc2182a9517a390de2c45e0ecdac92eaa9f2ad6dc785abf5","blcokNum":"6681097","cow2Usdt":"1.232","burnCow":"100.2122127644562","burnCow2Usdt":"110.00"},
                    {"txid":"0x5283fc5c88c7ee7ffc2182a9517a390de2c45e0ecdac92eaa9f2ad6dc785abf5","blcokNum":"6681097","cow2Usdt":"1.232","burnCow":"100.2122127644562","burnCow2Usdt":"110.00"},
                    {"txid":"0x5283fc5c88c7ee7ffc2182a9517a390de2c45e0ecdac92eaa9f2ad6dc785abf5","blcokNum":"6681097","cow2Usdt":"1.232","burnCow":"100.2122127644562","burnCow2Usdt":"110.00"},
                    {"txid":"0x5283fc5c88c7ee7ffc2182a9517a390de2c45e0ecdac92eaa9f2ad6dc785abf5","blcokNum":"6681097","cow2Usdt":"1.232","burnCow":"100.2122127644562","burnCow2Usdt":"110.00"},
                    {"txid":"0x5283fc5c88c7ee7ffc2182a9517a390de2c45e0ecdac92eaa9f2ad6dc785abf5","blcokNum":"6681097","cow2Usdt":"1.232","burnCow":"100.2122127644562","burnCow2Usdt":"110.00"},
                    {"txid":"0x5283fc5c88c7ee7ffc2182a9517a390de2c45e0ecdac92eaa9f2ad6dc785abf5","blcokNum":"6681097","cow2Usdt":"1.232","burnCow":"100.2122127644562","burnCow2Usdt":"110.00"},
                    {"txid":"0x5283fc5c88c7ee7ffc2182a9517a390de2c45e0ecdac92eaa9f2ad6dc785abf5","blcokNum":"6681097","cow2Usdt":"1.232","burnCow":"100.2122127644562","burnCow2Usdt":"110.00"},
                    {"txid":"0x5283fc5c88c7ee7ffc2182a9517a390de2c45e0ecdac92eaa9f2ad6dc785abf5","blcokNum":"6681097","cow2Usdt":"1.232","burnCow":"100.2122127644562","burnCow2Usdt":"110.00"},
                    {"txid":"0x5283fc5c88c7ee7ffc2182a9517a390de2c45e0ecdac92eaa9f2ad6dc785abf5","blcokNum":"6681097","cow2Usdt":"1.232","burnCow":"100.2122127644562","burnCow2Usdt":"110.00"},
                    {"txid":"0x5283fc5c88c7ee7ffc2182a9517a390de2c45e0ecdac92eaa9f2ad6dc785abf5","blcokNum":"6681097","cow2Usdt":"1.232","burnCow":"100.2122127644562","burnCow2Usdt":"110.00"},
                    {"txid":"0x5283fc5c88c7ee7ffc2182a9517a390de2c45e0ecdac92eaa9f2ad6dc785abf5","blcokNum":"6681097","cow2Usdt":"1.232","burnCow":"100.2122127644562","burnCow2Usdt":"110.00"}]
                }
        }
        
        axios.get(url).then((res) => {
            if(res.data.code==='0'){
                setDataList(res.data.data);
            } else {
                dataList ? setDataList(dataList) : setDataList([]);
            }
        }); 
    }, [origin])

    useEffect(() => {
        if(!_IS_CURRENT_NETWORK_ID_SUPPORTED_) return;
        fetchBoardDividend()
        let refreshInterval = null;
        window.clearInterval(refreshInterval)
        refreshInterval = setInterval(() => {
            fetchBoardDividend()
        }, 10000)
        return () => clearInterval(refreshInterval)
    }, [fetchBoardDividend])
    return {
        dataList
    }
}
export function useCountDown() {
    const { web3, address } = useConnectWallet();
    const {earnContractAddress: EARN_CONTRACT_ADDRESS, daoConfig: DAO_CONFIG} = useBasicInfo();
    const [dataInfo, setDataInfo] = useState([])
    const fetchRate = useCallback(async () => {
        if (!web3) { return }
        const contract = new web3.eth.Contract(DaoConfigure, DAO_CONFIG);

        const data = await contract.methods.getConfigure().call().catch(e => console.log(e,'111111111111'))
        setDataInfo(data)
        
    }, [ address, EARN_CONTRACT_ADDRESS])
    useEffect(() => {
        fetchRate();
      
    }, [fetchRate])
    return {
        dataInfo,
        setDataInfo
    }
}