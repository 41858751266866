import React, { memo,useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
// import { useConnectWallet } from '../../home/redux/hooks';
import {useAggregate,useNeedMigrator} from '../redux/hooks';
// import { useHubChainTvlAndProfit } from '../redux/hooks';
import '../jss/sections/SectionTotal.css'
import BigNumber from "bignumber.js";
// 千分位格式化
function formatThousands(num) {
    return num.toString().replace(/\d+/, function (n) { // 先提取整数部分
        return n.replace(/(\d)(?=(\d{3})+$)/g, function ($1) { // 对整数部分添加分隔符
            return $1 + ",";
        });
    });
}
const forMat = (number, precision = 2) => {
    return new BigNumber(
      number
    ).multipliedBy(
      new BigNumber(10000)
    ).dividedToIntegerBy(
      new BigNumber(1)
    ).dividedBy(
      new BigNumber(10000)
    ).toNumber().toFixed(precision)
  }
export default memo(({address}) => {
    const { t } = useTranslation();
    const theme = useSelector(state => state.common.theme);
    const [isTrue, setIsTrue] = useState(false);
    useNeedMigrator((data)=>{
      // console.log(data)
      setIsTrue(data)
    })
    const aggregateData = useAggregate(theme==='bsc',isTrue);
    // console.log(aggregateData)
    const rateAndTvl = aggregateData ? aggregateData[1]/1e18 : 0;
    const rateAndReward = aggregateData ? aggregateData[0]/1e18 : 0;
    const depositedAll = aggregateData ? aggregateData[3]/1e18 : 0;
    const allEarned = aggregateData ? aggregateData[2]/1e18 : 0;
    return <ul className={`pool__total-tvl pool__total-tvl-${theme} new_total-${theme} new-top`}>
        <li>
            <div>{t('Total-lock-in')}($)</div>
            <div>{formatThousands(forMat(rateAndTvl,2))}</div>
        </li>
        <li>
            <div>{t('v2-ui-20')}($)</div>
            <div>{formatThousands(forMat(rateAndReward,2))}</div>
        </li>
        <li>
            <div>{t('v2-ui-19')}($)</div>
            <div>{formatThousands(forMat(depositedAll,2))}</div>
        </li>
        <li>
            <div>{t('v2-ui-18')}($)</div>
            <div>{formatThousands(forMat(allEarned,2))}</div>
        </li>
    </ul>
})
