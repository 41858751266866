/* eslint-disable */
import React from "react";
import { useSelector } from 'react-redux';
import {
    withRouter
} from "react-router-dom";
import { useTranslation } from 'react-i18next';
import { _IS_ETH_ } from '../../features/configure/chainsParamsConfig'
import './H5Tab.css';

export default withRouter(function H5Menu({ history }) {
    const { t, i18n } = useTranslation();
    const currentPathName = history.location.pathname;
    const theme = useSelector(state => state.common.theme);
    return (
        <div className={`h-btns`}>
            <div className={`h-btns-content`}>
                <button 
                    className={` ${currentPathName === '/vault' ?  `g-button tab-${theme} g-width`  : `nothing-button-${theme}`}`}
                    onClick={() => {
                        history.push('/vault');
                        window.location.reload();
                    }}
                >{t('Bi-tab1')}</button>
                <button
                    className={`${currentPathName === '/lp' ? `g-button tab-${theme}  g-width`  : `nothing-button-${theme}`}`}
                    onClick={() => {
                        history.push('/lp');
                        window.location.reload();
                    }}
                >{t('Bi-tab2')}</button>
            </div>
        </div>
    )
})

