
import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

import translationEN from './locales/en/translation.json';
import translationZH from './locales/zh/translation.json';
import translationJP from './locales/jp/translation.json';
import translationKR from './locales/kr/translation.json';
import translationRU from './locales/ru/translation.json';
import translationTU from './locales/tu/translation.json';

const resources = {
  en: {
    translation: translationEN
  },
  zh: {
    translation: translationZH
  },
  jp: {
    translation: translationJP
  },
  kr: {
    translation: translationKR
  },
  ru: {
    translation: translationRU
  },
  tu: {
    translation: translationTU
  }
};

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources,
    fallbackLng: 'zh',
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;