import {
    defaultFontColor,
    primaryColor,
    whiteColor,
} from "../material-kit-pro-react.js";

const headerLinksStyle = theme => ({
    navContainer: {
        width: "240px",
        height: "100%",
        backgroundColor: whiteColor,
    },
    navWapper: {
        paddingTop: "40px",
    },
    navListItem: {
        width: "100%",
    },
    navListItemContainer: {
        width: "100%",
        height: "64px",
        display: "flex",
        alignItems: "center",
        backgroundColor: whiteColor,
        paddingLeft: "33px",
        boxSizing: "border-box",
        position: "relative",
        cursor: "pointer",
        fontWeight: "700",
        transition: "all .5s",
        fontSize: "14px",
        "&:hover": {
            backgroundColor: primaryColor[4],
        },
        "&:hover a, &:hover span": {
            color: primaryColor[0],
        },
        "& a, & span": {
            fontSize: '14px',
        },
        // "&:hover img": {
        //     // backgroundColor: ,
        //     filter: `drop-shadow(${primaryColor[0]} 0.3)`,
        // }
    },
    navListItemActived: {
        position: "relative",
        display: "flex",
        alignItems: "center",
        backgroundColor: primaryColor[4],
        color: `${primaryColor[0]} !important`,
        fontWeight: "bold !important",
        // "& i": {
        //     background: `url(${require('../../../images/icon-index-blue.svg')}) no-repeat center center !important`,
        // },
        '& span': {
            position: 'absolute',
            right: "0",
            width: "4px",
            height: "22px",
            background: primaryColor[0],
            "border-radius": "2px"
        }
    },

    navIcon: {
        width: "20px",
        height: "20px",
        display: "block",
        marginRight: '8px',
    },
    // indexIcon: {
    //     "& i": {
    //         background: `url(${require('../../../images/icon-index.svg')}) no-repeat center center`,
    //     },
    //     "&:hover i": {
    //         background: `url(${require('../../../images/icon-index-blue.svg')}) no-repeat center center`,
    //     }
    // },
    // danbiIcon: {
    //     "& i": {
    //         background: `url(${require('../../../images/icon-high.svg')}) no-repeat center center`,
    //     },
    //     "&:hover i": {
    //         background: `url(${require('../../../images/icon-high-blue.svg')}) no-repeat center center`,
    //     }
    // },
    lpIcon: {
        // "& i": {
        //     background: `url(${require('../../../images/icon-lp.svg')}) no-repeat center center`,
        // },
        // "&:hover i": {
        //     background: `url(${require('../../../images/icon-lp-blue.svg')}) no-repeat center center`,
        // },
        "& strong": {
            height: "20px",
            padding: "0 10px",
            borderRadius: "9px 9px 9px 0",
            background: primaryColor[9],
            position: "absolute",
            fontSize: "12px",
            color: "#fff",
            right: "50px",
            fontWeight: "400",
            fontFamily: "PingFangSC-Medium;",
            lineHeight: "20px",
        }
    },
    navListItemOptionContainer: {
        // backgroundColor: primaryColor[5],
        maxHeight: 0,
        overflow: "hidden",
        transition: "all linear .3s",
    },
    navListItemOptionItem: {
        height: "48px",
        lineHeight: "48px",
        // fontFamily: "MicrosoftYaHei",
        fontSize: "14px",
        color: defaultFontColor.color,
        fontWeight: "400",
        paddingLeft: "60px",
        boxSizing: "border-box",
        cursor: "pointer",
        overflow: "hidden",
        "text-overflow": "ellipsis",
        "white-space": "nowrap",
        "&:hover": {
            color: primaryColor[0],
            backgroundColor: primaryColor[4],
        }
    },
    tutorilItem: {
        "&:hover span": {
            color: primaryColor[0]
        }
    }
});

export default headerLinksStyle;
