/* eslint-disable */
import React from "react";
import {useSelector } from 'react-redux';
import Hidden from "@material-ui/core/Hidden";
import { useTranslation } from 'react-i18next';
import {footerLinksReports,_NETWORK_ID_MAP_NAME_} from '../../features/configure/chainsParamsConfig'

export default function FooterLinks(props) {
    const { t, i18n } = useTranslation();
    const networkId = useSelector(state => state.home.networkId);
    const lang = (i18n.language === 'zh' || i18n.language === 'zh_CN')?'zh':'en';
    return (
        <div className="footer">
            {/* pc */}
            <Hidden smDown implementation="css">
                <div className="footer__wrapper">
                    <div className="footer__left">
                        <img className="footer__left-logo" src={require('../../images/logo-hui.svg')} alt=""/>
                       
                    </div>
                    <div className="footer__right">
                        {(networkId in _NETWORK_ID_MAP_NAME_)?footerLinksReports[networkId][lang].map(item=>{
                            return <a className="footer__left-item" target="_blank" key={item.content} href={item.href}>{t(item.content)}</a>
                        }):null}
                        {/* <span style={{marginLeft:'50px'}}>{t('Footer-us')}</span> */}
                        {/* <div className="footer__right-img-box" style={{marginRight: "56px"}} onClick={() => {
                            const larkUrl = i18n.language === 'zh' || i18n.language === 'zh-CN' ? "https://0.plus/coinwind_cn" : "https://t.me/CoinWind"
                            window.open(larkUrl)
                        }}>
                            <i className="footer__right-img-wechat"></i>
                        </div>
                        <div className="footer__right-img-box">
                            <a href="https://twitter.com/coinwind_com" target="_blank">
                                <i className="footer__right-img-tw"></i>
                            </a>
                        </div> */}
                    </div>
                </div>
            </Hidden>
            {/* h5 */}
            {/* <Hidden mdUp implementation="css">
                <div className="footer__wrapper">
                    <div className="footer__right-img-box" style={{marginRight: "64px"}}  onClick={() => {
                            const larkUrl = i18n.language === 'zh' || i18n.language === 'zh-CN' ? "https://t.me/coinwind_cn" : "https://t.me/CoinWind"
                            window.open(larkUrl)
                        }}>
                        <i className="footer__right-img-wechat"></i>
                    </div>
                    <div className="footer__right-img-box">
                        <a href="https://twitter.com/coinwind_com" target="_blank">
                            <i className="footer__right-img-tw"></i>
                        </a>
                    </div>
                </div>
            </Hidden> */}
        </div>
    )
}

