/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useCallback, useEffect } from 'react';
import axios from 'axios';
import { CowAirdop, erc20ABI, AggregateHub, AggregateHubNew ,DaoAggregate,DaoConfigure} from 'features/configure/abi';
import { useSnackbar } from 'notistack';
import { useConnectWallet } from '../../home/redux/hooks';
import { useSelector } from 'react-redux';

import { useBasicInfo } from "../../configure/pools";
import { ctrAddressMap,_IS_BSC_,_IS_CURRENT_NETWORK_ID_SUPPORTED_,_IS_ETH_ } from '../../configure/chainsParamsConfig';

export { useFetchApproval } from './fetchApproval';
export { useFetchDeposit } from './fetchDeposit';
export { useFetchWithdraw } from './fetchWithdraw';
export { useFetchContractApy } from './fetchContractApy';

// 获取COW余额
export function useFetchCowBalances() {
    const { web3, address } = useConnectWallet();
    const [cowBalance, setCowBalance] = useState(0);
    const {cowAddress} = useBasicInfo();
    const getBalance = useCallback(async () => {
        if (!web3 || !_IS_CURRENT_NETWORK_ID_SUPPORTED_) return;
        const contract = new web3.eth.Contract(erc20ABI, cowAddress)
        const balance = await contract.methods.balanceOf(address).call({ from: address });
        setCowBalance(balance/1e18)
    }, [web3, address])
    useEffect(() => {
        getBalance()
        const timer = setInterval(() => {
            getBalance()
        }, 5000);
        return () => clearInterval(timer)
    }, [web3, address])
    return {
        cowBalance,
    }
}

// 获取空投 领取空投
export function useFetchDrop() {
    const { web3,networkId, address } = useConnectWallet();
    const { enqueueSnackbar } = useSnackbar();
    const [dropPending, setDropPending] = useState(false)
    const [reward, setReward] = useState(0)
    const ctrAddress = ctrAddressMap[networkId]?ctrAddressMap[networkId]:ctrAddressMap['128'];
    const getDrop = useCallback(async () => {
        if(_IS_ETH_) return;
        const contract = new web3.eth.Contract(CowAirdop, ctrAddress)
        const rewards = await contract.methods.userReward(address).call({ from: address });
        setReward(rewards/1e18)
    }, [web3, address])
    const onDrop = useCallback(() => {
        setDropPending(true)
        const contract = new web3.eth.Contract(CowAirdop, ctrAddress)
        contract.methods.takeReward().send({ from: address }).on('transactionHash', function(hash){
            enqueueSnackbar(`领取中:${hash}`, {
                variant: 'success', anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'center',
                },
                autoHideDuration: 4000
            })
        })
        .on('receipt', function (receipt) {
            enqueueSnackbar(`领取成功`, {
                variant: 'success', anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'center',
                },
                autoHideDuration: 4000
            })
            setDropPending(false);
        })
        .on('error', function (error) {
            enqueueSnackbar(`错误${JSON.stringify(error)}`, {
                variant: 'error', anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'center',
                },
                autoHideDuration: 4000
            })
            setDropPending(false);
        })
        .catch((error) => {
            enqueueSnackbar(`错误${JSON.stringify(error)}`, {
                variant: 'error', anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'center',
                },
                autoHideDuration: 4000
            })
            setDropPending(false);
        });
    })
    useEffect(() => {
        const timer = setInterval(() => {
            getDrop()
        }, 5000);
        return () => clearInterval(timer)
    }, [web3, address])
    return {
        reward,
        dropPending,
        onDrop,
    }
}

// 获取单币列表数据
export function usePoolDaoList(callback) {
    const {pools, earnContractAddress: EARN_CONTRACT_ADDRESS, aggAddress} = useBasicInfo();
    const { web3, address } = useConnectWallet();
    const tabActive = useSelector(state => state.vault.tabActive);
    const getPoolList = useCallback(async () => {
        if(!web3 || !_IS_CURRENT_NETWORK_ID_SUPPORTED_){
            return;
        }
        const contract = new web3.eth.Contract(DaoAggregate, aggAddress);
        const arr = [];
        for (let pool of pools) {
            const poolInfo = await contract.methods.getPoolInfo(EARN_CONTRACT_ADDRESS, pool.tokenAddress, address, tabActive).call().catch(e => {
                // console.log(EARN_CONTRACT_ADDRESS, pool.tokenAddress, "EARN_CONTRACT_ADDRESS");
            });
            // console.log(poolInfo)
            arr.push({...pool, ...poolInfo});
        }
        // console.log(arr)
        callback(arr);
    }, [web3, address, tabActive])
    useEffect(() => {
        getPoolList()
        const timer = setInterval(() => {
            getPoolList();
        }, 5000);
        return () => clearInterval(timer)
    }, [getPoolList])
}

// 单个币种对U汇率
export function useCoinRate(tokenAddress) {
    const { web3 } = useConnectWallet();
    const {aggAddress, MdxAddress} = useBasicInfo();
    const [tokenURate, setTokenURate] = useState({});
    const [tokenRate, setTokenRate] = useState({}); 
    const fetchRate = useCallback(async () => {
        if (!web3 || !_IS_CURRENT_NETWORK_ID_SUPPORTED_) { return }
        const contract = new web3.eth.Contract(AggregateHub, aggAddress);
        // console.log(tokenAddress,aggAddress,'wwwwwwwwww')
        const rate = await contract.methods.getUsdtRate(tokenAddress, _IS_BSC_ ? 1 : 0, false).call().catch(e => console.log(e));
        // console.log(rate,tokenAddress,'aaaaaaaaaaaa')
        // mdx兑usdt的价格
        const mdxUsdtRate = await contract.methods.getUsdtRate(MdxAddress, _IS_BSC_ ? 1 : 0, false).call().catch(0);
        setTokenURate(rate/mdxUsdtRate);
        setTokenRate(rate);
    })
    useEffect(() => {
        fetchRate();
        let refreshInterval = setInterval(fetchRate, 300000);
        return () => clearInterval(refreshInterval);
    }, [web3, fetchRate])
    return {
        tokenURate,
        tokenRate,
    }
}

// 所有币种汇率
export function useAllRate(isLp) {
    const { web3 } = useConnectWallet();
    const {pools, aggAddress, MdxAddress,cowAddress} = useBasicInfo();
    const [poolRate, setPoolRate] = useState(false);
    const [coinRate, setCoinRate] = useState({});
    const [bnbRates, setBnbRate] = useState();
    const tabActive = useSelector(state => state.vault.tabActive);
    const fetchRate = useCallback(async () => {
        if (!web3 || !_IS_CURRENT_NETWORK_ID_SUPPORTED_) { return }
        const contract = new web3.eth.Contract(AggregateHub, aggAddress);
        // mdx兑usdt的价格
        const mdxUsdtRate = await contract.methods.getUsdtRate(_IS_ETH_?cowAddress:MdxAddress, _IS_BSC_ ? 1 : 0, false).call().catch(0);
        if(mdxUsdtRate <= 0){
            return;
        }
        let returnRate = poolRate || {};
        let returnCoinRate = coinRate || {};
         // 获取bsc链 bnb 对 u 汇率
         // 获取bsc链 bnb 对 u 汇率
         let returnBnbRate = bnbRates || null;
         if (_IS_BSC_) {
             const rate = await contract.methods.getUsdtRate("0xbb4cdb9cbd36b01bd1cbaebf2de08d9173bc095c", _IS_BSC_ ? 1 : 0, false).call().catch(0);
             returnBnbRate = rate;
             if(rate > 0){
                 setBnbRate(returnBnbRate);
             }
         }
        //getTokenUsdtRate 接口返回的汇率放大了1e18倍
        // returnRate[MDXAddress] = mdxUsdtRate/1e18;
        for(let pool of pools) {
            // console.log(pool.tokenAddress,aggAddress,isLp,'333333333333')
            const rate = await contract.methods.getUsdtRate(pool.tokenAddress, _IS_BSC_ ? 1 : 0, isLp).call().catch(0);
            // console.log(rate,'4444444444')
            if(rate <= 0){
                return;
            }
            //token兑换mdx的价格, 比如一个btc兑换usdt的价格
            const tokenMdxRate = rate/mdxUsdtRate;
            returnRate[pool.tokenAddress] = tokenMdxRate;
            returnCoinRate[pool.tokenAddress] = rate;
        }
        // console.log(returnCoinRate,'qqqqqqqqqqqqqqqq')
        setPoolRate(returnRate);
        setCoinRate(returnCoinRate);
    }, [web3, isLp, tabActive,bnbRates])

    useEffect(() => {
        fetchRate();
        let refreshInterval = setInterval(fetchRate, 300000);
        return () => clearInterval(refreshInterval);
    }, [fetchRate])
    // console.log(poolRate, coinRate,'11111111111')
    return {poolRate, coinRate,bnbRates}
}

// 单链 总锁仓 总收益

export function useAggregate(isBscChain) {
    const { web3, address } = useConnectWallet();
    const {earnContractAddress: EARN_CONTRACT_ADDRESS, aggAddress: AGG_ADDRESS} = useBasicInfo();
    const [data, setApy] = useState([]);
    let chainType = isBscChain? 1 : 0;
    const fetchApy = useCallback(async () => {
        if (!web3 || !_IS_CURRENT_NETWORK_ID_SUPPORTED_) { return }
        const contract = new web3.eth.Contract(AggregateHub, AGG_ADDRESS);
        contract.options.gas = 18600000;
        const data = await contract.methods.statePoolAndUserTokenUsd(EARN_CONTRACT_ADDRESS,address,chainType,false).call().catch(e => console.log(e))
        setApy(data);
    }, [isBscChain, address, EARN_CONTRACT_ADDRESS])

    useEffect(() => {
        if (web3 && address) {
            fetchApy()
        }
        let refreshInterval = setInterval(fetchApy, 10000)
        return () => clearInterval(refreshInterval)
    }, [web3, fetchApy])

    return data
}

// 所有链所有项目 总锁仓量 及 收益
export function useTvlAndProfit() {
    const [tvl, setTvl] = useState('');
    const [profit, setProfit] = useState('');
    const [cowProfit, setCowProfit] = useState('');
    const [daoRewardUsdt, setDaoRewardUsdt] = useState('');
    const [daoBurnCow, setDaoBurnCow] = useState('');
    const origin = window.location.origin=='http://localhost:3000'? "http://47.93.150.214:9998":window.location.origin;
    const fetchTvlAndProfit = useCallback(async () => {
        const url = `${origin}/api/defi/tvlAndProfit`;
        const res={
            code:"0",
            msg:"",
            data:{
                tvl:"799078429.9933153932762533",
                profit:"72469595.4313373326667361",
                cowProfit:"5218042944799583246497635",
                daoRewardUsdt:"111221.5462",
                daoBurnCow:"10002.92"
            }
        }
        axios.get(url).then((res) => {
            if(res.data.code==='0'){
                setTvl(res.data.data.tvl);
                setProfit(res.data.data.profit);
                setCowProfit(res.data.data.cowProfit);
                setDaoRewardUsdt(res.data.data.daoRewardUsdt);
                setDaoBurnCow(res.data.data.daoBurnCow);
            } else {
                tvl ? setTvl(tvl) : setTvl(1703111851.26);
                profit ? setProfit(profit) : setProfit(45202302.67);
                cowProfit ? setCowProfit(cowProfit) : setCowProfit(0);
                daoRewardUsdt ? setCowProfit(daoRewardUsdt) : setCowProfit(1703111851.26);
                daoBurnCow ? setCowProfit(daoBurnCow) : setCowProfit(0);
            }
        });
    }, [origin])

    useEffect(() => {
        if(!_IS_CURRENT_NETWORK_ID_SUPPORTED_) return;
        fetchTvlAndProfit()
        let refreshInterval = null;
        window.clearInterval(refreshInterval)
        refreshInterval = setInterval(() => {
            fetchTvlAndProfit()
        }, 10000)
        return () => clearInterval(refreshInterval)
    }, [fetchTvlAndProfit])
    return {
        tvl,
        profit,
        cowProfit,
        daoRewardUsdt,
        daoBurnCow
    }
}
// 获取销毁比例
export function useCountDown() {
    const { web3, address } = useConnectWallet();
    const {earnContractAddress: EARN_CONTRACT_ADDRESS, daoConfig: DAO_CONFIG} = useBasicInfo();
    // console.log(DAO_CONFIG,'111111111112222222')
    const [data, setApy] = useState([]);
    // let chainType = isBscChain? 1 : 0;
    const fetchRate = useCallback(async () => {
        if (!web3 || !_IS_CURRENT_NETWORK_ID_SUPPORTED_) { return }
        // console.log(EARN_CONTRACT_ADDRESS,'11111111111')
        const contract = new web3.eth.Contract(DaoConfigure, DAO_CONFIG);
        const data = await contract.methods.getConfigure().call().catch(e => console.log(e))
        setApy(data);
    }, [ address, EARN_CONTRACT_ADDRESS])
    useEffect(() => {
        fetchRate();
        let refreshInterval = setInterval(fetchRate, 300000);
        return () => clearInterval(refreshInterval);
    }, [fetchRate])
   
    return data
}
