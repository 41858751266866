import React from 'react';
import { useTranslation } from 'react-i18next';
import { isMobile } from 'features/helpers/utils';
import { useSelector } from 'react-redux';
import '../jss/sections/SectionTitle.css'

const pic = {
    "zh-heco-pc":require("../../../assets/img/web/wusun/banner-wusun-heco-zhong.png"),
    "en-heco-pc":require("../../../assets/img/web/wusun/banner-wusun-heco-english.png"),
    "zh-heco-h5":require("../../../assets/img/app/wusun/banner-wusun-heco-zhong.png"),
    "en-heco-h5":require("../../../assets/img/app/wusun/banner-wusun-heco-english.png"),

    "zh-bsc-pc":require("../../../assets/img/web/wusun/banner-wusun-bsc-zhong.png"),
    "en-bsc-pc":require("../../../assets/img/web/wusun/banner-wusun-bsc-english.png"),
    "zh-bsc-h5":require("../../../assets/img/app/wusun/banner-wusun-bsc-zhong.png"),
    "en-bsc-h5":require("../../../assets/img/app/wusun/banner-wusun-bsc-english.png"),

    "zh-eth-pc":require("../../../assets/img/web/wusun/banner-wusun-eth-zhong.png"),
    "en-eth-pc":require("../../../assets/img/web/wusun/banner-wusun-eth-english.png"),
    "zh-eth-h5":require("../../../assets/img/app/wusun/banner-wusun-eth-zhong.png"),
    "en-eth-h5":require("../../../assets/img/app/wusun/banner-wusun-eth-english.png"),

    // "zh-oec-pc":require("../../../assets/img/web/wusun/banner-wusun-oec-zhong.png"),
    // "en-oec-pc":require("../../../assets/img/web/wusun/banner-wusun-oec-english.png"),
    // "zh-oec-h5":require("../../../assets/img/app/wusun/banner-wusun-oec-zhong.png"),
    // "en-oec-h5":require("../../../assets/img/app/wusun/banner-wusun-oec-english.png"),
}
export default props => {
    const isH5 = isMobile()?'h5':'pc';
    const { i18n } = useTranslation();
    const lang = (i18n.language==='zh-CN' || i18n.language==='zh')?'zh':'en'
    const theme = useSelector(state => state.common.theme);
    return <div className="section-banner">
        <img src={pic[`${lang}-${theme}-${isH5}`]} alt=""/>
    </div>
}