export { _CURRENT_NETWORK_ID_ } from '../../features/configure/chainsParamsConfig'

export const netStyleMap = {
    "56": {
        customerSlider: "#ffa62d",
        sliderBgImage: "linear-gradient(90deg, #ffa62d 0%, rgba(255,166,45,.8) 100%)",
        headerBg: "rgba(255,255,255,0.60)",
        noticeColor: "#8391A8",
        buttonBorder: "1px solid #FFA34F",
        buttonBg: "#FFFFFF",
        buttonColor: "#FFA34F",
    },
    "128": {
        customerSlider: "#01A2FE",
        sliderBgImage: "linear-gradient(90deg, #01a3fe 0%, #006eff 100%)",
        headerBg: "rgba(255,255,255,0.60)",
        noticeColor: "#8391A8",
        buttonBorder: "1px solid #3078FF",
        buttonBg: "#FFFFFF",
        buttonColor: "#3078FF",
    },
    "1":{
        customerSlider: "#01A2FE",
        sliderBgImage: "linear-gradient(90deg, #01a3fe 0%, #006eff 100%)",
        headerBg: "rgba(255,255,255,0.60)",
        noticeColor: "#8391A8",
        buttonBorder: "1px solid #233BB8",
        buttonBg: "#FFFFFF",
        buttonColor: "#233BB8",
    }
}
export const primaryColorMap = {
    "56":[
        '#FFA34F',
        "rgba(255,163,79,.06)",
        "#8391A8",
        "#F6F7FA",
        "rgba(255,163,79,.06)",
        "#F6F8FB",
        "rgba(255,255,255,0.60)",
        "#F1F9FE",
        "rgba(255,163,79,0.1)",
        "linear-gradient(90deg, #FFA34F 0%, rgba(255,163,79,.6) 100%)",
      
        "#ab47bc",
        "#8e24aa",
        "#af2cc5",
        "#e1bee7",
        "#ba68c8"
      ],
      "128":[
        // 主色 蓝色
        '#3078FF',
        // 下拉option 滑过
        "#F1FAFF",
        // notice color
        "#8391A8",
        // 整个背景色
        "#F6F7FA",
        // nav hover
        "rgba(1,163,254,.06)",
        // nav 子option
        "#F6F8FB;",
        // h5 钱包主色
        "rgba(255,255,255,0.60)",
        // home profit bgc
        "#F1F9FE",
        // button  bg
        "rgba(48,120,255,0.1)",
        // 主色渐变
        "linear-gradient(90deg, #3078FF 0%, rgba(48,120,255,.6) 100%)",
        
        "#ab47bc",
        "#8e24aa",
        "#af2cc5",
        "#e1bee7",
        "#ba68c8"
      ],
      "1":[
        // 主色 蓝色
        '#233BB8',
        // 下拉option 滑过
        "#F1FAFF",
        // notice color
        "#8391A8",
        // 整个背景色
        "#F6F7FA",
        // nav hover
        "rgba(1,163,254,.06)",
        // nav 子option
        "#F6F8FB;",
        // h5 钱包主色
        "rgba(255,255,255,0.60)",
        // home profit bgc
        "#F1F9FE",
        // button  bg
        "rgba(48,120,255,0.1)",
        // 主色渐变
        "linear-gradient(90deg, #233BB8 0%, rgba(48,120,255,.6) 100%)",
        "#ab47bc",
        "#8e24aa",
        "#af2cc5",
        "#e1bee7",
        "#ba68c8"
      ]
}