import * as React from "react";
const { useState, useEffect, useRef } = React;

interface IProps {
    timeStamp: any;
}

const CountDown = (props) => {
    const { timeStamp } = props;
    const intervalRef = useRef(null);

    const now= Math.round(new Date().getTime() / 1000).toString();  //获取当前时间
    const end= timeStamp;  //设置截止时间  

    const [leftTime, setLeftTime] = useState(end - now); //时间间隔
    const [t, setTime] = useState("");   //天
    const [h, setHours] = useState("");   //小时
    const [m, setMinutes] = useState("");  //分钟
    const [s, setSeconds] = useState("");  //秒


    useEffect(() => {
        if (leftTime > 0) {
            intervalRef.current = setInterval(() => {
                const newNow= Math.round(new Date().getTime() / 1000).toString();  // 重新获取当前时间

                let newLeftTime = timeStamp - newNow
                setLeftTime(() => newLeftTime)  //计算新的时间间隔数值
                let times = Math.floor(newLeftTime / 60 / 60 /24) < 10 ? `${Math.floor(newLeftTime / 60 / 60 /24)}` : Math.floor(newLeftTime / 60 / 60 /24);
                let hours = Math.floor(newLeftTime / 60 / 60 % 24) < 10 ? `0${Math.floor(newLeftTime / 60 / 60 % 24)}` : Math.floor(newLeftTime / 60 / 60 % 24);
                let minutes = Math.floor(newLeftTime / 60 % 60) < 10 ? `0${Math.floor(newLeftTime / 60 % 60)}` : Math.floor(newLeftTime / 60 % 60);
                let seconds = Math.floor(newLeftTime % 60) < 10 ? `0${Math.floor(newLeftTime % 60)}` : Math.floor(newLeftTime % 60);
                setTime(()=>times)
                setHours(() => hours)  //函数写法 设置小时
                setMinutes(() => minutes)  //函数写法 设置分钟
                setSeconds(() => seconds)  //函数写法保证值在setInterval里更新，避免useEffect的bug

            }, 1000);
        } else {
            setLeftTime(0)
            setTime(0)
            setHours(0)
            setMinutes(0)
            setSeconds(0)
            clearInterval(intervalRef.current);
        }
        return () => clearInterval(intervalRef.current);   
    }, []);  //不传依赖


    return (
        <>
            { leftTime <= 0 && <span>{`0天00时00分00秒`}</span>}
            { leftTime > 0 && <span>{`${t}天${h}时${m}分${s}秒`}</span>}
        </>
    )

}

export default CountDown;