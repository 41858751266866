import { combineReducers } from 'redux';
// import { routerReducer } from 'react-router-redux';
import { connectRouter } from 'connected-react-router'
import history from './history';
import commonReducer from 'features/common/redux/reducer';
import homeReducer from 'features/home/redux/reducer';
import vaultReducer from 'features/vault/redux/reducer';
import lpReducer from 'features/lp/redux/reducer';
import bonusReducer from 'features/bonus/redux/reducer';
import daoLpReducer from 'features/daoLp/redux/reducer'
import daoVaultReducer  from 'features/daoVault/redux/reducer'
import assetExchangeReducer from 'features/assetExchange/redux/reducer';
import stakingReducer  from 'features/staking/redux/reducer'

// NOTE 1: DO NOT CHANGE the 'reducerMap' name and the declaration pattern.
// This is used for Rekit cmds to register new features, remove features, etc.
// NOTE 2: always use the camel case of the feature folder name as the store branch name
// So that it's easy for others to understand it and Rekit could manage them.
const reducerMap = {
  router: connectRouter(history),
  home: homeReducer,
  vault: vaultReducer,
  lp: lpReducer,
  common: commonReducer,
  bonus:bonusReducer,
  daoLp:daoLpReducer,
  daoVault:daoVaultReducer,
  assetExchange:assetExchangeReducer,
  staking:stakingReducer,
};

export default combineReducers(reducerMap);
