/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useCallback, useEffect } from 'react';
import axios from 'axios';
import { CowAirdop, erc20ABI, AggregateHub, AggregateHubNew ,Migrator,AggregateHubCtoken} from 'features/configure/abi';
import { useSnackbar } from 'notistack';
import { useConnectWallet } from '../../home/redux/hooks';
import { useSelector } from 'react-redux';
import { useBasicInfo } from "../../configure/pools";
import { ctrAddressMap,_IS_BSC_,_IS_CURRENT_NETWORK_ID_SUPPORTED_,_IS_ETH_ ,_IS_HECO_} from '../../configure/chainsParamsConfig';
import { cow_heco_info_v2 } from "../../configure/pools_cow_heco-v2";
import { cow_bsc_info_v2 } from "../../configure/pools_cow_bsc-v2";
import { cow_eth_info_v2 } from "../../configure/pools_cow_eth-v2";
import { cow_heco_info_ctoken } from "../../configure/pools_cow_heco-ctoken";
import { cow_heco_info_ctokenVault } from "../../configure/pools_cow_heco-ctokenVault";
import { cow_heco_info } from "../../configure/pools_cow_heco";
import { cow_bsc_info } from "../../configure/pools_cow_bsc";
import { cow_eth_info } from "../../configure/pools_cow_eth";
import { cow_bsc_info_ctoken } from "../../configure/pools_cow_bsc-ctoken";
import { cow_bsc_info_ctokenVault } from "../../configure/pools_cow_bsc-ctokenVault";
export { useFetchApproval } from './fetchApproval';
export { useFetchApprovalCtoken } from './fetchApprovalCtoken';
export { useFetchDeposit } from './fetchDeposit';
export { useFetchWithdraw } from './fetchWithdraw';
export { useFetchContractApy } from './fetchContractApy';

// 获取COW余额
export function useFetchCowBalances() {
    const { web3, address } = useConnectWallet();
    const [cowBalance, setCowBalance] = useState(0);
    const {cowAddress} = useBasicInfo();
    const getBalance = useCallback(async () => {
        if (!web3 || !_IS_CURRENT_NETWORK_ID_SUPPORTED_) return;
        // console.log(cowAddress,'111111111111')
        const contract = new web3.eth.Contract(erc20ABI, cowAddress)
        const balance = await contract.methods.balanceOf(address).call({ from: address });
        setCowBalance(balance/1e18)
    }, [web3, address])
    useEffect(() => {
        getBalance()
        const timer = setInterval(() => {
            getBalance()
        }, 5000);
        return () => clearInterval(timer)
    }, [web3, address])
    return {
        cowBalance,
    }
}

// 获取空投 领取空投
export function useFetchDrop() {
    const { web3,networkId, address } = useConnectWallet();
    const { enqueueSnackbar } = useSnackbar();
    const [dropPending, setDropPending] = useState(false)
    const [reward, setReward] = useState(0)
    const ctrAddress = ctrAddressMap[networkId]?ctrAddressMap[networkId]:ctrAddressMap['128'];
    const getDrop = useCallback(async () => {
        // if(_IS_ETH_) return;
        const contract = new web3.eth.Contract(CowAirdop, ctrAddress)
        const rewards = await contract.methods.userReward(address).call({ from: address });
        setReward(rewards/1e18)
    }, [web3, address])
    const onDrop = useCallback(() => {
        setDropPending(true)
        const contract = new web3.eth.Contract(CowAirdop, ctrAddress)
        contract.methods.takeReward().send({ from: address }).on('transactionHash', function(hash){
            enqueueSnackbar(`领取中:${hash}`, {
                variant: 'success', anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'center',
                },
                autoHideDuration: 4000
            })
        })
        .on('receipt', function (receipt) {
            enqueueSnackbar(`领取成功`, {
                variant: 'success', anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'center',
                },
                autoHideDuration: 4000
            })
            setDropPending(false);
        })
        .on('error', function (error) {
            enqueueSnackbar(`错误${JSON.stringify(error)}`, {
                variant: 'error', anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'center',
                },
                autoHideDuration: 4000
            })
            setDropPending(false);
        })
        .catch((error) => {
            enqueueSnackbar(`错误${JSON.stringify(error)}`, {
                variant: 'error', anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'center',
                },
                autoHideDuration: 4000
            })
            setDropPending(false);
        });
    })
    useEffect(() => {
        const timer = setInterval(() => {
            getDrop()
        }, 5000);
        return () => clearInterval(timer)
    }, [web3, address])
    return {
        reward,
        dropPending,
        onDrop,
    }
}

// 获取单币列表数据
export function usePoolList(callback) {
    const {pools, earnContractAddress: EARN_CONTRACT_ADDRESS, aggAddress} = useBasicInfo();
    // console.log(pools)
    const { web3, address } = useConnectWallet();
    const tabActive = useSelector(state => state.vault.tabActive);
    const getPoolList = useCallback(async () => {
        if(!web3 || !_IS_CURRENT_NETWORK_ID_SUPPORTED_){
            return;
        }
        const contract = new web3.eth.Contract(AggregateHubNew, aggAddress);
        const arr = [];
        // console.log(EARN_CONTRACT_ADDRESS)
        for (let pool of pools) {
            const poolInfo = await contract.methods.getPoolInfo(EARN_CONTRACT_ADDRESS, pool.tokenAddress, address, tabActive).call().catch(e => {
                console.log(e);
            });
        //    console.log(pool,poolInfo)
            arr.push({...pool, ...poolInfo});
           
        }
        callback(arr);
    }, [web3, address, tabActive])
    useEffect(() => {
        getPoolList()
        const timer = setInterval(() => {
            getPoolList();
        }, 5000);
        return () => clearInterval(timer)
    }, [getPoolList])
}
// 获取单币列表数据
export function usePoolListsQian(callback) {
    let EARN_CONTRACT_ADDRESS = null
    let poolss = []
    let aggAddresss = null
    if(_IS_HECO_){
        const { earnContractAddress, pools, aggAddress} = cow_heco_info();
        EARN_CONTRACT_ADDRESS = earnContractAddress
        poolss = pools
        aggAddresss = aggAddress
    }else  if(_IS_BSC_){
        const { earnContractAddress, pools, aggAddress} = cow_bsc_info();
        EARN_CONTRACT_ADDRESS = earnContractAddress
        poolss = pools
        aggAddresss = aggAddress
    }else  if(_IS_ETH_){
        const { earnContractAddress, pools, aggAddress} = cow_eth_info();
        EARN_CONTRACT_ADDRESS = earnContractAddress
        poolss = pools
        aggAddresss = aggAddress
    }
    // console.log(pools)
    const { web3, address } = useConnectWallet();
    const tabActive = useSelector(state => state.vault.tabActive);
    const getPoolList = useCallback(async () => {
        if(!web3 || !_IS_CURRENT_NETWORK_ID_SUPPORTED_){
            return;
        }
        const contract = new web3.eth.Contract(AggregateHubNew, aggAddresss);
        const arr = [];
        // console.log(EARN_CONTRACT_ADDRESS)
        for (let pool of poolss) {
            const poolInfo = await contract.methods.getPoolInfo(EARN_CONTRACT_ADDRESS, pool.tokenAddress, address, tabActive).call().catch(e => {
                // console.log(e);
            });
        //    console.log(pool,poolInfo)
            arr.push({...pool, ...poolInfo});
           
        }
        callback(arr);
    }, [web3, address, tabActive])
    useEffect(() => {
        getPoolList()
        const timer = setInterval(() => {
            getPoolList();
        }, 5000);
        return () => clearInterval(timer)
    }, [getPoolList])
}
// 单个币种对U汇率
export function useCoinRate(tokenAddress) {
    const { web3 } = useConnectWallet();
    const {aggAddress, MdxAddress} = useBasicInfo();
    const [tokenURate, setTokenURate] = useState({});
    const [tokenRate, setTokenRate] = useState({}); 
    const fetchRate = useCallback(async () => {
        if (!web3 || !_IS_CURRENT_NETWORK_ID_SUPPORTED_) { return }
        const contract = new web3.eth.Contract(AggregateHub, aggAddress);
        const rate = await contract.methods.getUsdtRate(tokenAddress, _IS_BSC_ ? 1 : 0, false).call().catch(e => console.log());
        // mdx兑usdt的价格
        const mdxUsdtRate = await contract.methods.getUsdtRate(MdxAddress, _IS_BSC_ ? 1 : 0, false).call().catch(0);
        setTokenURate(rate/mdxUsdtRate);
        setTokenRate(rate);
    })
    useEffect(() => {
        fetchRate();
        let refreshInterval = setInterval(fetchRate, 300000);
        return () => clearInterval(refreshInterval);
    }, [web3, fetchRate])
    return {
        tokenURate,
        tokenRate,
    }
}

// 所有币种汇率
export function useAllRate(isLp) {
    const { web3 } = useConnectWallet();
    const {pools, aggAddress, MdxAddress,cowAddress} = useBasicInfo();
    const [poolRate, setPoolRate] = useState(false);
    const [coinRate, setCoinRate] = useState({});
    const tabActive = useSelector(state => state.vault.tabActive);

    const fetchRate = useCallback(async () => {
        if (!web3 || !_IS_CURRENT_NETWORK_ID_SUPPORTED_) { return }
        const contract = new web3.eth.Contract(AggregateHub, aggAddress);
        // mdx兑usdt的价格
        const mdxUsdtRate = await contract.methods.getUsdtRate(_IS_ETH_?cowAddress:MdxAddress, _IS_BSC_ ? 1 : 0, false).call().catch(0);
        if(mdxUsdtRate <= 0){
            return;
        }
        let returnRate = poolRate || {};
        let returnCoinRate = coinRate || {};
        //getTokenUsdtRate 接口返回的汇率放大了1e18倍
        // returnRate[MDXAddress] = mdxUsdtRate/1e18;
        pools.forEach(async pool => {
            const rate = await contract.methods.getUsdtRate(pool.tokenAddress, _IS_BSC_ ? 1 : 0, isLp).call().catch(0);
            if(rate <= 0){
                return;
            }
            //token兑换mdx的价格, 比如一个btc兑换usdt的价格
            const tokenMdxRate = rate/mdxUsdtRate;
            returnRate[pool.tokenAddress] = tokenMdxRate;
            returnCoinRate[pool.tokenAddress] = rate;
        })
        setPoolRate(returnRate);
        setCoinRate(returnCoinRate);
    }, [web3, isLp, tabActive])

    useEffect(() => {
        fetchRate();
        let refreshInterval = setInterval(fetchRate, 300000);
        return () => clearInterval(refreshInterval);
    }, [fetchRate])

    return {poolRate, coinRate}
}

// 单链 总锁仓 总收益
export function useAggregate(isBscChain,bool) {
    // console.log(isBscChain,bool)
    // console.log(bool===false&&_IS_ETH_)
    let EARN_CONTRACT_ADDRESS=null;
    let AGG_ADDRESS=null;
    const { web3, address } = useConnectWallet();
    const {earnContractAddress: EARN_CONTRACT_ADDRESNew, aggAddress: AGG_ADDRESNew} = useBasicInfo();
    // if(bool===false&&_IS_ETH_){
    //     const {earnContractAddress: EARN_CONTRACT_ADDRES, aggAddress: AGG_ADDRES} = cow_eth_info_v2();
    //     EARN_CONTRACT_ADDRESS=EARN_CONTRACT_ADDRES
    //     AGG_ADDRESS=AGG_ADDRES
    // }else{
        EARN_CONTRACT_ADDRESS=EARN_CONTRACT_ADDRESNew
        AGG_ADDRESS=AGG_ADDRESNew
    // }
    // if(_IS_BSC_){
    //     const {earnContractAddress: EARN_CONTRACT_ADDRES, aggAddress: AGG_ADDRES} = cow_bsc_info_v2();
    //     EARN_CONTRACT_ADDRESS=EARN_CONTRACT_ADDRES
    //     AGG_ADDRESS=AGG_ADDRES
    // }  else if(_IS_HECO_) {
    //     const {earnContractAddress: EARN_CONTRACT_ADDRES, aggAddress: AGG_ADDRES} = cow_heco_info_v2();
    //     EARN_CONTRACT_ADDRESS=EARN_CONTRACT_ADDRES
    //     AGG_ADDRESS=AGG_ADDRES
    // }   else if(_IS_ETH_) {
    //     const {earnContractAddress: EARN_CONTRACT_ADDRES, aggAddress: AGG_ADDRES} = cow_eth_info_v2();
    //     EARN_CONTRACT_ADDRESS=EARN_CONTRACT_ADDRES
    //     AGG_ADDRESS=AGG_ADDRES
    // } 
   
//    console.log(EARN_CONTRACT_ADDRESS)
    const [data, setApy] = useState([]);
    let chainType = isBscChain? 1 : 0;
    const fetchApy = useCallback(async () => {
        if (!web3 || !_IS_CURRENT_NETWORK_ID_SUPPORTED_) { return }
        const contract = new web3.eth.Contract(AggregateHub, AGG_ADDRESS);
        contract.options.gas = 18600000;
        const data = await contract.methods.statePoolAndUserTokenUsd(EARN_CONTRACT_ADDRESS,address,chainType,false).call().catch(e => console.log(e))
        setApy(data);
    }, [isBscChain, address, EARN_CONTRACT_ADDRESS])

    useEffect(() => {
        if (web3 && address) {
            fetchApy()
        }
        let refreshInterval = setInterval(fetchApy, 10000)
        return () => clearInterval(refreshInterval)
    }, [web3, fetchApy])

    return data
}

// 所有链所有项目 总锁仓量 及 收益
export function useTvlAndProfit() {
    const [tvl, setTvl] = useState('');
    const [profit, setProfit] = useState('');
    const [cowProfit, setCowProfit] = useState('');
    const [daoRewardUsdt, setDaoRewardUsdt] = useState(0);
    const [daoBurnCow, setDaoBurnCow] = useState(0);
    const origin = window.location.origin=='http://localhost:3000'? "http://47.93.150.214:9998":window.location.origin;
    const fetchTvlAndProfit = useCallback(async () => {
        const url = `${origin}/api/defi/tvlAndProfit`;
        const res={
            code:"0",
            msg:"",
            data:{
                tvl:"799078429.9933153932762533",
                profit:"72469595.4313373326667361",
                cowProfit:"5218042944799583246497635",
                daoRewardUsdt:"111221.5462",
                daoBurnCow:"10002.92"
            }
        }
        axios.get(url).then((res) => {
            if(res.data.code==='0'){
                setTvl(res.data.data.tvl);
                setProfit(res.data.data.profit);
                setCowProfit(res.data.data.cowProfit);
                setDaoRewardUsdt(res.data.data.daoRewardUsdt);
                setDaoBurnCow(res.data.data.daoBurnCow);
            } else {
                tvl ? setTvl(tvl) : setTvl(1703111851.26);
                profit ? setProfit(profit) : setProfit(45202302.67);
                cowProfit ? setCowProfit(cowProfit) : setCowProfit(0);
                daoRewardUsdt ? setCowProfit(daoRewardUsdt) : setCowProfit(1703111851.26);
                daoBurnCow ? setCowProfit(daoBurnCow) : setCowProfit(0);
            }
        });
    }, [origin])

    useEffect(() => {
        if(!_IS_CURRENT_NETWORK_ID_SUPPORTED_) return;
        fetchTvlAndProfit()
        let refreshInterval = null;
        window.clearInterval(refreshInterval)
        refreshInterval = setInterval(() => {
            fetchTvlAndProfit()
        }, 10000)
        return () => clearInterval(refreshInterval)
    }, [fetchTvlAndProfit])
    return {
        tvl,
        profit,
        cowProfit,
        daoRewardUsdt,
        daoBurnCow
    }
}






// 判断用户是否需要迁移
export function useNeedMigrator(callback) {
    const { web3, address } = useConnectWallet();
    const tabActives = useSelector(state => state.vault.tabActive);
    const {earnContractAddress: EARN_CONTRACT_ADDRESS, aggAddress: AGG_ADDRESS,migrator} = useBasicInfo();
    const fetchApy = useCallback(async () => {
        if (!web3 || !_IS_CURRENT_NETWORK_ID_SUPPORTED_) { return }
        const contract = new web3.eth.Contract(Migrator, migrator);
        if(!_IS_ETH_){
            contract.options.gas = 18600000;
        }
        const data = await contract.methods.needMigrator(address).call().catch(e => console.log(e))
        callback(data);
    }, [web3,address])

    useEffect(() => {
        // if (web3 && address) {
            fetchApy()
        // }
    }, [fetchApy])
}
// 获取单币列表数据
export function usePoolListNew(callback) {
    // const {pools, earnContractAddress: EARN_CONTRACT_ADDRESS, aggAddress} = useBasicInfo();
    const { web3, address } = useConnectWallet();
    const tabActives = useSelector(state => state.vault.tabActive);
    let EARN_CONTRACT_ADDRESS = null
    let poolss = []
    let aggAddresss = null
    if(_IS_HECO_){
        const { earnContractAddress, pools, aggAddress} = cow_heco_info_v2();
        EARN_CONTRACT_ADDRESS = earnContractAddress
        poolss = pools
        aggAddresss = aggAddress
    }else  if(_IS_BSC_){
        const { earnContractAddress, pools, aggAddress} = cow_bsc_info_v2();
        EARN_CONTRACT_ADDRESS = earnContractAddress
        poolss = pools
        aggAddresss = aggAddress
    }else  if(_IS_ETH_){
        const { earnContractAddress, pools, aggAddress} = cow_eth_info_v2();
        EARN_CONTRACT_ADDRESS = earnContractAddress
        poolss = pools
        aggAddresss = aggAddress
    }
   
   
    // console.log(tabActives)
    // let tabActive =null
    // if(tabActives===2){
    //     tabActive=1
    // }else{
    //     tabActive=tabActives
    // }
    // console.log(aggAddress)
    let pooList = []
    pooList =poolss.map(pool => {
        return {
          ...pool,
          // 对每一个pool增加弹出框状态控制
          dialog: {
            //对话框弹出
            opened: false,
            // 提现是否打开
            widthdrawOpened: false,
            // 存入是否打开
            depositOpened: false
          }
        }
      })
    const getPoolList = useCallback(async () => {
        if(!web3 || !_IS_CURRENT_NETWORK_ID_SUPPORTED_){
            return;
        }
        const contract = new web3.eth.Contract(AggregateHubNew, aggAddresss);
        const arr = [];
        // for (let pool of pools) {
        for (let pool of pooList) {
            const poolInfo = await contract.methods.getPoolInfo(EARN_CONTRACT_ADDRESS, pool.tokenAddress, address, 1).call().catch(e => {
                // console.log(EARN_CONTRACT_ADDRESS, pool.tokenAddress, "EARN_CONTRACT_ADDRESS");
            });
            // console.log(poolInfo)
            arr.push({...pool, ...poolInfo});
           
        }
        // if(!_IS_ETH_){
            callback(arr);
        // }
        
    }, [web3, address])
    useEffect(() => {
        getPoolList()
        const timer = setInterval(() => {
            getPoolList();
        }, 5000);
        return () => clearInterval(timer)
    }, [getPoolList])
}
// 迁移数据
export function useMigratorV2Pool  (callback) {
    const { web3, address } = useConnectWallet();
    const { enqueueSnackbar } = useSnackbar();
    const {earnContractAddress: EARN_CONTRACT_ADDRESS, aggAddress: AGG_ADDRESS,migrator} = useBasicInfo();
    // const [data, setApy] = useState([]);
    async function tiv2(){
        if (!web3 || !_IS_CURRENT_NETWORK_ID_SUPPORTED_) { return }
        const contract = new web3.eth.Contract(Migrator, migrator);
        if(!_IS_ETH_){
            contract.options.gas = 18600000;
        }
        return new Promise((resolve, reject) => {
            contract.methods.migratorV2Pool().send({ from: address })
                .on('transactionHash', function (hash) {
                    resolve(hash);
                })
                .on('receipt', function (receipt) {
                    resolve(receipt)
                })
                .on('error', function (error) {
                    reject(error)
                })
                .catch((error) => {
                    reject(error)
                })
        })
    }
        // setApy(data);
        // setOne(data)
        // callback(data);

    // useEffect(() => {
    //     if (web3 && address) {
    //         fetchApy()
    //     }
    //     let refreshInterval = setInterval(fetchApy, 10000)
    //     return () => clearInterval(refreshInterval)
    // }, [web3, fetchApy])

    return {
        tiv2
    }
}


// 获取ctoken列表数据
export function usePoolListCtoken(callback) {
    // const {pools, earnContractAddress: EARN_CONTRACT_ADDRESS, aggAddress} = useBasicInfo();
    const { web3, address } = useConnectWallet();
    const tabActives = useSelector(state => state.vault.tabActive);
    let EARN_CONTRACT_ADDRESS = null
    let poolss = []
    let aggAddresss = null
    if(_IS_HECO_){
        const { earnContractAddress, pools, aggAddress} = cow_heco_info_ctoken();
        EARN_CONTRACT_ADDRESS = earnContractAddress
        poolss = pools
        aggAddresss = aggAddress
    }else  if(_IS_BSC_){
        const { earnContractAddress, pools, aggAddress} = cow_bsc_info_ctoken();
        EARN_CONTRACT_ADDRESS = earnContractAddress
        poolss = pools
        aggAddresss = aggAddress
    }else  if(_IS_ETH_){
        const { earnContractAddress, pools, aggAddress} = cow_eth_info_v2();
        EARN_CONTRACT_ADDRESS = earnContractAddress
        poolss = pools
        aggAddresss = aggAddress
    }
   
   
    // console.log(tabActives)
    // let tabActive =null
    // if(tabActives===2){
    //     tabActive=1
    // }else{
    //     tabActive=tabActives
    // }
    // console.log(aggAddress)
    let pooList = []
    pooList =poolss.map(pool => {
        return {
          ...pool,
          // 对每一个pool增加弹出框状态控制
          dialog: {
            //对话框弹出
            opened: false,
            // 提现是否打开
            widthdrawOpened: false,
            // 存入是否打开
            depositOpened: false
          }
        }
      })
    const getPoolList = useCallback(async () => {
        if(!web3 || !_IS_CURRENT_NETWORK_ID_SUPPORTED_){
            return;
        }
        const contract = new web3.eth.Contract(AggregateHubCtoken, aggAddresss);
        const arr = [];
        // for (let pool of pools) {
        for (let pool of pooList) {
            const poolInfo = await contract.methods.getPoolInfoOfCToken(EARN_CONTRACT_ADDRESS, pool.tokenAddress, address, 1,1).call().catch(e => {
                console.log(e);
            });
            // console.log(poolInfo)
            arr.push({...pool, ...poolInfo});
           
        }
        // console.log(arr)
        // if(!_IS_ETH_){
            callback(arr);
        // }
        
    }, [web3, address])
    useEffect(() => {
        getPoolList()
        const timer = setInterval(() => {
            getPoolList();
        }, 5000);
        return () => clearInterval(timer)
    }, [getPoolList])
}
// 判断用户是否需要领取ctoken
export function useCtoken(callback) {
    const { web3, address } = useConnectWallet();
    const tabActives = useSelector(state => state.vault.tabActive);
    const {earnContractAddress: EARN_CONTRACT_ADDRESS, aggAddress: AGG_ADDRESS,cTokenMigrator} = useBasicInfo();
    const fetchApy = useCallback(async () => {
        if (!web3 || !_IS_CURRENT_NETWORK_ID_SUPPORTED_) { return }
        const contract = new web3.eth.Contract(Migrator, cTokenMigrator);
        if(!_IS_ETH_){
            contract.options.gas = 18600000;
        }
        const data = await contract.methods.needMigrator(address).call().catch(e => console.log(e))
        callback(data);
        // return data
    }, [web3,address])

    useEffect(() => {
        if (web3 && address) {
            fetchApy()
        }
    }, [fetchApy])
    // return {
    //     fetchApy
    // }
}
// ctoken领取
export function useMigratorV2PoolCtoekn  (callback) {
    const { web3, address } = useConnectWallet();
    const { enqueueSnackbar } = useSnackbar();
    const {earnContractAddress: EARN_CONTRACT_ADDRESS, aggAddress: AGG_ADDRESS,migrator,cTokenMigrator} = useBasicInfo();
    // const [data, setApy] = useState([]);
    async function tivCtoken(){
        if (!web3 || !_IS_CURRENT_NETWORK_ID_SUPPORTED_) { return }
        const contract = new web3.eth.Contract(Migrator, cTokenMigrator);
        if(!_IS_ETH_){
            contract.options.gas = 18600000;
        }
        return new Promise((resolve, reject) => {
            contract.methods.migratorV2Pool().send({ from: address })
                .on('transactionHash', function (hash) {
                    resolve(hash);
                })
                .on('receipt', function (receipt) {
                    resolve(receipt)
                })
                .on('error', function (error) {
                    reject(error)
                })
                .catch((error) => {
                    reject(error)
                })
        })
    }
    return {
        tivCtoken
    }
}
// 判断用户是否领取ctoken
export function useCtokenLing(callback) {
    const { web3, address } = useConnectWallet();
    const tabActives = useSelector(state => state.vault.tabActive);
    const {earnContractAddress: EARN_CONTRACT_ADDRESS, aggAddress: AGG_ADDRESS,cTokenMigrator} = useBasicInfo();
    const fetchApy = useCallback(async () => {
        if (!web3 || !_IS_CURRENT_NETWORK_ID_SUPPORTED_) { return }
        const contract = new web3.eth.Contract(Migrator, cTokenMigrator);
        if(!_IS_ETH_){
            contract.options.gas = 18600000;
        }
        const data = await contract.methods.getUerMigratorBlock(address).call().catch(e => console.log(e))
        callback(data);
    }, [web3,address])

    useEffect(() => {
        if (web3 && address) {
            fetchApy()
        }
    }, [fetchApy])
}
//获取领取ctoken弹框下拉
export function usePoolLists(callback) {
    const {pools, earnContractAddress: EARN_CONTRACT_ADDRESS, aggAddress} = cow_heco_info();
    const { web3, address } = useConnectWallet();
    const tabActive = useSelector(state => state.vault.tabActive);
    const getPoolList = useCallback(async () => {
        if(!web3 || !_IS_CURRENT_NETWORK_ID_SUPPORTED_){
            return;
        }
        const contract = new web3.eth.Contract(AggregateHubNew, aggAddress);
        const arr = [];
        // console.log(EARN_CONTRACT_ADDRESS)
        for (let pool of pools) {
            const poolInfo = await contract.methods.getPoolInfo(EARN_CONTRACT_ADDRESS, pool.tokenAddress, address, tabActive).call().catch(e => {
                console.log(e);
            });
        //    console.log(pool,poolInfo)
            arr.push({...pool, ...poolInfo});
           
        }
        callback(arr);
    }, [web3, address, tabActive])
    useEffect(() => {
        getPoolList()
        const timer = setInterval(() => {
            getPoolList();
        }, 5000);
        return () => clearInterval(timer)
    }, [getPoolList])
}

// 获取ctoken单币列表数据
export function usePoolListCtokenVault(callback) {
    // const {pools, earnContractAddress: EARN_CONTRACT_ADDRESS, aggAddress} = useBasicInfo();
    const { web3, address } = useConnectWallet();
    const tabActives = useSelector(state => state.vault.tabActive);
    let EARN_CONTRACT_ADDRESS = null
    let poolss = []
    let aggAddresss = null
    if(_IS_HECO_){
        const { earnContractAddress, pools, aggAddress} = cow_heco_info_ctokenVault();
        EARN_CONTRACT_ADDRESS = earnContractAddress
        poolss = pools
        aggAddresss = aggAddress
    }else if(_IS_BSC_){
        const { earnContractAddress, pools, aggAddress} = cow_bsc_info_ctokenVault();
        EARN_CONTRACT_ADDRESS = earnContractAddress
        poolss = pools
        aggAddresss = aggAddress
    }
    let pooList = []
    pooList =poolss.map(pool => {
        return {
          ...pool,
          // 对每一个pool增加弹出框状态控制
          dialog: {
            //对话框弹出
            opened: false,
            // 提现是否打开
            widthdrawOpened: false,
            // 存入是否打开
            depositOpened: false
          }
        }
      })
    const getPoolList = useCallback(async () => {
        if(!web3 || !_IS_CURRENT_NETWORK_ID_SUPPORTED_){
            return;
        }
        const contract = new web3.eth.Contract(AggregateHubNew, aggAddresss);
        const arr = [];
        // for (let pool of pools) {
        for (let pool of pooList) {
            const poolInfo = await contract.methods.getPoolInfo(EARN_CONTRACT_ADDRESS, pool.tokenAddress, address, 1).call().catch(e => {
                // console.log(EARN_CONTRACT_ADDRESS, pool.tokenAddress, "EARN_CONTRACT_ADDRESS");
            });
            // console.log(poolInfo)
            arr.push({...pool, ...poolInfo});
        }
            callback(arr);
        // }
        
    }, [web3, address])
    useEffect(() => {
        getPoolList()
        const timer = setInterval(() => {
            getPoolList();
        }, 5000);
        return () => clearInterval(timer)
    }, [getPoolList])
}
// 所有币种汇率
export function useAllRateCtokenVault(isLp) {
    const { web3 } = useConnectWallet();
    let poolss = []
    let aggAddresss = null
    let MdxAddresss = null
    let cowAddresss = null
    if(_IS_HECO_){
        const {pools, aggAddress, MdxAddress,cowAddress} = cow_heco_info_ctokenVault();
        poolss =pools
        aggAddresss = aggAddress
        MdxAddresss =MdxAddress
        cowAddresss =cowAddress
    }else if(_IS_BSC_){
        const {pools, aggAddress, MdxAddress,cowAddress} = cow_bsc_info_ctokenVault();
        poolss =pools
        aggAddresss = aggAddress
        MdxAddresss =MdxAddress
        cowAddresss =cowAddress
    }
    const [poolRateCtokenVault, setPoolRate] = useState(false);
    const [coinRateCtokenVault, setCoinRate] = useState({});
    const tabActive = useSelector(state => state.vault.tabActive);

    const fetchRate = useCallback(async () => {
        if (!web3 || !_IS_CURRENT_NETWORK_ID_SUPPORTED_) { return }
        const contract = new web3.eth.Contract(AggregateHub, aggAddresss);
        // mdx兑usdt的价格
        const mdxUsdtRate = await contract.methods.getUsdtRate(_IS_ETH_?cowAddresss:MdxAddresss, _IS_BSC_ ? 1 : 0, false).call().catch(0);
        if(mdxUsdtRate <= 0){
            return;
        }
        let returnRate = poolRateCtokenVault || {};
        let returnCoinRate = coinRateCtokenVault || {};
        //getTokenUsdtRate 接口返回的汇率放大了1e18倍
        // returnRate[MDXAddress] = mdxUsdtRate/1e18;
        poolss.forEach(async pool => {
            const rate = await contract.methods.getUsdtRate(pool.tokenAddress, _IS_BSC_ ? 1 : 0, isLp).call().catch(0);
            if(rate <= 0){
                return;
            }
            //token兑换mdx的价格, 比如一个btc兑换usdt的价格
            const tokenMdxRate = rate/mdxUsdtRate;
            returnRate[pool.tokenAddress] = tokenMdxRate;
            returnCoinRate[pool.tokenAddress] = rate;
        })
        setPoolRate(returnRate);
        setCoinRate(returnCoinRate);
    }, [web3, isLp, tabActive])

    useEffect(() => {
        fetchRate();
        let refreshInterval = setInterval(fetchRate, 300000);
        return () => clearInterval(refreshInterval);
    }, [fetchRate])

    return {poolRateCtokenVault, coinRateCtokenVault}
}