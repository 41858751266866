import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Hidden from "@material-ui/core/Hidden";
// 多语言
import { useTranslation } from 'react-i18next';
// nodejs library to set properties for components
// import PropTypes from "prop-types";
// core components
import styles from "assets/jss/components/headerStyle.js";
const useStyles = makeStyles(styles);

export default function Header(props) {
  // const [mobileOpen, setMobileOpen] = useState(false);
  const { links } = props;
  const classes = useStyles();
  const { t } = useTranslation();
  // 当前域名 logo展示判断依据
  // const domain = window.location.hostname.split('.')[window.location.hostname.split('.').length-1]
  return (
    <div className={classes.headerContainer}>
      <div className="header__wrapper-box">
        {/* pc */}
        <Hidden smDown implementation="css">
          <div className={classes.headerWrapper}>
            <div className={classes.headerLeft}>
              {/* <Link to="/" className={classes[domain==='pro'?'div':'logoCom']}>{brand}</Link> */}
              <a href="/" className={classes.logoCom}><img src={require('../../images/logo.svg')} alt=""/></a>
              {/* <div className={classes.headerNotice} title={t('nav-tip')}>
                <img src={require('../../assets/img/icon-gonggao.svg')} alt=""/>
                <p>{t('nav-tip')}</p>
              </div> */}
            </div>
              <div className={classes.headerRight}>
                {links}
              </div>
          </div>
        </Hidden>
        {/* h5 */}
        <Hidden mdUp implementation="css">
          <div className={classes.headerWrapper}>
            <div className={classes.headerLeft}>
              <a href="/" className={classes.logoCom}><img src={require('../../images/logo.svg')} alt=""/></a>
            </div>
              <div className={classes.headerRight}>
                {links}
              </div>
          </div>
        </Hidden>
      </div>
    </div>
  );
}
// Header.propTypes = {
//   brand: PropTypes.string,
// }
