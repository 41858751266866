import React, { memo,useState,useRef,useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
// import { useConnectWallet } from '../../home/redux/hooks';
import {useAggregate,useRepurchaseDestruction,useCountDown} from '../redux/hooks';
import { cowAddressMap, airdropAddressMap, compoundHubAdressMap,carouselBanners, _IS_ETH_, _IS_CURRENT_NETWORK_ID_SUPPORTED_ } from '../../configure/chainsParamsConfig';
import '../jss/sections/SectionTotal.css'
import BigNumber from "bignumber.js";
import {useCoinRate} from '../../vault/redux/hooks';
// 千分位格式化
function formatThousands(num) {
    return num.toString().replace(/\d+/, function (n) { // 先提取整数部分
        return n.replace(/(\d)(?=(\d{3})+$)/g, function ($1) { // 对整数部分添加分隔符
            return $1 + ",";
        });
    });
}
const forMat = (number, precision = 2) => {
    return new BigNumber(
      number
    ).multipliedBy(
      new BigNumber(10000)
    ).dividedToIntegerBy(
      new BigNumber(1)
    ).dividedBy(
      new BigNumber(10000)
    ).toNumber().toFixed(precision)
  }
export default memo(({address}) => {
  // useCountDown(pool=>{
  //   console.log(pool)
  // })
    const {dataInfo,setDataInfo} = useCountDown()
    // console.log(dataInfo)
    const [lastTime,setLastTime] = useState()
    const isFirst = useRef(true)

    useEffect(()=>{
        // if(dataInfo.length&&isFirst.current){
        //    setLastTime(dataInfo.burnSetting.averageTime)
        //     isFirst.current = false
        // }
      },[dataInfo])
    const { t } = useTranslation();
    const theme = useSelector(state => state.common.theme);
    const aggregateData = useAggregate(theme==='bsc');
    const rateAndTvl = aggregateData ? aggregateData[1]/1e18 : 0;
    const rateAndReward = aggregateData ? aggregateData[0]/1e18 : 0;
    const depositedAll = aggregateData ? aggregateData[3]/1e18 : 0;
    const allEarned = aggregateData ? aggregateData[2]/1e18 : 0;
    const [isDestruction, setIsDestruction] = useState(false);

    //回购销毁数据
    const { mulChainBurnTvl,
      mulChainBurnCowTvl,
      mulChainUnUseTvl ,
      curChainBurnTvl,
      curChainBurnCowTvl,
      curChainUnUseTvl,
      historyCow2Usdt ,
      // cow2Usdt,
      averageTime} = useRepurchaseDestruction()
       // {isNaN(cowProfit) ? 0.0000 : formatThousands((cowProfit / 1e18).toFixed(0))}
      // {isNaN(cow2Usdt) ? 0.0000 : (cow2Usdt / 1e18).toFixed(4)}
      const networkId = useSelector(state => state.home.networkId);
      const { tokenRate: cow2Usdt } = useCoinRate(cowAddressMap[networkId] ? cowAddressMap[networkId] : cowAddressMap['128']);
      // console.log(cow2Usdt,cowAddressMap,networkId)

    return <>
      <div className={`back-destroy-data`}>
        <div className={`back-destroy-data-title ${theme}-back`}>
            <div className={`back-destroy-data-title-start`}>{t('Buy-Back-Destruction')}</div>
            <div className={`back-destroy-data-title-end`} onClick={
            ()=>setIsDestruction(true)
          }>{t('Buy-Back-Rule')}>></div>
        </div>
        <div className={`back-destroy-data-content`}>
          <div className={`back-destroy-data-content-top`}>
            <ul className={`pool__total-tvl pool__total-tvl-${theme} pool__total-tvl-b b-${theme}`}>
              <li>
                <div className='li_back'>{t('Multi-Stay-Money')}</div>
                <div className='li_back'>{isNaN(mulChainUnUseTvl) ? 0.0000 :  formatThousands(forMat(mulChainUnUseTvl,2))}</div>
              </li>
              <li>
                <div className='li_back'>{t('Multi-Already-Money')}</div>
                <div className='li_back'>{isNaN(mulChainBurnTvl) ? 0.0000 :  formatThousands(forMat(mulChainBurnTvl,2))}</div>
              </li>
              <li>
                <div className='li_back'>{t('Multi-Already-Quantity')}</div>
                <div className='li_back'>{isNaN(mulChainBurnCowTvl) ? 0.0000 :   formatThousands(forMat(mulChainBurnCowTvl,2))}</div>
              </li>
              <li>
                <div className='li_back'>{t('Time-1')}({averageTime}{t('Time-3')})</div>
                <div className='li_back'>{isNaN(historyCow2Usdt) ? 0.0000 :   formatThousands(forMat(historyCow2Usdt,2))}</div>
              </li>
            </ul>
          </div>
          <div className={`back-destroy-data-content-bottom`}>
            <ul className={`pool__total-tvl pool__total-tvl-${theme} pool__total-tvl-bs`}>
              <li>
                <div>{theme==="heco"?'HECO':(theme==="bsc"?"BNB Chain":'ETH')} {t('Current-Stay-Money')}</div>
                <div>{isNaN(curChainUnUseTvl) ? 0.0000 : formatThousands(forMat(curChainUnUseTvl,2))}</div>
              </li>
              <li>
                <div>{theme==="heco"?'HECO':(theme==="bsc"?"BNB Chain":'ETH')} {t('Current-Already-Money')}</div>
                <div>{isNaN(curChainBurnTvl) ? 0.0000 : formatThousands(forMat(curChainBurnTvl,2))}</div>
              </li>
              <li>
                <div>{theme==="heco"?'HECO':(theme==="bsc"?"BNB Chain":'ETH')} {t('Current-Already-Quantit')}</div>
                <div>{isNaN(curChainBurnCowTvl) ? 0.0000 : formatThousands(forMat(curChainBurnCowTvl,2))}</div>
              </li>
              <li>
                <div>{theme==="heco"?'HECO':(theme==="bsc"?"BNB Chain":'ETH')} {t('Time-2')}</div>
                {/* <div>{isNaN(cow2Usdt) ? 0.0000 : formatThousands(forMat(cow2Usdt,4))}</div> */}
                <div>{isNaN(cow2Usdt) ? 0.0000 : (cow2Usdt / 1e18).toFixed(4)}</div>
              </li>
            </ul>
          </div>
        </div>
      </div>
      {
      isDestruction&&<div className={`destruction-dialog`}>
        <div className={`destruction-dialog-inner`}>
          <div className={`destruction_dialog_header`}>{t('Buy-Back-rule-1')}
          <span className="destruction__dialog__header__close"  onClick={() => setIsDestruction(false)}></span>
          </div>
          <div className={`destruction_dialog-capital`}>
            <div className={`destruction_dialog-capital-title`}>{t('Buy-Back-rule-2')}：</div>
            <div className={`destruction_dialog-capital-content`}>{t('Buy-Back-rule-3')}</div>
          </div>
          <div className={`destruction_dialog-rule`}>
            <div className={`destruction_dialog-rule-title`}>{t('Buy-Back-rule-4')}：</div>
            <div className={`destruction_dialog-rule-content`}>{t('Buy-Back-rule-5')}</div>
            <div className={`destruction_dialog-rule-content`}>{t('Buy-Back-rule-5-1')}</div>
            <div className={`destruction_dialog-rule-content`}>{t('Buy-Back-rule-6')}</div>
          </div>
        </div>
      </div>
    }
    </>
})
