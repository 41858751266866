import React, { useState, useEffect } from 'react';
import {
  withRouter
} from "react-router-dom";
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { isMobile } from 'features/helpers/utils';
import BigNumber from "bignumber.js";
// https://www.kaifa99.com//GitHub/article_124389
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { useConnectWallet } from './redux/hooks';
import { HubPool, CompoundHub } from 'features/configure/abi';
import { useBasicInfo } from 'features/configure/pools';
import {
  useFetchCowBalances,
  useTvlAndProfit,
  usePoolListNew,
  useAllRate as valutUseAllRate,
  useCoinRate,
  usePoolList
} from '../vault/redux/hooks';
import {usePoolDaoList} from '../daoVault/redux/hooks'
import {usePoolList as usePoolListStaking} from '../staking/redux/hooks'
import Staking from './staking/staking'
import {
  useFetchPoolBalances as lpFetchPoolBalances,
  useAllRate as lpUseAllRate
} from '../lp/redux/hooks';
import Button from "components/CustomButtons/Button.js";
// import VButton from '../../components/Button/Button'
import { makeStyles } from "@material-ui/core/styles";
import styles from "assets/jss/HomeStyle.js";
import "./HomePage.css";
import i18n from 'i18n.js';
import { cowAddressMap, airdropAddressMap, compoundHubAdressMap,carouselBanners, _IS_ETH_, _IS_CURRENT_NETWORK_ID_SUPPORTED_,_IS_HECO_ } from '../configure/chainsParamsConfig';
const useStyles = makeStyles(styles);
const forMat = (number, precision = 2) => {
  return new BigNumber(
    number
  ).multipliedBy(
    new BigNumber(10000)
  ).dividedToIntegerBy(
    new BigNumber(1)
  ).dividedBy(
    new BigNumber(10000)
  ).toNumber().toFixed(precision)
}
// 千分位格式化
function formatThousands(num) {
  return num.toString().replace(/\d+/, function (n) { // 先提取整数部分
    return n.replace(/(\d)(?=(\d{3})+$)/g, function ($1) { // 对整数部分添加分隔符
      return $1 + ",";
    });
  });
}
// 合作伙伴数据
const partnerList = [
  require("../../images/MDEX.png"),
  require("../../images/WhatsCion LOGO.svg"),
  require("../../images/huobi wallet logo.svg"),
  require("../../images/Coinhub-logo.svg"),
  require("../../images/king_logo.svg"),
  require("../../images/math.svg"),
  require("../../images/TokenPocket_Logo__ traverse.svg"),
  require("../../images/bitkeep.png"),
  require("../../images/HyperPay-Logo.svg"),
  require("../../images/ONTO-black.svg"),
  require("../../images/aolink.svg"),
  require("../../images/codebank.svg"),
]
// const pic = {
//   "zh-heco-pc":require("../../assets/img/web/home/banner-home-heco-zhong.png"),
//   "en-heco-pc":require("../../assets/img/web/home/banner-home-heco-english.png"),
//   "zh-heco-h5":require("../../assets/img/app/home/banner-home-heco-zhong.png"),
//   "en-heco-h5":require("../../assets/img/app/home/banner-home-heco-english.png"),

//   "zh-bsc-pc":require("../../assets/img/web/home/banner-home-bsc-zhong.png"),
//   "en-bsc-pc":require("../../assets/img/web/home/banner-home-bsc-english.png"),
//   "zh-bsc-h5":require("../../assets/img/app/home/banner-home-bsc-zhong.png"),
//   "en-bsc-h5":require("../../assets/img/app/home/banner-home-bsc-english.png"),

//   "zh-eth-pc":require("../../assets/img/web/home/banner-home-eth-zhong.png"),
//   "en-eth-pc":require("../../assets/img/web/home/banner-home-eth-english.png"),
//   "zh-eth-h5":require("../../assets/img/app/home/banner-home-eth-zhong.png"),
//   "en-eth-h5":require("../../assets/img/app/home/banner-home-eth-english.png"),

//   "zh-oec-pc":require("../../assets/img/web/home/banner-home-oec-zhong.png"),
//   "en-oec-pc":require("../../assets/img/web/home/banner-home-oec-english.png"),
//   "zh-oec-h5":require("../../assets/img/app/home/banner-home-oec-zhong.png"),
//   "en-oec-h5":require("../../assets/img/app/home/banner-home-oec-english.png"),
// }
export default withRouter(function HomePage({ history }) {
  const theme = useSelector(state => state.common.theme);
  const networkId = useSelector(state => state.home.networkId);
  // const [shortAddress, setShortAddress] = useState('');
  const { t } = useTranslation();
  const { web3, address } = useConnectWallet();
  const { tvl, profit, cowProfit,daoRewardUsdt,daoBurnCow } = useTvlAndProfit();
  const classes = useStyles();
  const isH5 = isMobile() ? 'h5' : 'pc';
  const lang = (i18n.language === 'zh-CN' || i18n.language === 'zh') ? 'zh' : 'en';
  const { pools } = useBasicInfo();
  const [vaultPools, setVaultPools] = useState([]);
  // cow复利 取 cow策略下 cow池子cow单利字段
  const [cowCompoundApy, setCowCompoundApy] = useState("");
  const _CURRENT_NETWORK_ID_ = Number(window.localStorage.getItem("NETWORK_ID_USE"));
  useEffect(() => {
    const temp = [];
    for (let pool of pools) {
      const poolInfo = {
        token: pool.tokenAddress,
        amount: 0,
        balance: 0,
        allowance: 0,
        mdxApy: 0,
        cowApy: 0,
        mdxCompoundApy: 0,
        mdxReward: 0,
        cowReward: 0,
        totalAmount: 0,
        totalAmountLimit: 0,
      }
      temp.push({ ...pool, ...poolInfo });
    }
    setVaultPools(temp);
    if(_CURRENT_NETWORK_ID_===128){
      setCowCompoundApy(temp[3].cowApy);
    }else  if(_CURRENT_NETWORK_ID_===56){
      setCowCompoundApy(temp[7].cowApy);
    }else  if(_CURRENT_NETWORK_ID_===1){
      setCowCompoundApy(temp[0].cowApy);
    }
    // setCowCompoundApy(temp[0].cowApy);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
   // 更新取列表数据
  usePoolList(pool => {
    // if(_IS_ETH_){
      setVaultPools([...pool]);
      if(_CURRENT_NETWORK_ID_===128){
        setCowCompoundApy(pool[3].cowApy);
      }else  if(_CURRENT_NETWORK_ID_===56){
        setCowCompoundApy(pool[7].cowApy);
      }else  if(_CURRENT_NETWORK_ID_===1){
        setCowCompoundApy(pool[0].cowApy);
      }
    // }
    
    // setCowCompoundApy(pool[2].cowApy);
  });
  // 更新取列表数据
  usePoolListNew(pool => {
    // if(!_IS_ETH_){
      setVaultPools([...pool]);
      if(_CURRENT_NETWORK_ID_===128){
        setCowCompoundApy(pool[7].cowApy);
      }else  if(_CURRENT_NETWORK_ID_===56){
        setCowCompoundApy(pool[10].cowApy);
      }else  if(_CURRENT_NETWORK_ID_===1){
        setCowCompoundApy(pool[0].cowApy);
      }
    // }
    
    // setCowCompoundApy(pool[2].cowApy);
  });
  const [listData, setListData] = useState([]);
  // 更新取列表数据
  // usePoolListStaking((pools) => {
  //   console.log(pools,'sdfghdsfddsfd')
  //   // setListData([...pools]);
  // });
  //获取dao单币
  usePoolDaoList(pools => {
    setListData([...pools]);
  });
  let { pools: lpPools } = lpFetchPoolBalances();
  // cow余额
  const { cowBalance } = useFetchCowBalances();
  // 取cow对U汇率 / cow兑MDX汇率
  const { tokenRate: cow2Usdt } = useCoinRate(cowAddressMap[networkId] ? cowAddressMap[networkId] : cowAddressMap['128']);
  // console.log(cow2Usdt,cowAddressMap,networkId)
  // 计算总收益
  let totalUsdProfit = (profit * 1) + (cowProfit * cow2Usdt / 1e36);
  // tab栏 数据 纯展示
  const tabList = [
    {
      text: t('Home-Tab1'),
      img: require("../../images/high.svg"),
    },
    {
      text: t('Home-Tab2'),
      img: require("../../images/peizi.svg"),
    },
    {
      text: t('Home-Tab3'),
      img: require("../../images/duichong.svg"),
    },
    {
      text: t('Home-Tab4'),
      img: require("../../images/zijin.svg"),
    },
  ]
  // 各币种兑MDX汇率 
  const { poolRate: vaultCoinRate, coinRate } = valutUseAllRate(false);
  // LP各币种兑MDX汇率
  // const { poolRate } = lpUseAllRate(true);

  // 无损 单币 年化数据
  const singlePools = vaultPools.filter(pool => pool.topHome)
  // console.log(listData)
  const singlistData = listData.filter(pool => pool.topHome)
  // LP 单币 年化数据
  const lpSinglePools = lpPools.filter(pool => pool.topHome)
  const lpApyMaps = {}
  lpSinglePools.forEach(pool => {
    lpApyMaps[pool.tokenAddress] = ''
  })
  const [lpApys, setLpApys] = useState(lpApyMaps)
  // useEffect(() => {
  //   let apyIntervalTimer = null;
  //   async function getLpPoolsApy() {
  //     if (!web3 || !_IS_CURRENT_NETWORK_ID_SUPPORTED_ || _IS_ETH_) {
  //       return;
  //     }
  //     const poolsApys = {}
  //     if (!poolRate) {
  //       return;
  //     }
  //     for (let pool of lpSinglePools) {
  //       const contract = new web3.eth.Contract(HubPool, pool.earnContractAddress);
  //       const apy = await contract.methods.getApy(pool.tokenAddress).call().catch(0)
  //       // 回报率
  //       const rateMdx = poolRate[pool.tokenAddress];
  //       const depositedApy = forMat(apy / (100 * rateMdx) / (10 ** (18 - pool.tokenDecimals)))
  //       let compound = 0;
  //       let finaCompoundTotalView;
  //       const compoundHubAdress = compoundHubAdressMap[networkId];
  //       if (compoundHubAdress) {
  //         const compoundHubClient = new web3.eth.Contract(CompoundHub, compoundHubAdress);
  //         compound = await compoundHubClient.methods.getApy(pool.tokenAddress).call().catch(0)
  //       }
  //       if (compound > 0) {
  //         const depositedCompound = compound / 100;
  //         const xCompound = Math.pow((1 + (depositedCompound / 100) / 105120), 105120);
  //         finaCompoundTotalView = forMat((((depositedApy / 100) / (depositedCompound / 100)) * (xCompound - 1)) * 100);
  //       } else {
  //         finaCompoundTotalView = 0;
  //       }
  //       poolsApys[pool.tokenAddress] = finaCompoundTotalView
  //     }
  //     setLpApys(poolsApys)
  //   }
  //   getLpPoolsApy();
  //   apyIntervalTimer = window.setInterval(getLpPoolsApy, 5000)
  //   return () => {
  //     window.clearInterval(apyIntervalTimer)
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [web3, address, poolRate])
  return (
    <>
      <div className={`home__container wrapper ${classes.homeContainer}`}>
        {/* 轮播 */}
        <Carousel
          className="home__carousel"
          showArrows={false}
          swipeScrollTolerance={50}
          autoPlay={true}
          interval={5000}
          showStatus={false}
          showThumbs={false}
          emulateTouch={true}
          infiniteLoop={true}
        >
          {
            carouselBanners[theme][isH5][lang].map((banner, index) => {
              return <div className={`home__banner  home__common-container`} key={index}>
                {
                  typeof (banner) === 'string' ?
                    <img src={banner} alt="" style={{ width: "100%" }} />
                    :
                    <a href={banner.href} target="_blank" rel="noopener noreferrer">
                      <img src={banner.bannerSrc} alt="" style={{ width: "100%" }} />
                    </a>
                }
              </div>
            })
          }
        </Carousel>

        {/* Tab 纯展示 无交互 */}
        {/* {
          isH5 === "h5" ? null : <div className={`home__Tab home__common-container ${classes.homeTab}`} style={{
            display: "flex",
            alignItems: "center",
          }}>
            {
              tabList.map((item, ind) => {
                return (
                  <div className={`tab__item ${classes.tabItem} tab__item${ind}`} key={ind}>
                    <img className={classes.tabImg} src={item.img} alt="" />
                    <p className={classes.tabText}>{item.text}</p>
                  </div>
                )
              })
            }
          </div>
        } */}
        {/* <div className="section-banner"style={{margin: '0px auto 20px'}}>
          <img src={pic[`${lang}-${theme}-${isH5}`]} alt=""/>
        </div> */}
        {/* 收益 */}
        <div className={`${classes.homeProfit} home__common-container`}>
          <div className={classes.profit}>
            <div className={`${classes.profitItem} ${classes.profitItemLeft} ${theme}-home_li`}>
              <div className={`${classes.profitLeftContent} profit__left-content `}>

                {/* <p className={classes.profitTitle}>{t('Total-lock-in-total')}($)</p> */}
                <div className='icon-li'>
                  <i className={`home-icon-ask`}></i>
                  <p className={`${classes.profitTitle} ${theme}-home_li-span`}>{t('Multi-value')}</p>
                </div>
                <p className={`${classes.profitText} ${theme}-span ${theme}-title`}>{tvl ? formatThousands(forMat(tvl, 2)) : 0}</p>
              </div>
            </div>
            <div className={`${classes.profitItem}  ${theme}-home_li`}>
              <div className={`${classes.profitLeftContent} profit__left-content`}>
                {/* <p className={classes.profitTitle}>{t('Total-profit')}($)</p> */}
                <div className='icon-li'>
                  <i className={`home-icon-asks`}></i>
                  <p className={`${classes.profitTitle} ${theme}-home_li-span`}>{t('Home-all')}</p>
                </div>
                <p className={`${classes.profitText} ${theme}-span ${theme}-title`}>{isNaN(totalUsdProfit * 1) ? 0 : formatThousands(forMat(totalUsdProfit, 2))}</p>
              </div>
            </div>
          </div>
          {/* 平台币 */}
          <div className="drop__info">
            <div className="drop__info-item">
              <p className="drop__info-item-title">{t('Cow-Price')}</p>
              <p className="drop__info-item-text">{isNaN(cow2Usdt) ? 0.0000 : (cow2Usdt / 1e18).toFixed(4)}</p>
            </div>
            <div className="drop__info-item">
              <p className="drop__info-item-title">
                {t('Home-balance')}
                {isH5 === "h5" ? <span className={`${theme}-span`} onClick={() => window.open(airdropAddressMap[theme])}>{t("Cow-Access")}</span> : null}
              </p>
              <p className="drop__info-item-text">
                {formatThousands(cowBalance.toFixed(2))}
                {isH5 === "pc" ? <span className={`${theme}-span`} onClick={() => window.open(airdropAddressMap[theme])}>{t("Cow-Access")}</span> : null}
              </p>
            </div>
            <div className="drop__info-item">
              <p className="drop__info-item-title">{t('Cow-Volume')}</p>
              <p className="drop__info-item-text">
                {isNaN(cowProfit) ? 0.0000 : formatThousands((cowProfit / 1e18).toFixed(0))}
              </p>
            </div>
            <div className="drop__info-item">
              {/* <p className="drop__info-item-title">{t('Cow-Market')}</p> */}
              <p className="drop__info-item-title">{t('Home-Out-Value')}</p>
              <p className="drop__info-item-text">{isNaN(cowProfit) || isNaN(cow2Usdt) ? 0.0000 : formatThousands(((cowProfit / 1e18) * (cow2Usdt / 1e18)).toFixed(0))}</p>
            </div>
            <div className="drop__info-item">
              <p className="drop__info-item-title">{t('Multi-Bonus')}</p>
              <p className="drop__info-item-text">{isNaN(daoRewardUsdt) ? 0.0000 : formatThousands(forMat(daoRewardUsdt,2))}</p>
            </div>
            <div className="drop__info-item">
              <p className="drop__info-item-title">{t('Multi-Destruction-Quantity')}</p>
              <p className="drop__info-item-text">{isNaN(daoBurnCow) ? 0.0000 :  formatThousands(forMat(daoBurnCow,2))}</p>
            </div>
          </div>
        </div>
        {/* 项目展示 */}
        <div className={classes.homeProject}>
          <div className={`${classes.homeProjectItem} ${classes.homeProjectItemSingle}`}>
            <div className={classes.projectWraper}>
              <div className={classes.projectHeader}>
                <p className={classes.projectTitle}>{t('Home-projectTitle1')}</p>
                <p className={classes.projectMore}>
                  <span className={classes.projectMoreText} onClick={() => {
                    history.push('/vault')
                  }}>{t('notice-more')}</span>
                  <img src={require('../../images/icon-more.svg')} alt="" />
                </p>
              </div>
              <div className={classes.projectContainer}>
                {singlePools.map((pool) => {
                  // COW策略====>收益率 mdx + mdx复利 + cow
                  const rateMdx = vaultCoinRate[pool.tokenAddress];
                  const mdxApy = (pool.mdxApy / (100 * rateMdx)) / (10 ** (18 - pool.tokenDecimals));
                  // 计算cow与质押币种的汇率
                  const cow2Token = cow2Usdt / coinRate[pool.tokenAddress];
                  const cowApy = (pool.cowApy * cow2Token / (100)) / (10 ** (18 - pool.tokenDecimals));
                  const depositedMdxApy = forMat(mdxApy);
                  const depositedCowApy = forMat(cowApy);
                  // 复利 R1/R2*((1+R2/m)^m-1)
                  let finaCompound; // 复利新增 =  复利 - 单利
                  if (pool.mdxCompoundApy <= 0) {
                    pool.mdxCompoundApy = pool.mdxCoinwindApy;
                  }
                  if (pool.mdxCompoundApy > 0 && rateMdx) {
                    const depositedCompound = pool.mdxCompoundApy / 100;
                    const xCompound = Math.pow((1 + (depositedMdxApy / 100) / 105120), 105120);
                    finaCompound = forMat(((((mdxApy / 100) / (depositedMdxApy / 100)) * (xCompound - 1)) * 100) - mdxApy);
                    // console.log(finaCompound)
                    // finaCompound = forMat(((((mdxApy / 100) / (depositedCompound / 100)) * (xCompound - 1)) * 100) - mdxApy);
                  } else {
                    finaCompound = 0;
                  }
                  // COW 复利
                  let finaCowCompound;
                  if (cowCompoundApy > 0) {
                    const depositedCompound = cowCompoundApy / 100;
                    const xCompound = Math.pow((1 + (depositedCompound / 100) / 105120), 105120);
                    finaCowCompound = forMat(((((cowApy / 100) / (depositedCompound / 100)) * (xCompound - 1)) * 100) - cowApy);
                  } else {
                    finaCowCompound = 0
                  }
                  // COW策略====>收益率 mdx + mdx复利 + cow
                  // 0.00 10.72 0.00 3.31
                  // 0.01 10.93 -0.01 3.37
                  // 0.40 51.57 -0.40 15.91
                  // console.log(depositedMdxApy,depositedCowApy,finaCompound,finaCowCompound)
                  const cowApyView =((isNaN(depositedMdxApy)?'0':depositedMdxApy) * 1 + (isNaN(depositedCowApy)?'0':depositedCowApy) * 1 + (isNaN(finaCompound)?'0':finaCompound) * 1 + (isNaN(finaCowCompound)?'0':finaCowCompound) * 1).toFixed(2);
                  
                  return (
                    <div className={classes.projectItem} key={pool.name}>
                      <div className={classes.projectCoin}>
                        <img className={classes.projectCoinImg} src={require(`../../images/icon2/${pool.name}.svg`)} alt="" />
                        <span className={classes.projectCoinText}>{pool.name}</span>
                      </div>
                      <div className={classes.projectRight}>
                        <div className={`${classes.projectApy} project__apy`}>
                          {/* <i>{t('Home-Project-Text')}</i> */}
                          <span>{!isNaN(cowApyView) ? cowApyView : '--'}%</span>
                        </div>
                        <div className={classes.projectButton}>
                          <Button
                            className={classes.projectLink}
                            
                            style={{
                              "textTransform":'none'
                            }}
                            round
                            type="button"
                            color="primary"
                            onClick={() => {
                              history.push('/vault')
                            }}
                          >
                            {t('Home-Project-Button')}
                          </Button>
                        </div>
                      </div>
                    </div>
                  )
                })}
              </div>
            </div>
          </div>
          {
            _IS_ETH_ && isH5==='h5' ? null : <div className={classes.homeProjectItem}>
              {
                _IS_ETH_ ?
                <div className="project_empty">{t("commingsoon")}</div>:<Staking></Staking>
              }
            </div>
          }
          {/* <div className={`${classes.homeProjectItem} ${classes.homeProjectItemSingle}`}>
            <Staking></Staking>
            
          </div> */}
          {/* {
            _IS_ETH_ && isH5==='h5' ? null :
              <div className={classes.homeProjectItem}>
                {
                  _IS_ETH_ ?
                    <div className="project_empty">{t("commingsoon")}</div>
                    : <div className={classes.projectWraper}>
                      <div className={classes.projectHeader}>
                        <p className={classes.projectTitle}>{t('Home-projectTitle2')}</p>
                        <p className={classes.projectMore}>
                          <span className={classes.projectMoreText} onClick={() => {
                            history.push('/lp')
                          }}>{t('notice-more')}</span>
                          <img src={require('../../images/icon-more.svg')} alt="" />
                        </p>
                      </div>
                      <div className={classes.projectContainer}>
                        {lpSinglePools.map((pool, index) => {
                          return (
                            <div className={classes.projectItem} key={pool.token}>
                              <div className={classes.projectCoin}>
                                <img className={`${classes.projectCoinImg} ${classes.projectCoinImgLp}`} src={require(`../../images/icon2/${pool.tokenOne}.svg`)} alt="" />
                                <img className={`${classes.projectCoinImg} ${classes.projectCoinImgLp}`} src={require(`../../images/icon2/${pool.tokenTwo}.svg`)} alt="" />
                                <span className={`${classes.projectCoinText} ${classes.projectCoinTextLp}`}>{pool.name}</span>
                              </div>
                              <div className={classes.projectRight}>
                                <div className={`home__project-pay ${classes.projectApy} ${classes.projectApySecond} project__apy`}>
                                  <i>{t('Home-Project-Text')}</i>
                                  <span>{!isNaN(lpApys[pool.tokenAddress]) ? lpApys[pool.tokenAddress] : '--'}%</span>
                                </div>
                                <div className={classes.projectButton}>
                                  <Button
                                    className={classes.projectLink}
                                    round
                                    type="button"
                                    color="primary"
                                    onClick={() => {
                                      history.push('/lp')
                                    }}
                                  >
                                    {t('Home-Project-Button')}
                                  </Button>
                                </div>
                              </div>
                            </div>
                          )
                        })}
                      </div>
                    </div>
                }
              </div>
          } */}
        </div>
        {/* 审计 */}
        <div className={`${classes.homeAudit} home__common-container`} style={{
          width: "100%",
          padding: "32px 80px 48px",
          background: "rgba(255,255,255,0.80)",
          border: "1px solid #FFFFFF",
          borderRadius: "16px",
          boxSizing: "border-box",
        }}>
          <p className={classes.auditTitle}>{t('Home-Audit')}</p>
          <div className={classes.auditImg}>
            <img className={`${classes.auditImgOne} ${classes.auditImgItem}`} src={require("images/logo-lingzonganquan.svg")} alt="" />
            <img className={classes.auditImgItem} src={require("images/logo-chengdulianan.svg")} alt="" />
          </div>
        </div>
        {/* 合作伙伴 */}
        <div className={`home__partner home__common-container ${classes.homePartner}`}>
          <p className={classes.auditTitle}>{t('Home-Partner')}</p>
          <div className={classes.partnerImgContainer}>
            {
              partnerList.map((item, ind) => {
                return (
                  <div className={classes.partnerImgItem} key={ind}>
                    <img src={item} alt="" />
                  </div>
                )
              })
            }
          </div>
        </div>
      </div>
    </>
  )

})